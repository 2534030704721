import { FSA, DashboardState } from "../constants";
import { SET_DASHBOARD_DATA } from "../actions/dashboard";

const defaultState = {
  template: "",
  config: {
    linkTitle: "",
    title: "",
    route: "",
    source: "",
    data: {},
  },
};

export default (
  state: DashboardState = defaultState,
  { type, payload }: FSA
) => {
  switch (type) {
    case SET_DASHBOARD_DATA: {
      return {
        template: payload.template,
        config: payload.config,
      };
    }
    default:
      return state;
  }
};
