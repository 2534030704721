import React, { useState, useEffect } from 'react';
// @ts-ignore
import { useDispatch, useSelector, useStore } from 'react-redux';
import MaterialTable from 'material-table';
import { format } from 'date-fns';
import { push } from 'connected-react-router';

import { theme } from '../styles';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemText } from '@material-ui/core';
import List from '@material-ui/core/List';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

import { getRawListEntriesByOptions, getDefaultNewEntryData } from '../reduxMiddleware/listEntries';
import { getFormElementsByForm } from '../reduxMiddleware/form';
import { getPath } from '../reduxMiddleware/routing';
import { deleteEntry, save } from '../actions/entries';

import { execValidations } from '../utility/validation';
import { sortBySequence, getInlineVariableKeys } from '../utility/helpers';
import { sortList } from '../utility/sorting';

import DatePicker from './form/DatePicker';
import Input from './form/Input';
import Checkbox from './form/Checkbox';
import DateTimePicker from './form/DateTimePicker';
import Radio from './form/Radio';
import Textarea from './form/Textarea';
import Select from './form/Select';
import CrossSelect from './form/CrossSelect';
import { getEntriesByForm } from '../selectors/entries';
import { Defaults, FormType, State } from '../constants';
import { getDefaultValuesByFormAndSource } from '../selectors/defaultValues';

// @ts-ignore
const useStyles = makeStyles(() => ({
    arrow: {
        fontSize: 20,
        color: theme.colors.lightGray,
    },
    grid: {
        width: '100%',
    },
    list: {
        margin: '0 -10px',
        padding: 0,
    },
    listAndFormError: {
        marginTop: '-5px',
        paddingBottom: '4px',
    },
    tableContainer: {
        '& .MuiTable-root': {
            marginBottom: '300px',
        },
    },
}));

const localization = {
    body: {
        emptyDataSourceMessage: 'Geen ingevulde formulieren beschikbaar',
        addTooltip: 'Voeg toe',
        deleteTooltip: 'Verwijder',
        editTooltip: 'Bewerken',
        editRow: {
            deleteText: 'Weet u zeker dat u de wijzigingen in dit formulier wilt wissen en alle gekoppelde documenten wilt verwijderen? Dit kan niet worden herstelt!',
            cancelTooltip: 'Annuleren',
            saveTooltip: 'Opslaan',
        },
    },
    toolbar: {
        searchTooltip: 'Zoeken',
        searchPlaceholder: 'Zoeken',
    },
};

const getErrors = (errors: any, elementName: string, id: string) => {
    if (errors.id) {
        if ((!id && errors.id === Defaults.NEW_ENTRY_ID) || id === errors.id) {
            return errors[elementName];
        }
    }

    return undefined;
}

interface Table {
    form: string;
    childForm: string;
    entryIds?: string[];
    groupId?: string;
    addition?: string;
    matchFields: string[];
}
const Table = ({ form, entryIds, groupId, childForm, addition, matchFields }: Table) => {
    const [errors, setErrors] = useState({});
    const classes = useStyles();
    const dispatch = useDispatch();
    const entries = getRawListEntriesByOptions({ form, entryIds, groupId });
    const elements = getFormElementsByForm({ form });
    const emptyEntry = getDefaultNewEntryData(childForm);
    const path = getPath();
    const defaultValueSources: any = {};

    const columns = elements
        .sort(sortBySequence)
        .map((element: any, index: number) => {
            let defaultKey: string;
            let defaultHandle: string;

            if (element.defaultValue) {
                const variableKeys = getInlineVariableKeys(element.defaultValue);
                if (variableKeys && variableKeys[0].includes('.')) {
                    const [key, handle] = variableKeys[0].split('.');
                    defaultKey = key;
                    defaultHandle = handle;
                    defaultValueSources[key] = useSelector((state: State) => getEntriesByForm(state, { form: key }));
                }
            }

            let editComponent;
            let render = (rowData: any) => {
                if (
                    rowData[element.name]
                    && typeof rowData[element.name] === 'object'
                    && rowData[element.name].value
                    && typeof rowData[element.name].value !== 'object'
                ) {
                    return (<span style={{ whiteSpace: 'pre-wrap' }}>{rowData[element.name].value}</span>);
                }

                return <span></span>;
            };

            switch (element.type) {
                case 'radio':
                    editComponent = (props: any) => {
                        let value = props.value;
                        if (defaultKey && !value && props.rowData[defaultKey]) {
                            const entryId = props.rowData[defaultKey].value;
                            value = defaultValueSources[defaultKey][entryId][defaultHandle];
                        }

                        return (
                            // @ts-ignore
                            <Radio
                                value={value && value.value}
                                handleChange={(event: any) => props.onChange({ value: event.target.value })}
                                errors={getErrors(errors, element.name, props.rowData.id)}
                                name={element.name}
                            />
                        );
                    };
                    break;
                case 'text':
                case 'number':
                case 'time':
                case 'email':
                    editComponent = (props: any) => {
                        let value = props.value;
                        if (defaultKey && !value && props.rowData[defaultKey]) {
                            const entryId = props.rowData[defaultKey].value;
                            value = defaultValueSources[defaultKey][entryId][defaultHandle];
                        }

                        return (
                            // @ts-ignore
                            <Input
                                form={form}
                                type={element.type}
                                value={value && value.value}
                                handleChange={(event: any) => props.onChange({ value: event.target.value })}
                                errors={getErrors(errors, element.name, props.rowData.id)}
                                pattern={element.pattern}
                                title={element.title}
                                name={element.name}
                                disableUnderline={false}
                            />
                        );
                    };
                    break;
                case 'date':
                    editComponent = (props: any) => {
                        let value = props.value;
                        if (defaultKey && !value && props.rowData[defaultKey]) {
                            const entryId = props.rowData[defaultKey].value;
                            value = defaultValueSources[defaultKey][entryId][defaultHandle];
                        }

                        return (
                            <DatePicker
                                value={value && value.value}
                                handleChange={(event: any) => props.onChange({ value: event.target.value })}
                                errors={getErrors(errors, element.name, props.rowData.id)}
                                name={element.name}
                                disableUnderline={false}
                            />
                        );
                    };
                    render = (rowData: any) => {
                        if (rowData[element.name]) {
                            return (<span>{format(new Date(rowData[element.name].value), 'dd-MM-yyyy')}</span>);
                        }

                        return <span></span>;
                    };
                    break;
                case 'dateTime':
                    editComponent = (props: any) => {
                        let value = props.value;
                        if (defaultKey && !value && props.rowData[defaultKey]) {
                            const entryId = props.rowData[defaultKey].value;
                            value = defaultValueSources[defaultKey][entryId][defaultHandle];
                        }

                        return (
                            <DateTimePicker
                                value={value && value.value}
                                handleChange={(event: any) => props.onChange({ value: event.target.value })}
                                errors={getErrors(errors, element.name, props.rowData.id)}
                                name={element.name}
                                disableUnderline={false}
                            />
                        );
                    };
                    render = (rowData: any) => {
                        if (rowData[element.name]) {
                            return (<span>{format(new Date(rowData[element.name].value), 'dd-MM-yyyy HH:mm')}</span>);
                        }

                        return <span></span>;
                    };
                    break;
                case 'checkbox':
                    editComponent = (props: any) => {
                        let value = props.value;
                        if (defaultKey && !value && props.rowData[defaultKey]) {
                            const entryId = props.rowData[defaultKey].value;
                            value = defaultValueSources[defaultKey][entryId][defaultHandle];
                        }

                        return (
                            <Checkbox
                                value={value && value.value === 'true'}
                                handleChange={(event: any) => props.onChange({ value: event.target.value })}
                                errors={getErrors(errors, element.name, props.rowData.id)}
                                name={element.name}
                            />
                        );
                    };
                    render = (rowData: any) => {
                        if (rowData[element.name] && rowData[element.name].value === 'true') {
                            return (<span>Ja</span>);
                        }


                        return (<span>Nee</span>);
                    }
                    break;
                case 'textarea':
                    editComponent = (props: any) => {
                        let value = props.value;
                        if (defaultKey && !value && props.rowData[defaultKey]) {
                            const entryId = props.rowData[defaultKey].value;
                            value = defaultValueSources[defaultKey][entryId][defaultHandle];
                        }

                        return (
                            // @ts-ignore
                            <Textarea
                                form={form}
                                value={value && value.value}
                                handleChange={(event: any) => props.onChange({ value: event.target.value })}
                                errors={getErrors(errors, element.name, props.rowData.id)}
                                name={element.name}
                                disableUnderline={false}
                            />
                        );
                    };
                    break;
                case 'select':
                    editComponent = (props: any) => {
                        let value = props.value;

                        if (!value) {
                            value = emptyEntry[element.name];
                        }

                        if (defaultKey && !value && props.rowData[defaultKey]) {
                            const entryId = props.rowData[defaultKey].value;
                            value = { ...emptyEntry[element.name], ...defaultValueSources[defaultKey][entryId][defaultHandle] };
                        }

                        return (
                            // @ts-ignore
                            <Select
                                {...value}
                                form={form}
                                handleChange={(event: any) => props.onChange({ value: event.target.value })}
                                errors={getErrors(errors, element.name, props.rowData.id)}
                                name={element.name}
                                disableUnderline={false}
                                hideLabel={true}
                                options={element.options}
                            />
                        );
                    };
                    render = (rowData: any) => {
                        if (rowData[element.name]) {
                            const option = element.options[0].elements.find(({ value }: any) => value === rowData[element.name].value);
                            if (option) {
                                return option.label;
                            }
                        }
                        return '';
                    }
                    break;
                case 'crossSelect':
                    editComponent = (props: any) => {
                        let value = props.value;

                        if (!props.value) {
                            value = emptyEntry[element.name];
                        } else {
                            value = { ...emptyEntry[element.name], ...props.value };
                        }

                        return (
                            // @ts-ignore
                            <CrossSelect
                                handleChange={(event: any) => props.onChange({ value: event.target.value })}
                                errors={getErrors(errors, element.name, props.rowData.id)}
                                name={element.name}
                                disableUnderline={false}
                                hideLabel={true}
                                options={element.options}
                                form={childForm}
                                {...value}
                                currentForm={form}
                            />
                        );
                    };
                    render = (rowData: any) => {
                        if (rowData[element.name] && rowData[element.name].value && rowData[element.name].elements) {
                            const match = rowData[element.name].elements.find(({ value }: any) => value === rowData[element.name].value);
                            if (match) {
                                return match.label;
                            }
                        }

                        return <span></span>;
                    }
                    break;
                case FormType.FORM:
                    render = (rowData: any) => {
                        let onClick = () => {
                            if (rowData[element.name] && rowData[element.name].value) {
                                dispatch(push(`${path}/${childForm}/${rowData.id}/${element.name}/${rowData[element.name].value[0]}`));
                            } else {
                                dispatch(push(`${path}/${childForm}/${rowData.id}/${element.name}/${Defaults.NEW_ENTRY_ID}`))
                            }
                        };

                        return (
                            <List dense={true} className={classes.list}>
                                <ListItem button onClick={onClick}>
                                    <ListItemText primary={element.options[0].label} />
                                    <ArrowForwardIos className={classes.arrow} />
                                </ListItem>
                            </List>
                        );
                    }
                    editComponent = () => (<span></span>);
                    break;
                case FormType.MENU:
                    render = (rowData: any) => {
                        let onClick = () => {
                            dispatch(push(`${path}/${rowData.id}/${element.name}`));
                        };

                        return (
                            <List dense={true} className={classes.list}>
                                <ListItem button onClick={onClick}>
                                    <ListItemText primary={element.options[0].label} />
                                    <ArrowForwardIos className={classes.arrow} />
                                </ListItem>
                            </List>
                        );
                    }
                    editComponent = () => (<span></span>);
                    break;
                case FormType.LIST:
                    render = (rowData: any) => {
                        let onClick = () => {
                            dispatch(push(`${path}/${childForm}/${rowData.id}/${element.name}`));
                        };

                        return (
                            <List dense={true} className={classes.list}>
                                <ListItem button onClick={onClick}>
                                    <ListItemText primary={element.options[0].label} />
                                    <ArrowForwardIos className={classes.arrow} />
                                </ListItem>
                            </List>
                        );
                    }
                    editComponent = () => (<span></span>);
                    break;
                case 'static':
                    render = (rowData: any) => {
                        if (rowData[element.name]) {
                            return rowData[element.name].label;
                        }

                        return <span></span>;
                    };
                    editComponent = (props: any) => {
                        return (<span style={{ whiteSpace: 'pre-wrap' }}>{props.value ? props.value.label : emptyEntry[element.name].label}</span>);
                    };
                    break;
                case 'lastStoredBy':
                    render = (rowData: any) => {
                        if (rowData.lastStoredByName) {
                            return rowData.lastStoredByName;
                        }

                        return <span></span>;
                    }
                    editComponent = () => (<span></span>);
                    break;
                default:
                    editComponent = () => {
                        return (<span></span>);
                    };
            }

            return ({
                cellStyle: {
                    backgroundColor: index % 2 ? theme.colors.white : theme.colors.lightGray,
                },
                title: element.options[0].label,
                field: element.name,
                editComponent,
                render,
            });
        });

    const initialData = entries
        .filter(({ closed }: any) => !closed)
        .sort(sortList(matchFields))
        .map((entry: any) => ({ id: entry.id, ...entry }));

    const [data, setData] = useState(initialData);

    useEffect(() => {
        if (data.length !== initialData.length) {
            setData(initialData);
        }
    });

    const deleteRow = (oldData: any) => new Promise((resolve, reject) => {
        try {
            // @ts-ignore
            dispatch(deleteEntry(childForm, oldData.id));
            const index = data.indexOf(oldData);
            data.splice(index, 1);
            setData([...data]);
            resolve();
        } catch (e) {
            console.error(e);
            reject(e);
        }
    });

    const updateRow = (newData: any, oldData: any) => new Promise((resolve, reject) => {
        try {
            const record = { ...newData, formName: childForm };
            const errorObject = execValidations(record, elements, entries, record.id, childForm, []);
            const valid = Object.keys(errorObject).length === 0;
            setErrors({ ...errorObject, id: record.id });

            if (!valid) {
                alert('Het ingevulde formulier is niet valide, verbeter de inhoud van de velden met een foutmelding.');
                return reject();
            }

            // @ts-ignore
            dispatch(save(record, false));

            // @ts-ignore
            const index = data.indexOf(oldData);
            data[index] = newData;
            setData([...data]);
            resolve();
        } catch (e) {
            console.error(e);
            reject(e);
        }
    });

    const globalState = useStore();

    const addRow = (newData: any) => new Promise((resolve, reject) => {
        try {
            const record = { ...newData, formName: childForm };
            const errorObject = execValidations(record, elements, entries, record.id, childForm, []);
            const valid = Object.keys(errorObject).length === 0;
            setErrors({ ...errorObject, id: Defaults.NEW_ENTRY_ID });
            if (!valid) {
                alert('Het ingevulde formulier is niet valide, verbeter de inhoud van de velden met een foutmelding.');
                return reject();
            }

            elements
                .filter(({ type, name }: any) => type === 'crossSelect' && record[name])
                .forEach(({ name }: any) => {
                    const defaultValues = getDefaultValuesByFormAndSource(
                        globalState.getState(),
                        {
                            form: childForm,
                            element: name,
                            value: record[name].value,
                        }
                    );

                    defaultValues.forEach((defaultValue: any) => {
                        if (!record[defaultValue.name] || !record[defaultValue.name].value) {
                            record[defaultValue.name] = { ...record[defaultValue.name], value: defaultValue.value };
                        }
                    });
                });

            // @ts-ignore
            dispatch(save(record, false));

            setData([...data, newData]);
            resolve();
        } catch (e) {
            console.error(e);
            reject(e);
        }
    });

    const augmentedLocalization = { ...localization };
    if (addition) {
        augmentedLocalization.body.addTooltip = addition;
    }

    return (
        <div className={classes.tableContainer}>
            <MaterialTable
                title=''
                columns={columns}
                data={data}
                localization={augmentedLocalization}
                options={{
                    addRowPosition: 'first',
                    paging: false,
                    sorting: false,
                    rowStyle: {
                        verticalAlign: 'top',
                    },
                }}
                editable={{
                    onRowAdd: addRow,
                    onRowUpdate: updateRow,
                    onRowDelete: deleteRow,
                }}
            />
        </div>
    );
};

export default Table;
