import React from 'react';
// @ts-ignore
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ListItem, ListItemText } from '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../styles';
import { isInCurrentPathById, pathToEntryByForm } from '../../selectors/routing';
import { ExpirationStatus, State } from '../../constants';

interface EntryListItemType {
    id: string;
    form: string;
    list: string;
    label: string;
    groupId?: string;
    isRoot: boolean;
    expirationStatus: ExpirationStatus;
}

// @ts-ignore
const useStyles = makeStyles(() => ({
    listItem: {
        borderTop: `1px solid ${theme.colors.borderGray}`,
        height: '56px',
        '&.selected': {
            backgroundColor: theme.colors.backgroundLightGray,
        },
    },
    arrow: {
        fontSize: 20,
        color: theme.colors.lightGray,
    },
    listItemText: {
        fontSize: 16,
    },
    [ExpirationStatus.VALID]: {},
    [ExpirationStatus.EXPIRED]: {
        color: theme.colors.red,
    },
    [ExpirationStatus.EXPIRING]: {
        color: theme.colors.orange,
    },
}));

const EntryListItem = ({ id, form, list, label, groupId, isRoot = false, expirationStatus }: EntryListItemType) => {
    const classes = useStyles();
    const isSelected = useSelector((state: State) => isInCurrentPathById(state, { id }));
    const pathToComponent = useSelector((state: State) => pathToEntryByForm(state, { form: list }));
    const dispatch = useDispatch();

    const handleClick = () => {
        let entryPath = `${id}`;

        if (groupId) {
            entryPath = `${groupId}/${id}`;
        } else if (!isRoot) {
            entryPath = `${form}/${id}`;
        }

        dispatch(push(`${pathToComponent}/${entryPath}`));
    };

    return (
        <ListItem
            button
            onClick={handleClick}
            // @ts-ignore
            className={`${classes.listItem} ${classes[expirationStatus]}`}
            selected={isSelected}
        >
            <ListItemText primary={label} classes={{ primary: classes.listItemText }} />
            <ArrowForwardIos className={classes.arrow} />
        </ListItem>
    );
};

export default EntryListItem;
