import { FormType } from "../constants";

export default {
    deletable: true,
    displayName: 'Filter',
    name: 'formBuilderFilter',
    latest: 'default',
    type: FormType.FORM,
    versions: {
        default: {
            elements: [
                {
                    name: 'key',
                    options: [{ label: 'Field' }],
                    required: true,
                    sequence: 0,
                    type: 'text',
                    localUnique: true,
                },
                {
                    name: 'value',
                    options: [{ label: 'Value' }],
                    required: true,
                    sequence: 1,
                    type: 'text',
                    localUnique: false,
                },
            ],
        },
    },
};
