import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import createReduxStore, { history } from './createReduxStore.ts';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';

const store = createReduxStore();

// Setup react-redux so that connect HOC can be used
render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
