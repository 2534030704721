import { put } from "redux-saga/effects";
import { functions, storage } from "../../firebase";
import { InputElement } from "../../constants";
import { generateHTMLFile } from "./html";
import { setPDFUrl, dialogClose } from "../../actions/export";

export default function* (
  title: string,
  elements: InputElement[],
  values: Record<string, any>,
  inlineListFormElements: Record<string, InputElement[]>,
  { form, entryId }: { form: string; entryId: string },
  coverUrl?: string
) {
  const html = generateHTMLFile(
    title,
    elements,
    values,
    inlineListFormElements
  );

  try {
    const htmlToPdf = functions.httpsCallable("htmlToPdf");
    const path = yield htmlToPdf({
      form,
      entryId,
      title,
      html,
      coverUrl,
    });
    const storageRef = storage.ref(path.data);
    const url = yield storageRef.getDownloadURL();
    yield put(setPDFUrl(url));
    return url;
  } catch (error) {
    console.warn(error);
    yield put(dialogClose());
  }
}
