import { FormType } from "../constants";

export default {
    deletable: true,
    displayName: 'Option',
    name: 'formBuilderOption',
    latest: 'default',
    type: FormType.FORM,
    versions: {
        default: {
            elements: [
                {
                    name: 'label',
                    options: [{ label: 'Label' }],
                    required: true,
                    sequence: 0,
                    type: 'text',
                    unique: false,
                },
                {
                    name: 'value',
                    options: [{ label: 'Value' }],
                    required: true,
                    sequence: 1,
                    type: 'text',
                    unique: false,
                },
                {
                    name: 'sequence',
                    options: [{ label: 'Sequence' }],
                    required: true,
                    sequence: 2,
                    type: 'number',
                    unique: false,
                },
            ],
        },
    },
};
