import React from "react";

import { getErrorMessage } from "./Error";

import { withStyles } from "@material-ui/core/styles";
import { theme } from "../../styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MaterialCheckbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import {getLabel} from "../../utility/helpers";

const RedCheckbox = withStyles({
  root: {
    "&$checked": {
      color: theme.colors.red,
    },
  },
  checked: {},
})((props: CheckboxProps) => <MaterialCheckbox color="default" {...props} />);

const Checkbox = ({
  options,
  name,
  handleChange,
  value,
  readonly = false,
  errors = [],
}: any) => {
  const customHandleChange = (event: any) => {
    event.target.value = event.target.checked;
    handleChange(event);
  };

  const label = getLabel(options);

  return (
    <div style={{ lineHeight: "56px" }}>
      {!options[0].hideLabel && (
        <FormControlLabel
          control={
            <RedCheckbox
              checked={value || value === "true"}
              name={name}
              id={name}
              onChange={customHandleChange}
              disabled={readonly}
            />
          }
          label={label}
        />
      )}
      {errors.length > 0 &&
        errors.map((error: any, index: number) => (
          <FormHelperText error key={`${error.type}-${index}`}>
            {getErrorMessage(error.type, error.message)}
          </FormHelperText>
        ))}
    </div>
  );
};

export default Checkbox;
