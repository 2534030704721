import React from 'react';
import { FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export enum CustomErrorTypes {
    NOT_UNIQUE = 'not_unique',
    NO_MATCH = 'no_match',
    GREATER_THAN = 'greaterThan',
    SAME_OR_GREATER_THAN = 'sameOrGreaterThan',
    REQUIRED = 'required',
    EXPIRED = 'expired',
    NOT_VALID = 'not_valid',
    FORBIDDEN = 'forbidden',
    NO_VALUE = 'no_value',
    NOT_ENOUGH_CAPACITY = 'not_enough_capacity',
}

export interface CustomError {
    type: CustomErrorTypes;
    message?: string;
}

const useStyles = makeStyles(() => ({
    error: {
        marginTop: '-14px',
        paddingBottom: '4px',
    },
}));

export const ErrorList = ({ errors = [] }: { errors: CustomError[] }) => {
    if (errors.length === 0) {
        return null;
    }

    const classes = useStyles();

    return (
        <>
            {errors.map((error, index) => (
                <FormHelperText
                    error
                    component={'span'}
                    key={`${error.type}-${index}`}
                    className={classes.error}
                >
                    {getErrorMessage(error.type, error.message)}
                </FormHelperText>
            ))}
        </>
    );
}

const Error = ({ issues = [] }: { issues: CustomError[] }) => {
    const errorList = issues.map((issue) => {
        return (<li key={issue.type} style={{ color: 'red' }}>{getErrorMessage(issue.type, issue.message)}</li>);
    });

    return (
        <ul>
            {errorList}
        </ul>
    );
};

export const getErrorMessage = (type: string, message?: string) => {
    switch (type) {
        case CustomErrorTypes.NOT_UNIQUE:
            return 'De waarde in dit veld is niet uniek';
        case CustomErrorTypes.NO_MATCH:
        case CustomErrorTypes.GREATER_THAN:
        case CustomErrorTypes.SAME_OR_GREATER_THAN:
            return message;
        case CustomErrorTypes.REQUIRED:
            return 'Dit veld is verplicht';
        case CustomErrorTypes.EXPIRED:
            return 'Deze datum is verstreken';
        case CustomErrorTypes.NOT_VALID:
            return 'Formulier onvolledig';
        case CustomErrorTypes.FORBIDDEN:
            return 'Deze waarde is niet toegestaan';
        case CustomErrorTypes.NO_VALUE:
            return 'Dit veld moet een waarde bevatten';
        case CustomErrorTypes.NOT_ENOUGH_CAPACITY:
            return 'De geplaatste capaciteit is onvoldoende';
        default:
            console.warn(`${type} is not handled: ${message}`);
            return 'Onbekende validatie fout';
    }
}

export default Error;
