import React from "react";
import {
  Grid,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";

import Input from "./Input";
import { theme } from "../../styles";

// @ts-ignore
const useStyles = makeStyles(() => ({
  listItem: {
    borderBottom: `1px solid ${theme.colors.lightGray}`,
    minHeight: "48px",
    lineHeight: "48px",
    padding: "0px 10px",
  },
  borderRight: {
    borderRight: `1px solid ${theme.colors.lightGray}`,
  },
  deleteButton: {
    marginLeft: "-10px",
    marginTop: "8px",
  },
  controlStyle: {
    width: "100%",
    lineHeight: "56px",
    whiteSpace: "pre-wrap",
  },
}));

export interface Space {
  length: number;
  width: number;
  height: number;
}

const twoDecimals = (value: number) => (Math.round(value * 100) / 100).toLocaleString("nl-NL");

const computeSpaceCapacityInSquareMeters = ({
  length,
  width,
  height,
}: Space) => {
  return length * width * height;
};

export const computeTotalCapacityInSquareMeters = (spaces: Space[]) =>
  spaces.reduce(
    (squareMeters: number, space: Space) =>
      squareMeters + computeSpaceCapacityInSquareMeters(space),
    0
  );

export const computeSuperfluousCapacity = (
  availableCapacity: number = 0,
  necessaryCapacity: number
) => availableCapacity - necessaryCapacity;

export const computeNecessaryEquipmentCapactiy = (
  totalCapacity: number,
  multiplyFactor: number
) => totalCapacity * multiplyFactor;

interface ContainmentCalculator {
  multiplyFactor: number;
  name: string;
  handleChange: Function;
  errors: any;
  value: any;
  readonly: boolean;
}

const ContainmentCalculator = ({
  multiplyFactor = 1,
  name,
  handleChange,
  errors,
  value,
  readonly,
}: ContainmentCalculator) => {
  const classes = useStyles();
  const totalCapacity = computeTotalCapacityInSquareMeters(value.spaces || []);
  const necessaryEquipmentCapacity = computeNecessaryEquipmentCapactiy(
    totalCapacity,
    multiplyFactor || 1
  );
  const superfluousCapacity = computeSuperfluousCapacity(
    value.availableCapacity,
    necessaryEquipmentCapacity
  );

  const updateSpaceValue = (event: any) => {
    const [spaceIdx, dimension]: [
      string,
      "length" | "width" | "height"
    ] = event.target.name.split(".");
    const spaces = value.spaces.map((space: Space, idx: number) => {
      if (idx === parseInt(spaceIdx, 10)) {
        space[dimension] = parseFloat(event.target.value || 0);
      }
      return space;
    });

    handleChange({
      target: {
        name,
        value: {
          availableCapacity: value.availableCapacity,
          spaces,
        },
      },
    });
  };

  const updateAvailableCapacity = (event: any) => {
    handleChange({
      target: {
        name,
        value: {
          availableCapacity: parseFloat(event.target.value),
          spaces: value.spaces,
        },
      },
    });
  };

  const createNewSpace = () => {
    let spaces = [{ length: "", width: "", height: "" }];

    if (value && value.spaces) {
      spaces = value.spaces.concat(spaces);
    }

    handleChange({
      target: {
        name,
        value: {
          availableCapacity: value.availableCapacity || 0,
          spaces,
        },
      },
    });
  };

  const deleteSpace = (idx: number) => () => {
    let spaces = [{ length: "", width: "", height: "" }];

    if (value.spaces.length > 1) {
      spaces = [...value.spaces];
      spaces.splice(idx, 1);
    }

    handleChange({
      target: {
        name,
        value: {
          availableCapacity: value.availableCapacity,
          spaces,
        },
      },
    });
  };

  if (!value) {
    createNewSpace();
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} className={classes.listItem}>
        <Button startIcon={<Add />} onClick={createNewSpace} disableElevation>
          Blok toevoegen
        </Button>
      </Grid>
      {value.spaces &&
        value.spaces.map((space: Space, idx: number) => {
          return (
            <Grid container spacing={0} key={idx}>
              <Grid
                item
                xs={2}
                className={`${classes.listItem} ${classes.borderRight}`}
              >
                <Input
                  options={[{ label: "Lengte" }]}
                  name={`${idx}.length`}
                  handleChange={updateSpaceValue}
                  type={"number"}
                  value={space.length}
                  readonly={readonly}
                />
              </Grid>
              <Grid
                item
                xs={2}
                className={`${classes.listItem} ${classes.borderRight}`}
              >
                <Input
                  options={[{ label: "Breedte" }]}
                  name={`${idx}.width`}
                  handleChange={updateSpaceValue}
                  type={"number"}
                  value={space.width}
                  readonly={readonly}
                />
              </Grid>
              <Grid
                item
                xs={2}
                className={`${classes.listItem} ${classes.borderRight}`}
              >
                <Input
                  options={[{ label: "Hoogte" }]}
                  name={`${idx}.height`}
                  handleChange={updateSpaceValue}
                  type={"number"}
                  value={space.height}
                  readonly={readonly}
                />
              </Grid>
              <Grid
                item
                xs={5}
                className={`${classes.listItem} ${classes.borderRight}`}
              >
                <Input
                  options={[{ label: "Inhoud" }]}
                  name={`${idx}.capacity`}
                  value={twoDecimals(computeSpaceCapacityInSquareMeters(space))}
                  readonly={true}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ color: theme.colors.black }}
                    >
                      m<sup style={{ marginTop: "-8px" }}>3</sup>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid
                item
                xs={1}
                className={`${classes.listItem} ${classes.deleteButton}`}
              >
                <IconButton onClick={deleteSpace(idx)} disabled={readonly}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      <Grid
        item
        xs={9}
        className={`${classes.listItem} ${classes.borderRight}`}
      >
        <FormControl className={classes.controlStyle}>
          Totale inhoud
        </FormControl>
      </Grid>
      <Grid item xs={3} className={classes.listItem}>
        <FormControl
          className={classes.controlStyle}
          style={{ marginTop: "8px" }}
        >
          <Input
            options={[{ label: "" }]}
            name="totalCapacity"
            value={twoDecimals(totalCapacity)}
            readonly={true}
            endAdornment={
              <InputAdornment
                position="end"
                style={{ color: theme.colors.black }}
              >
                m<sup style={{ marginTop: "-8px" }}>3</sup>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={9}
        className={`${classes.listItem} ${classes.borderRight}`}
      >
        <FormControl className={classes.controlStyle}>
          Benodigde capaciteit
        </FormControl>
      </Grid>
      <Grid item xs={3} className={classes.listItem}>
        <FormControl
          className={classes.controlStyle}
          style={{ marginTop: "8px" }}
        >
          <Input
            options={[{ label: "" }]}
            name="necessaryCapacity"
            value={twoDecimals(necessaryEquipmentCapacity)}
            readonly={true}
            endAdornment={
              <InputAdornment
                position="end"
                style={{ color: theme.colors.black }}
              >
                m<sup style={{ marginTop: "-8px" }}>3</sup>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={9}
        className={`${classes.listItem} ${classes.borderRight}`}
      >
        <FormControl className={classes.controlStyle}>
          Geplaatste capaciteit
        </FormControl>
      </Grid>
      <Grid item xs={3} className={classes.listItem}>
        <FormControl
          className={classes.controlStyle}
          style={{ marginTop: "8px" }}
        >
          <Input
            options={[{ label: "" }]}
            name={"availableCapacity"}
            handleChange={updateAvailableCapacity}
            type={"number"}
            value={value.availableCapacity || 0}
            readonly={readonly}
            errors={errors}
            endAdornment={
              <InputAdornment position="end">
                m<sup style={{ marginTop: "-8px" }}>3</sup>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid
        item
        xs={9}
        className={`${classes.listItem} ${classes.borderRight}`}
      >
        <FormControl className={classes.controlStyle}>
          Overcapacteit
        </FormControl>
      </Grid>
      <Grid item xs={3} className={classes.listItem}>
        <FormControl
          className={classes.controlStyle}
          style={{ marginTop: "8px" }}
        >
          <Input
            options={[{ label: "" }]}
            name="superfluousCapacity"
            value={twoDecimals(superfluousCapacity)}
            readonly={true}
            endAdornment={
              <InputAdornment
                position="end"
                style={{ color: theme.colors.black }}
              >
                m<sup style={{ marginTop: "-8px" }}>3</sup>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ContainmentCalculator;
