import { select, take, takeEvery, all, fork } from "redux-saga/effects";
import { INITIAL_LOAD_DONE } from "../actions/entries";
import * as actions from "../actions/export";
import { getEntryByFormAndId } from "../selectors/entries";
import { InputElement, InputTypes, ExportTarget } from "../constants";
import { getReferencedFormName } from "../utility/helpers";
import { getElementsSortedAndFilteredByFormAndEntry } from "../selectors/elements";
import { getFormMeta, getElementsByForm } from "../selectors/forms";
import { convertDisplayFormatByFormAndEntryId } from "../selectors/displayFormatByGraph";
import data from "./export/data";
import customExport from "./export/custom";
import { exporters } from "./export/helpers";

enum ExportMode {
  SINGLE = "single",
  COMPLETE = "complete",
  CUSTOM = "custom",
}

function* getTitle(form: string, entryId: string) {
  const { displayName } = yield select(getFormMeta, { form });
  const title = yield select(convertDisplayFormatByFormAndEntryId, {
    form,
    entryId,
    displayFormat: displayName,
  });
  return title;
}

function* getElementsForInlineLists(elements: InputElement[]) {
  const inlineListForms = elements
    .filter(({ type }) => type === InputTypes.INLINE_LIST)
    .map((element) => {
      const [form] = getReferencedFormName(element);
      return [element.name, form];
    });

  const inlineListFormElements = yield all(
    inlineListForms.map(([_, form]) => select(getElementsByForm, { form }))
  );
  return inlineListForms.reduce(
    (acc: Record<string, InputElement[]>, [form], idx: number) => {
      acc[form] = inlineListFormElements[idx];
      return acc;
    },
    {}
  );
}

function* exportEntry(
  form: string,
  entryId: string,
  targetFormat: ExportTarget,
  coverUrl?: string
) {
  const entry = yield select(getEntryByFormAndId, { form, entryId });
  const elements = yield select(getElementsSortedAndFilteredByFormAndEntry, {
    form,
  });
  const inlineListFormElements = yield getElementsForInlineLists(elements);
  const title = yield getTitle(form, entryId);
  const values = yield data(
    { form, entryId, ...entry },
    elements,
    inlineListFormElements
  );
  yield exporters[targetFormat](
    title,
    elements,
    values,
    inlineListFormElements,
    { form, entryId },
    coverUrl
  );
}

function* handleRequest({
  payload: { form, entryId, targetFormat, coverUrl },
}: actions.Request) {
  // @todo: we should request this from the user (except if this is invoked by the custom flow)
  const exportMode = ExportMode.SINGLE;

  if (exportMode === ExportMode.SINGLE) {
    yield exportEntry(form, entryId, targetFormat, coverUrl);
  }
}

export default function* saga() {
  yield fork(customExport);
  yield take(INITIAL_LOAD_DONE);

  yield takeEvery(actions.REQUEST, handleRequest);
}
