export enum ValueType {
  TIME = "time",
  FLOAT = "float",
  INT = "int",
}

export function convertValueToNumber(
  value: string,
  valueType: ValueType = ValueType.INT
) {
  if (!value) {
    return;
  }

  switch (valueType) {
    case ValueType.INT:
      return parseInt(value, 10);
    case ValueType.FLOAT:
      return parseFloat(value);
    case ValueType.TIME:
      const [hours, minutes] = value.split(":");
      return [parseInt(hours, 10), parseInt(minutes, 10)];
  }
}

export function convertMinutesToHours(hours: number = 0, minutes: number = 0) {
  // negative minutes so we need to subtract those from the hours (can never be more than 1 hours)
  if (minutes < 0) {
    return [hours - 1, 60 + minutes];
  }

  const additionalHours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return [hours + additionalHours, remainingMinutes];
}

export const arithmetic = {
  "-": (a: any, b: any) => {
    if (a.includes(":")) {
      const [hoursA, minutesA] = convertValueToNumber(a, ValueType.TIME) as [
        number,
        number
      ];
      const [hoursB, minutesB] = convertValueToNumber(b, ValueType.TIME) as [
        number,
        number
      ];
      const [hours, minutes] = convertMinutesToHours(
        hoursA - hoursB,
        minutesA - minutesB
      );
      return `${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    }

    const parsedA = convertValueToNumber(a) as number;
    const parsedB = convertValueToNumber(b) as number;

    return parsedA - parsedB;
  },
};
