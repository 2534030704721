import { createSelector } from "reselect";
import { getAll } from "./forms";
import { getCurrentPath } from "./routing";
import { sortBySequence } from "../utility/helpers";
import { isRootOrSuperuser } from "./user";
import { FormType } from "../constants";
import {hasDashboard} from "./dashboard";

export const getMenuItems = createSelector(
  getAll,
  getCurrentPath,
  isRootOrSuperuser,
  hasDashboard,
  ({ forms }, currentPath, isRootOrSuperuser, activeDashboard) => {
    const currentRoot = currentPath.split("/")[1];
    const menuItems = Object.values(forms)
      .filter(
        ({ type, isRoot }) =>
          type === FormType.ROOTFORM || (type === FormType.MENU && isRoot)
      )
      .sort(sortBySequence)
      .map((route) => ({
        route: `/${route.name}`,
        handle: route.displayName,
        active: route.name === currentRoot,
      }));

    if (isRootOrSuperuser) {
      menuItems.push({
        route: "/graph",
        handle: "Graph",
        active: "graph" === currentRoot,
      });
    }

    if (activeDashboard) {
      menuItems.push({
        route: "/",
        handle: "Dashboard",
        active: "" === currentRoot,
      });
    }

    return menuItems;
  }
);
