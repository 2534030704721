import { ExportTarget } from "../../constants";
import html from "./html";
import pdf from "./pdf";
import excel from "./excel";

export const exporters = {
  [ExportTarget.HTML]: html,
  [ExportTarget.PDF]: pdf,
  [ExportTarget.EXCEL]: excel,
};

