import { take, put } from "redux-saga/effects";

import * as formActions from "../actions/form";
import * as usersActions from "../actions/users";
import * as companiesActions from "../actions/companies";

import formBuilderForms from "../fixedForms/formBuilderForms";
import formBuilderFormMeta from "../fixedForms/formBuilderFormMeta";
import formBuilderFormField from "../fixedForms/formBuilderFormField";
import formBuilderOption from "../fixedForms/formBuilderOption";
import formBuilderCondition from "../fixedForms/formBuilderCondition";
import formBuilderValidation from "../fixedForms/formBuilderValidation";
import formBuilderFilter from "../fixedForms/formBuilderFilter";

import rules from "../fixedForms/rules";
import rule from "../fixedForms/rule";
import ruleBuilderValidation from "../fixedForms/ruleBuilderValidation";

import user from "../fixedForms/user";
import users from "../fixedForms/users";

import company from "../fixedForms/company";
import companies from "../fixedForms/companies";
import { Roles } from "../constants";
import { STATUS_CHANGED } from "../actions/auth";

export default function* saga() {
  const { payload } = yield take(STATUS_CHANGED);
  yield put(formActions.fetchAll());
  yield put(usersActions.fetchAll());

  if ([Roles.SUPERUSER].includes(payload.role)) {
    if (process.env.REACT_APP_FB_PROJECT_ID === "formbuildersdev") {
      yield put(formActions.setFixed({ companies, company }));
    }

    yield put(companiesActions.fetchAll());
  } else if ([Roles.ROOT].includes(payload.role)) {
    yield put(companiesActions.fetch(payload.company));
  }

  if ([Roles.ROOT, Roles.SUPERUSER].includes(payload.role)) {
    yield put(
      formActions.setFixed({
        formBuilderForms,
        formBuilderFormMeta,
        formBuilderFormField,
        formBuilderOption,
        formBuilderCondition,
        formBuilderValidation,
        formBuilderFilter,

        rules,
        rule,
        ruleBuilderValidation,

        users,
        user,
      })
    );
  }
}
