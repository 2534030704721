import { createSelector } from "reselect";
import { State, ByForm, Rule, RuleType, InputTypes } from "../constants";
import {
  getValidEntriesByForm,
  getEntryIdsLimitedByCurrentParentByForm,
  getCurrentFormsParentEntry,
} from "./entries";
import { getElementsByForm } from "./forms";
import { getReferencedFormName } from "../utility/helpers";
import { getDefaultValuesByFormAndSource } from "./defaultValues";

const getAll = ({ rules }: State) => rules;

export const getRulesBySourceForm = createSelector(
  getAll,
  (_: State, { form }: ByForm) => form,
  (rules, form) => Object.values(rules).filter(({ source }) => source === form)
);

export const getRulesByDestinationForm = createSelector(
  getAll,
  (_: State, { form }: ByForm) => form,
  (rules, form) =>
    Object.values(rules).filter(({ destination }) => destination === form)
);

const getCrossSelectReferencedDefaultValues = (
  state: State,
  form: string,
  inlineForm: string,
  field: string,
  entries: any
) => {
  const elements = getElementsByForm(state, { form });
  const [inlineListElement] = elements.filter(
    ({ name }) => name === inlineForm
  );
  const [referencedFormName] = getReferencedFormName(inlineListElement);
  const inlineFormElements = getElementsByForm(state, {
    form: referencedFormName,
  });
  const crossSelects = inlineFormElements.filter(
    ({ type }) => type === InputTypes.CROSS_SELECT
  );

  return entries.map((value: { value: string }) => {
    const mappedEntry = { [field]: value };
    const isCrossSelect = crossSelects.find(({ name }) => name === field);
    if (isCrossSelect) {
      const defaultValues = getDefaultValuesByFormAndSource(state, {
        form: referencedFormName,
        element: name,
        value: value.value,
      });
      defaultValues.forEach((defaultValue) => {
        mappedEntry[defaultValue.name] = { value: defaultValue.value };
      });
    }

    return mappedEntry;
  });
};

export const uponCreationRule = createSelector(
  getAll,
  (_: State, { form }: ByForm) => ({ form }),
  (state: State) => state,
  (rules, { form }, state) => {
    const formRules = Object.values(rules).filter(
      ({ type, destination }) =>
        type === RuleType.UPON_CREATION && destination === form
    );

    if (formRules.length === 0) {
      return {};
    }

    return formRules.reduce((acc: any, rule: Rule) => {
      // @todo: leverage the graph to get all this information?
      const parentSourceIds = getEntryIdsLimitedByCurrentParentByForm(state, {
        form: rule.source,
      });

      const allListEntries = getValidEntriesByForm(state, {
        form: rule.source,
      });

      let entries = Object.keys(allListEntries)
        .filter((id: string) => {
          if (!parentSourceIds) {
            return true;
          }

          return parentSourceIds.includes(id);
        })
        .map((id: string) => ({ value: id }));

      if (rule.sourceField) {
        const parentEntry = getCurrentFormsParentEntry(state);

        if (
          !parentEntry ||
          !parentEntry[rule.source] ||
          parentEntry[rule.source].value.length === 0
        ) {
          return {};
        }

        entries = Object.entries(allListEntries)
          .filter(([id]) => parentEntry[rule.source].value.includes(id))
          .map(([, entry]: any) => entry[rule.sourceField]);
      }

      if (entries.length > 0) {
        const [inlineForm, field] = rule.destinationField.split(".");
        acc[inlineForm] = getCrossSelectReferencedDefaultValues(
          state,
          form,
          inlineForm,
          field,
          entries
        );
      }

      return acc;
    }, {});
  }
);
