import React from "react";
// @ts-ignore
import { useDispatch } from "react-redux";

import { getSignedInStatus } from "./reduxMiddleware/user";
import { initialLoadDone, initialLoadProgress } from "./reduxMiddleware/entries";
import { getPath } from "./reduxMiddleware/routing";
import { isScheduleActive } from "./reduxMiddleware/schedule";
import Navigation from "./components/Navigation";
import DrawerContainer from "./components/DrawerContainer";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import ExportDialog from "./components/ExportDialog";

import styles from "./App.module.css";
import { theme } from "./styles";
import { CircularProgress, Snackbar, Button } from "@material-ui/core";
import { cancelInterval } from "./actions/schedule.ts";

const App = () => {
  const isSignedIn = getSignedInStatus();
  const isInitialLoadDone = initialLoadDone();
  const progress = initialLoadProgress();
  const isIntervalScheduled = isScheduleActive();
  const path = getPath();
  const dispatch = useDispatch();

  if (!isSignedIn) {
    return <Login />;
  }

  if (!isInitialLoadDone) {
    return (
      <div className={styles.loader}>
        <CircularProgress style={{ color: theme.colors.red }} />
        <p>
          Een ogenblik geduld, de gegevens worden ingeladen...&nbsp;
          {progress.total !== 999 && `${progress.loaded} / ${progress.total}`}
        </p>
      </div>
    );
  }

  const displayDashboard = path === "/";
  const handleClose = () => {
    dispatch(cancelInterval());
  };

  return (
    <div className={styles.wrapper}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isIntervalScheduled}
        message="Auto refresh is actief"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
              ANNULEREN
            </Button>
          </React.Fragment>
        }
      />
      <ExportDialog />
      <Navigation />
      <div className={styles.container}>
        {displayDashboard && <Dashboard />}
        {!displayDashboard && <DrawerContainer />}
      </div>
    </div>
  );
};

export default App;
