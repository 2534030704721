const combineMatchingValues = (sourceObj: Record<string, { value: any }>, keys: string[]) => {
    const objKeys = Object.keys(sourceObj);
    return keys.reduce((result: string, key: string) => {
        const aKey = objKeys.find((aKey: string) => aKey.includes(key) && sourceObj[aKey] && sourceObj[aKey].value);

        if (!aKey) {
            return result;
        }

        return `${result} ${sourceObj[aKey].value.toString().toUpperCase()}`;
    }, '');
};

export const sortList = (matchFields: string | string[], reverse = false) => (a: Record<string, { value: any }>, b: Record<string, { value: any }>) => {
    let sortFields;

    if (!Array.isArray(matchFields)) {
        sortFields = matchFields.split(',');
    } else {
        sortFields = matchFields;
    }

    const firstString = combineMatchingValues(a, sortFields);
    const secondString = combineMatchingValues(b, sortFields);

    if (reverse) {
        return secondString.localeCompare(firstString);
    }

    return firstString.localeCompare(secondString);
};