import { syncForms, syncExports } from "../actions/companies";
import { FormType } from "../constants";

export default {
    displayName: 'Bedrijf: [name]',
    name: 'company',
    latest: 'default',
    type: FormType.FORM,
    versions: {
        default: {
            elements: [
                {
                    name: 'domain',
                    options: [{ label: 'Domein' }],
                    required: true,
                    sequence: 0,
                    type: 'text',
                },
                {
                    name: 'name',
                    options: [{ label: 'Bedrijfsnaam' }],
                    required: true,
                    sequence: 1,
                    type: 'text',
                },
                {
                    name: 'primaryColor',
                    options: [{ label: 'Primaire kleur (hex: #000000)' }],
                    required: true,
                    sequence: 2,
                    type: 'text',
                },
                {
                    name: 'email',
                    options: [{ label: 'E-mail adres beheerder' }],
                    required: true,
                    sequence: 3,
                    type: 'text',
                },
                {
                    name: 'username',
                    options: [{ label: 'Naam beheerder' }],
                    required: true,
                    sequence: 4,
                    type: 'text',
                },
                {
                    name: 'password',
                    options: [{ label: 'Wachtwoord' }],
                    required: false,
                    sequence: 5,
                    type: 'text',
                },
                {
                    name: 'environment',
                    options: [{
                        label: 'Environment',
                        placeholder: '-- Choose the environment',
                        elements: [
                            { value: 'formbuildersdev', label: 'FormBuilders' },
                            { value: 'asbestlogboek-testomgeving', label: 'Asbestlogboek' },
                            { value: 'origo-testomgeving', label: 'Origo' },
                        ],
                    }],
                    required: true,
                    sequence: 6,
                    type: 'select',
                    unique: false,
                },
                {
                    name: 'sourceCompany',
                    options: [{
                        label: 'Source company',
                        placeholder: '-- Select a company to copy the rules and forms from --',
                        displayFormat: '[name] ([domain])',
                        source: 'company',
                    }],
                    required: false,
                    sequence: 7,
                    type: 'crossSelect',
                },
                {
                    type: 'section',
                    options: [{ label: 'Synchronize' }],
                    sequence: 8,
                },
                {
                    name: 'syncSource',
                    options: [{
                        label: 'Synchronization source company',
                        placeholder: '-- Select a company to copy the data from --',
                        displayFormat: '[name] ([domain])',
                        source: 'company',
                    }],
                    required: false,
                    sequence: 9,
                    type: 'crossSelect',
                },
                {
                    type: 'button',
                    options: [{ label: 'Synchronize forms and rules' }],
                    name: 'syncButtonForms',
                    sequence: 10,
                    action: syncForms,
                    valuesToSelect: ['syncSource', 'id'],
                },
                {
                    type: 'button',
                    options: [{ label: 'Synchronize exports' }],
                    name: 'syncButtonExports',
                    sequence: 11,
                    action: syncExports,
                    valuesToSelect: ['syncSource', 'id'],
                },
            ],
        },
    },
}
