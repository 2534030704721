import { FSA } from '../constants';

export const FETCHED = 'ENTRIES_FETCHED';
export const fetched = (entries: any): FSA => ({ type: FETCHED, payload: entries });

export const BUILDER_FETCHED = 'ENTRIES_FETCHED_BUILDER';
export const builderFetched = (entries: any): FSA => ({ type: BUILDER_FETCHED, payload: entries });

export const ALL_FETCHED = 'ENTRIES_ALL_FETCHED';
export const allFetched = (): FSA => ({ type: ALL_FETCHED });

export const SAVE = 'ENTRIES_SAVE';
export const save = (entry: any, valid: boolean, redirect: undefined | Function): FSA =>
    ({ type: SAVE, payload: { entry, valid }, meta: { redirect } });

export const DELETE = 'ENTRIES_DELETE';
export const deleteEntry = (form: string, entryId: string): FSA =>
    ({ type: DELETE, payload: { form, entryId } });

export const DELETE_IN_STATE = 'ENTRIES_DELETE_IN_STATE';
export const deleteEntryInState = (form: string, entryId: string): FSA =>
    ({ type: DELETE_IN_STATE, payload: { form, entryId } });

export const UPDATE_STATE = 'ENTRIES_UPDATE_STATE';
export const updateInState = (entry: any, isNew: boolean = false): FSA =>
    ({ type: UPDATE_STATE, payload: { entry }, meta: { isNew } });

export const BULK_VALIDATE = 'ENTRIES_BULK_VALIDATE';
export const bulkValidate = () => ({ type: BULK_VALIDATE });

export const DELETE_FILES = 'ENTRIES_DELETE_FILES';
export const deleteFiles = (paths: string[]) => ({ type: DELETE_FILES, payload: { paths }} );

export const INITIAL_LOAD_DONE = 'ENTRIES_INITIAL_LOAD_DONE';
export const initialLoadDone = () => ({ type: INITIAL_LOAD_DONE });

export const INITIAL_LOAD_PROGRESS = 'ENTRIES_INITIAL_PROGRESS';
export const initialLoadProgressUpdate = (progress: { loaded: number, total: number }) => ({ type: INITIAL_LOAD_PROGRESS, payload: progress });

export const INCREASE_LOAD_PROGRESS = 'ENTRIES_INCREASE_LOAD_PROGRESS';
export const increaseLoadProgress = () => ({ type: INCREASE_LOAD_PROGRESS });

export const STORED = 'ENTRIES_STORED';
export const stored = () => ({ type: STORED });

export const FETCH_FORM_ENTRIES = 'ENTRIES_FETCH_FORM_ENTRIES';
export const fetchFormEntries = (form: string) => ({ type: FETCH_FORM_ENTRIES, payload: { form } });

export const FETCH_ENTRY = 'ENTRIES_FETCH_ENTRY';
export const fetchEntry = (form: string, entryId: string) => ({ type: FETCH_ENTRY, payload: { form, entryId } });
