import { createSelector } from "reselect";
import { getElementsByForm } from "./forms";
import { ByForm, InputElement, InputTypes, State } from "../constants";
import { getAll } from "./entries";
import { getAll as getAllForms } from "./forms";
import { getPathParams, getRoot } from "./routing";
import { replaceVariables } from "./displayFormat";
import { format, startOfDay } from "date-fns";

const hasDefaultValue = ({ defaultValue }: InputElement) => defaultValue;
const hasPrefill = ({ type, options }: InputElement) => {
  if (
    [InputTypes.DATE, InputTypes.DATE_TIME, InputTypes.TIME].includes(type) &&
    options![0].prefill
  ) {
    return true;
  }

  return false;
};

export const getDefaultValuesByForm = createSelector(
  getElementsByForm,
  getRoot,
  getPathParams,
  getAll,
  getAllForms,
  (_: State, { form }: ByForm) => form,
  (elements, rootNode, pathParams, entries, { forms }, currentNode) => {
    const defaultValues: Record<string, string> = {};

    const prefillFormatter = {
      [InputTypes.TIME]: () => format(new Date(), "HH:mm"),
      [InputTypes.DATE]: () => startOfDay(new Date(Date.now())).getTime(),
      [InputTypes.DATE_TIME]: () => new Date(Date.now()).getTime(),
    };

    elements.filter(hasPrefill).forEach(({ type, name }) => {
      // @ts-ignore
      defaultValues[name] = prefillFormatter[type]();
    });

    const formElementNames = elements.map(({ name }) => name);

    elements.filter(hasDefaultValue).forEach(({ defaultValue, name }) => {
      const defaultValueIncludesLocalElement = formElementNames.find((name) =>
        defaultValue!.includes(name)
      );
      if (defaultValueIncludesLocalElement) {
        defaultValues[name] = "";
      } else {
        defaultValues[name] = replaceVariables(
          defaultValue!,
          rootNode,
          currentNode,
          pathParams,
          entries,
          forms
        );
      }
    });

    return defaultValues;
  }
);

export const getDefaultValuesByFormAndSource = createSelector(
  getElementsByForm,
  getRoot,
  getPathParams,
  getAll,
  getAllForms,
  (
    _: State,
    { element, form, value }: { element: string; form: string; value: string }
  ) => ({ element, form, value }),
  (
    elements,
    rootNode,
    pathParams,
    entries,
    { forms },
    { element, form, value }
  ) => {
    return elements
      .filter(
        ({ defaultValue }: InputElement) =>
          defaultValue && defaultValue.includes(element)
      )
      .map(({ name, defaultValue }: InputElement) => {
        const replacedVariables = replaceVariables(
          defaultValue!,
          rootNode,
          form,
          pathParams,
          entries,
          forms,
          true,
          value
        );

        if (replacedVariables === "undefined") {
          return { name, value: "" };
        }

        return { name, value: replacedVariables || "" };
      });
  }
);

