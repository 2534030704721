import { takeEvery, put, select } from "redux-saga/effects";

import { firestore } from "../firebase";

import * as formActions from "../actions/form";
import * as entriesActions from "../actions/entries";
import * as rulesActions from "../actions/rules";

import { getCompany } from "../selectors/company";

import { FSA, RuleType, Rule, Defaults } from "../constants";
import { populateEntriesGraph } from "../utility/graphHelpers";

function* setRules(): IterableIterator<any> {
  const company = yield select(getCompany);
  const rules = yield firestore
    .collection(`companies/${company}/rules`)
    .get()
    .then((rulesObject: any) =>
      rulesObject.docs.reduce((acc: any, rule: any) => {
        acc[rule.id] = rule.data();
        return acc;
      }, {})
    );

  yield put(rulesActions.fetchedAll(rules));

  // @ts-ignore
  const ruleObject = Object.entries(rules).reduce(
    (acc: any, [id, rule]: any) => {
      acc[id] = {
        type: { value: rule.type },
        source: { value: rule.source },
        sourceField: { value: rule.sourceField },
        destination: { value: rule.destination },
        destinationField: { value: rule.destinationField },
      };

      if (rule.type === RuleType.LIST_ITEM_CREATION) {
        acc[id].listField = { value: rule.listField };
      }

      if (rule.validations) {
        acc[id].ruleBuilderValidations = rule.validations.map(
          ({ type, field }: any) => ({
            validationType: { value: type },
            field: { value: field },
          })
        );
      }

      return acc;
    },
    {}
  );

  populateEntriesGraph("rule", ruleObject);

  yield put(entriesActions.fetched({ rule: ruleObject }));
}

function* saveRule({ payload }: FSA): IterableIterator<any> {
  const { entry } = payload;

  if (entry.formName === "rule") {
    const rule: Rule = {
      type: entry.type.value,
      source: entry.source.value,
      sourceField: entry.sourceField.value,
      destination: entry.destination.value,
      destinationField: entry.destinationField.value,
    };
    if (entry.type.value === RuleType.LIST_ITEM_CREATION) {
      rule.listField = entry.listField.value;
    }
    if (Object.keys(entry.ruleBuilderValidations[0]).length > 0) {
      rule.validations = entry.ruleBuilderValidations.map(
        ({ validationType, field }: any) => ({
          type: validationType.value,
          field: field.value,
        })
      );
    }

    const company = yield select(getCompany);

    if (entry.id === Defaults.NEW_ENTRY_ID) {
      // create
      yield firestore.collection(`companies/${company}/rules`).add(rule);
      return;
    }

    // update
    yield firestore
      .collection(`companies/${company}/rules`)
      .doc(entry.id)
      .update(rule);
  }
}

export default function* saga() {
  yield takeEvery(entriesActions.SAVE, saveRule);
  yield takeEvery(formActions.FETCH_ALL, setRules);
}
