import { FETCHED_ALL, SET_FIXED, UPDATE, FETCH, FETCH_VERSION, FETCH_ALL } from '../actions/form';
import * as entriesActions from '../actions/entries';
import { FSA, FormsState, FetchingState, FormType } from '../constants';
import { LOGGED_OUT } from '../actions/auth';

const initialState = {
    fetchingState: FetchingState.NOT_FETCHED,
    globalFetch: FetchingState.NOT_FETCHED,
    forms: {},
};

export default (state: FormsState = initialState, { type, payload }: FSA) => {
    let listForms;

    switch (type) {
        case FETCHED_ALL:
            listForms = Object
                .entries(payload)
                .reduce((lists: any, [, { latest, versions }]: any) => {
                    if (!versions[latest] || !versions[latest].elements) {
                        return lists;
                    }

                    const listElements = versions[latest].elements
                        .filter(({ type }: any) => type === FormType.LIST)
                        .reduce((accList: any, list: any) => {
                            accList[list.name] = {
                                displayName: list.options[0].label,
                                name: list.name,
                                latest: 'default',
                                type: FormType.LIST,
                                versions: { default: { elements: [{ ...list, conditions: [], sequence: 0 }] } },
                            };
                            return accList;
                        }, {});

                    return { ...lists, ...listElements };
                }, []);

            return {
                ...state,
                globalFetch: FetchingState.FETCHED,
                forms: {
                    ...state.forms,
                    ...payload,
                    ...listForms,
                },
            };
        case SET_FIXED:
            listForms = Object
                .entries(payload)
                .reduce((lists: any, [, { latest, versions }]: any) => {
                    const listElements = versions[latest].elements
                        .filter(({ type }: any) => type === FormType.LIST)
                        .reduce((accList: any, list: any) => {
                            accList[list.name] = {
                                displayName: list.options[0].label,
                                name: list.name,
                                latest: 'default',
                                type: FormType.LIST,
                                versions: { default: { elements: [{ ...list, conditions: [], sequence: 0 }] } },
                            };
                            return accList;
                        }, {});

                    return { ...lists, ...listElements };
                }, []);

            return {
                ...state,
                forms: {
                    ...state.forms,
                    ...payload,
                    ...listForms,
                },
            };
        case entriesActions.FETCHED:
            return { ...state, fetchingState: FetchingState.FETCHED };
        case UPDATE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [payload.formId]: payload.form,
                },
                fetchingState: FetchingState.FETCHED,
            };
        case FETCH_ALL:
        case FETCH:
        case FETCH_VERSION:
            return { ...state, fetchingState: FetchingState.FETCHING };
        case LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
