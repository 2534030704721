import { fork, take } from "redux-saga/effects";

import auth from "./auth";
import init from "./init";
import form from "./forms";
import users from "./users";
import entries from "./entries";
import builder from "./builder";
import companies from "./companies";
import rules from "./rules";
import dashboard from "./dashboard";
import externalApis from "./externalApis";
import * as exportSaga from "./export";
import { Roles } from "../constants";
import { STATUS_CHANGED } from "../actions/auth";

function* main(): IterableIterator<any> {
  yield fork(auth);
  yield fork(init);
  yield fork(form);
  yield fork(entries);
  yield fork(companies);

  // @ts-ignore
  const { payload } = yield take(STATUS_CHANGED);

  yield fork(users);
  yield fork(rules);
  yield fork(dashboard);
  yield fork(externalApis);

  if (![Roles.GUEST, Roles.USER].includes(payload.role)) {
    yield fork(exportSaga.default);
  }

  if ([Roles.SUPERUSER, Roles.ROOT].includes(payload.role)) {
    yield fork(builder);
  }
}

export default main;
