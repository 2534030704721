import {
  takeEvery,
  select,
  put,
  all,
  call,
  take,
  fork,
  cancelled,
} from "redux-saga/effects";
import { LOCATION_CHANGE } from "connected-react-router";

import { firestore, storage } from "../firebase";
import firebase from "firebase/app";

import * as actions from "../actions/entries";
import * as formActions from "../actions/form";
import {
  FSA,
  Rule,
  RuleType,
  InputElement,
  FormType,
  Defaults,
  ExpirationStatus,
} from "../constants";
import { getCompany } from "../selectors/company";
import { get as getUser, isRootOrSuperuser } from "../selectors/user";
import {
  getEntryByFormAndId,
  getEntriesByForm,
  getAll,
  formsToIgnore,
} from "../selectors/entries";
import {
  getRulesBySourceForm,
  getRulesByDestinationForm,
} from "../selectors/rules";
import {
  getBranchByForm,
  getElementsByForm,
  getExpiringElementsByForm,
  getFileElementsByForm,
  getInlineListAndCrossSelectElementsNameAndReferenceByForm,
  getInlineListFileElementsByForm,
} from "../selectors/forms";
import { deleteIn } from "../utility/helpers";
import {
  getCurrentFormsParentFormAndId,
  getCurrentFormAndId,
} from "../selectors/routing";
import { eventChannel } from "redux-saga";
import {
  execValidations,
  getExpirationStatus,
  ignoreExpiring,
} from "../utility/validation";
import { getDefaultValuesByFormAndSource } from "../selectors/defaultValues";
import { populateEntriesGraph } from "../utility/graphHelpers";
import { entriesGraph, formGraph } from "../graph";
import { Conditions } from "../conditionConstants";

function* validateAndUpdateEntry(
  entry: any,
  elements: InputElement[],
  entryId: string,
  form: string
) {
  const validations = ignoreExpiring(
    execValidations(entry, elements, {}, entryId, form, [])
  );
  const company = yield select(getCompany);

  const valid = Object.keys(validations).length === 0;
  const updatedEntry = {
    ...entry,
    valid,
  };
  yield firestore
    .collection(`companies/${company}/entries/${form}/entries`)
    .doc(entryId)
    .update(updatedEntry);

  return valid;
}

function* validateFormEntries(form: string, entries: Record<string, any>) {
  try {
    const formElements = yield select(getElementsByForm, { form });
    yield all(
      Object
        // @ts-ignore
        .entries(entries)
        // @ts-ignore
        .map(([entryId, entry]) =>
          validateAndUpdateEntry(entry, formElements, entryId, form)
        )
    );
  } catch (e) {
    return;
  }
}

function* validateAllEntries() {
  const allEntries = yield select(getAll);
  const listOfFormsToIgnore = yield select(formsToIgnore);

  yield all(
    Object.entries(allEntries)
      .filter(([form]) => !listOfFormsToIgnore.includes(form))
      // @ts-ignore
      .map(([form, formEntries]) => validateFormEntries(form, formEntries))
  );
}

function* fetchEntries(ref: any, form: string) {
  const expiringElements = yield select(getExpiringElementsByForm, { form });
  const referenceMap = yield select(
    getInlineListAndCrossSelectElementsNameAndReferenceByForm,
    { form }
  );

  const entries: any = {};
  yield ref.get().then((querySnapshot: any) => {
    querySnapshot.forEach((doc: any) => {
      const entry = doc.data();
      let valid = entry.valid;

      const entryExpirationStatusList = expiringElements
        // @ts-ignore
        .filter(({ name }) => entry[name])
        // @ts-ignore
        .map(({ name, expires }) =>
          getExpirationStatus(
            new Date(entry[name].value),
            parseInt(expires, 10)
          )
        );

      if (entryExpirationStatusList.includes(ExpirationStatus.EXPIRED)) {
        valid = false;
      }

      entries[doc.id] = { ...entry, valid };
    });
  });

  populateEntriesGraph(form, entries, referenceMap);
  yield put(actions.fetched({ [form]: entries }));
  yield put(actions.increaseLoadProgress());

  return [form, Object.keys(entries).length];
}

/*
function attachRefListener(ref: any) {
  return eventChannel((emit) => ref.onSnapshot(emit));
}

function* listenForValues(ref: any, form: string) {
  const channel = yield call(attachRefListener, ref);
  const expiringElements = yield select(getExpiringElementsByForm, { form });
  const referenceMap = yield select(
    getInlineListAndCrossSelectElementsNameAndReferenceByForm,
    { form }
  );

  try {
    while (true) {
      const snapshot = yield take(channel);
      const entries: any = {};
      snapshot.docChanges().forEach((change: any) => {
        if (["added", "modified"].includes(change.type)) {
          const entry = change.doc.data();
          let valid = entry.valid;

          const entryExpirationStatusList = expiringElements
            // @ts-ignore
            .filter(({ name }) => entry[name])
            // @ts-ignore
            .map(({ name, expires }) =>
              getExpirationStatus(
                new Date(entry[name].value),
                parseInt(expires, 10)
              )
            );

          if (entryExpirationStatusList.includes(ExpirationStatus.EXPIRED)) {
            valid = false;
          }

          entries[change.doc.id] = { ...entry, valid };
        }
      });

      populateEntriesGraph(form, entries, referenceMap);

      yield put(actions.fetched({ [form]: entries }));
    }
  } finally {
    if (yield cancelled() && typeof ref.unsubscribe === "function") {
      ref.unsubscribe();
    }
  }
}
*/

export const sanitizeEntry = (entry: any) => {
  if (!entry) {
    return entry;
  }

  return Object.entries(entry).reduce(
    (cleanedEntry: any, [key, value]: any) => {
      if (typeof value === "object" && value !== null) {
        cleanedEntry[key] = deleteIn(
          value,
          "label",
          "placeholder",
          "elements",
          "defaultValue",
          "filters"
        );
        return cleanedEntry;
      }

      cleanedEntry[key] = value;
      return cleanedEntry;
    },
    {}
  );
};

const flagDeletedFields = (entry: any) => {
  if (!entry) {
    return entry;
  }

  return Object.entries(entry).reduce(
    (acc: any, [key, value]: [string, any]) => {
      acc[key] = value;

      if (acc[key] === null) {
        acc[key] = firebase.firestore.FieldValue.delete();
      }
      return acc;
    },
    {}
  );
};

function deduplicateAndCleanArrayValues(values: string[]) {
  const deduplicatedValues = Array.from(new Set([...values]));
  return deduplicatedValues.filter((id) => id !== Defaults.NEW_ENTRY_ID);
}

function* updateFirebase(
  form: string,
  id: string,
  uid: string,
  company: string,
  entry: any,
  valid: boolean,
  redirect?: any
) {
  let storeId = id;

  const isRoot = yield select(isRootOrSuperuser);

  if (!isRoot) {
    entry.lastStoredBy = uid;
  }

  entry.formVersion = yield select((state) => state.forms.forms[form].latest);
  entry = sanitizeEntry(entry);

  if (storeId !== Defaults.NEW_ENTRY_ID) {
    const record = yield firestore
      .collection(`companies/${company}/entries/${form}/entries`)
      .doc(storeId)
      .get();

    if (!record.exists) {
      yield firestore
        .collection(`companies/${company}/entries/${form}/entries`)
        .doc(storeId)
        .set({});
    }
  }

  // @todo: deduplicate this code because a lot of logic is repeated
  if (storeId === Defaults.NEW_ENTRY_ID) {
    entry.created = Math.round(Date.now() / 1000);
    entry = flagDeletedFields(entry);
    const newEntry = yield firestore
      .collection(`companies/${company}/entries/${form}/entries`)
      .add(entry);
    storeId = newEntry.id;

    const parentFormAndId = yield select(getCurrentFormsParentFormAndId);

    if (parentFormAndId) {
      // Update the parent with this new id
      const parentRecord = yield select(getEntryByFormAndId, parentFormAndId);

      const updatedParent: any = {
        ...parentRecord,
        [form]: { value: [storeId], valid },
      };
      if (parentRecord[form] && parentRecord[form].value) {
        updatedParent[form].value = deduplicateAndCleanArrayValues([
          ...parentRecord[form].value,
          storeId,
        ]);
      }

      const elements = yield select(getElementsByForm, {
        form: parentFormAndId.form,
      });
      const isValid = yield validateAndUpdateEntry(
        JSON.parse(JSON.stringify(updatedParent)),
        elements,
        parentFormAndId.entryId,
        parentFormAndId.form
      );

      const newParentEntry = {
        ...updatedParent,
        id: parentFormAndId.entryId,
        formName: parentFormAndId.form,
        valid: isValid,
      };
      yield put(actions.updateInState(newParentEntry));
      const referenceMap = yield select(
        getInlineListAndCrossSelectElementsNameAndReferenceByForm,
        { form: parentFormAndId.form }
      );

      // Already update this entry in the store so the entries graph population does not break things
      yield put(
        actions.updateInState(
          { ...entry, id: storeId, formName: form },
          id === Defaults.NEW_ENTRY_ID
        )
      );
      populateEntriesGraph(
        parentFormAndId.form,
        { [parentFormAndId.entryId]: newParentEntry },
        referenceMap
      );
    }

    if (redirect) {
      redirect(storeId);
    }
  } else {
    const localEntry = yield select(getEntryByFormAndId, {
      form,
      entryId: storeId,
    });
    const elements = yield select(getElementsByForm, { form });
    const valuesToKeep = elements
      .filter(({ type }: any) => [FormType.LIST, FormType.FORM].includes(type))
      .reduce((acc: any, { name, type, options }: any) => {
        if (type === FormType.LIST) {
          acc[options[0].elements[0].name] =
            localEntry[options[0].elements[0].name];
          return acc;
        }

        acc[name] = localEntry[name];
        return acc;
      }, {});

    entry = JSON.parse(JSON.stringify({ ...entry, ...valuesToKeep }));
    entry = flagDeletedFields(entry);

    yield firestore
      .collection(`companies/${company}/entries/${form}/entries`)
      .doc(storeId)
      .update(entry);

    const parentFormAndId = yield select(getCurrentFormsParentFormAndId);

    if (parentFormAndId && parentFormAndId.form !== form) {
      // Update the parent with this new id
      const parentRecord = yield select(getEntryByFormAndId, parentFormAndId);

      const updatedParent: any = {
        ...parentRecord,
        [form]: { value: [storeId], valid },
      };
      if (parentRecord[form] && parentRecord[form].value) {
        updatedParent[form].value = deduplicateAndCleanArrayValues([
          ...parentRecord[form].value,
          storeId,
        ]);
      }

      const elements = yield select(getElementsByForm, {
        form: parentFormAndId.form,
      });
      const isValid = yield validateAndUpdateEntry(
        JSON.parse(JSON.stringify(updatedParent)),
        elements,
        parentFormAndId.entryId,
        parentFormAndId.form
      );

      yield put(
        actions.updateInState({
          ...updatedParent,
          id: parentFormAndId.entryId,
          formName: parentFormAndId.form,
          valid: isValid,
        })
      );
    }
  }

  yield put(
    actions.updateInState(
      { ...entry, id: storeId, formName: form },
      id === Defaults.NEW_ENTRY_ID
    )
  );
  yield put(actions.stored());

  return storeId;
}

function* createRecord(field: string, value: any, form: string) {
  const company = yield select(getCompany);

  const user = yield select(getUser);
  const entry = {
    [field]: value,
    lastStoredBy: user.uid,
    created: Math.round(Date.now() / 1000),
    valid: true,
  };
  entry.lastStoredBy = user.uid;
  entry.formVersion = yield select((state) => state.forms.forms[form].latest);

  const elements = yield select(getElementsByForm, { form });
  const destinationElement = elements.find(
    ({ name }: InputElement) => name === field
  );

  if (destinationElement && destinationElement.type === "crossSelect") {
    const defaultValues = yield select(getDefaultValuesByFormAndSource, {
      form,
      element: field,
      value: value.value,
    });
    defaultValues.forEach(({ name, value }: any) => {
      entry[name] = { value };
    });
  }

  const newEntry = yield firestore
    .collection(`companies/${company}/entries/${form}/entries`)
    .add(entry);

  return newEntry.id;
}

function* updateParent(
  form: string,
  entryIds: string[],
  parent: { form: string; entryId: string }
) {
  const parentRecord = yield select(getEntryByFormAndId, parent);

  if (!parentRecord) {
    return;
  }

  const company = yield select(getCompany);

  const updatedParent: any = { ...parentRecord, [form]: { value: entryIds } };
  if (parentRecord[form] && parentRecord[form].value) {
    updatedParent[form].value = [
      ...parentRecord[form].value.filter(
        (id: string) => id !== Defaults.NEW_ENTRY_ID
      ),
      ...entryIds,
    ];
  }

  yield put(
    actions.updateInState({
      ...updatedParent,
      id: parent.entryId,
      formName: parent.form,
    })
  );

  yield firestore
    .collection(`companies/${company}/entries/${parent.form}/entries`)
    .doc(parent.entryId)
    .update(JSON.parse(JSON.stringify(updatedParent)));
}

function* createListEntries(rule: Rule, parentId: string) {
  const sourceEntries = yield select(getEntriesByForm, { form: rule.source });
  const sourceElements = yield select(getElementsByForm, { form: rule.source });

  const validatedSourceEntries = Object.entries(sourceEntries)
    .map(([key, entry]: [string, any]) => {
      if (rule.validations) {
        const ruleValidationValid = rule.validations.some(({ field }: any) => {
          return entry[field] && entry[field].value === "true";
        });

        if (!ruleValidationValid) {
          return false;
        }
      }

      const validations = execValidations(
        entry,
        sourceElements,
        {},
        key,
        rule.source,
        []
      );

      // if validations failed
      if (Object.keys(validations).length > 0) {
        return false;
      }

      return { ...entry, id: key };
    })
    .filter((value) => value);

  let form = rule.destination;
  let listField = rule.listField;

  if (rule.listField && rule.listField.includes(".")) {
    [form, listField] = rule.listField.split(".");
  }

  const destinationElements = yield select(getElementsByForm, { form });
  const destinationParentElement = destinationElements.find(
    ({ name }: InputElement) => name === listField
  );

  if (!destinationParentElement) {
    return undefined;
  }

  const destinationFormName =
    destinationParentElement.options[0].elements[0].name;

  const entryIds = yield all(
    validatedSourceEntries.map((entry: any) => {
      return call(
        createRecord,
        rule.destinationField,
        rule.sourceField && entry[rule.sourceField]
          ? entry[rule.sourceField]
          : { value: entry.id },
        destinationFormName
      );
    })
  );

  yield call(updateParent, destinationFormName, entryIds, {
    form: rule.destination,
    entryId: parentId,
  });
}

function* maybeCreateListItems(form: string, entryId: string) {
  const destinationRules: Rule[] = yield select(getRulesByDestinationForm, {
    form,
  });

  if (destinationRules.length === 0) {
    return false;
  }

  yield all(
    destinationRules
      .filter(({ type }) => type === RuleType.LIST_ITEM_CREATION)
      .map((rule: Rule): any => call(createListEntries, rule, entryId))
  );
}

function* applyRule(form: string, entry: any, company: string, uid: string) {
  if (entry.ruleApplied) {
    return true;
  }

  const sourceRules: Rule[] = yield select(getRulesBySourceForm, { form });

  if (sourceRules.length === 0) {
    return false;
  }

  interface EntryToCreate {
    form: string;
    entry: any;
    storeId: string;
  }

  const entriesToCreateAfterSave = sourceRules
    .filter(({ type }) => type === RuleType.AFTER_SAVE)
    .map(
      ({
        sourceField,
        destination,
        destinationField,
      }: Rule): EntryToCreate => ({
        form: destination,
        entry: { [destinationField]: entry[sourceField] },
        storeId: Defaults.NEW_ENTRY_ID,
      })
    );

  yield all(
    entriesToCreateAfterSave.map(({ form, entry, storeId }) => {
      return call(updateFirebase, form, storeId, uid, company, entry, true);
    })
  );

  return true;
}

function* storeEntry({ payload, meta }: FSA) {
  const { formName, id, ...entry } = JSON.parse(JSON.stringify(payload.entry));

  const listOfFormsToIgnore = yield select(formsToIgnore);
  if (listOfFormsToIgnore.includes(formName)) {
    return;
  }

  const company = yield select(getCompany);
  const { uid } = yield select(getUser);

  if (payload.valid) {
    entry.ruleApplied = yield applyRule(formName, entry, company, uid);
  }

  let storeId = id || Defaults.NEW_ENTRY_ID;

  const entryId = yield call(
    updateFirebase,
    formName,
    storeId,
    uid,
    company,
    entry,
    payload.valid,
    meta.redirect
  );

  if (id === Defaults.NEW_ENTRY_ID) {
    yield maybeCreateListItems(formName, entryId);
  }

  const referenceMap = yield select(
    getInlineListAndCrossSelectElementsNameAndReferenceByForm,
    { form: formName }
  );
  populateEntriesGraph(formName, { [entryId]: entry }, referenceMap);
}

function* deleteFiles({ payload }: FSA) {
  yield all(payload.paths.map(deleteFile));
}

function* deleteFile(path: string) {
  if (path.endsWith(".png") || path.endsWith(".jpg")) {
    const thumbnailPath = path.split("/");
    thumbnailPath[thumbnailPath.length - 1] = `thumb_${
      thumbnailPath[thumbnailPath.length - 1]
    }`;
    yield storage.ref(thumbnailPath.join("/")).delete();
  }

  return storage.ref(path).delete();
}

function* deleteEligibleEdgeEntries(
  company: string,
  entryId: string,
  formsOfEntriesToDelete: string[]
): any {
  if (!entriesGraph.has(entryId)) {
    return;
  }

  const entryNode = entriesGraph.get(entryId);

  if (entryNode.name && !formsOfEntriesToDelete.includes(entryNode.name)) {
    return;
  }

  const fileElements = yield select(getFileElementsByForm, {
    form: entryNode.name!,
  });
  const inlineListFileElements = yield select(getInlineListFileElementsByForm, {
    form: entryNode.name!,
  });

  const entry = yield select(getEntryByFormAndId, {
    form: entryNode.name!,
    entryId,
  });
  const filepathsToDelete: string[] = fileElements
    .map(({ name }: InputElement) => {
      if (!entry[name]) {
        return false;
      }

      return entry[name].value.path;
    })
    .filter((value: boolean | string) => value);

  const subFormFilePathsToDelete = inlineListFileElements
    .filter(
      ({ fileElements }: { fileElements: string[] }) => fileElements.length > 0
    )
    .map(({ name, fileElements }: { name: string; fileElements: string[] }) => {
      if (!entry[name]) {
        return;
      }

      return Object.values(entry[name])
        .map((inlineEntry: any) => {
          return fileElements.map((valueHandle) => {
            if (!inlineEntry[valueHandle]) {
              return;
            }

            return inlineEntry[valueHandle].value.path;
          });
        })
        .reduce((acc: string[], values: string[]) => {
          return [...acc, ...values];
        }, []);
    })
    .reduce((acc: string[], values: string[]) => {
      return [...acc, ...values];
    }, []);

  yield all(
    [...filepathsToDelete, ...subFormFilePathsToDelete].map(deleteFile)
  );

  yield firestore
    .collection(`companies/${company}/entries/${entryNode.name}/entries`)
    .doc(entryId)
    .delete();

  if (entryNode.edges.size === 0) {
    return;
  }

  const edges = Array.from(entryNode.edges);

  yield all(
    edges.map((subEntry: string) =>
      deleteEligibleEdgeEntries(company, subEntry, formsOfEntriesToDelete)
    )
  );

  return;
}

function* deleteEntry({ payload }: FSA) {
  const { form, entryId } = payload;

  const listOfFormsToIgnore = yield select(formsToIgnore);
  if (listOfFormsToIgnore.includes(form)) {
    return;
  }

  const company = yield select(getCompany);
  /*
  const branch = yield select(getBranchByForm, { form });
  const [root] = Object.keys(branch);
  const childFormsOfBranch = branch[root].map(
    ({ name }: { name: string }) => name
  );
  yield deleteEligibleEdgeEntries(company, entryId, childFormsOfBranch);
  */

  const parent = yield select(getCurrentFormsParentFormAndId);

  if (
    parent &&
    parent.entryId &&
    parent.form &&
    entryId !== Defaults.NEW_ENTRY_ID
  ) {
    let parentRecord = yield select(getEntryByFormAndId, {
      form: parent.form,
      entryId: parent.entryId,
    });

    if (parentRecord[form]) {
      parentRecord[form].value = parentRecord[form].value.filter(
        (id: string) => id !== entryId
      );
      parentRecord = sanitizeEntry(parentRecord);

      if (parentRecord[form].value.length === 0) {
        parentRecord[form] = firebase.firestore.FieldValue.delete();
      }

      try {
        yield firestore
          .collection(`companies/${company}/entries/${parent.form}/entries`)
          .doc(parent.entryId)
          // @ts-ignore
          .update(parentRecord);
      } catch (e) {
        console.warn("Update parent failed", e);
        // do nothing
      }

      if (!parentRecord[form].value) {
        parentRecord[form].value = [Defaults.NEW_ENTRY_ID];
      }
    }

    yield put(
      actions.updateInState({
        ...parentRecord,
        id: parent.entryId,
        formName: parent.form,
      })
    );
  }

  yield put(actions.deleteEntryInState(form, entryId));
}

function* isInitialLoadDone(forms: string[]) {
  const remainingForms = [...forms];

  while (remainingForms.length > 0) {
    const { payload } = yield take(actions.FETCHED);
    remainingForms.splice(remainingForms.indexOf(Object.keys(payload)[0]), 1);
  }

  yield put(actions.initialLoadDone());
}

export default function* saga() {
  const forms = yield take(formActions.FETCHED_ALL);
  yield put(actions.allFetched());
  const company = yield select(getCompany);

  // Figure out the characteristics so we can create this list on the fly
  const formsToIgnore = [
    "asbestosInventoryOffice",
    "asbestosTypes",
    "assigneAllocateDTA",
    "assignedPartners",
    "catergories",
    "checklistDemolitionSetup",
    "checklistWorkplaceInspection",
    "containmentWorkerForm",
    "exports",
    "exposureHoursEmployee",
    "exposureHoursProject",
    "fileUploadAscert",
    "fileUploadBlower1",
    "fileUploadBlower2",
    "fileUploadFaceFit1",
    "fileUploadFaceFit2",
    "fileUploadFiberLimitation",
    "fileUploadFiberProtection",
    "fileUploadMask1",
    "fileUploadMask2",
    "fileUploadMedicalWaiver",
    "fileUploadProcessCertificate",
    "fileUploadProjectBlueprint",
    "fileUploadProjectFile",
    "fileUploadProjectFoto",
    "fileUploadSituationDrawing",
    "fileUploadToolCertificate",
    "fileUploadVCA",
    "fileUploadWorkAreaPhoto",
    "fileUploadtoolDocuments",
    "filesDemolitionNotification",
    "filesISZW",
    "filesLAVS",
    "finalDeliveryChecklist",
    "generalList",
    "hoursList",
    "instructedPersons",
    "machineAdmin",
    "manifest",
    "materialAppliedCategories",
    "partner",
    "productSheetsOverview",
    "projectBlueprint",
    "projectPlan",
    "projects",
    "references",
    "removedSources",
    "rightsTest",
    "templates",
    "thirdPartiesInstructionRK1File",
    "timeInOut",
    "toolCertificate",
    "toolDocuments",
    "tools",
    "workedHours",
    "workersHours",
  ];
  const formNames = Object.keys(forms.payload).filter(
    (form) => !formsToIgnore.includes(form)
  );
  const shuffledFormNames = shuffle(formNames);

  yield put(
    actions.initialLoadProgressUpdate({
      loaded: 0,
      total: shuffledFormNames.length,
    })
  );
  yield fork(isInitialLoadDone, shuffledFormNames);

  yield all(
    shuffledFormNames.map((form: string) =>
      call(
        fetchEntries,
        firestore.collection(`/companies/${company}/entries/${form}/entries`),
        form
      )
    )
  );

  // note: this is disabled due to a lot of listeners
  /*
  yield all(
    shuffledFormNames.map((form: string) =>
      fork(
        listenForValues,
        firestore.collection(`/companies/${company}/entries/${form}/entries`),
        form
      )
    )
  );
  */

  yield takeEvery(actions.SAVE, storeEntry);
  yield takeEvery(actions.DELETE, deleteEntry);
  yield takeEvery(actions.BULK_VALIDATE, validateAllEntries);
  yield takeEvery(actions.DELETE_FILES, deleteFiles);
  // @ts-ignore
  yield takeEvery(actions.FETCH_FORM_ENTRIES, fetchFormEntries, company);
  // @ts-ignore
  yield takeEvery(actions.FETCH_ENTRY, fetchEntry, company);
}

function* locationChanged(company: string) {
  const { form, entryId } = yield select(getCurrentFormAndId);
  if (entryId === Defaults.NEW_ENTRY_ID) {
    return;
  }

  if (!entryId) {
    yield call(fetchFormEntries, company, actions.fetchFormEntries(form));
  } else {
    const entry = yield call(
      fetchEntry,
      company,
      actions.fetchEntry(form, entryId)
    );
    const formElements = yield select(getElementsByForm, { form });
    const conditions = formElements
      .filter(
        (element: any) => element.conditions && element.conditions.length > 0
      )
      .map(({ conditions }: any) => conditions)
      .flat();
    const subFormsConditionsRelyOn = conditions
      .filter(({ type }: any) =>
        [Conditions.VALID, Conditions.VALID_AND_CLOSED].includes(type)
      )
      .map(({ source }: any) => source);

    const entriesToFetch = formElements
      .filter(({ name }: any) => subFormsConditionsRelyOn.includes(name))
      .map((element: any) => {
        let referencedFormName = element.name;
        if (element.type === "list") {
          referencedFormName = element.options[0].elements[0].name;
        }

        if (entry[referencedFormName] && entry[referencedFormName].value) {
          return [referencedFormName, entry[referencedFormName].value];
        }

        return [referencedFormName, []];
      });

    for (const conditionEntries of entriesToFetch) {
      if (conditionEntries[1]) {
        for (const conditionEntry of (conditionEntries[1] as unknown) as string[]) {
          yield call(
            fetchEntry,
            company,
            actions.fetchEntry(conditionEntries[0], conditionEntry)
          );
        }
      }
    }
  }
}

function* fetchFormEntries(company: string, action: any) {
  let formName = action.payload.form;

  // if this is not a form it might be a list
  if (!formGraph.has(formName)) {
    const formElements = yield select(getElementsByForm, { form: formName });

    if (!formElements) {
      return;
    }

    const parentFormAndId = yield select(getCurrentFormsParentFormAndId);

    if (parentFormAndId) {
      const parentEntry = yield call(
        fetchEntry,
        company,
        actions.fetchEntry(parentFormAndId.form, parentFormAndId.entryId)
      );
      const subFormName = formElements[0].options[0].elements[0].name;
      if (parentEntry[subFormName] && parentEntry[subFormName].value) {
        const referencedEntries = parentEntry[subFormName].value;
        for (const referencedEntry in referencedEntries) {
          yield call(
            fetchEntry,
            company,
            actions.fetchEntry(subFormName, referencedEntries[referencedEntry])
          );
        }
      }
    }

    return;
  } else {
    const formObject = formGraph.get(formName);

    if (formObject.type === "root") {
      formName = formObject.edges.values().next().value;
    }
  }

  yield call(
    fetchEntries,
    firestore.collection(`/companies/${company}/entries/${formName}/entries`),
    formName
  );
}

function* fetchEntry(company: string, action: any): any {
  const docRef = firestore
    .collection(`/companies/${company}/entries/${action.payload.form}/entries`)
    .doc(action.payload.entryId);

  // copy the document
  const entry = yield docRef
    .get({ source: "server" })
    .then((doc) => doc.exists && doc.data());

  yield put(
    actions.fetched({
      [action.payload.form]: { [action.payload.entryId]: entry },
    })
  );

  return entry;
}

function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
