import { createSelector } from "reselect";
import { getElementsByForm } from "./forms";
import { sortBySequence } from "../utility/helpers";
import { InputElement } from "../constants";
import { getConditionStatus } from "./conditions";

const getSortedElementsByForm = createSelector(
  getElementsByForm,
  // @ts-ignore
  (elements: InputElement[]) => elements.sort(sortBySequence)
);

export const getElementsSortedAndFilteredByFormAndEntry = createSelector(
  getSortedElementsByForm,
  getConditionStatus,
  (elements, metConditions) =>
    elements.filter(({ conditions, name }: InputElement) => {
      if (conditions && conditions.length > 0 && !metConditions[name]) {
        return false;
      }

      return true;
    })
);
