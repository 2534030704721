import { LOCATION_CHANGE } from 'connected-react-router';
// @ts-ignore
import { matchPath } from 'react-router-dom';

import { FETCHED_ALL, SET_FIXED } from '../actions/form';
import { FSA, RoutingState, RoutingBranch, FormType } from '../constants';
import { LOGGED_OUT } from '../actions/auth';

const initialState = {
    routingTable: {},
    root: '',
    path: '',
    pathParams: {},
    form: '',
};

// @todo:
// - combine all parent things into one
const computeRoutingTable = ({ path, parentType, parentName }: any, form: any, forms: any) => {
    let elements = form.elements;
    const initialTable: any[] = [];

    if (form.type === FormType.MENU && form.isRoot) {
        const route = {
            path,
            parent: path,
            type: form.type,
            name: form.name,
            entryId: '',
            parentType,
            parentName,
            entriesHandle: '',
        };

        parentType = form.type;
        parentName = form.name;

        initialTable.push(route);
    }

    if (!elements) {
        if (form.versions && form.versions[form.latest] && form.versions[form.latest].elements) {
            elements = form.versions[form.latest].elements;
        } else {
            return [];
        }
    }

    return elements
        .filter(({ type }: any) => [FormType.ROOT, FormType.FORM, FormType.LIST, FormType.MENU].includes(type))
        .map((element: any) => {
            if (element.type === FormType.FORM || element.type === FormType.MENU) {
                const subForm = forms[element.name];
                if (subForm && subForm.versions[subForm.latest]) {
                    subForm.elements = subForm.versions[subForm.latest].elements;
                }
                return { ...element, options: [subForm], name: element.name };
            }

            return element;
        })
        .reduce((table: any, { name, options, type }: any) => {
            const route = {
                path: `${path}/${name}`,
                parent: path,
                type,
                name,
                entryId: '',
                parentType,
                parentName,
                entriesHandle: name,
            };
            let parentPath;

            switch (type) {
                case FormType.ROOT:
                    route.path = path;
                    parentPath = path;
                    route.name = path.replace('/', '');
                    if (options[0].elements && options[0].elements[0] && options[0].elements[0].name) {
                        route.entriesHandle = options[0].elements[0].name;
                    }

                    table.push(route);

                    if (options[0].isGrouped) {
                        table.push({
                            ...route,
                            path: `${route.path}/:${name}Group`,
                            parentType: route.type,
                            parentName: route.name,
                            type: FormType.LIST,
                        });
                        parentPath = `${route.path}/:${name}Group`;
                    }

                    break;
                case FormType.FORM:
                    route.path = `${path}/${name}/:${name}Id`;
                    parentPath = `${path}/${name}/:${name}Id`;
                    route.entryId = `${name}Id`;
                    if (parentType === FormType.ROOT) {
                        route.path = `${path}/:${name}Id`;
                        parentPath = `${path}/:${name}Id`;
                    }

                    table.push(route);
                    break;
                case FormType.LIST:
                    name = parentName;
                    parentPath = route.path;
                    table.push(route);
                    break;
                case FormType.MENU:
                    route.path = `${path}/${name}`;
                    parentPath = `${path}/${name}`;
                    route.entryId = '';
                    if (parentType === FormType.ROOT) {
                        route.path = path;
                        parentPath = path;
                    }

                    table.push(route);
                    break;
            }

            return table.concat(computeRoutingTable({ path: parentPath, parentType: type, parentName: name }, options[0], forms));
        }, initialTable);
}

const disectPath = (branch: RoutingBranch[], currentPath: string) => {
    if (!branch || branch.length === 0) {
        return { form: '', params: {} };
    }

    // const currentRoute = branch
    //     .map(({ path, name }) => ({ ...matchPath(currentPath, { path, exact: true, strict: true }), name }))
    //     .filter(({ isExact }) => isExact);
    const currentRoute = branch
        .map(({ path, name }) => ({ ...matchPath(currentPath, { path, strict: true }), name }))
        .filter(({ params }) => params)
        .reverse();

    if (currentRoute.length === 0) {
        return { form: '', params: {} };
    }

    return {
        form: currentRoute[0].name,
        params: currentRoute[0].params,
    };
};

export default (state: RoutingState = initialState, { type, payload }: FSA) => {
    switch (type) {
        case FETCHED_ALL: {
            const routingTable = Object
                .entries(payload)
                .filter(([, { type, isRoot }]: any) => type === 'root' || (type === FormType.MENU && isRoot))
                .reduce((routes: any, [key]: any) => {
                    routes[key] = computeRoutingTable({ path: `/${key}` }, payload[key], payload);
                    return routes;
                }, state.routingTable);

            const { form, params } = disectPath(routingTable[state.root], state.path);

            return {
                ...state,
                routingTable,
                pathParams: params,
                form,
            };
        }
        case SET_FIXED: {
            const routingTable = Object
                .entries(payload)
                .filter(([, { type }]: any) => [FormType.ROOT, FormType.MENU, "root"].includes(type))
                .reduce((routes: any, [key]: any) => {
                    routes[key] = computeRoutingTable({ path: `/${key}` }, payload[key], payload);
                    return routes;
                }, state.routingTable);

            const { form, params } = disectPath(routingTable[state.root], state.path);

            return {
                ...state,
                routingTable,
                pathParams: params,
                form,
            };
        }
        case LOCATION_CHANGE: {
            const [, root] = payload.location.pathname.split('/');
            const { form, params } = disectPath(state.routingTable[root], payload.location.pathname);

            return {
                ...state,
                path: payload.location.pathname,
                root,
                pathParams: params,
                form,
            }
        }
        case LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
