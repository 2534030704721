import { eventChannel, END } from "redux-saga";

export default function scheduler(
  duration: number = 300,
  interval: number = 60
) {
  return eventChannel((emitter) => {
    let elapsedIntervalDuration = 0;
    const intervalId = setInterval(() => {
      elapsedIntervalDuration += interval;

      if (elapsedIntervalDuration < duration) {
        emitter(elapsedIntervalDuration);
      } else {
        emitter(END);
      }
    }, interval * 1000);

    return () => {
      clearInterval(intervalId);
    };
  });
}
