import { Defaults, FormType } from "../constants";
import { addEdge, addVertex, entriesGraph, formGraph } from "../graph";
import { randomString } from "./helpers";

interface Reference {
  name: string;
  reference: string;
}

const getReferencedFormEntryHandle = (
  edge: string,
  crossSelectReferenceMap: Reference[]
) => {
  const reference = crossSelectReferenceMap.find(
    ({ reference }) => reference === edge
  );

  if (!reference) {
    return edge;
  }

  return reference.name;
};

const getAllDirectEdges = (edges: Set<string>) => {
  let referencedForms: string[] = [];

  edges.forEach((edge) => {
    if (formGraph.has(edge)) {
      const edgeNode = formGraph.get(edge);

      if (edgeNode.type !== FormType.MENU) {
        referencedForms.push(edge);
      } else {
        referencedForms = [
          ...referencedForms,
          ...getAllDirectEdges(edgeNode.edges),
        ];
      }
    }
  });

  return referencedForms;
};

function createEdges(
  form: string,
  id: string,
  entry: any,
  referenceMap: Reference[]
) {
  addVertex(entriesGraph, { name: form, id });
  const formVertex = formGraph.get(form);
  if (formVertex && formVertex.edges.size > 0) {
    const referencedForms = getAllDirectEdges(formVertex.edges);
    referencedForms.forEach((edge) => {
      const referencedFormHandle = getReferencedFormEntryHandle(
        edge,
        referenceMap
      );

      // @ts-ignore
      if (entry[referencedFormHandle] && entry[referencedFormHandle].value) {
        const value = entry[referencedFormHandle].value;

        // @ts-ignore
        if (Array.isArray(value)) {
          // @ts-ignore
          value.forEach((refId) => {
            if (refId !== Defaults.NEW_ENTRY_ID) {
              addEdge(entriesGraph, id, refId, true);
            }
          });
          // @ts-ignore
        } else if (value !== Defaults.NEW_ENTRY_ID) {
          // @ts-ignore
          addEdge(entriesGraph, id, value, true);
        }
      } else if (
        entry[referencedFormHandle] &&
        !entry[referencedFormHandle].label &&
        formGraph.has(edge)
      ) {
        const inlineListEntryKeys = Object.keys(entry[referencedFormHandle]);

        if (
          inlineListEntryKeys.length == 0 ||
          !inlineListEntryKeys.includes("0")
        ) {
          return;
        }

        // if this is the case, we are handling a inlineList form
        Object.values(entry[referencedFormHandle]).forEach(
          (inlineListEntry: any) => {
            // we generate a random id because this will only serve reference lookups
            const randomId = randomString();
            createEdges(edge, randomId, inlineListEntry, referenceMap);
            addEdge(entriesGraph, id, randomId, true);
          }
        );
      }
    });
  }
}

export const populateEntriesGraph = (
  form: string,
  entries: Record<string, any>,
  referenceMap: Reference[] = []
) =>
  Object.entries(entries).forEach(([id, entry]) =>
    createEdges(form, id, entry, referenceMap)
  );
