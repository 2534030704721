import { FSA, UserState } from '../constants';

export const STATUS_CHANGED = 'AUTH_STATUS_CHANGED';
export const statusChanged = (user: UserState): FSA => ({
    type: STATUS_CHANGED,
    payload: user,
});

export const LOGOUT = 'AUTH_LOGOUT';
export const logout = (): FSA => ({ type: LOGOUT });

export const LOGGED_OUT = 'AUTH_LOGGED_OUT';
export const loggedOut = (): FSA => ({ type: LOGGED_OUT });
