import { put } from "redux-saga/effects";
import * as actions from "../../actions/export";
import { InputElement, InputTypes } from "../../constants";
import { sortBySequence, getLabel } from "../../utility/helpers";
import { containmentCalculatorToHTML } from "./html/containmentCalculator";

const generateValueContainer = (
  columnSpan: number = 12,
  label: string,
  content: string
) => {
  return `
<div class="field ${!label && "borderless"}" style="width: ${100 / (12 / columnSpan)}%">
  ${label ? `<span class="label">${label}</span><br />` : ""}
  <span class="content">${content}</span>
</div>
`;
};

const getHTMLElements = (
  elements: InputElement[],
  values: Record<string, any>,
  inlineListFormElements?: Record<string, InputElement[]>
) => {
  return (
    elements
      // @ts-ignore
      .sort(sortBySequence)
      .filter(({ type }: InputElement) => type !== InputTypes.CROSS_SELECT)
      .map((element: InputElement) => {
        if (element.type === InputTypes.SECTION) {
          // @ts-ignore
          if (element.options[0].hideLabel) {
            return `</div><div class="pageBreak">&nbsp;</div><div class="container">`;
          }
          // @ts-ignore
          return `</div><div class="pageBreak">&nbsp;</div><div class="section">${element.options[0].label}</div><div class="container">`;
        }
        const value = generatHTMLContent(element)(
          values[element.name],
          inlineListFormElements
        );
        if (element.type === InputTypes.INLINE_LIST) {
          return value;
        }
        if (element.type === InputTypes.CHECKBOX) {
          return generateValueContainer(element.columnWidth, "", value);
        }
        const label = getLabel(element.options);
        return generateValueContainer(element.columnWidth, label, value);
      })
  );
};

const inlineListToHTML = (element: InputElement) => (
  value: any,
  inlineListFormElements: Record<string, InputElement[]>
) => {
  const htmlElements = value.map((values: any) =>
    getHTMLElements(inlineListFormElements[element.name], values)
  );
  return htmlElements
    .map((itemElements: any[]) => itemElements.join(""))
    .join("");
};

const generatHTMLContent = (element: InputElement) => {
  const transformers = {
    [InputTypes.CONTAINMENT_CALCULATOR]: containmentCalculatorToHTML(element),
    [InputTypes.SIGNATURE]: (value: string) => `<img src='${value}'/>`,
    [InputTypes.FORM]: () => "",
    [InputTypes.INLINE_LIST]: inlineListToHTML(element),
    default: (value: any) => value || "",
  };

  // @ts-ignore
  return transformers[element.type] || transformers.default;
};

export function generateHTMLFile(
  title: string,
  elements: InputElement[],
  values: Record<string, any>,
  inlineListFormElements: Record<string, InputElement[]>
) {
  const htmlElements = getHTMLElements(
    elements,
    values,
    inlineListFormElements
  );
  return `
<html>
  <head>
    <title>${title}</title>
    <style>
    @page {
      size: A4;
    }
    html, body {
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      font-size: 16pt;
    }
    h1 {
      background-color: black;
      color: white;
      text-align: center;
      margin-bottom: 0;
      font-size: 1.4em;
      line-height: 1.8em;
    }
    .container {
    }
    .pageBreak {
      break-after: page;
      clear: both;
      height: 0;
    }
    .section {
      background-color: #ddd;
      text-align: center;
      padding: 10px 5px 8px 10px;
      clear: both;
      font-weight: bold;
    }
    .container div {
      border-top: 1px solid #ddd;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      box-sizing: border-box;
      float: left;
      padding: 10px 5px 8px 10px;
      page-break-inside: avoid;
    }
    .container div.borderless {
      border-top: none !important;
    }
    .field .label {
      margin-bottom: 4px;
      display: inline-block;
      color: #000;
      font-weight: bold;
    }
    .field .content {
      white-space: pre-wrap;
    }
    .inlineList {
      width: 100%;
      white-space: normal;
      border: none !important;
      padding: 0 !important;
    }
    table {
      border: 1px solid #e1e1e1;
      font-size: 16pt;
    }
    table thead td {
      font-weight: bold;
    }
    table td, table th {
      padding: 6px 10px;
      border-bottom: 1px solid #eee;
      min-width: 100px;
      text-align: left;
      font-weight: normal;
    }
    ul, ol {
      margin: 0;
      padding: 0 24px;
    }
    li {
      margin: -5px 0;
    }
    </style>
  </head>
  <body>
    <h1>${title}</h1>
    <div class="container">
      ${htmlElements.join("")}
    </div>
  </body>
</html>
`;
}

export default function* (
  title: string,
  elements: InputElement[],
  values: Record<string, any>,
  inlineListFormElements: Record<string, InputElement[]>
) {
  const html = generateHTMLFile(
    title,
    elements,
    values,
    inlineListFormElements
  );

  const newWindow = window.open();
  // @ts-ignore
  newWindow.document.open().write(html);

  yield put(actions.dialogClose());
  return;
}
