import { FormType, Roles } from "../constants";

export default {
    displayName: 'Form',
    name: 'formBuilderFormMeta',
    latest: 'default',
    type: FormType.FORM,
    versions: {
        default: {
            elements: [{
                name: 'displayName',
                options: [{ label: 'Label' }],
                required: true,
                sequence: 0,
                type: 'text',
            }, {
                name: 'type',
                options: [{
                    label: 'Type',
                    placeholder: '-- Choose the type --',
                    elements: [
                        { value: 'root', label: 'Root list' },
                        { value: FormType.FORM, label: 'Form' },
                        { value: FormType.MENU, label: 'Menu' },
                    ],
                }],
                required: true,
                sequence: 1,
                type: 'select',
                unique: false,
                columnWidth: 6,
            }, {
                name: 'name',
                options: [{ label: 'Technical name' }],
                required: true,
                sequence: 2,
                type: 'text',
                unique: true,
                columnWidth: 6,
            }, {
                name: 'deletable',
                options: [{ label: 'Deletable' }],
                required: false,
                sequence: 3,
                type: 'checkbox',
                unique: false,
                columnWidth: 4,
                conditions: [{
                    source: 'type',
                    type: 'notEquals',
                    values: FormType.MENU,
                }],
            }, {
                name: 'exportable',
                options: [{ label: 'Exportable' }],
                required: false,
                sequence: 4,
                type: 'checkbox',
                unique: false,
                columnWidth: 4,
                conditions: [{
                    source: 'type',
                    type: 'equals',
                    values: FormType.FORM,
                }],
            }, {
                name: 'closeable',
                options: [{ label: 'Closeable' }],
                required: false,
                sequence: 4,
                type: 'checkbox',
                unique: false,
                columnWidth: 4,
                conditions: [{
                    source: 'type',
                    type: 'equals',
                    values: FormType.FORM,
                }],
            }, {
                name: 'isRoot',
                options: [{ label: 'Is root' }],
                required: false,
                sequence: 4,
                type: 'checkbox',
                unique: false,
                columnWidth: 4,
                conditions: [{
                    source: 'type',
                    type: 'equals',
                    values: FormType.MENU,
                }],
            }, {
                name: 'sequence',
                options: [{ label: 'Sequence' }],
                required: true,
                sequence: 5,
                type: 'number',
                unique: false,
                columnWidth: 4,
            }, {
                name: 'overrideValidation',
                options: [{ label: 'Ignore validation on close' }],
                required: false,
                sequence: 6,
                type: 'checkbox',
                unique: false,
                columnWidth: 6,
                conditions: [{
                    source: 'type',
                    type: 'equals',
                    values: FormType.FORM,
                }],
            }, {
                name: 'roles',
                options: [{
                    label: 'Roles allowed to edit',
                    placeholder: '-- choose the roles --',
                    multiple: true,
                    elements: [
                        { label: 'Beheerder', value: Roles.ROOT },
                        { label: 'Supervisor', value: Roles.SUPERVISOR },
                        { label: 'Administrator', value: Roles.ADMIN },
                        { label: 'User', value: Roles.USER },
                    ],
                }],
                required: false,
                sequence: 7,
                type: 'select',
                unique: false,
                columnWidth: 6,
                conditions: [{
                    source: 'type',
                    type: 'equals',
                    values: FormType.FORM,
                }],
            }, {
                name: 'formBuilderFormFields',
                options: [{
                    addition: 'Add field',
                    displayFormat: '[sequence]: [displayName]',
                    elements: [{
                        name: 'formBuilderFormField',
                        type: FormType.FORM,
                    }],
                    label: 'Fields',
                    matchFields: 'sequence,displayName',
                }],
                required: false,
                sequence: 8,
                type: FormType.LIST,
                unique: false,
            }],
        },
    },
};
