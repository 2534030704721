import { FETCHED_ALL } from '../actions/rules';
import { FSA, RulesState } from '../constants';
import { LOGGED_OUT } from '../actions/auth';

const initialState: RulesState = {};

export default (state: any = initialState, { type, payload }: FSA) => {
    switch (type) {
        case FETCHED_ALL:
            return { ...payload };
        case LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
