import React from "react";
// @ts-ignore
import { useSelector } from "react-redux";

import { getErrorMessage } from "./Error";

import { theme } from "../../styles";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { State, ValidationTypes } from "../../constants";
import { convertDisplayFormat } from "../../selectors/displayFormat";
import {getLabel} from "../../utility/helpers";

const useStyles = makeStyles(() => ({
  input: theme.input,
}));

interface InputProps {
  options: any;
  name: string;
  handleChange?: any;
  type?: string;
  value: string | number;
  pattern?: string;
  title?: string;
  readonly?: boolean;
  validations?: any[];
  errors?: any[];
  disableUnderline?: boolean;
  endAdornment?: any;
  form?: string;
}

const Input = ({
  options,
  name,
  handleChange = () => {},
  type = "text",
  value,
  pattern = undefined,
  title = "",
  readonly = false,
  validations = [],
  errors = [],
  disableUnderline = true,
  endAdornment = undefined,
  form,
}: InputProps) => {
  const classes = useStyles();
  const { placeholder } = options[0];
  let placeholderValue = placeholder;
  let defaultValue = "";

  if (form && placeholder) {
    const selectorArgs = { displayFormat: placeholder, currentNode: form };
    placeholderValue = useSelector((state: State) =>
      convertDisplayFormat(state, selectorArgs)
    );
  }

  // @ts-ignore
  const customHandleChange = (event) => {
    handleChange(event);
  };

  const handleBlur = () => {
    if (validations) {
      const maxLimit = validations.find(
        ({ type }) => type === ValidationTypes.MAX_LIMIT
      );
      // @ts-ignore
      if (maxLimit && parseInt(value, 10) > parseInt(maxLimit.value, 10)) {
        // @ts-ignore
        alert(maxLimit.message);
      }

      const minLimit = validations.find(
        ({ type }) => type === ValidationTypes.MIN_LIMIT
      );
      // @ts-ignore
      if (minLimit && parseInt(value, 10) < parseInt(minLimit.value, 10)) {
        // @ts-ignore
        alert(minLimit.message);
      }
    }
  };

  const label = getLabel(options);
  const style = { marginTop: 13 };

  if (label) {
      style.marginTop = 8;
  }

  return (
    <>
      <TextField
        className={classes.input}
        id={name}
        name={name}
        type={type}
        label={label}
        style={style}
        fullWidth
        margin="normal"
        value={value || defaultValue}
        onChange={customHandleChange}
        // @ts-ignore
        pattern={pattern || undefined}
        title={title}
        disabled={readonly}
        InputLabelProps={{ shrink: true }}
        InputProps={{ disableUnderline, endAdornment }}
        // @ts-ignore
        helperText={
          errors.length > 0 &&
          getErrorMessage(errors[0].type, errors[0].message)
        }
        error={errors.length > 0}
        onBlur={handleBlur}
        placeholder={placeholderValue}
      />
      {options && options[0].helpText && (
        <details>
          <summary>Details</summary>
          <pre>{options[0].helpText}</pre>
        </details>
      )}
    </>
  );
};

export default Input;
