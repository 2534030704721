import React, { useState } from 'react';
import { auth } from '../firebase';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../styles';

const useStyles = makeStyles(materialTheme => ({
    '@global': {
        body: {
            backgroundColor: materialTheme.palette.common.white,
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: (props: any) => ({
        margin: materialTheme.spacing(1),
        backgroundColor: props.colors.red,
    }),
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: materialTheme.spacing(1),
    },
    submit: {
        margin: materialTheme.spacing(3, 0, 2),
        ...theme.redButton,
    },
    input: theme.input,
}));

const Login = () => {
    const [errors, setErrors] = useState(false);
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const classes = useStyles(theme);
    const containerStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/${process.env.REACT_APP_SPLASH})`,
        backgroundPosition: 'center',
        height: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };

    const doLogin = async (event: any) => {
        event.preventDefault();
        try {
            setErrors(false);
            await auth.signInWithEmailAndPassword(credentials.email, credentials.password);
        } catch (error) {
            console.error(error);
            setErrors(true);
        }
    };
    const onChange = (event: any) => {
        setCredentials({ ...credentials, [event.target.name]: event.target.value });
    }

    return (
        <Grid container direction="row" justify="center" alignItems="center" style={containerStyle}>
            <Grid>
                <Container component='main' maxWidth='xs' style={{ backgroundColor: 'white', paddingBottom: 10, paddingTop: 10 }}>
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Login
                        </Typography>
                        <form onSubmit={doLogin}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                className={classes.input}
                                onChange={onChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Wachtwoord"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                className={classes.input}
                                onChange={onChange}
                            />
                            {errors && (
                                <FormHelperText error>
                                    Er is een onbekende fout opgetreden, probeer het nog eens
                                </FormHelperText>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disableElevation
                            >
                                Inloggen
                            </Button>
                        </form>
                    </div>
                    <Box mt={8}>
                        <Typography variant="body2" color="textSecondary" align="center" data-testid='footer'>
                            {'Copyright © '}
                            <Link color="inherit" href={process.env.REACT_APP_WEBSITE_URL}>
                                {process.env.REACT_APP_NAME}
                            </Link>
                            {` ${new Date().getFullYear()}.`}
                        </Typography>
                    </Box>
                </Container>
            </Grid>
        </Grid>
    )
};

export default Login;
