import deepmerge from "deepmerge";
import produce from "immer";
import {
  FETCHED,
  UPDATE_STATE,
  SAVE,
  DELETE_IN_STATE,
  INITIAL_LOAD_DONE,
  INITIAL_LOAD_PROGRESS,
  INCREASE_LOAD_PROGRESS,
  STORED,
  BUILDER_FETCHED,
} from "../actions/entries";
import { FSA, EntriesState, FetchingState, FormType } from "../constants";
import { LOGGED_OUT } from "../actions/auth";
import { FETCHED_ALL, SET_FIXED } from "../actions/form";

const initialState: EntriesState = {
  fetching: FetchingState.FETCHED,
  initialLoadDone: false,
  initialLoadProgress: { loaded: 0, total: 999 },
  storing: false,
  formsToIgnore: [
    "company",
    "formBuilderForms",
    "formBuilderFormFields",
    "formBuilderOptions",
    "formBuilderValidations",
    "formBuilderConditions",
    "formBuilderFilters",
    "fetching",
    "storing",
  ],
};

const useSource = (_: any, source: any) => {
  return source;
};

const combineMerge = (target: any, source: any) => {
  // @ts-ignore
  return [...new Set(source.concat(...target))];
};

export default (state: any = initialState, { type, payload }: FSA) => {
  switch (type) {
    case FETCHED_ALL:
      const formKeys = Object.keys(payload).reduce(
        (acc: Record<string, any>, form) => {
          acc[form] = {};
          return acc;
        },
        {}
      );
      return { ...formKeys, ...state };
    case FETCHED:
      return deepmerge(state, payload, { arrayMerge: useSource });
    case BUILDER_FETCHED:
      return deepmerge(state, payload, { arrayMerge: combineMerge });
    case SAVE:
      return { ...state, storing: !state.formsToIgnore.includes(payload.entry.formName) };
    case UPDATE_STATE:
      const entry = Object.assign({}, { ...payload.entry });
      let value = entry.id;

      if (entry.parentType === FormType.LIST) {
        value = [value];
      }

      return {
        ...state,
        [entry.formName]: {
          ...state[entry.formName],
          [entry.id]: entry,
        },
      };
    case STORED:
      return {
        ...state,
        storing: false,
      };
    case DELETE_IN_STATE:
      const newState = { ...state };
      delete newState[payload.form][payload.entryId];
      return newState;
    case LOGGED_OUT:
      return initialState;
    case INITIAL_LOAD_DONE:
      return { ...state, initialLoadDone: true };
    case INITIAL_LOAD_PROGRESS:
      return { ...state, initialLoadProgress: payload };
    case INCREASE_LOAD_PROGRESS:
      return { ...state, initialLoadProgress: { ...state.initialLoadProgress, loaded: state.initialLoadProgress.loaded + 1 } };
    case SET_FIXED:
      return { ...state, formsToIgnore: [...state.formsToIgnore, ...Object.keys(payload)] };
    default:
      return state;
  }
};
