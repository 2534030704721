import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import app from "./app";
import forms from "./forms";
import entries from "./entries";
import user from "./user";
import company from "./company";
import routing from "./routing";
import rules from "./rules";
import exportReducer from "./export";
import dashboard from "./dashboard";
import schedule from "./schedule";

export default (history: any) =>
  combineReducers({
    // @ts-ignore
    router: connectRouter(history),
    app,
    forms,
    entries,
    company,
    user,
    routing,
    rules,
    export: exportReducer,
    dashboard,
    schedule,
  });
