import React from "react";
// @ts-ignore
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import {
  makeStyles,
  Grid,
  Paper,
  Toolbar,
  Typography,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import parse from "html-react-parser";

import { theme } from "../styles";
import { getDashboardItems } from "../selectors/dashboard";
import { DashboardItem } from "../constants";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    backgroundColor: theme.colors.backgroundLightGray,
    alignItems: "flex-start",
    padding: "10px",
  },
  toolbar: {
    backgroundColor: theme.colors.black,
    color: theme.colors.white,
    height: "56px",
  },
  item: {
    marginTop: "10px",
    "& .padding": {
      padding: "12px",
      "& div": {
        marginBottom: "8px",
      },
    },
  },
  listItem: {
    borderTop: `1px solid ${theme.colors.borderGray}`,
    height: "56px",
    "&.selected": {
      backgroundColor: theme.colors.backgroundLightGray,
    },
  },
  arrow: {
    fontSize: 20,
    color: theme.colors.lightGray,
  },
  listItemText: {
    fontSize: 16,
  },
}));

const Item = ({ item }: { item: DashboardItem }) => {
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(push(item.link.destination));
  };

  return (
    <Grid item key="1" xs={12} sm={6} md={4} xl={3} className={classes.item}>
      <Paper>
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography variant="h6">{item.title}</Typography>
        </Toolbar>
        <div>
        {parse(item.blocks[0] || "")}
      </div>
        <ListItem
          button
          onClick={handleClick}
          className={classes.listItem}
        >
          <ListItemText
            primary={item.link.title}
            classes={{ primary: classes.listItemText }}
          />
          <ArrowForwardIos className={classes.arrow} />
        </ListItem>
      </Paper>
    </Grid>
  );
};

const Dashboard = () => {
  const classes = useStyles(theme);
  const items = useSelector(getDashboardItems);

  return (
    <div className={classes.container}>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="flex-start"
        spacing={3}
      >
        {items.map((item: DashboardItem) => (
          <Item item={item} key={item.id} />
        ))}
      </Grid>
    </div>
  );
};

export default Dashboard;
