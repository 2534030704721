import { FormType } from "../constants";

export default {
    displayName: 'Gebruikers',
    name: 'users',
    latest: 'default',
    type: 'root',
    versions: {
        default: {
            elements: [{
                name: 'userList',
                options: [{
                    addition: 'Maak gebruiker aan',
                    displayFormat: '[email]',
                    elements: [{
                        name: 'user',
                        type: FormType.FORM,
                    }],
                    matchFields: 'email',
                }],
                required: true,
                sequence: 0,
                type: FormType.ROOT,
                nonEditable: true,
            }],
        },
    },
};
