import { createSelector } from "reselect";
import { ByForm, Roles, State } from "../constants";
import { convertDisplayFormatByEntryId } from "./displayFormat";
import {
  getEntriesByForm,
  getEntryIdsLimitedByCurrentParentByForm,
} from "./entries";

interface ByFormSortFieldsAndCurrentEntryId extends ByForm {
  sortFields: string[];
  currentEntryId?: string;
  exposeFields: string[];
}

interface Field {
  value: string;
}

interface Entry {
  [key: string]: Field | string | boolean | undefined;
  closed?: boolean;
  valid?: boolean;
  role?: {
    value: Roles;
  };
}

interface RawCrossSelectElement {
  entryId: string;
  closed: boolean;
  filterValues: any[];
  exposedFieldsValue: Record<string, any>;
}

const areEntriesLimited = (entryIdList: string[]) => Array.isArray(entryIdList);
const isEntryIdInList = (
  entryId: string,
  entryIdList: string[] | undefined
) => {
  if (!entryIdList) {
    return true;
  }

  return entryIdList.includes(entryId);
};
const isEntryValid = (entry: Entry) => entry.valid;
const isEntryAHiddenRecord = (entry: Entry, form: string) => {
  if (form !== "user") {
    return false;
  }

  if (!entry.role || !entry.role.value) {
    return false;
  }

  return [Roles.ROOT, Roles.SUPERUSER].includes(entry.role.value);
};

const getFieldValue = (fieldEntry: Field) => {
  if (!fieldEntry || !fieldEntry.value) {
    return "";
  }

  return fieldEntry.value;
};

interface ByFormSortFieldsAndCurrentEntryIdWithIgnoreFlag
  extends ByFormSortFieldsAndCurrentEntryId {
  ignoreValidation?: boolean;
}

const getElementsBySource = createSelector(
  getEntriesByForm,
  getEntryIdsLimitedByCurrentParentByForm,
  (_: State, input: ByFormSortFieldsAndCurrentEntryIdWithIgnoreFlag) => input,
  (
    formEntries,
    limitedIds,
    { sortFields, currentEntryId, exposeFields, form, ignoreValidation }
  ): RawCrossSelectElement[] => {
    if (!formEntries) {
      return [];
    }

    return (
      Object.entries(formEntries)
        .filter(([entryId]) => {
          if (!areEntriesLimited(limitedIds)) {
            return true;
          }

          return isEntryIdInList(entryId, [currentEntryId, ...limitedIds]);
        })
        .filter(
          // @ts-ignore
          ([, entry]: [string, Entry]) =>
            ignoreValidation || isEntryValid(entry)
        )
        .filter(
          // @ts-ignore
          ([, entry]: [string, Entry]) => !isEntryAHiddenRecord(entry, form)
        )
        // @ts-ignore
        .map(([entryId, entry]: [string, Entry]) => {
          return {
            entryId,
            closed: entry.closed || false,
            filterValues: sortFields.map((field) =>
              getFieldValue(entry[field] as Field)
            ),
            exposedFieldsValue: exposeFields.reduce((acc: any, field) => {
              acc[field] = getFieldValue(entry[field] as Field);
              return acc;
            }, {}),
          };
        })
    );
  }
);

interface ByFormSortFieldsCurrentEntryIdAndDisplayFormat
  extends ByFormSortFieldsAndCurrentEntryId {
  displayFormat: string;
}

export interface RawCrossSelectElementWithLabel {
  label: string;
  entryId: string;
  closed: boolean;
  filterValues: any[];
  exposedFieldsValue: Record<string, any>;
}

export const getElementsWithLabel = createSelector(
  getElementsBySource,
  (state: State, args: ByFormSortFieldsCurrentEntryIdAndDisplayFormat) => ({
    state,
    args,
  }),
  (elements, { state, args }) => {
    return elements.map((entry) => {
      const label = convertDisplayFormatByEntryId(state, {
        displayFormat: args.displayFormat,
        currentNode: args.form,
        entryId: entry.entryId,
      });
      return {
        label,
        entryId: entry.entryId,
        closed: entry.closed,
        filterValues: entry.filterValues,
        exposedFieldsValue: entry.exposedFieldsValue,
      };
    });
  }
);
