import { createSelector } from 'reselect';
import { format } from 'date-fns';

import { State, InputTypes, FormType, Defaults } from '../constants';
import { getAll as getAllForms, getElementsByForm } from './forms';
import { getAll as getAllEntries, getEntryIdByCurrentBranchAndForm, getLastStoredByName, getEntryByFormAndId } from './entries';
import { getInlineVariableKeys, determineType } from '../utility/helpers';
import { uponCreationRule } from './rules';
import { getParentFormAndIdByForm } from './routing';

const getFormAndKey = (handle: string, form: string) => {
    const handleParts = handle.split('.');
    if (handleParts.length > 1) {
        return { form: handleParts[0], key: handleParts[1] };
    }

    return { form, key: handle };
};

const getElement = (form: any, key: string) => {
    if (!form) {
        return false;
    }

    return form.versions[form.latest].elements.find(({ name }: any) => name === key);
}

const replaceVariable = (input: string, variable: string, value: string = '', type: string = '') => {
    if (type) {
        return input.replace(`[${type}:${variable}]`, value);
    }
    return input.replace(`[${variable}]`, value);
};

export const convertDisplayFormat = (state: State, defaultForm: string, displayFormat: string) => (entry: any, returnKey = false) => {
    const matches = getInlineVariableKeys(displayFormat) as string[];

    if (!matches) {
        return displayFormat;
    }

    return matches.reduce((output: string, match: string) => {
        const { handle, type } = determineType(match);
        const parent = getParentFormAndIdByForm(state, { form: defaultForm });
        let { form, key } = getFormAndKey(handle, defaultForm);

        let element = getElement(state.forms.forms[form], key);
        let entryId = entry.id;

        if (form !== defaultForm && entry[form]) {
            entryId = entry[form].value;
        }

        if (!element) {
            element = getElement(state.forms.forms[defaultForm], form);
        }

        if (element && element.type === 'crossSelect') {
            form = element.options[0].source;
            if (entry[form]) {
                entryId = entry[form].value;
            } else if (entry[key]) {
                entryId = entry[key].value;
            }
        }

        if (type === 'root') {
            entryId = 'default';
        }

        if (Array.isArray(entryId)) {
            entryId = entryId[0];
        }

        if (!entryId && parent && parent.entryId) {
            entryId = parent.entryId;
        }

        if (!state.entries[form]) {
            return replaceVariable(output, handle, '', type);
        }

        if (!state.entries[form][entryId]) {
            if (parent && parent.entryId && state.entries[form][parent.entryId]) {
                entryId = parent.entryId;
            }

            const gotEntryId = getEntryIdByCurrentBranchAndForm(state, { form });
            if (gotEntryId) {
                entryId = gotEntryId;
            }

            if (!gotEntryId && match.includes('.')) {
                const [alternateForm, ...rest] = match.split('.');
                if (state.entries[alternateForm]) {
                    if (state.entries[alternateForm][entryId] && state.entries[alternateForm][entryId][key]) {
                        entryId = state.entries[alternateForm][entryId][key].value;
                    } else {
                        const gotAlternateEntryId = getEntryIdByCurrentBranchAndForm(state, { form: alternateForm });
                        if (gotAlternateEntryId && state.entries[alternateForm][gotAlternateEntryId][form]) {
                            entryId = state.entries[alternateForm][gotAlternateEntryId][form].value;
                        }
                    }
                }
            }

            if (!state.entries[form][entryId] && Object.keys(state.entries[form]).length > 0 && type === 'root') {
                entryId = Object.keys(state.entries[form])[0];
            } else if (!state.entries[form][entryId]) {
                return replaceVariable(output, handle, '', type);
            }
        }

        let entryValue: any = '';

        if (!state.entries[form][entryId][key]) {
            const handParts = handle.split('.');
            if (handParts[handParts.length - 1] === key) {
                return replaceVariable(output, handle, '', type);
            }

            key = handParts[handParts.length - 1];
            element = getElement(state.forms.forms[handParts[handParts.length - 2]], key);

            if (state.entries[form][entryId][key]) {
                entryValue = state.entries[form][entryId][key].value;
            }
        } else {
            entryValue = state.entries[form][entryId][key].value;
        }

        if (!element) {
            return replaceVariable(output, handle, entryValue);
        }

        const recursiveGetValue = (recHandle: string, value: any, previousForm?: string): any => {
            let [form, recKey, ...remainingHandle] = recHandle.split('.');

            if (recKey) {
                let recEntryId = Array.isArray(value) ? value[0] : value;

                if (remainingHandle.length === 0 && element.type === 'crossSelect' && !state.entries[form]) {
                    form = element.options[0].source;
                }

                if (
                    state.entries[form]
                    && state.entries[form][recEntryId]
                    && state.entries[form][recEntryId][recKey]
                ) {
                    recEntryId = state.entries[form][recEntryId][recKey].value;
                } else if (remainingHandle.length === 0) {
                    recEntryId = '';
                }

                return recursiveGetValue([recKey, ...remainingHandle].join('.'), recEntryId, form);
            }

            if (previousForm) {
                const sourceElements = getFormElements(state.forms.forms, previousForm);
                const sourceElement = sourceElements.find(({ name }: any) => name === form);

                switch (sourceElement.type) {
                    case 'select':
                        const option = element.options[0].elements.find((opt: any) => opt.value === value);

                        if (option) {
                            value = returnKey ? option.value : option.label;
                        }

                        break;
                    case 'date':
                        value = format(new Date(value), 'dd-MM-yyyy');
                        break;
                    case 'dateTime':
                        value = format(new Date(value), 'dd-MM-yyyy HH:mm');
                        break;
                    case 'file':
                        value = entryValue.value;
                        break;
                    // no default
                }
            }

            return value;
        };

        switch (element.type) {
            case 'select':
                const option = element.options[0].elements.find(({ value }: any) => value === entryValue);

                if (!option) {
                    return replaceVariable(output, handle, '', type);
                }

                return replaceVariable(
                    output,
                    handle,
                    returnKey ? option.value : option.label,
                    type
                );
            case 'date':
                if (!entryValue) {
                    return replaceVariable(output, handle, entryValue, type);
                }
                return replaceVariable(output, handle, format(new Date(entryValue), 'dd-MM-yyyy'), type);
            case 'dateTime':
                if (!entryValue) {
                    return replaceVariable(output, handle, entryValue, type);
                }
                return replaceVariable(output, handle, format(new Date(entryValue), 'dd-MM-yyyy HH:mm'), type);
            case 'file':
                return replaceVariable(output, handle, entryValue.value, type);
            case FormType.FORM: {
                return replaceVariable(
                    output,
                    handle,
                    recursiveGetValue(handle, entryValue),
                    type
                );
            }
            case 'crossSelect': {
                if (entryValue !== '') {
                    return replaceVariable(output, handle, entryValue, type);
                }
                return replaceVariable(
                    output,
                    handle,
                    recursiveGetValue(handle, entryValue),
                    type
                );
            }
            default:
                if (entryValue === '' && match.includes('.')) {
                    const [alternateForm, ...rests] = match.split('.');
                    const gotAlternateEntryId = getEntryIdByCurrentBranchAndForm(state, { form: alternateForm });
                    if (gotAlternateEntryId && state.entries[alternateForm][gotAlternateEntryId][form]) {
                        entryValue = state.entries[alternateForm][gotAlternateEntryId][form].value;
                    }
                    return replaceVariable(output, handle, recursiveGetValue(rests.join('.'), entryValue), type);
                }

                return replaceVariable(output, handle, entryValue, type);
        }
    }, displayFormat);
};

export const getRawListEntries = createSelector(
    getElementsByForm,
    getAllEntries,
    (_: State, { entryIds }: any) => ({ entryIds }),
    (state: State) => state,
    ([element], allEntries, { entryIds }, state) => {
        if (!element.options) {
            return [];
        }

        if (!element.options[0]) {
            return [];
        }

        if (allEntries.fetching === 'not_fetched') {
            return [];
        }

        // @ts-ignore
        const childForm = element.options[0].elements[0].name;

        if (!allEntries[childForm] || Object.keys(allEntries).length === 0) {
            return [];
        }

        const formElements = getFormElements(state.forms.forms, childForm);
        let parsedEntries = Object
            .entries(allEntries[childForm])
            .map(([id, entry]: any) => {
                const lastStoredByName = getLastStoredByName(state, { form: childForm, entryId: id });
                const baseForm = formElements
                    .filter(({ type }: any) => !isListOrInlineList(type))
                    .map(convertDisplayTexts(state, childForm, { ...entry, id }))
                    .filter(({ label, filters }: any) => label || filters)
                    .reduce(convertElementToValue, { ...entry, id });

                return { id, ...baseForm, lastStoredByName };
            });

        if (Array.isArray(entryIds)) {
            parsedEntries = parsedEntries.filter(({ id }) => entryIds.includes(id));
        }

        return parsedEntries;
    }
);

const convertDisplayTexts = (state: State, form: string, entry: any = {}) => (element: any) => {
    let result: { name: string, label?: string } = { name: element.name };
    const [{ displayFormat }] = element.options;

    if (displayFormat) {
        result.label = convertDisplayFormat(state, form, displayFormat)(entry);
        if (result.label.includes('[')) {
            result.label = convertDisplayFormat(state, form, result.label)(entry);
        }
    }

    return result;
};

const convertElementToValue = (entry: any, element: any) => {
    if (!entry[element.name]) {
        entry[element.name] = {};
    }

    if (element.label) {
        entry[element.name].label = element.label;
    }

    if (element.elements) {
        entry[element.name].elements = element.elements;
    }

    if (element.filters) {
        entry[element.name].filters = element.filters;
    }

    return entry;
};

const getFormElements = (forms: any, form: string) => {
    if (!forms[form]) {
        return [];
    }

    return forms[form].versions[forms[form].latest].elements;
}

const isListOrInlineList = (type: InputTypes) => [InputTypes.LIST, InputTypes.INLINE_LIST].includes(type);
const isNewEntryId = (entryId: string) => entryId === Defaults.NEW_ENTRY_ID;

export const getFormEntry = createSelector(
    getAllForms,
    getEntryByFormAndId,
    (_: State, { form, entryId }: any) => ({ form, entryId }),
    (state: State) => state,
    ({ forms }, entry, { form, entryId }, state: State) => {
        if (!forms[form]) {
            return {};
        }

        let currentEntry = {};

        if (!isNewEntryId(entryId)) {
            if (!entry) {
                return {};
            }

            currentEntry = { ...entry, id: entryId };
        } else {
            // @ts-ignore
            currentEntry = uponCreationRule(state, { form });
        }

        const formElements = getFormElements(forms, form);

        const baseForm = formElements
            .filter(({ type }: any) => !isListOrInlineList(type))
            .map(convertDisplayTexts(state, form, currentEntry))
            .filter(({ label, filters }: any) => label || filters)
            .reduce(convertElementToValue, currentEntry);

        const inlineLists = formElements
            .filter(({ type }: any) => type === InputTypes.INLINE_LIST)
            .reduce((lists: any, inlineList: any) => {
                const inlineFormName = inlineList.options[0].elements[0].name;
                const inlineElements = getFormElements(forms, inlineFormName);

                // @ts-ignore
                let listEntries = currentEntry[inlineList.name] || [{}];
                if (!Array.isArray(listEntries)) {
                    listEntries = Object.values(listEntries);
                }

                lists[inlineList.name] = listEntries.map((listEntry: any) => {
                    return inlineElements
                        .filter(({ type }: any) => !isListOrInlineList(type))
                        .map(convertDisplayTexts(state, inlineFormName, listEntry))
                        .filter(({ label, filters }: any) => label || filters)
                        .reduce(convertElementToValue, listEntry);
                });

                return lists;
            }, {});

        return { ...baseForm, ...inlineLists };
    }
);
