import { Roles, UserState } from '../constants';
import { STATUS_CHANGED } from '../actions/auth';

const initialState: UserState = {
    company: '',
    role: Roles.GUEST,
    name: '',
    uid: '',
    email: '',
};

export default (state: UserState = initialState, action: any) => {
    switch (action.type) {
        case STATUS_CHANGED:
            return action.payload;
        default:
            return state;
    }
};
