import { select, all, call } from "redux-saga/effects";
// @ts-ignore
import SignaturePad from "signature_pad";
import { format } from "date-fns";
import { InputElement, InputTypes } from "../../constants";
import { convertDisplayFormatByFormAndEntryId } from "../../selectors/displayFormatByGraph";
import { getReferencedFormName } from "../../utility/helpers";
import { getLastStoredByName } from "../../selectors/entries";

interface FormAndEntryId {
  form: string;
  entryId: string;
}

function getRawValue(value?: any) {
  return value;
}

function getOptionLabel(value: string = "", { options = [] }: InputElement) {
  if (!options[0]) {
    return;
  }
  // @ts-ignore
  const selectedOption = options[0].elements.find(
    (option: any) => value === option.value
  );
  // @ts-ignore
  return selectedOption && selectedOption.label;
}

function getCheckboxValue(value: string, { options = [] }: InputElement) {
  if (typeof value === "undefined") {
    return;
  }

  return value === "true" ? options[0].label : "";
}

function getFileValue(value?: { value: string }) {
  return value && value.value;
}

function getSignatureDataUrl(
  { signature }: { signature: any[] } = { signature: [] }
) {
  if (!signature) {
    return;
  }

  const canvas = document.createElement("canvas");
  canvas.width = 360;
  canvas.height = 200;
  const signaturePadInstance = new SignaturePad(canvas);
  signaturePadInstance.fromData(Object.values(signature));
  return signaturePadInstance.toDataURL();
}

function* getStaticValue(
  _: undefined,
  { options = [] }: InputElement,
  { form, entryId }: FormAndEntryId
) {
  const [{ displayFormat }] = options;

  if (!displayFormat) {
    return;
  }

  const value = yield select(convertDisplayFormatByFormAndEntryId, {
    form,
    entryId,
    displayFormat,
  });

  return value;
}

function* getCrossSelectValue(
  value: string | string[] = [],
  element: InputElement
) {
  if (!value) {
    return "";
  }
  const [form] = getReferencedFormName(element);
  // @ts-ignore
  const displayFormat = element.options[0].displayFormat!;
  const entryIds = typeof value === "string" ? [value] : value;
  if (!entryIds.map) {
    return "";
  }
  const selectedValues = yield all(
    entryIds.map((entryId) => {
      return select(convertDisplayFormatByFormAndEntryId, {
        form,
        entryId,
        displayFormat,
      });
    })
  );

  return selectedValues.join(", ");
}

function* getLastStoredUserName(
  _value: undefined,
  _element: InputElement,
  { form, entryId }: FormAndEntryId
) {
  const userName = yield select(getLastStoredByName, { form, entryId });
  return userName;
}

function getFormattedDate(pattern: string) {
  return (value?: number) => value && format(new Date(value), pattern);
}

function* getInlineListValues(
  value: Record<string, any>,
  { name }: InputElement,
  formAndEntryId: FormAndEntryId,
  _: any,
  inlineListFormElements: Record<string, InputElement[]>
) {
  const filteredElements = filterElements(inlineListFormElements[name]);
  const values = Object.values(value);
  const selectedValues = yield all(
    values.map((entry: any) =>
      call(
        selectValues,
        filteredElements,
        entry,
        formAndEntryId,
        inlineListFormElements
      )
    )
  );
  return selectedValues.reduce((acc: Record<string, any>[], values: any[]) => {
    const entry = filteredElements.reduce(
      (subAcc: Record<string, any>, { name }, idx: number) => {
        subAcc[name] = values[idx];
        return subAcc;
      },
      {}
    );
    acc.push(entry);
    return acc;
  }, []);
}

const filterElements = (elements: InputElement[]) =>
  elements.filter(
    ({ type }) => ![InputTypes.SECTION, InputTypes.BUTTON].includes(type)
  );

const getters = {
  [InputTypes.TEXT]: getRawValue,
  [InputTypes.RADIO]: getOptionLabel,
  [InputTypes.NUMBER]: getRawValue,
  [InputTypes.TIME]: getRawValue,
  [InputTypes.EMAIL]: getRawValue,
  [InputTypes.DATE]: getFormattedDate("dd-MM-yyyy"),
  [InputTypes.DATE_TIME]: getFormattedDate("dd-MM-yyyy HH:mm"),
  [InputTypes.CHECKBOX]: getCheckboxValue,
  [InputTypes.TEXTAREA]: getRawValue,
  [InputTypes.SELECT]: getOptionLabel,
  [InputTypes.CROSS_SELECT]: getCrossSelectValue,
  [InputTypes.FORM]: getRawValue,
  [InputTypes.FILE]: getFileValue,
  [InputTypes.SIGNATURE]: getSignatureDataUrl,
  [InputTypes.LAST_STORED_BY]: getLastStoredUserName,
  [InputTypes.STATIC]: getStaticValue,
  [InputTypes.INLINE_LIST]: getInlineListValues,
  [InputTypes.CONTAINMENT_CALCULATOR]: getRawValue,
  [InputTypes.MENU]: getRawValue,
  [InputTypes.LIST]: getRawValue,
};

function* selectValues(
  elements: InputElement[],
  entry: any,
  formAndEntryId: FormAndEntryId,
  inlineListFormElements: Record<string, InputElement[]>
) {
  return yield all(
    elements.map((element: InputElement) => {
      const value =
        entry[element.name] && entry[element.name].value
          ? entry[element.name].value
          : entry[element.name];
      return call(
        // @ts-ignore
        getters[element.type],
        value,
        element,
        formAndEntryId,
        entry,
        inlineListFormElements
      );
    })
  );
}

export default function* getPlainEntryValues(
  { form, entryId, ...entry }: any,
  elements: InputElement[],
  inlineListFormElements: Record<string, InputElement[]>
) {
  const filteredElements = filterElements(elements);
  const selectedValues = yield selectValues(
    filteredElements,
    entry,
    { form, entryId },
    inlineListFormElements
  );

  return filteredElements.reduce((acc: Record<string, any>, { name }, idx) => {
    acc[name] = selectedValues[idx];
    return acc;
  }, {});
}
