import { ScheduleState } from "../constants";
import { CANCEL_INTERVAL } from "../actions/schedule";
import { SCHEDULE_REST_REQUEST } from "../actions/externalApi";
import { createReducer } from "./reducerFactory";

const strategies = {
  [CANCEL_INTERVAL]: cancelInterval,
  [SCHEDULE_REST_REQUEST]: setActiveInterval,
};

const initialState = {
  activeInterval: false,
};

export default createReducer<ScheduleState>(strategies, initialState);

function setActiveInterval(state: ScheduleState) {
  return { ...state, activeInterval: true };
}

function cancelInterval(state: ScheduleState) {
  return { ...state, activeInterval: false };
}
