import { FormType, ValidationTypes } from "../constants";

export default {
    deletable: true,
    displayName: 'Validation',
    name: 'formBuilderValidation',
    latest: 'default',
    type: FormType.FORM,
    versions: {
        default: {
            elements: [
                {
                    name: 'type',
                    options: [{
                        label: 'Type',
                        placeholder: '-- choose the type of validation --',
                        elements: [
                            { label: 'Expires', value: ValidationTypes.EXPIRES },
                            { label: 'Greater than', value: ValidationTypes.GREATER_THAN },
                            { label: 'Same or greater than', value: ValidationTypes.SAME_OR_GREATER_THAN },
                            { label: 'Forbidden value', value: ValidationTypes.FORBIDDEN },
                            { label: 'Warning value', value: ValidationTypes.WARNING },
                            { label: 'Upper bound', value: ValidationTypes.MAX_LIMIT },
                            { label: 'Lower bound', value: ValidationTypes.MIN_LIMIT },
                        ],
                    }],
                    required: true,
                    sequence: 0,
                    type: 'select',
                    unique: false,
                },
                {
                    name: 'expires',
                    options: [{ label: 'Number of days (until given date)' }],
                    required: true,
                    sequence: 1,
                    type: 'number',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: [ValidationTypes.EXPIRES].join(),
                    }],
                },
                {
                    // @todo: make dropdown of all form fields
                    name: 'source',
                    options: [{ label: 'Technical name of the field to compare the value to' }],
                    required: true,
                    sequence: 1,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: [ValidationTypes.GREATER_THAN, ValidationTypes.SAME_OR_GREATER_THAN].join(),
                    }],
                },
                {
                    name: 'value',
                    options: [{ label: 'Value' }],
                    required: true,
                    sequence: 1,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: [ValidationTypes.FORBIDDEN, ValidationTypes.WARNING, ValidationTypes.MAX_LIMIT, ValidationTypes.MIN_LIMIT].join(),
                    }],
                },
                {
                    name: 'message',
                    options: [{ label: 'Dialog message' }],
                    required: true,
                    sequence: 2,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: [ValidationTypes.FORBIDDEN, ValidationTypes.WARNING, ValidationTypes.MAX_LIMIT, ValidationTypes.MIN_LIMIT].join(),
                    }],
                },
            ],
        },
    },
};
