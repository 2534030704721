import { createSelector } from 'reselect';
import { getAll } from './entries';
import { getPathParams } from './routing';
import { ByForm, Defaults, FormType, State } from '../constants';
import { formGraph } from '../graph';

const findParentFormEntryId = (pathParams: Record<string, string>, form: string) => {
    let entryId = pathParams[`${form}Id`];
    let startForm = form;

    do {
        entryId = pathParams[`${startForm}Id`];

        if (!formGraph.has(startForm)) {
            entryId = Defaults.NEW_ENTRY_ID;
        }

        if (!entryId && formGraph.has(startForm)) {
            startForm = formGraph.get(startForm).introvertedEdges.values().next().value;
        }

        if (!startForm) {
            entryId = Defaults.NEW_ENTRY_ID;
        }
    } while (!entryId)

    return entryId;
};

const getEntryIdByFormNameAndCurrentPath = createSelector(
    getPathParams,
    (_: State, { form }: ByForm) => form,
    (pathParams, form) => {
        const entryId = findParentFormEntryId(pathParams, form);

        if (!entryId || entryId === Defaults.NEW_ENTRY_ID) {
            return false;
        }

        return entryId;
    }
)

// @todo: there must be a more elegant way (without the pathParams part)
const findParentForm = (pathParams: Record<string, string>, form: string) => {
    let entryId = pathParams[`${form}Id`];
    let startForm = form;

    do {
        entryId = pathParams[`${startForm}Id`];

        if (!formGraph.has(startForm)) {
            entryId = Defaults.NEW_ENTRY_ID;
        }

        if (!entryId && formGraph.has(startForm)) {
            startForm = formGraph.get(startForm).introvertedEdges.values().next().value;
        }

        if (!startForm) {
            entryId = Defaults.NEW_ENTRY_ID;
        }
    } while (!entryId)

    return startForm;
};

interface ByFormAndName extends ByForm {
    name: string;
}

export const getElementArrayValueByFormAndName = createSelector(
    getEntryIdByFormNameAndCurrentPath,
    getPathParams,
    getAll,
    (_: State, { name, form }: ByFormAndName) => ({ name, form }),
    (entryId, pathParams, allEntries, { name, form }) => {
        if (!entryId) {
            // if the parent form is the root
            if (formGraph.has(form) && formGraph.get(form).introvertedEdges.size === 0 && allEntries[name]) {
                return Object.keys(allEntries[name]);
            }

            return [];
        }

        let parentForm = findParentForm(pathParams, name);

        if (!parentForm || parentForm === name) {
            parentForm = form;
        }

        if (formGraph.has(parentForm) && formGraph.get(parentForm).type === FormType.MENU) {
            parentForm = Array.from(formGraph.get(parentForm).introvertedEdges)[0];
        }

        const entries = allEntries[parentForm];

        if (!entries || !entries[entryId] || !entries[entryId][name]) {
            return [];
        }

        return entries[entryId][name].value || [];
    }
);
