import { FSA } from "../constants";
import { RestRequest } from "../sharedTypes";

type ResponseHandler = (data: unknown) => void;

export const REST_REQUEST = "API_REST_REQUEST";
export const restRequest = (
  requestArgs: RestRequest,
  responseHandler: ResponseHandler = (data) => console.log(data)
): FSA => ({
  type: REST_REQUEST,
  payload: {
    args: requestArgs,
    responseHandler,
  },
});

export const SCHEDULE_REST_REQUEST = "API_SCHEDULE_REST_REQUEST";
export const scheduleRestRequest = (
  duration: number,
  interval: number,
  requestArgs: RestRequest,
  responseHandler: ResponseHandler = (data) => console.log(data)
) => ({
  type: SCHEDULE_REST_REQUEST,
  payload: {
    duration,
    interval,
    args: requestArgs,
    responseHandler,
  },
});
