import { FormType, Roles } from "../constants";

export default {
    deletable: true,
    displayName: 'Condition',
    name: 'formBuilderCondition',
    latest: 'default',
    type: FormType.FORM,
    versions: {
        default: {
            elements: [
                {
                    name: 'type',
                    options: [{
                        label: 'Type',
                        placeholder: '-- choose the type of condition --',
                        elements: [
                            { label: 'Equals', value: 'equals' },
                            { label: 'Valid and closed', value: 'validAndClosed' },
                            { label: 'Valid', value: 'valid' },
                            { label: 'User role', value: 'hasRole' },
                        ],
                    }],
                    required: true,
                    sequence: 0,
                    type: 'select',
                    unique: false,
                },
                {
                    name: 'source',
                    options: [{ label: 'Source' }],
                    required: true,
                    sequence: 1,
                    type: 'text', // @todo: dropdown of all the other fields?
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'notEquals',
                        values: 'hasRole',
                    }],
                },
                {
                    name: 'values',
                    options: [{ label: 'Values (comma seperated)' }],
                    required: true,
                    sequence: 2,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'equals',
                    }],
                },
                {
                    name: 'roles',
                    options: [{
                        label: 'Allowed roles',
                        placeholder: '-- choose the roles --',
                        multiple: true,
                        elements: [
                            { label: 'Beheerder', value: Roles.ROOT },
                            { label: 'Supervisor', value: Roles.SUPERVISOR },
                            { label: 'Administrator', value: Roles.ADMIN },
                            { label: 'User', value: Roles.USER },
                        ],
                    }],
                    required: true,
                    sequence: 3,
                    type: 'select',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'hasRole',
                    }],
                },
            ],
        },
    },
};
