import { FormType } from "../constants";

export default {
    displayName: 'RuleBuilder',
    name: 'rules',
    latest: 'default',
    type: 'root',
    sequence: 100,
    versions: {
        default: {
            elements: [{
                name: 'autoEntryCreationList',
                options: [{
                    addition: 'Rule',
                    displayFormat: '[type] -> [destinationField]',
                    elements: [{
                        name: 'rule',
                        type: FormType.FORM,
                    }],
                    matchFields: 'type,destinationField',
                }],
                required: true,
                sequence: 0,
                type: FormType.ROOT,
            }],
        },

    },
};
