import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { getUser } from "../reduxMiddleware/user";
import { getCompanyName } from "../reduxMiddleware/company";
import { getMenu } from "../reduxMiddleware/navigation";
import { isRootOrHigher } from "../reduxMiddleware/user";
import { logout } from "../actions/auth";

import { theme } from "../styles";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import { bulkValidate } from "../actions/entries";
import { getCompany, getCompanyById } from "../selectors/company";

const useStyles = makeStyles((theme, overrides = {}) => ({
  userInformation: {
    textAlign: "right",
    padding: `0 ${theme.spacing(1)}px`,
  },
  avatar: {
    float: "right",
    cursor: "pointer",
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  appBar: (props) => ({
    backgroundColor: props.overrides.primaryColor || props.colors.red,
  }),
  menuLink: {
    color: "white",
    textDecoration: "none",
    fontWeight: "bold",
    lineHeight: "58px",
    padding: "0 10px",
  },
  activeMenuItem: (props) => ({
    backgroundColor: props.colors.black,
    color: "white",
    textDecoration: "none",
    fontWeight: "bold",
    lineHeight: "58px",
    padding: "0 10px",
  }),
  navIconHide: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  drawerMenuLink: (props) => ({
    color: props.colors.black,
    textDecoration: "none",
  }),
  logoutButton: {
    marginTop: "20px",
  },
  browserWarning: (props) => ({
    textAlign: "center",
    backgroundColor: props.colors.orange,
  }),
}));

const computeInitials = (name) => {
  if (!name) {
    return "";
  }

  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
};

const isChrome =
  navigator.userAgent.match(/chrome|chromium|crios/i);

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [browserWarningDismissed, setBrowserWarningDismissed] = useState(
    isChrome
  );
  const [avatarElement, setAvatarElement] = useState(null);
  const user = getUser();
  const companyName = getCompanyName();
  const company = useSelector(getCompany);
  const companyData = useSelector((state) =>
    getCompanyById(state, { id: company })
  );
  const primaryColor = companyData ? companyData.primaryColor || "" : "";
  const classes = useStyles({ ...theme, overrides: { primaryColor } });
  const isAllowedToBulkValidate = isRootOrHigher();
  const dispatch = useDispatch();
  const menu = getMenu();
  const version = process.env.REACT_APP_VERSION || "local";

  const toggleMenuOpen = () => setMenuOpen(!menuOpen);
  const dismissBrowserWarning = () => setBrowserWarningDismissed(true);
  const handleDetailMenuOpen = (event) => {
    setAvatarElement(event.currentTarget);
  };
  const handleDetailMenuClose = () => {
    setAvatarElement(null);
  };

  const initials = computeInitials(user.name);
  const logoutHandler = () => {
    dispatch(logout());
    dispatch(push("/"));
  };

  return (
    <div>
      <AppBar position="static" className={classes.appBar}>
        {!browserWarningDismissed && (
          <Toolbar variant="dense" className={classes.browserWarning}>
            <div style={{ flexGrow: 1 }}>
              Voor een optimale ervaring raden wij het gebruik van de Google
              Chrome webbrowser aan
            </div>
            <IconButton
              edge="end"
              color="inherit"
              onClick={dismissBrowserWarning}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        )}
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.navIconHide}
            color="inherit"
            aria-label="menu"
            onClick={toggleMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Hidden mdDown>
            {menu.map(({ route, handle, active }) => (
              <Link
                to={route}
                className={active ? classes.activeMenuItem : classes.menuLink}
                key={handle}
                component={React.forwardRef((props, ref) => (
                  <RouterLink innerRef={ref} {...props} />
                ))}
              >
                {handle}
              </Link>
            ))}
          </Hidden>
          <div style={{ flexGrow: 1 }}>
            <Avatar onClick={handleDetailMenuOpen} className={classes.avatar}>
              {initials}
            </Avatar>
          </div>
          <Menu
            anchorEl={avatarElement}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(avatarElement)}
            onClose={handleDetailMenuClose}
          >
            <MenuItem disabled>Versie: {version}</MenuItem>
            <MenuItem disabled>
              {user.name} ({user.humanReadableRole})
            </MenuItem>
            <MenuItem>
              <Tooltip title={company} arrow>
                <span>{companyName}</span>
              </Tooltip>
            </MenuItem>
            {isAllowedToBulkValidate && (
              <MenuItem onClick={() => dispatch(bulkValidate())}>
                Bulk Validate
              </MenuItem>
            )}
            <MenuItem onClick={logoutHandler}>Uitloggen</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Hidden lgUp>
        <Drawer variant="temporary" open={menuOpen} onClose={toggleMenuOpen}>
          <List>
            {menu.map(({ route, handle, active }) => (
              <MenuItem key={handle}>
                <Link
                  to={route}
                  className={classes.drawerMenuLink}
                  style={{
                    color: active ? theme.colors.red : theme.colors.black,
                  }}
                  component={React.forwardRef((props, ref) => (
                    <RouterLink innerRef={ref} {...props} />
                  ))}
                  onClick={toggleMenuOpen}
                >
                  {handle}
                </Link>
              </MenuItem>
            ))}
          </List>
        </Drawer>
      </Hidden>
    </div>
  );
};

export default Navigation;
