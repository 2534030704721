// @ts-ignore
import { useSelector } from 'react-redux';
import { getRawListEntries, getFormEntry } from '../selectors/drawerContainer';
import { Defaults, State } from '../constants';

interface GetListEntriesByOptions {
    form: string;
    entryIds?: string[];
    groupId?: string;
}
export const getRawListEntriesByOptions = (options: GetListEntriesByOptions) =>
    useSelector((state: State) => getRawListEntries(state, options));

export const getDefaultNewEntryData = (form: string) =>
    useSelector((state: State) => getFormEntry(state, { form, entryId: Defaults.NEW_ENTRY_ID }));
