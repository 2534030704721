import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
// @ts-ignore
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import reducer from './reducers';
import sagas from './sagas';

export const history = createBrowserHistory();

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// @ts-ignore
const enhancers = [applyMiddleware(routerMiddleware(history), sagaMiddleware)];

// @ts-ignore
const reduxDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
if (process.env.NODE_ENV === 'development' && typeof reduxDevToolsExtension === 'function') {
    enhancers.push(reduxDevToolsExtension())
}

const initialState = {};

export default () => {
    const store = createStore(
        reducer(history),
        initialState,
        // @ts-ignore
        compose(...enhancers),
    );

    sagaMiddleware.run(sagas);

    return store;
}
