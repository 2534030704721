import { FormType } from "../constants";

export default {
    displayName: 'FormBuilder',
    name: 'formBuilderForms',
    latest: 'default',
    type: 'root',
    sequence: 99,
    versions: {
        default: {
            elements: [{
                name: 'formBuilderFormList',
                options: [{
                    addition: 'Formulier',
                    displayFormat: '[sequence]: [displayName] - [name] - [type]',
                    elements: [{
                        name: 'formBuilderFormMeta',
                        type: FormType.FORM,
                    }],
                    matchFields: 'sequence,displayName,name,type',
                }],
                required: true,
                sequence: 0,
                type: FormType.ROOT,
            }],
        },

    },
};
