import { createSelector } from 'reselect';

import { State, InputElement, InputTypes, FormMeta, ExpirationStatus } from '../constants';
import { getEntryByFormAndId } from './entries';
import { getFormEntry } from './drawerContainer';
import { getElementsByVersion, getFormMeta } from './forms';
import { getConditionStatus } from './conditions';

const getFormNameByElement = (element: InputElement) => {
    if (element.type === InputTypes.LIST) {
        // @ts-ignore
        return element.options[0].elements[0].name;
    }

    return element.name;
}

const isListOrForm = (type: InputTypes) => [InputTypes.LIST, InputTypes.FORM].includes(type);
const areConditionsMet = (element: InputElement, metConditions: Record<string, string>) => {
    if (!element.conditions || element.conditions.length === 0) {
        return true;
    }

    return metConditions[element.name];
};
const isFormCloseable = (meta: FormMeta) => meta && meta.closeable;

export const getClosedStatus = createSelector(
    getElementsByVersion,
    getFormEntry,
    getConditionStatus,
    (state: State) => state,
    (elements: InputElement[], entry: any, metConditions, state: State) => {
        const elementsToCheck = elements
            .filter((element) => isListOrForm(element.type) && areConditionsMet(element, metConditions))
            .map(element => ({
                form: getFormNameByElement(element),
                name: element.name,
            }))
            .filter(({ form }) => {
                const formMeta = getFormMeta(state, { form });
                return isFormCloseable(formMeta as FormMeta);
            });

        return elementsToCheck.reduce((acc: any, { form, name }: { form: string, name: string }) => {
            if (!entry[form] || !entry[form].value) {
                return acc;
            }

            const entryIds: string[] = entry[form].value;

            const allEntriesClosed = entryIds.every((entryId) => {
                const entry = getEntryByFormAndId(state, { form, entryId });

                if (!entry || !entry.closed) {
                    return false;
                }

                return entry.closed;
            });

            if (!allEntriesClosed) {
                acc[name] = [{ type: 'not_valid' }];
            }

            return acc;
        }, {});
    }
);

export const getWorstExpirationStatus = (expirationStatusList: string[]) => {
    if (expirationStatusList.includes(ExpirationStatus.EXPIRED)) {
        return ExpirationStatus.EXPIRED;
    }

    if (expirationStatusList.includes(ExpirationStatus.EXPIRING)) {
        return ExpirationStatus.EXPIRING;
    }

    return ExpirationStatus.VALID;

}
