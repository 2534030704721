import React from 'react';
// @ts-ignore
import { connect, useSelector, useDispatch } from 'react-redux';
// @ts-ignore
import { matchPath } from 'react-router';

import Form from './form/Form';
import Elements from './form/Elements';
import List from './List';
import Table from './Table';

import { save, deleteEntry as deleteEntryAction } from '../actions/entries';
import { fetchFormEntries, fetchEntry } from "../actions/entries";
import { fetchVersion } from '../actions/form';
import { getFormMeta, getElementsByForm, getParentFormRouteByForm } from '../selectors/forms';

import styles from './DrawerContainer.module.css';
import { theme } from '../styles';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Refresh from '@material-ui/icons/Refresh';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import Typography from '@material-ui/core/Typography';
import { toggleFullscreen } from '../actions/app';
import { getFullscreen } from '../selectors/app';
import { getDrawers } from '../reduxMiddleware/drawers';
import { getFormTitle } from '../reduxMiddleware/form';

// @ts-ignore
import CytoscapeComponent from 'react-cytoscapejs';
import { entriesGraph } from '../graph';
import { Defaults, FormType } from '../constants';
import { getPath } from '../reduxMiddleware/routing';
import { push } from 'connected-react-router';


// @todo:
// - replace all any types
// - start a loader with storeEntry, stop the loader after useEffect is executed with an existing transactionId

// @ts-ignore
const useStyles = makeStyles(materialTheme => ({
    container: {
        backgroundColor: theme.colors.backgroundLightGray,
    },
    column: {
        margin: materialTheme.spacing(2),
    },
    toolbar: {
        backgroundColor: theme.colors.black,
        color: theme.colors.white,
        height: '56px',
    },
    iconButton: {
        color: theme.colors.white,
    },
}));

const Drawer = ({ close, isLast, path, type, formName, rootForm, storeEntry, entryId, parentType, deleteEntry, readonly, uniqueReferenceIds, entryIds, ...props }: any) => {
    const classes = useStyles(theme);
    const dispatch = useDispatch();

    const title = getFormTitle(formName, entryId);

    let style = {};

    // @todo: clean all these if statements up
    if (!props.versions[props.versionId]) {
        if (props.fetching !== 'fetching') {
            props.getFormVersion(formName, props.versionId);
        }
        return null;
    }

    if (props.entriesFetching !== 'fetched') {
        return null;
    }

    const elements = props.versions[props.versionId].elements;

    let content;

    const refreshForm = () => {
      if (!entryId) {
        // note: not currently used
        dispatch(fetchFormEntries(formName));
      } else {
        dispatch(fetchEntry(formName, entryId));
      }
    };

    switch (type) {
        case FormType.MENU:
            content = <Elements
                readonly={true}
                baseUrl={path}
                name={formName}
                handleChange={() => {}}
                elements={elements}
                values={props.values}
                entryId={''}
                isLast={isLast}
            />;
            break;
        case FormType.FORM:
            const listEntries = elements
                .filter(({ type }: any) => type === FormType.LIST)
                .reduce((listValues: any, { options }: any) => {
                    const name = options[0].elements[0].name;
                    if (props.entries[entryId] && props.entries[entryId][name]) {
                        listValues[name] = props.entries[entryId][name];
                    }
                    return listValues;
                }, {});

            content = <Form
                baseUrl={path}
                submit={storeEntry(rootForm, entryId, listEntries)}
                name={formName}
                allEntries={props.entries}
                entryId={entryId}
                uniqueReferenceIds={uniqueReferenceIds}
                deleteEntry={(id: string) => {
                    deleteEntry(formName, id);
                    const pathParts = path.split('/');
                    pathParts.pop();
                    pathParts.pop();
                    setTimeout(() => {
                        dispatch(push(pathParts.join('/')));
                    }, 1000);
                }}
                isLast={isLast}
                version={props.versionId}
            />;
            break;
        case 'root':
            if (props.fullscreen && elements[0].options[0].table) {
                content = <Table
                    form={formName}
                    childForm={elements[0].options[0].elements[0].name}
                    {...elements[0].options[0]}
                />;
            } else {
                content = <List
                    {...elements[0].options[0]}
                    form={formName}
                    groupId={props.params[`${elements[0].name}Group`]}
                    isRoot={true}
                    nonEditable={elements[0].nonEditable}
                />;
            }
            break;
        case FormType.LIST:
            if (props.fullscreen && elements[0].options[0].table) {
                content = <Table
                    form={formName}
                    childForm={props.childForm}
                    entryIds={entryIds}
                    {...elements[0].options[0]}
                />;
            } else {
                content = <List
                    {...elements[0].options[0]}
                    form={formName}
                    readonly={readonly}
                    nonEditable={elements[0].nonEditable}
                />;
            }
            break;
    }

    return (
        <div className={!props.fullscreen ? styles.drawerContent : ''} style={style}>
            <Paper className={!props.fullscreen ? classes.column : ''}>
                <Toolbar variant='dense' className={classes.toolbar}>
                    {close && (<IconButton edge='start' onClick={close} className={classes.iconButton}>
                        <ArrowBackIos />
                    </IconButton>)}
                    <Typography variant='h6'>{title}</Typography>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton edge='end' onClick={refreshForm} style={{ color: "white" }}>
                            <Refresh />
                        </IconButton>
                        <IconButton edge='end' onClick={() => props.toggleFullscreen()} className={classes.iconButton}>
                            {props.fullscreen
                                ? <FullscreenExit />
                                : <Fullscreen />}
                        </IconButton>
                    </div>
                </Toolbar>
                {content}
            </Paper>
        </div>
    );
};

const DrawerConnector = connect(
    (state: any, { formName, entryId, ...props }: any) => {
        // @todo:
        // - move these into selectors

        // @ts-ignore
        const formMeta: { type: string, latest: string, displayName: string, isRoot: boolean } = getFormMeta(state, { form: formName });
        const formData = state.forms.forms[formName];
        let versionId = formMeta.latest;
        let entries: any = {};
        let readonly = false;
        let uniqueReferenceIds: string[] = [];
        // @todo: only populate the entryIds if we are in table mode
        let entryIds: any[] = [];
        let childForm;
        let values;
        let parent = { name: props.parentName };
        const fullscreen = getFullscreen(state);

        if (state.entries.fetching !== 'fetched' && state.forms.fetchingState !== 'fetched') {
            return {
                versions: formData.versions,
                versionId,
                type: formMeta.type,
                entries,
                formFetching: state.forms.fetchingState,
                entriesFetching: state.entries.fetching,
                readonly,
                uniqueReferenceIds,
                entryIds,
                childForm,
                values,
            };
        }

        if (entries[entryId]) {
            if (entries[entryId].readonly) {
                versionId = entries[entryId].formVersion || 'default';
            }
        }

        switch (formMeta.type) {
            case FormType.FORM: {
                const formParent = getParentFormRouteByForm(state, { form: formName });

                if (formParent) {
                    parent = formParent;
                }

                if (
                    state.entries[parent.name]
                    && state.entries[parent.name][props.params[`${parent.name}Id`]]
                    && state.entries[parent.name][props.params[`${parent.name}Id`]][formName]
                ) {
                    uniqueReferenceIds = state.entries[parent.name][props.params[`${parent.name}Id`]][formName].value;
                }

                break;
            }
            case FormType.LIST: {
                if (fullscreen) {
                    if (props.parentType === FormType.MENU) {
                        const parentElem = getElementsByForm(state, { form: props.parentName });
                        // @ts-ignore
                        childForm = parentElem.find(({ name }) => name === formName).options[0].elements[0].name;
                        if (state.entries[childForm]) {
                            const parentFormRoute = getParentFormRouteByForm(state, { form: formName });

                            if (parentFormRoute && state.entries[parentFormRoute.name]) {
                                const parentEntry = state.entries[parentFormRoute.name][props.params[parentFormRoute.entryId]];
                                entryIds = parentEntry && parentEntry[childForm] ? parentEntry[childForm].value : [];
                            } else {
                                entryIds = Object.keys(state.entries[childForm]);
                            }
                        }
                    }

                    if (state.entries[props.parentName]) {
                        const parentElem = getElementsByForm(state, { form: props.parentName });
                        // @ts-ignore
                        childForm = parentElem.find(({ name }) => name === formName).options[0].elements[0].name;
                        const entryId = props.params[`${props.parentName}Id`];
                        if (
                            state.entries[props.parentName][entryId]
                            && state.entries[props.parentName][entryId][childForm]
                            && state.entries[props.parentName][entryId][childForm].value
                        ) {
                            entryIds = [...state.entries[props.parentName][entryId][childForm].value];
                        }
                    }
                }
                break;
            }
            case FormType.MENU: {
                const elements = getElementsByForm(state, { form: formName });
                const parentFormRoute = getParentFormRouteByForm(state, { form: formName });

                if (formMeta.isRoot || !parentFormRoute) {
                    values = elements
                        // @ts-ignore
                        .filter(({ type }): any => type === FormType.FORM)
                        .reduce((acc: any, element: any) => {
                            if (state.entries[element.name]) {
                                acc[element.name] = { value: Object.keys(state.entries[element.name]) };
                            }

                            return acc;
                        }, {});
                } else {
                    if (state.entries[parentFormRoute.name]) {
                        const parentEntry = state.entries[parentFormRoute.name][props.params[parentFormRoute.entryId]];
                        values = elements
                            // @ts-ignore
                            .filter(({ type }): any => type === FormType.FORM)
                            .reduce((acc: any, element: any) => {
                                if (parentEntry && parentEntry[element.name]) {
                                    acc[element.name] = parentEntry[element.name];
                                }

                                return acc;
                            }, {});
                    }
                }
            }
            // no default
        }

        return {
            versions: formData.versions,
            versionId,
            type: formMeta.type,
            entries,
            formFetching: state.forms.fetchingState,
            entriesFetching: state.entries.fetching,
            readonly,
            uniqueReferenceIds,
            entryIds,
            childForm,
            values,
            parent,
            fullscreen,
        };
    },
    (dispatch: any, props: any) => {
        const entryId = props.params[`${props.parentName}Id`];
        return {
            storeEntry: (rootForm: string, id: string, listEntries: any) => (entry: any, valid: boolean) => {
                let redirect;

                if (!entry.id) {
                    entry.id = id;
                    redirect = (newId: string) => {
                        const newPath = props.path.replace(Defaults.NEW_ENTRY_ID, newId);
                        dispatch(push(newPath));
                    };
                }

                dispatch(save({ ...entry, ...listEntries, rootForm, entryId }, valid, redirect));
            },
            getFormVersion: (formId: string, versionId: string) => dispatch(fetchVersion(formId, versionId)),
            deleteEntry: (form: string, entryId: string) => dispatch(deleteEntryAction(form, entryId)),
            toggleFullscreen: () => dispatch(toggleFullscreen()),
        };
    }
)(Drawer);

const DrawerContainer = () => {
    const fullscreen = useSelector(getFullscreen);
    const dispatch = useDispatch();
    const activeDrawers = getDrawers();
    const currentPath = getPath();

    const drawers = activeDrawers.map(({ parent, ...drawer }: any) => {
        let result = { ...drawer };

        if (parent !== drawer.path) {
            // @todo: maybe this whole logic can no be move to the drawer?
            result.close = () => {
                dispatch(push(parent ? matchPath(currentPath, { path: parent }).url : '/'));
            };
        }

        return result;
    });

    if (currentPath === '/graph') {
        // @ts-ignore
        const els = Array.from(entriesGraph).reduce((acc, [nodeName, { edges }]) => {
            // @ts-ignore
            acc.push({ data: { id: nodeName, label: nodeName }});
            edges.forEach((edge) => {
                if (entriesGraph.has(edge)) {
                    // @ts-ignore
                    acc.push({ data: { source: nodeName, target: edge }});
                }
            });
            return acc;
        }, []);

        return <CytoscapeComponent
            style={{ width: '100%', height: '100%' }}
            layout={{ name: 'cose' }}
            elements={els}
            stylesheet={[{
                selector: 'node',
                style: {
                    width: 20,
                    height: 20,
                    label: 'data(label)',
                },
            }, {
                selector: 'edge',
                style: {
                    'targetArrowShape': 'triangle',
                    curveStyle: 'bezier',
                },
            }]}
        />
    }

    if (fullscreen) {
        return (<DrawerConnector {...drawers[drawers.length - 1]} isLast={true} />);
    }

    return (
        <div className={styles.drawerContainer}>
            {drawers.map((drawer: any, i: number) => (
                <DrawerConnector key={i} {...drawer} isLast={drawers.length - 1 === i} />
            ))}
        </div>
    );
};

export default DrawerContainer;
