import { compareAsc, subDays, format } from "date-fns";

import {
  ExpirationStatus,
  InputElement,
  InputTypes,
  ValidationTypes,
} from "../constants";
import { sanitizeEntry } from "../sagas/entries";
import {
  computeNecessaryEquipmentCapactiy,
  computeTotalCapacityInSquareMeters,
  computeSuperfluousCapacity,
} from "../components/form/ContainmentCalculator";
import { Conditions, Condition } from "../conditionConstants";

const createOrAppendToArray = (sourceArray: any, ...records: any) => {
  if (sourceArray) {
    return sourceArray.concat(...records);
  }

  return records;
};

const isDateExpired = (date: Date) =>
  compareAsc(date, new Date(Date.now())) === -1;

export const ignoreExpiring = (errorObject: any) => {
  return Object.entries(errorObject).reduce((acc: any, [id, errArr]: any) => {
    const filtered = errArr.filter(
      ({ type }: any) => type !== ExpirationStatus.EXPIRING
    );

    if (filtered.length > 0) {
      acc[id] = filtered;
    }

    return acc;
  }, {});
};

export const getExpirationStatus = (date: Date, numberOfDays: number) => {
  if (isDateExpired(date)) {
    return ExpirationStatus.EXPIRED;
  }

  if (isDateExpired(subDays(date, numberOfDays))) {
    return ExpirationStatus.EXPIRING;
  }

  return ExpirationStatus.VALID;
};

export const isExpiringElement = (element: InputElement) => {
  if (!element.validations) {
    return false;
  }

  if (
    !element.validations.find(({ type }) => type === ValidationTypes.EXPIRES)
  ) {
    return false;
  }

  return true;
};

export const execValidations = (
  currentState: any,
  elements: InputElement[],
  entries: any,
  entryId: string,
  formName: string,
  uniqueReferenceIds: string | string[],
  strict: boolean = false
) => {
  const errorObject: Record<string, any> = {};

  const state = sanitizeEntry(currentState);

  if (!state) {
    return errorObject;
  }

  const { unique, localUnique } = elements
    .filter(({ conditions }: any) => {
      if (conditions && conditions.length > 0) {
        return conditions.every((condition: Condition) => {
          if (!condition.source) {
            // This is probably type:expires
            return true;
          }

          if (condition.source.includes("[")) {
            return false;
          }

          let value = "";

          if (state[condition.source] && state[condition.source].value) {
            value = state[condition.source].value;
          }

          switch (condition.type) {
            case Conditions.EQUALS: {
              if (!value && condition.values === "false") {
                return true;
              }

              return condition.values!.split(",").includes(value);
            }
            case Conditions.NOT_EQUALS: {
              if (!value && condition.values === "false") {
                return false;
              }

              return !condition.values!.split(",").includes(value);
            }
            default:
            // no default
          }
        });
      }

      return true;
    })
    .reduce(
      (acc: any, element: any) => {
        if (element.unique) {
          acc.unique.push({
            element: element.name,
            ignoreClosed: Boolean(element.ignoreClosedUnique),
          });
        }

        if (element.localUnique) {
          acc.localUnique.push({
            element: element.name,
            ignoreClosed: Boolean(element.ignoreClosedUnique),
          });
        }

        let elementState = state[element.name];
        if ([InputTypes.LIST].includes(element.type)) {
          elementState = state[element.options[0].elements[0].name];
        }

        if (
          [InputTypes.LIST].includes(element.type) &&
          element.required &&
          !state[element.options[0].elements[0].name]
        ) {
          errorObject[element.name] = createOrAppendToArray(
            errorObject[element.name],
            { type: "required" }
          );
        }

        if (
          ![InputTypes.CONTAINMENT_CALCULATOR].includes(element.type) &&
          element.required &&
          (!elementState ||
            !elementState.value ||
            elementState.value.availableCapacity === 0)
        ) {
          errorObject[element.name] = createOrAppendToArray(
            errorObject[element.name],
            { type: "required" }
          );
        }

        if (
          ![InputTypes.INLINE_LIST, InputTypes.LIST].includes(element.type) &&
          element.required &&
          !(elementState && elementState.value)
        ) {
          errorObject[element.name] = createOrAppendToArray(
            errorObject[element.name],
            { type: "required" }
          );
        }

        if (
          element.type === InputTypes.FORM &&
          element.required &&
          !(elementState && elementState.valid)
        ) {
          errorObject[element.name] = createOrAppendToArray(
            errorObject[element.name],
            { type: "not_valid" }
          );
        }

        if (
          element.pattern &&
          elementState &&
          (!elementState.value.match(element.pattern) ||
            elementState.value.match(element.pattern)[0] !== elementState.value)
        ) {
          errorObject[element.name] = createOrAppendToArray(
            errorObject[element.name],
            { type: "no_match", message: element.title }
          );
        }

        if (element.hasValue) {
          if (element.type === InputTypes.INLINE_LIST) {
            if (!elementState || Object.keys(elementState).length === 0) {
              errorObject[element.name] = createOrAppendToArray(
                errorObject[element.name],
                { type: "no_value" }
              );
            }
          } else if (element.type === InputTypes.CONTAINMENT_CALCULATOR) {
            if (!elementState || elementState.value.availableCapacity === 0) {
              errorObject[element.name] = createOrAppendToArray(
                errorObject[element.name],
                { type: "no_value" }
              );
            }
          } else if (!elementState || !elementState.value) {
            errorObject[element.name] = createOrAppendToArray(
              errorObject[element.name],
              { type: "no_value" }
            );
          }
        }

        if (
          strict &&
          element.hasValueOnClose &&
          element.type === InputTypes.CONTAINMENT_CALCULATOR &&
          (!elementState || elementState.value.availableCapacity === 0)
        ) {
          errorObject[element.name] = createOrAppendToArray(
            errorObject[element.name],
            { type: "no_value" }
          );
        }

        if (
          strict &&
          element.hasValueOnClose &&
          (!elementState || !elementState.value)
        ) {
          errorObject[element.name] = createOrAppendToArray(
            errorObject[element.name],
            { type: "no_value" }
          );
        }

        if (element.validations) {
          const errors = element.validations.reduce(
            (acc: any, validation: any) => {
              if (!state[element.name]) {
                if (validation.type === ValidationTypes.EXPIRES) {
                  acc.push({ type: ExpirationStatus.EXPIRED });
                }
                return acc;
              }

              switch (validation.type) {
                case ValidationTypes.GREATER_THAN:
                case ValidationTypes.SAME_OR_GREATER_THAN:
                  if (state[validation.source]) {
                    const sourceElement = elements.find(
                      ({ name }: any) => name === validation.source
                    );

                    const value =
                      sourceElement!.type !== InputTypes.TIME
                        ? parseInt(state[element.name].value, 10)
                        : state[element.name].value;
                    let comparedTo =
                      sourceElement!.type !== InputTypes.TIME
                        ? parseInt(state[validation.source].value, 10)
                        : state[validation.source].value;
                    if (
                      (value &&
                        validation.type === ValidationTypes.GREATER_THAN &&
                        value <= comparedTo) ||
                      (validation.type ===
                        ValidationTypes.SAME_OR_GREATER_THAN &&
                        value < comparedTo)
                    ) {
                      // @ts-ignore
                      const label = sourceElement.options[0].label;

                      // @ts-ignore
                      switch (sourceElement.type) {
                        case "date":
                          comparedTo = format(
                            new Date(comparedTo),
                            "dd-MM-yyyy"
                          );
                          break;
                        case "dateTime":
                          comparedTo = format(
                            new Date(comparedTo),
                            "dd-MM-yyyy HH:mm"
                          );
                          break;
                        // no default
                      }

                      acc.push({
                        type: validation.type,
                        message:
                          validation.type === ValidationTypes.GREATER_THAN
                            ? `${value} moet groter zijn dan ${comparedTo} (uit veld ${label})`
                            : `${value} moet groter dan of gelijk zijn aan  ${comparedTo} (uit veld ${label})`,
                      });
                    }
                  }
                  break;
                case ValidationTypes.EXPIRES:
                  const value = state[element.name].value;
                  const expirationStatus = getExpirationStatus(
                    new Date(value),
                    parseInt(validation.expires, 10)
                  );
                  if (
                    [
                      ExpirationStatus.EXPIRED,
                      ExpirationStatus.EXPIRING,
                    ].includes(expirationStatus)
                  ) {
                    acc.push({ type: expirationStatus });
                  }
                  break;
                case ValidationTypes.FORBIDDEN:
                  if (state[element.name].value === validation.value) {
                    acc.push({ type: ValidationTypes.FORBIDDEN });
                  }
                  break;
              }
              return acc;
            },
            []
          );

          if (errors.length > 0) {
            errorObject[element.name] = createOrAppendToArray(
              errorObject[element.name],
              ...errors
            );
          }
        }

        if (
          element.type === InputTypes.CONTAINMENT_CALCULATOR &&
          elementState &&
          elementState.value
        ) {
          const totalCapacity = computeTotalCapacityInSquareMeters(
            elementState.value.spaces
          );
          const necessaryCapactiy = computeNecessaryEquipmentCapactiy(
            totalCapacity,
            element.options[0].multiplyingFactor || 1
          );
          const superfluousCapacity = computeSuperfluousCapacity(
            elementState.value.availableCapacity,
            necessaryCapactiy
          );

          if (superfluousCapacity < 0) {
            errorObject[element.name] = createOrAppendToArray(
              errorObject[element.name],
              { type: "not_enough_capacity" }
            );
          }
        }

        return acc;
      },
      { unique: [], localUnique: [] }
    );

  let entryKeys = Object.keys(entries || {})
    .filter((key: string) => key !== entryId)
    .map((key: string) => ({
      key,
      closed: entries[key] ? Boolean(entries[key].closed) : false,
    }));

  // verify that all local unique values are unique
  if (!Array.isArray(uniqueReferenceIds)) {
    uniqueReferenceIds = [uniqueReferenceIds];
  }

  let localEntryKeys = Object.keys(entries || {})
    .filter(
      (key: string) => key !== entryId && uniqueReferenceIds.includes(key)
    )
    .map((key: string) => ({
      key,
      closed: entries[key] ? Boolean(entries[key].closed) : false,
    }));

  if (formName === "formBuilderFormField") {
    const [form] = entryId.split("_");
    entryKeys = entryKeys.filter(
      ({ key }: { key: string }) => key.split("_")[0] === form
    );
  }

  entryKeys.forEach(({ key, closed }: { key: string; closed: boolean }) => {
    unique.forEach(
      ({
        element,
        ignoreClosed,
      }: {
        element: string;
        ignoreClosed: boolean;
      }) => {
        if (
          entries[key][element] &&
          state[element] &&
          entries[key][element].value === state[element].value
        ) {
          if (ignoreClosed && closed) {
            return;
          }

          // @ts-ignore
          if (errorObject[element]) {
            // @ts-ignore
            errorObject[element].push({ type: "not_unique" });
          } else {
            // @ts-ignore
            errorObject[element] = [{ type: "not_unique" }];
          }
        }
      }
    );
  });

  localEntryKeys.forEach(
    ({ key, closed }: { key: string; closed: boolean }) => {
      localUnique.forEach(
        ({
          element,
          ignoreClosed,
        }: {
          element: string;
          ignoreClosed: boolean;
        }) => {
          if (
            entries[key][element] &&
            state[element] &&
            entries[key][element].value === state[element].value
          ) {
            if (ignoreClosed && closed) {
              return;
            }

            // @ts-ignore
            if (errorObject[element]) {
              // @ts-ignore
              errorObject[element].push({ type: "not_unique" });
            } else {
              // @ts-ignore
              errorObject[element] = [{ type: "not_unique" }];
            }
          }
        }
      );
    }
  );

  return errorObject;
};
