import { FormType } from "../constants";

export default {
    deletable: true,
    displayName: 'Validation',
    name: 'ruleBuilderValidation',
    latest: 'default',
    type: FormType.FORM,
    versions: {
        default: {
            elements: [
                {
                    name: 'validationType',
                    options: [{
                        label: 'Type',
                        placeholder: '-- choose the type of validation --',
                        elements: [
                            { label: 'Is checked', value: 'isChecked' },
                        ],
                    }],
                    required: false,
                    sequence: 0,
                    type: 'select',
                    unique: false,
                    columnWidth: 5,
                },
                {
                    // @todo: make dropdown of all form fields
                    name: 'field',
                    options: [{ label: 'Technical name of the field' }],
                    required: false,
                    sequence: 1,
                    type: 'text',
                    unique: false,
                    columnWidth: 5,
                },
            ],
        },
    },
};
