import { createSelector } from "reselect";
import { ExportState } from "../constants";

const getExportState = (state: { export: ExportState }) => state.export;

export const isDialogOpen = createSelector(
  getExportState,
  ({ dialogOpen }) => dialogOpen
);

export const getExportFormAndEntryId = createSelector(
  getExportState,
  ({ form, entryId }) => ({ form, entryId })
);

export const getPDFStatus = createSelector(
  getExportState,
  ({ exportingPdf, pdfDownloadUrl }) => ({
    exporting: exportingPdf,
    downloadUrl: pdfDownloadUrl,
  })
);

export const getCustomExport = createSelector(
  getExportState,
  (_: { export: ExportState }, { name }: { name: string }) => name,
  ({ customExports }, name) => customExports[name]
);
