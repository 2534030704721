import { FSA } from "../constants";

export const createReducer = <S>(
  strategyMap: Record<string, Function>,
  initialState: S
) => (state = initialState, action: FSA) =>
  (strategyMap[action.type] ? strategyMap[action.type] : (state: S) => state)(
    state,
    action
  );


