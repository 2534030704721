import { FormType } from "../constants";

export default {
    displayName: 'Bedrijven',
    name: 'companies',
    latest: 'default',
    type: 'root',
    versions: {
        default: {
            elements: [{
                name: 'companyList',
                options: [{
                    addition: 'Bedrijf',
                    displayFormat: '[name]',
                    elements: [{
                        name: 'company',
                        type: FormType.FORM,
                    }],
                    matchFields: 'name',
                    isGrouped: true,
                    groupDisplayFormat: '[company.environment]',
                    groupBy: 'environment',
                }],
                required: true,
                sequence: 0,
                type: FormType.ROOT,
                nonEditable: true,
            }],
        },
    },
};
