import { createSelector } from "reselect";
import { State, RoutingBranch, ByForm, FormType } from "../constants";
// @ts-ignore
import { matchPath } from "react-router-dom";

const getState = ({ routing }: State) => routing;

export const getCurrentPath = createSelector(getState, ({ path }) => path);

export const getRoutingTable = createSelector(
  getState,
  ({ routingTable }) => routingTable
);

export const getRoot = createSelector(getState, ({ root }) => root);

export const getCurrentBranch = createSelector(
  getState,
  ({ root, routingTable }) => routingTable[root]
);

export const getCurrentForm = createSelector(getState, ({ form }) => form);

export const getPathParams = createSelector(
  getState,
  ({ pathParams }) => pathParams
);

export const getCurrentFormAndId = createSelector(
  getState,
  ({ pathParams, form }) => ({
    form,
    entryId: pathParams[`${form}Id`] || undefined,
  })
);

const findParentFormRoute = (
  routes: RoutingBranch[],
  route: RoutingBranch
): any => {
  const foundRoute = routes.find(({ path }) => path === route.parent);

  if (
    !foundRoute ||
    foundRoute.type === FormType.FORM ||
    foundRoute.path === foundRoute.parent
  ) {
    if (foundRoute && foundRoute.type === FormType.FORM) {
      return foundRoute;
    }

    return;
  }

  return findParentFormRoute(routes, foundRoute);
};

export const getCurrentFormsParentFormAndId = createSelector(
  getState,
  getCurrentBranch,
  ({ pathParams, form }, branch) => {
    let matchIndex = branch.findIndex(({ name }: any) => name === form);

    if (!branch[matchIndex]) {
      return;
    }

    const parentRoute = findParentFormRoute(branch, branch[matchIndex]);

    if (!parentRoute) {
      return;
    }

    return {
      form: parentRoute.name,
      entryId: pathParams[parentRoute.entryId],
    };
  }
);

export const getParentFormAndIdByForm = createSelector(
  getState,
  getCurrentBranch,
  (_: State, { form }: ByForm) => ({ form }),
  ({ pathParams }, branch, { form }) => {
    let matchIndex = branch.findIndex(({ name }: any) => name === form);

    if (!branch[matchIndex]) {
      return;
    }

    const parentRoute = findParentFormRoute(branch, branch[matchIndex]);

    if (!parentRoute) {
      return;
    }

    return {
      form: parentRoute.name,
      entryId: pathParams[parentRoute.entryId],
    };
  }
);

const getUrlInBranchByFormName = (
  currentPath: string,
  branch: RoutingBranch[],
  form: string
) => {
  const formPaths = branch.filter(({ name }) => name === form);

  if (formPaths.length === 0) {
    return "";
  }

  const [matchedPath] = formPaths
    .map(({ path }) => matchPath(currentPath, { path }))
    .filter((matched) => matched);

  if (!matchedPath) {
    return "";
  }

  return matchedPath.url;
};

export const pathToEntryByForm = createSelector(
  getCurrentPath,
  getCurrentBranch,
  (_: State, { form }: ByForm) => ({ form }),
  (currentPath, currentBranch, { form }) =>
    getUrlInBranchByFormName(currentPath, currentBranch, form)
);

export const isInCurrentPathByForm = createSelector(
  pathToEntryByForm,
  (pathToEntry) => Boolean(pathToEntry)
);

export const isInCurrentPathById = createSelector(
  getCurrentPath,
  (_: State, { id }: { id: string }) => ({ id }),
  (currentPath, { id }) => currentPath.includes(id)
);
