import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";

import {
  getLastStoredByName,
  getEntryByFormAndId,
} from "../../selectors/entries";
import { getConditionStatus } from "../../selectors/conditions";

import Radio from "./Radio";
import Input from "./Input.tsx";
import Textarea from "./Textarea.tsx";
import Select from "./Select.tsx";
import File from "./File.tsx";
import Signature from "./Signature.tsx";
import Checkbox from "./Checkbox";
import CrossSelect from "./CrossSelect";
import DatePicker from "./DatePicker";
import DateTimePicker from "./DateTimePicker";
import InlineList from "./InlineList";
import ContainmentCalculator from "./ContainmentCalculator";
import FormItem from "./FormItem";
import Static from "./Static";
import { sortBySequence, getLabel } from "../../utility/helpers";

import { theme } from "../../styles";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MaterialList from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import ListElement from "./ListElement";

import { Defaults } from "../../constants.ts";
import { ValidationTypes } from "../../constants";

// @ts-ignore
const useStyles = makeStyles(() => ({
  arrow: {
    fontSize: 20,
    color: theme.colors.lightGray,
  },
  disabledInput: {
    marginTop: 12,
    marginBottom: 4,
  },
  grid: {
    width: "100%",
  },
  list: {
    margin: "0 -10px",
    padding: 0,
  },
  formItem: {
    minHeight: "48px",
    height: "56px",
    padding: "0 10px",
    "&.selected": {
      backgroundColor: theme.colors.backgroundLightGray,
    },
  },
  listItem: {
    minHeight: "56px",
    padding: "0 10px",
    borderBottom: `1px solid ${theme.colors.lightGray}`,
    borderRight: `1px solid ${theme.colors.lightGray}`,
  },
  section: {
    backgroundColor: theme.colors.lightGray,
    lineHeight: "56px",
    height: "56px",
    fontWeight: "bold",
  },
  listAndFormError: {
    marginTop: "-14px",
    paddingBottom: "4px",
  },
  listItemText: {
    fontSize: 16,
  },
}));

export const getValue = (name, values = {}, typecast) => {
  if (name === "id") {
    return values[name];
  }

  switch (typecast) {
    case "boolean":
      if (values[name] && values[name].value) {
        if (
          typeof values[name].value === "boolean" ||
          values[name].value === "true"
        ) {
          return true;
        }
      }

      return false;
    case "array":
      if (values[name]) {
        return values[name];
      }

      return [];
    default:
      if (values[name] && values[name].value) {
        return values[name].value;
      }

      return "";
  }
};

const Elements = ({
  elements,
  handleChange,
  name,
  values = {},
  baseUrl,
  readonly,
  errors = {},
  entry,
  form,
  entryId,
  selectedEntry,
  ...props
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState("");
  // @todo: use the getElementsSortedAndFilteredByFormAndEntry selector (take inlineLists into account)
  const metConditions = useSelector((state) =>
    getConditionStatus(state, { values, form: name, entryId })
  );
  const dispatch = useDispatch();

  return (
    <Grid container spacing={0}>
      {elements
        .slice()
        .sort(sortBySequence)
        .map((element, index) => {
          let classNames = classes.listItem;
          let renderedElement = null;
          let validations = [];
          let errorElement = null;

          if (
            element.conditions &&
            element.conditions.length > 0 &&
            !metConditions[element.name]
          ) {
            return null;
          }

          if (element.validations) {
            const acceptedValidationTypes = [
              ValidationTypes.FORBIDDEN,
              ValidationTypes.WARNING,
              ValidationTypes.MAX_LIMIT,
              ValidationTypes.MIN_LIMIT,
              ValidationTypes.EXPIRES,
            ];
            validations = element.validations.filter(({ type }) =>
              acceptedValidationTypes.includes(type)
            );
          }

          switch (element.type) {
            case "radio":
              renderedElement = (
                <Radio
                  readonly={readonly}
                  options={element.options}
                  name={element.name}
                  handleChange={handleChange}
                  value={getValue(element.name, values)}
                  validations={validations}
                  errors={errors[element.name]}
                />
              );
              break;
            case "text":
            case "number":
            case "time":
            case "email":
              renderedElement = (
                <Input
                  form={name}
                  readonly={readonly}
                  options={element.options}
                  name={element.name}
                  handleChange={handleChange}
                  type={element.type}
                  value={getValue(element.name, values)}
                  pattern={element.pattern}
                  title={element.title}
                  validations={validations}
                  errors={errors[element.name]}
                />
              );
              break;
            case "date":
              renderedElement = (
                <DatePicker
                  readonly={readonly}
                  options={element.options}
                  name={element.name}
                  handleChange={handleChange}
                  value={getValue(element.name, values)}
                  errors={errors[element.name]}
                  validations={validations}
                />
              );
              break;
            case "dateTime":
              renderedElement = (
                <DateTimePicker
                  readonly={readonly}
                  options={element.options}
                  name={element.name}
                  handleChange={handleChange}
                  value={getValue(element.name, values)}
                  errors={errors[element.name]}
                  validations={validations}
                />
              );
              break;
            case "checkbox":
              renderedElement = (
                <Checkbox
                  readonly={readonly}
                  options={element.options}
                  name={element.name}
                  handleChange={handleChange}
                  type={element.type}
                  value={getValue(element.name, values, "boolean")}
                  errors={errors[element.name]}
                />
              );
              break;
            case "textarea":
              renderedElement = (
                <Textarea
                  form={name}
                  readonly={readonly}
                  options={element.options}
                  name={element.name}
                  handleChange={handleChange}
                  value={getValue(element.name, values)}
                  errors={errors[element.name]}
                />
              );
              break;
            case "select":
              renderedElement = (
                <Select
                  form={name}
                  readonly={readonly}
                  options={element.options}
                  name={element.name}
                  handleChange={handleChange}
                  value={getValue(element.name, values)}
                  errors={errors[element.name]}
                />
              );
              break;
            case "crossSelect":
              renderedElement = (
                <CrossSelect
                  readonly={readonly}
                  options={element.options}
                  name={element.name}
                  handleChange={handleChange}
                  value={getValue(element.name, values)}
                  errors={errors[element.name]}
                  form={name}
                />
              );
              break;
            case "form": {
              renderedElement = (
                <FormItem
                  form={name}
                  isNewEntry={entryId === Defaults.NEW_ENTRY_ID}
                  errors={errors[element.name]}
                  isReadonly={readonly}
                  element={element}
                />
              );
              break;
            }
            case "list": {
              renderedElement = (
                <ListElement
                  form={name}
                  isNewEntry={entryId === Defaults.NEW_ENTRY_ID}
                  errors={errors[element.name]}
                  element={element}
                />
              );
              break;
            }
            case "file":
              renderedElement = (
                <File
                  readonly={readonly}
                  options={element.options}
                  name={element.name}
                  handleChange={handleChange}
                  value={getValue(element.name, values)}
                  form={name}
                  entryId={entryId}
                  errors={errors[element.name]}
                  inline={props.inline}
                />
              );
              break;
            case "signature":
              renderedElement = (
                <Signature
                  readonly={readonly}
                  options={element.options}
                  name={element.name}
                  handleChange={handleChange}
                  value={getValue(element.name, values)}
                  errors={errors[element.name]}
                  isNewEntry={entryId === Defaults.NEW_ENTRY_ID}
                />
              );
              break;
            case "section":
              if (!element.options[0].hideLabel) {
                renderedElement = element.options[0].label;

                if (element.type === "section") {
                  classNames = `${classNames} ${classes.section}`;
                }
              }
              break;
            case "button":
              const Button = (props) => {
                const clickHandler = () => {
                  let payload = {};

                  if (props.valuesToSelect) {
                    payload = props.valuesToSelect.reduce((acc, handle) => {
                      acc[handle] = getValue(handle, values);
                      return acc;
                    }, {});
                  }

                  dispatch(props.action(payload));
                };

                return (
                  <button onClick={clickHandler} type="button">
                    {props.options[0].label}
                  </button>
                );
              };
              renderedElement = (
                <Button
                  name={element.name}
                  handleChange={handleChange}
                  options={element.options}
                  action={element.action}
                  valuesToSelect={element.valuesToSelect}
                  disableElevation
                />
              );
              break;
            case "lastStoredBy":
              renderedElement = (
                <Input
                  readonly={true}
                  options={[
                    {
                      ...element.options[0],
                      placeholder:
                        "Naam van ingelogde DTA wordt hier automatisch ingevuld na opslaan van formulier",
                    },
                  ]}
                  name={element.name}
                  type={"text"}
                  value={props.lastStoredByName}
                />
              );
              break;
            case "static":
              const label = getLabel(element.options);
              renderedElement = (
                <Static
                  label={label}
                  displayFormat={element.options[0].displayFormat}
                  form={name}
                  entryId={entryId}
                />
              );
              break;
            case "inlineList":
              renderedElement = (
                <InlineList
                  readonly={readonly}
                  options={element.options}
                  name={element.name}
                  handleChange={handleChange}
                  value={getValue(element.name, values, "array")}
                  errors={errors}
                  formName={name}
                />
              );
              break;
            case "menu": {
              let onClick = () => {
                setSelected(element.name);
                if (entryId === Defaults.NEW_ENTRY_ID) {
                  alert(
                    "Het formulier moet opgeslagen worden voordat dit onderdeel gebruikt kan worden"
                  );
                  return;
                }

                dispatch(push(`${baseUrl}/${element.name}`));
              };

              renderedElement = (
                <MaterialList dense={true} className={classes.list}>
                  <ListItem
                    button
                    onClick={onClick}
                    className={classes.formItem}
                    selected={selected === element.name && !props.isLast}
                  >
                    <ListItemText
                      primary={element.options[0].label}
                      secondary={errorElement}
                      classes={{ primary: classes.listItemText }}
                    />
                    <ArrowForwardIos className={classes.arrow} />
                  </ListItem>
                </MaterialList>
              );
              break;
            }
            case "containmentCalc":
              renderedElement = (
                <ContainmentCalculator
                  multiplyFactor={element.options[0].multiplyingFactor}
                  value={getValue(element.name, values)}
                  name={element.name}
                  handleChange={handleChange}
                  errors={errors[element.name]}
                  readonly={readonly}
                />
              );
              break;
            default:
              console.warn(`Unknown form input ${element.type}`);
              return null;
          }

          return (
            <Grid
              item
              className={classNames}
              key={`${name}-${index}`}
              xs={element.columnWidth ? parseInt(element.columnWidth, 10) : 12}
            >
              {renderedElement}
            </Grid>
          );
        })}
      {props.tail && (
        <Grid
          item
          className={classes.listItem}
          xs={2}
          style={{ lineHeight: "56px" }}
        >
          {props.tail}
        </Grid>
      )}
    </Grid>
  );
};

const ElementsConnector = connect((state, { name, entryId }) => {
  let lastStoredByName = "";
  let entry = {};

  if (name && entryId) {
    lastStoredByName = getLastStoredByName(state, { form: name, entryId });
  }

  if (entryId !== Defaults.NEW_ENTRY_ID && name) {
    entry = getEntryByFormAndId(state, { form: name, entryId });
  }

  return { lastStoredByName, selectedEntry: entry };
})(Elements);

export default ElementsConnector;
