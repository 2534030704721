import { select, call, put } from "redux-saga/effects";

import { firestore } from "../firebase";
import { getCompany } from "../selectors/company";
import { setDashboardData } from "../actions/dashboard";

function* fetchDashboardData() {
  const company = yield select(getCompany);
  const defaultDashboard = yield firestore
    .collection(`companies/${company}/dashboard`)
    .get()
    .then((dashboardObject: any) => {
      return dashboardObject.docs.reduce((acc: any, dashboard: any) => {
        if (dashboard.id === "default") {
          return dashboard.data();
        }
        return acc;
      }, {});
    });

  if (defaultDashboard && defaultDashboard.template && defaultDashboard.config) {
    yield put(setDashboardData(defaultDashboard.config, defaultDashboard.template));
  }
}

export default function* saga() {
  yield call(fetchDashboardData);
}
