import React, { useState } from "react";
// @ts-ignore
import { connect, useStore, useDispatch, useSelector } from "react-redux";

import { Defaults, InputElement, State, InputTypes } from "../../constants";
import { getElementsByForm, isFileElement } from "../../selectors/forms";
import {
  getDefaultValuesByFormAndSource,
  getDefaultValuesByForm,
} from "../../selectors/defaultValues";
import Elements from "./Elements";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import DoneAll from "@material-ui/icons/DoneAll";
import DynamicFeed from "@material-ui/icons/DynamicFeed";
import { getFormEntry } from "../../selectors/drawerContainer";
import { theme } from "../../styles";
import Checkbox from "./Checkbox";
import { deleteFiles } from "../../actions/entries";

// @ts-ignore
const useStyles = makeStyles(() => ({
  listItem: {
    borderBottom: `1px solid ${theme.colors.lightGray}`,
    minHeight: "48px",
    lineHeight: "48px",
    padding: "0px 10px",
  },
}));

export interface InlineListProps {
  readonly: boolean;
  options: any;
  name: string;
  handleChange: any;
  value: any;
  errors: any;
  elements?: any;
  formName: string;
  emptyEntry: any;
}

const InlineList = ({
  readonly,
  options,
  name,
  handleChange,
  value,
  errors,
  elements,
  formName,
  emptyEntry,
}: InlineListProps) => {
  const classes = useStyles();
  const globalState = useStore();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState({});
  const [multiValues, setMultiValue] = useState({});
  const generalDefaultValues = useSelector((state: any) =>
    getDefaultValuesByForm(state, { form: options[0].elements[0].name })
  );

  const customHandleChange = (event: any) => {
    const [, idx, elementName] = event.target.name.split(".");

    const isCrossSelect = elements.find(
      ({ name, type }: any) => name === elementName && type === InputTypes.CROSS_SELECT
    );
    if (isCrossSelect) {
      const defaultValues = getDefaultValuesByFormAndSource(
        globalState.getState(),
        {
          form: options[0].elements[0].name,
          element: elementName,
          value: event.target.value,
        }
      );

      defaultValues.forEach((defaultValue) => {
        handleChange({
          target: {
            name: `${name}.${idx}.${defaultValue.name}`,
            value: defaultValue.value,
          },
        });
      });
    }
    handleChange(event);
  };

  // @todo: it would be better if this value was already set by the callee of this element
  if (
    value.length === 1 &&
    Object.keys(generalDefaultValues).length > 0 &&
    Object.keys(value[0]).length === 0
  ) {
    Object.entries(generalDefaultValues).forEach(
      ([elementName, value]: [string, any]) => {
        customHandleChange({
          target: { name: `${name}.0.${elementName}`, value: value },
        });
      }
    );
  }

  const createNewEntry = () => {
    const newIdx = value ? value.length : 0;
    handleChange({
      target: {
        name: `${name}.${newIdx}.${elements[0].name}`,
        value: "",
      },
    });
    Object.entries(generalDefaultValues).forEach(
      ([elementName, value]: [string, any]) => {
        handleChange({
          target: { name: `${name}.${newIdx}.${elementName}`, value: value },
        });
      }
    );
  };

  const deleteEntry = (idx: number) => () => {
    const fileElements = elements.filter(isFileElement);
    if (fileElements.length > 0) {
      const entryToDelete = value[idx];
      const filePathsToDelete = fileElements
        .map(
          ({ name }: InputElement) =>
            entryToDelete[name] && entryToDelete[name].value.path
        )
        .filter((path: string) => path);

      dispatch(deleteFiles(filePathsToDelete));
    }

    setSelected({ ...selected, [idx]: false });

    handleChange({ target: { name: `${name}.${idx}`, value: null } });
  };

  if (value.length === 0) {
    createNewEntry();
  }

  let multiEdit = false;
  let editAtOnceElements;

  if (options[0].editAtOnce && !readonly) {
    multiEdit = true;
    editAtOnceElements = options[0].editAtOnce
      .split(",")
      .map((elementName: string) => {
        const originalElement = elements.find(
          ({ name }: any) => name === elementName
        );
        return { ...originalElement, name: `multi${elementName}` };
      });
  }

  const selectForMultiEdit = (idx: number) => {
    // @ts-ignore
    setSelected({ ...selected, [idx]: selected[idx] ? !selected[idx] : true });
  };

  const handleMultiChange = (event: any) => {
    setMultiValue({
      ...multiValues,
      [event.target.name]: { value: event.target.value },
    });
  };

  const updateSelectedRecords = () => {
    const fieldsToUpdate = Object.entries(multiValues).map(
      ([elementName, { value }]: [string, any]) => {
        return {
          elementName: elementName.replace("multi", ""),
          value,
        };
      }
    );

    Object.entries(selected)
      .filter(([, value]) => value)
      .forEach(([idx]) => {
        fieldsToUpdate.forEach(({ elementName, value }) => {
          customHandleChange({
            target: {
              name: `${name}.${idx}.${elementName}`,
              value: value,
            },
          });
        });
      });
  };

  const selectAll = () => {
    const selectedElements = value.reduce(
      (acc: Record<number, boolean>, _: any, idx: number) => {
        acc[idx] = true;
        return acc;
      },
      {}
    );
    setSelected(selectedElements);
  };

  return (
    <>
      {!readonly && (
        <div className={classes.listItem}>
          <Button startIcon={<Add />} onClick={createNewEntry} disableElevation>
            {options[0].addition}
          </Button>
        </div>
      )}
      {multiEdit && (
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <IconButton onClick={selectAll}>
              <DynamicFeed />
            </IconButton>
          </Grid>
          <Grid item xs={11}>
            <Elements
              handleChange={handleMultiChange}
              elements={editAtOnceElements}
              values={multiValues}
              inline={true}
              name={formName}
              tail={
                <IconButton onClick={updateSelectedRecords}>
                  <DoneAll />
                </IconButton>
              }
            />
          </Grid>
        </Grid>
      )}
      {value.map((entry: any, idx: number) => (
        <Grid container spacing={0} key={`${idx}`}>
          {multiEdit && (
            <Grid item xs={1}>
              <Checkbox
                options={[{ label: "" }]}
                name={"test"}
                handleChange={() => {
                  selectForMultiEdit(idx);
                }}
                // @ts-ignore
                value={selected[idx]}
              />
            </Grid>
          )}
          <Grid item xs={multiEdit ? 11 : 12}>
            <Elements
              readonly={readonly}
              handleChange={customHandleChange}
              elements={elements.map((element: any) => ({
                ...element,
                name: `${name}.${idx}.${element.name}`,
              }))}
              values={elements.reduce((acc: any, element: any) => {
                acc[`${name}.${idx}.${element.name}`] = {
                  // @ts-ignore
                  ...emptyEntry[element.name],
                  ...entry[element.name],
                };
                return acc;
              }, {})}
              errors={errors}
              tail={
                <IconButton onClick={deleteEntry(idx)}>
                  <Delete />
                </IconButton>
              }
              inline={true}
              name={formName}
              entryId={`${name}-${idx}`}
            />
          </Grid>
        </Grid>
      ))}
    </>
  );
};

const InlineListConnector = connect(
  (state: State, { options }: InlineListProps) => {
    return {
      elements: getElementsByForm(state, { form: options[0].elements[0].name }),
      emptyEntry: getFormEntry(state, {
        form: options[0].elements[0].name,
        entryId: Defaults.NEW_ENTRY_ID,
      }),
    };
  }
)(InlineList);

export default InlineListConnector;
