import { takeEvery, call, take, race, put } from "redux-saga/effects";
import { functions } from "../firebase";
import { REST_REQUEST, restRequest, SCHEDULE_REST_REQUEST } from "../actions/externalApi";
import { FSA } from "../constants";
import scheduler from "./scheduler";
import { LOCATION_CHANGE } from "connected-react-router";
import {cancelInterval, CANCEL_INTERVAL} from "../actions/schedule";

function* executeRestRequest({ payload }: FSA) {
  const { args, responseHandler } = payload;
  const restRequestFunction = functions.httpsCallable("restRequest");
  const { data } = yield restRequestFunction(args);

  yield responseHandler(data);
}

function* scheduleRestRequest({ payload }: FSA) {
  const { duration, interval, args, responseHandler } = payload;
  const channel = yield call(scheduler, duration, interval);
  try {
    while (true) {
      const { navigation, cancel } = yield race({
        request: take(channel),
        navigation: take(LOCATION_CHANGE),
        cancel: take(CANCEL_INTERVAL),
      });

      if (navigation || cancel) {
        channel.close();
      }

      yield put(restRequest(args, responseHandler));
    }
  } finally {
    yield put(cancelInterval());
  }
}

/**
 * @TODO
 *
 * Data refresh:
 * - implement a dropdown to set the fetch request interval (this is only for dashboards)
 *
 * External source dropdown:
 * - allow the dropdown to be added
 * - dropdown contains the source and arguments needed to display a list and get the id and necessary data
 * - other fields must be able to be populated with the data retrieved once and then stored
 * - for the external source field we should store the chosen id and the last sync timestamp
 *
 * Temp external data field:
 * - should contain the configuration for the request to fetch
 * - the fetched data should only be stored in the local state
 * - could use Ramda to create a lens to simplify getting and setting this data through the entries reducer
 * - data should be fetched upon opening the entry OR based on the interval for the dashboard
 * - the dashboard should create a scheduled interval based on inputs of this type
 */

export default function* saga() {
  yield takeEvery(REST_REQUEST, executeRestRequest);
  yield takeEvery(SCHEDULE_REST_REQUEST, scheduleRestRequest);
}
