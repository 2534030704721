import { FSA } from '../constants';

export const FETCH_ALL = 'FORM_FETCH_ALL';
export const fetchAll = () => ({ type: FETCH_ALL });

export const FETCHED_ALL = 'FORM_FETCHED_ALL';
export const fetchedAll = (forms: any) => ({
    type: FETCHED_ALL,
    payload: forms,
});

export const FETCH = 'FORM_FETCH';
export const fetch = (formId: string): FSA => ({ type: FETCH, payload: formId });

export const FETCH_VERSION = 'FORM_FETCH_VERSION';
export const fetchVersion = (formId: string, versionId: string): FSA => ({ type: FETCH_VERSION, payload: { formId, versionId }});

export const SET_FIXED = 'FORM_SET_FIXED';
export const setFixed = (forms: any) => ({ type: SET_FIXED, payload: forms });

export const UPDATE = 'FORM_UPDATE';
export const update = (formId: string, form: any): FSA => ({ type: UPDATE, payload: { formId, form } });
