import React from "react";

import { getErrorMessage } from "./Error";

import { sortBySequence } from "../../utility/helpers.ts";

import { theme } from "../../styles.ts";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { ValidationTypes } from "../../constants";

// @ts-ignore
const useStyles = makeStyles((materialTheme) => ({
  formControl: theme.input,
  green: theme.greenButton,
  red: theme.redButton,
  gray: theme.button,
  orange: theme.orangeButton,
}));

const validationContains = (validations, matchType, value) => {
  const result = validations.find(({ type }) => type === matchType);

  if (result && value === result.value) {
    return result.message;
  }

  return "";
};

const Radio = ({
  options,
  name,
  handleChange,
  value,
  readonly = false,
  validations = [],
  errors = [],
}) => {
  const classes = useStyles();

  const customHandleChange = (event) => {
    if (validations) {
      const forbidden = validationContains(
        validations,
        ValidationTypes.FORBIDDEN,
        event.target.value
      );
      if (forbidden) {
        alert(forbidden);
      }

      const warning = validationContains(
        validations,
        ValidationTypes.WARNING,
        event.target.value
      );
      if (warning) {
        alert(warning);
      }
    }

    handleChange(event);
  };

  const toggleValue = (toggleValue) => {
    customHandleChange({
      target: {
        value: toggleValue === value ? "" : toggleValue,
        name,
      },
    });
  };

  const determineClass = (targetValue) => {
    if (targetValue === value) {
      if (validations) {
        const forbidden = validationContains(
          validations,
          ValidationTypes.FORBIDDEN,
          value
        );
        if (forbidden) {
          return classes.red;
        }

        const warning = validationContains(
          validations,
          ValidationTypes.WARNING,
          value
        );
        if (warning) {
          return classes.orange;
        }
      }

      return classes.green;
    }

    return classes.gray;
  };

  return (
    <FormControl
      className={classes.formControl}
      style={{ marginTop: 8, marginBottom: 4, height: "84px" }}
      fullWidth
    >
      {!options[0].hideLabel && (
        <InputLabel htmlFor={name} shrink={true}>
          {options[0].label}
        </InputLabel>
      )}
      <Grid container spacing={3} style={{ marginTop: 18 }}>
        {options &&
          options[0].elements.sort(sortBySequence).map((option, index) => (
            <Grid item xs={4} key={`${name}-${index}`}>
              <Button
                variant="contained"
                className={determineClass(option.value)}
                onClick={() => toggleValue(option.value)}
                disabled={readonly}
                disableElevation
              >
                {option.label}
              </Button>
            </Grid>
          ))}
      </Grid>
      {errors.length > 0 &&
        errors.map((error, index) => (
          <FormHelperText error key={`${error.type}-${index}`}>
            {getErrorMessage(error.type, error.message)}
          </FormHelperText>
        ))}
    </FormControl>
  );
};

export default Radio;
