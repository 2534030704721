// @ts-ignore
import { useSelector } from "react-redux";
import { RoutingBranch, State } from "../constants";
import {
  getElementsWithLabel,
  RawCrossSelectElementWithLabel,
} from "../selectors/crossSelect";
import { replaceVariables } from "../selectors/displayFormat";
import { getEntryIdByCurrentBranchAndForm } from "../selectors/entries";
import { getBranchByForm } from "../selectors/forms";
import { getPathParams, getRoot } from "../selectors/routing";

interface FilterType {
  key: string;
  value: string;
}

const filterClosedBasedOnOption = (allowClosed: boolean, closed: boolean) => {
  if (!allowClosed && closed) {
    return false;
  }

  return true;
};

const applyFilterToExposedFields = (
  filters: FilterType[],
  entry: RawCrossSelectElementWithLabel
) => {
  if (filters.length === 0) {
    return true;
  }

  return filters.every(({ key, value }: FilterType) => {
    if (!entry.exposedFieldsValue[key]) {
      return false;
    }

    return value.split(",").includes(entry.exposedFieldsValue[key]);
  });
};

interface GetCrossSelectElements {
  currentEntryId: string;
  element: {
    form: string;
    sortFields: string[];
    displayFormat: string;
    filters: { key: string; value: string }[];
    allowClosed: boolean;
  };
  form: string;
  ignoreValidation: boolean;
}

export const getCrossSelectElements = ({
  currentEntryId,
  element,
  form,
  ignoreValidation,
}: GetCrossSelectElements) => {
  const selectorArgs = {
    form: element.form,
    sortFields: element.sortFields,
    currentEntryId,
    exposeFields: element.filters.map(({ key }: FilterType) => key),
    displayFormat: element.displayFormat,
    ignoreValidation,
  };
  const convertedFilters = useSelector((state: State) => {
    if (element.filters.length === 0) {
      return element.filters;
    }

    const parentEntryId = getEntryIdByCurrentBranchAndForm(state, { form });
    const rootForm = getRoot(state);
    const pathParams = getPathParams(state);

    return element.filters.map(({ key, value }) => ({
      key,
      value: replaceVariables(
        value,
        rootForm,
        form,
        pathParams,
        state.entries,
        state.forms.forms,
        false,
        parentEntryId
      ),
    }));
  });
  const entries: RawCrossSelectElementWithLabel[] = useSelector(
    (state: State) => getElementsWithLabel(state, selectorArgs)
  );

  return entries
    .filter(({ closed }) =>
      filterClosedBasedOnOption(element.allowClosed, closed)
    )
    .filter((entry) => applyFilterToExposedFields(convertedFilters, entry))
    .sort((entryA, entryB) =>
      entryA.filterValues.join(" ").localeCompare(entryB.filterValues.join(" "))
    )
    .map((entry) => ({
      label: entry.label,
      value: entry.entryId,
      closed: entry.closed,
      filter: [...entry.filterValues, entry.label].join(" "),
    }));
};

interface GetSourceAndTargetRoute {
  form: string;
  source: string;
}

export const getSourceAndTargetRoute = ({
  form,
  source,
}: GetSourceAndTargetRoute) => {
  const branches = useSelector((state: State) =>
    getBranchByForm(state, { form: source })
  );
  const firstBranch = Object.values(branches)[0] as RoutingBranch[];

  if (!firstBranch) {
    return { targetRoute: "", sourceRoute: "" };
  }

  const { path: targetRoute } = firstBranch.find(
    ({ name }) => name === source
  ) || { path: "" };
  const { path: sourceRoute } = firstBranch.find(
    ({ name }) => name === form
  ) || { path: "" };

  return { targetRoute, sourceRoute };
};

