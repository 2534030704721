import { eventChannel } from 'redux-saga';
import { call, take, put, race } from 'redux-saga/effects';
import { auth, firestore } from '../firebase';
import { statusChanged, loggedOut, LOGOUT } from '../actions/auth';

function* authStateChangeListener() {
    return eventChannel(emit => {
        auth.onAuthStateChanged((user) => emit({ user }));
        return () => {};
    });
}

export default function* saga () {
    const channel = yield call(authStateChangeListener);

    while (true) {
        const { authChange, logout } = yield race({
            authChange: take(channel),
            logout: take(LOGOUT),
        });

        if (logout) {
            auth.signOut();
            continue;
        }

        if(!authChange.user) {
            yield put(loggedOut());
            continue;
        }

        const user = yield firestore
            .collection(`users`)
            .doc(authChange.user.uid)
            .get()
            .then(doc => doc.data());

        yield put(statusChanged({ uid: authChange.user.uid, ...user }));
    }
};
