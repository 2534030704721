import { createSelector } from "reselect";
import { CompanyState } from "../constants";

const getCompanyState = ({ company }: { company: CompanyState }) => company;

const getActiveCompany = createSelector(
  getCompanyState,
  ({ activeCompany, companies }) => {
    const activeCompanyId = activeCompany;
    if (activeCompanyId && companies[activeCompanyId]) {
      return companies[activeCompanyId];
    }

    return { name: "", domain: "" };
  }
);

export const getCompany = (state: any) => state.company.activeCompany;

export const getCompanyName = createSelector(
  getActiveCompany,
  ({ name }) => name
);

export const getCompanyById = createSelector(
  getCompanyState,
  (_: { company: CompanyState }, { id }: { id: string }) => id,
  ({ companies }, companyId) => companies[companyId]
);
