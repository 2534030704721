import React, { useState } from "react";
// @ts-ignore
import { useDispatch, useSelector } from "react-redux";
import { storage } from "../firebase";
// @ts-ignore
import FileUploader from "react-firebase-file-uploader";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { isDialogOpen, getExportFormAndEntryId, getPDFStatus } from "../selectors/export";
import { dialogClose, request } from "../actions/export";
import { ExportTarget } from "../constants";
import { theme } from "../styles";
import {getCompany} from "../selectors/company";

// @ts-ignore
const useStyles = makeStyles(() => ({
  formControl: theme.input,
  gray: theme.button,
}));

const ExportDialog = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isDialogOpen);
  const { exporting, downloadUrl } = useSelector(getPDFStatus);
  const { form, entryId } = useSelector(getExportFormAndEntryId);
  const company = useSelector(getCompany);
  const [targetFormat, setTargetFormat] = useState(ExportTarget.HTML);
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [uploaded, setUploaded] = useState("");

  const handleClose = () => {
    dispatch(dialogClose());
    setUploaded("");
    setProgress(0);
  };

  const doExport = () => {
    dispatch(request(form, entryId, targetFormat, uploaded));
  };

  const handleChange = (event: any) => {
    setTargetFormat(event.target.value);
  };

  if (exporting) {
    return (
      <Dialog open={isOpen} onClose={handleClose} fullWidth={true}>
        <DialogTitle>PDF exporteren</DialogTitle>
        <DialogContent>
          <CircularProgress style={{ color: theme.colors.red }} />
          <br />
          <span>Even geduld a.u.b.</span>
        </DialogContent>
        <DialogActions />
      </Dialog>
    );
  }

  if (downloadUrl) {
    return (
      <Dialog open={isOpen} onClose={handleClose} fullWidth={true}>
        <DialogTitle>Export gereed</DialogTitle>
        <DialogContent>
          <Link href={downloadUrl} target="_blank">
            Download PDF
          </Link>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Sluiten</Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  const uploadPath = `${company}/temp`;

  const handleUploadStart = () => {
    setProgress(1);
  }

  const handleProgress = (progress: number) => {
    setProgress(progress);
  };

  const handleUploadError = (error: any) => {
    console.warn("upload error", error);
  };

  const handleUploadSuccess = (_: string, task: any) => {
    setProgress(100);
    setUploaded(task.metadata_.fullPath);
  };

  const uploadButtonDisabled = targetFormat !== ExportTarget.PDF;

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true}>
      <DialogTitle>Export</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" onChange={handleChange}>
          <RadioGroup name="format" value={targetFormat}>
            <FormControlLabel
              className={classes.formControl}
              value={ExportTarget.HTML}
              control={<Radio style={{ color: theme.colors.red }} />}
              label="HTML"
            />
          </RadioGroup>
          <RadioGroup name="format" value={targetFormat}>
            <FormControlLabel
              className={classes.formControl}
              value={ExportTarget.PDF}
              control={<Radio style={{ color: theme.colors.red }} />}
              label="PDF"
            />
          </RadioGroup>
        </FormControl>
        {uploaded === "" && (
          <Button
            variant="contained"
            className={classes.gray}
            component="label"
            style={{ marginTop: 16, marginBottom: 8, width: "100%" }}
            disabled={uploadButtonDisabled}
            disableElevation
          >
            Upload voorblad
            <FileUploader
              hidden
              name="file"
              randomizeFilename
              storageRef={storage.ref(uploadPath)}
              onUploadStart={handleUploadStart}
              onUploadError={handleUploadError}
              onUploadSuccess={handleUploadSuccess}
              onProgress={handleProgress}
            />
          </Button>
        )}
        {uploaded && (
          <div>Voorblad is succesvol geupload</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Annuleren</Button>
        <Button onClick={doExport}>Exporteer</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog;
