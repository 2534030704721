// @ts-ignore
import { useSelector } from 'react-redux';
import { get, isRootOrSuperuser } from '../selectors/user';
import { isSignedIn } from '../selectors/auth';

export const getUser = () => useSelector(get);

export const getSignedInStatus = () => useSelector(isSignedIn);

export const isRootOrHigher = () => useSelector(isRootOrSuperuser);
