import { createSelector } from 'reselect';
import { State, ByForm, RoutingBranch, FormsState, FormType, InputElement, InputTypes, ValidationTypes } from '../constants';
import { deleteIn, getReferencedFormName } from '../utility/helpers';
import { getRoutingTable, getCurrentBranch } from './routing';

export const getAll = ({ forms }: State) => forms;

export const getFormElements = (forms: FormsState['forms'], form: string) => {
    if (!forms[form] || !forms[form].latest) {
        return [];
    }

    return forms[form].versions[forms[form].latest].elements;
};

export const getFormMeta = createSelector(
    getAll,
    (_: State, { form }: { form: string }) => form,
    (forms, form) => deleteIn(forms.forms[form], 'versions')
);

export const getElementsByForm = createSelector(
    getAll,
    (_: State, { form }: ByForm) => form,
    ({ forms }, form) => getFormElements(forms, form)
);

// @todo: this should be imported from the utility, but has circular dependency
const isExpiringElement = (element: InputElement) => {
    if (!element.validations) {
        return false;
    }

    if (!element.validations.find(({ type }) => type === ValidationTypes.EXPIRES)) {
        return false;
    }

    return true;
}

export const getExpiringElementsByForm = createSelector(
    getElementsByForm,
    (elements) => {
        if (!elements || elements.length === 0) {
            return [];
        }

        return elements.filter(isExpiringElement);
    }
);

export const getFileElementsByForm = createSelector(
    getElementsByForm,
    (elements) => {
        if (!elements) {
            return [];
        }

        return elements.filter(({ type }) => type === InputTypes.FILE);
    }
)

const elementIsOfGivenType = ({ type }: InputElement, givenType: InputTypes) => type === givenType;
const isCrossSelect = (element: InputElement) => elementIsOfGivenType(element, InputTypes.CROSS_SELECT);
const isInlineList = (element: InputElement) => elementIsOfGivenType(element, InputTypes.INLINE_LIST);
export const isFileElement = (element: InputElement) => elementIsOfGivenType(element, InputTypes.FILE);

export const getInlineListAndCrossSelectElementsNameAndReferenceByForm = createSelector(
    getElementsByForm,
    (elements) => {
        if (!elements || elements.length === 0) {
            return [];
        }

        const inlineListReferences = elements
            .filter(isInlineList)
            .map((element) => ({ name: element.name, reference: getReferencedFormName(element)[0] }));

        const crossSelectReferences = elements
            .filter(isCrossSelect)
            .map((element) => ({ name: element.name, reference: getReferencedFormName(element)[0] }));

        return [...inlineListReferences, ...crossSelectReferences];
    }
);

export const getInlineListFileElementsByForm = createSelector(
    getElementsByForm,
    getAll,
    (elements, { forms }) => {
        if (!elements || elements.length === 0) {
            return [];
        }

        return elements
            .filter(isInlineList)
            .map((element) => {
                const referencedFormElements = getFormElements(forms, getReferencedFormName(element)[0]);

                const fileElements = referencedFormElements.filter(isFileElement).map(({ name }) => name);

                return ({ name: element.name, fileElements });
            });
    }
);

export const getElementsByParentForm = createSelector(
    getAll,
    getElementsByForm,
    ({ forms }, form) => {
        if (form[0] && form[0].options && form[0].options[0].elements && form[0].options[0].elements[0]) {
            const { name } = form[0].options[0].elements[0];
            return getFormElements(forms, name);
        }

        return form;
    }
)

interface ByFormAndVersion extends ByForm {
    version: string;
}

export const getElementsByVersion = createSelector(
    getAll,
    (_: State, { form, version }: ByFormAndVersion) => ({ form, version }),
    ({ forms }, { form, version }: ByFormAndVersion) => forms[form].versions[version].elements
);

export const getInlineListElementsByForm = createSelector(
    getAll,
    getElementsByVersion,
    ({ forms }, elements) => elements
        .filter(({ type, options }: any) => type === 'inlineList' && options.length > 0)
        .reduce((lists: Record<string, any>, { options, name }) => {
            if (options && options[0].elements && options[0].elements.length > 0) {
                const formName = options[0].elements[0].name;
                const { versions, latest } = forms[formName];
                lists[name] = versions[latest].elements;
            }

            return lists;
        }, {})
);

export const getBranchByForm = createSelector(
    getRoutingTable,
    (_: State, { form }: ByForm) => form,
    (routingTable, form) => {
        const branch = Object
            .entries(routingTable)
            .find(([, routingBranch]) => {
                return routingBranch.find(({ name }) => name === form);
            });

        if (!branch) {
            return {};
        }

        return { [branch[0]]: branch[1] };
    }
);

const findParentFormRoute = (routes: RoutingBranch[], route: RoutingBranch): any => {
    const foundRoute = routes.find(({ path }) => path === route.parent);

    if (!foundRoute || foundRoute.type === FormType.FORM || foundRoute.path === foundRoute.parent) {
        if (foundRoute && foundRoute.type === FormType.FORM) {
            return foundRoute;
        }

        return;
    }

    return findParentFormRoute(routes, foundRoute);
}

export const getParentFormRouteByForm = createSelector(
    getCurrentBranch,
    (_: State, { form }: ByForm) => ({ form }),
    (routes, { form }) => {
        if (!routes) {
            return;
        }

        let matchIndex = routes.findIndex(({ name }: any) => name === form);

        if (!routes[matchIndex]) {
            return;
        }

        return findParentFormRoute(routes, routes[matchIndex]);
    }
);
