import { FormType } from "../constants";

export default {
    deletable: true,
    displayName: 'Field',
    name: 'formBuilderFormField',
    latest: 'default',
    type: FormType.FORM,
    versions: {
        default: {
            elements: [
                {
                    name: 'type',
                    options: [{
                        label: 'Type',
                        placeholder: '-- Choose the type --',
                        elements: [
                            { value: FormType.ROOT, label: 'Menu item (rootlist)' },
                            { value: 'text', label: 'Text' },
                            { value: 'radio', label: 'Single choice' },
                            { value: 'number', label: 'Number' },
                            { value: 'time', label: 'Time' },
                            { value: 'date', label: 'Date' },
                            { value: 'dateTime', label: 'Datetime' },
                            { value: 'checkbox', label: 'Checkbox' },
                            { value: 'select', label: 'Dropdown' },
                            { value: 'crossSelect', label: 'External data dropdown' },
                            { value: 'textarea', label: 'Textarea' },
                            { value: FormType.FORM, label: 'Sub form' },
                            { value: FormType.LIST, label: 'List of sub forms' },
                            { value: 'file', label: 'File upload' },
                            { value: 'section', label: 'Divider' },
                            { value: 'signature', label: 'Signature' },
                            { value: 'lastStoredBy', label: 'Last stored user name' },
                            { value: 'static', label: 'Static (content display)' },
                            { value: 'inlineList', label: 'Inline list' },
                            { value: FormType.MENU, label: 'Menu' },
                            { value: 'containmentCalc', label: 'Containment calculator' },
                        ],
                    }],
                    required: true,
                    sequence: 0,
                    type: 'select',
                    unique: false,
                    columnWidth: 6,
                },
                {
                    name: 'name',
                    options: [{ label: 'Technical name (no underscores)' }],
                    required: true,
                    sequence: 1,
                    type: 'text',
                    unique: true,
                    columnWidth: 6,
                    conditions: [{
                        source: 'type',
                        type: 'notEquals',
                        values: [FormType.FORM, FormType.MENU].join(),
                    }],
                },
                {
                    name: 'technicalName',
                    options: [{
                        label: 'Source form',
                        displayFormat: '[displayName] ([name], [type])',
                        source: 'formBuilderFormMeta',
                        filters: [{ key: 'type', value: [FormType.FORM, FormType.MENU].join() }],
                    }],
                    required: true,
                    sequence: 1,
                    type: 'crossSelect',
                    unique: true,
                    columnWidth: 6,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: [FormType.FORM, FormType.MENU].join(),
                    }],
                },
                {
                    name: 'displayName',
                    options: [{ label: 'Label'}],
                    required: false,
                    sequence: 2,
                    type: 'text',
                    unique: false,
                },
                {
                    name: 'sequence',
                    options: [{ label: 'Sequence' }],
                    required: true,
                    sequence: 3,
                    type: 'number',
                    unique: false,
                    columnWidth: 6,
                },
                {
                    name: 'forUser',
                    options: [{ label: 'Track the logged in user' }],
                    required: false,
                    sequence: 3,
                    type: 'checkbox',
                    unique: false,
                    columnWidth: 6,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'signature',
                    }],
                },
                {
                    name: 'columnWidth',
                    options: [{ label: 'Column width (1 = small, 12 is full)' }],
                    required: false,
                    sequence: 3,
                    columnWidth: 6,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'notEquals',
                        values: [FormType.ROOT, 'signature', 'section'].join(),
                    }],
                },
                {
                    name: 'placeholder',
                    options: [{ label: 'Placeholder' }],
                    required: false,
                    sequence: 4,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'crossSelect,select,textarea,signature',
                    }],
                },
                {
                    name: 'displayFormat',
                    options: [{
                        label: 'Display format',
                        helpText: 'This can contain brackets ([]) to reference values\n'
                            + 'Properties of subForms can be rendered by using: [form.field]',
                    }],
                    required: true,
                    sequence: 5,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: [FormType.ROOT, FormType.LIST, FormType.FORM, 'crossSelect', 'static'].join(),
                    }],
                },
                {
                    name: 'source',
                    options: [{
                        label: 'Source',
                        placeholder: '-- Select the source --',
                        displayFormat: '[displayName] ([name], [type])',
                        source: 'formBuilderFormMeta',
                        filters: [{ key: 'type', value: FormType.FORM }],
                        sortField: 'displayName',
                    }],
                    required: true,
                    sequence: 6,
                    type: 'crossSelect',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'crossSelect',
                    }],
                },
                {
                    name: 'addition',
                    options: [{ label: 'Add button text' }],
                    required: true,
                    sequence: 7,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: [FormType.ROOT, FormType.LIST, 'file', 'inlineList'].join(),
                    }],
                },
                {
                    name: 'matchFields',
                    options: [{ label: 'Filter fields (comma seperated)' }],
                    required: true,
                    sequence: 9,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: [FormType.ROOT, FormType.LIST].join(),
                    }],
                },
                {
                    name: 'sourceName',
                    options: [{
                        label: 'Source form name',
                        placeholder: '-- Select the source --',
                        displayFormat: '[displayName] ([name], [type])',
                        source: 'formBuilderFormMeta',
                        filters: [{ key: 'type', value: FormType.FORM }],
                    }],
                    required: true,
                    sequence: 10,
                    type: 'crossSelect',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: [FormType.ROOT, FormType.LIST, 'inlineList'].join(),
                    }],
                },
                {
                    name: 'formBuilderOptions',
                    options: [{
                        addition: 'Add option',
                        displayFormat: '[sequence]: [label]',
                        elements: [{
                            name: 'formBuilderOption',
                            type: FormType.FORM,
                        }],
                        label: 'Options',
                        matchFields: 'label',
                        table: true,
                    }],
                    required: false,
                    sequence: 11,
                    type: FormType.LIST,
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'radio,select',
                    }],
                },
                {
                    name: 'formBuilderFilters',
                    options: [{
                        addition: 'Add filter',
                        displayFormat: '[key] is [value]',
                        elements: [{
                            name: 'formBuilderFilter',
                            type: FormType.FORM,
                        }],
                        label: 'Filters',
                        matchFields: 'key,value',
                        table: true,
                    }],
                    required: false,
                    sequence: 12,
                    type: FormType.LIST,
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'crossSelect',
                    }],
                },
                {
                    name: 'multiple',
                    options: [{ label: 'Allow multiple values' }],
                    required: false,
                    sequence: 14,
                    type: 'checkbox',
                    unique: false,
                    columnWidth: 4,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'select,crossSelect',
                    }],
                },
                {
                    name: 'backLink',
                    options: [{ label: 'Link back' }],
                    required: false,
                    sequence: 14,
                    type: 'checkbox',
                    unique: false,
                    columnWidth: 4,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'crossSelect',
                    }],
                },
                {
                    name: 'allowClosed',
                    options: [{ label: 'Allow closed' }],
                    required: false,
                    sequence: 14,
                    type: 'checkbox',
                    unique: false,
                    columnWidth: 4,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'crossSelect',
                    }],
                },
                {
                    name: 'hideLabel',
                    options: [{ label: 'Hide label' }],
                    required: false,
                    sequence: 14,
                    type: 'checkbox',
                    unique: false,
                },
                {
                    name: 'sortField',
                    options: [{ label: 'Technical name of field to sort by' }],
                    required: false,
                    sequence: 14,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'crossSelect',
                    }],
                },
                {
                    name: 'prefill',
                    options: [{ label: 'Prefill date/time' }],
                    required: false,
                    sequence: 15,
                    type: 'checkbox',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'time,date,dateTime',
                    }],
                },
                {
                    name: 'defaultValue',
                    options: [{ label: 'Default value' }],
                    required: false,
                    sequence: 15,
                    type: 'textarea',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'text,number,textarea,select,radio',
                    }],
                },
                {
                    name: 'isGrouped',
                    options: [{ label: 'Grouped' }],
                    required: false,
                    sequence: 15,
                    type: 'checkbox',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: FormType.ROOT,
                    }],
                },
                {
                    // @todo: make dropdown of form elements
                    name: 'groupBy',
                    options: [{ label: 'Technical name of field to group by' }],
                    required: false,
                    sequence: 16,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'isGrouped',
                        type: 'equals',
                        values: 'true',
                    }],
                },
                {
                    name: 'groupDisplayFormat',
                    options: [{
                        label: 'Group display format',
                        helpText: 'This can contain brackets ([]) to reference values\n'
                            + 'Dates can be formatted by using: [date:field]\n'
                            + 'Properties of subForms can be rendered by using: [form.field]\n'
                            + 'Select values can be formatted by using: [select:field]',
                    }],
                    required: true,
                    sequence: 17,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'isGrouped',
                        type: 'equals',
                        values: 'true',
                    }],
                },
                {
                    // @todo: make dropdown of form elements that are login account
                    name: 'limitByUser',
                    options: [{ label: 'Field that contains the user id to limit by' }],
                    required: false,
                    sequence: 17,
                    type: 'text',
                    unique: false,
                    columnWidth: 6,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: [FormType.ROOT, FormType.LIST].join(),
                    }],
                },
                {
                    name: 'nonEditable',
                    options: [{ label: 'Show closed items' }],
                    required: false,
                    sequence: 17,
                    type: 'checkbox',
                    unique: false,
                    columnWidth: 6,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: [FormType.ROOT, FormType.LIST].join(),
                    }],
                },
                {
                    name: 'multiplyingFactor',
                    options: [{ label: 'Multiplying factor' }],
                    required: false,
                    sequence: 17,
                    type: 'number',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'containmentCalc',
                    }],
                },
                {
                    name: 'editAtOnce',
                    options: [{ label: 'Fields that can be edited for multiple items at once (comma seperated)' }],
                    required: false,
                    sequence: 18,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'inlineList',
                    }],
                },
                {
                    type: 'section',
                    options: [{ label: 'Conditions' }],
                    sequence: 18,
                },
                {
                    name: 'formBuilderConditions',
                    options: [{
                        addition: 'Add condition',
                        displayFormat: '[source] [type] [values]',
                        elements: [{
                            name: 'formBuilderCondition',
                            type: FormType.FORM,
                        }],
                        label: 'Conditions',
                        matchFields: 'source',
                    }],
                    required: false,
                    sequence: 19,
                    type: FormType.LIST,
                    unique: false,
                },
                {
                    name: 'noUnclosed',
                    options: [{ label: 'Only one unclosed entry is permitted' }],
                    required: false,
                    sequence: 19,
                    type: 'checkbox',
                    unique: false,
                    columnWidth: 6,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: FormType.LIST,
                    }],
                },
                {
                    name: 'table',
                    options: [{ label: 'Display as table' }],
                    required: false,
                    sequence: 19,
                    type: 'checkbox',
                    unique: false,
                    columnWidth: 6,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: FormType.LIST,
                    }],
                },
                {
                    type: 'section',
                    options: [{ label: 'Validations' }],
                    sequence: 20,
                    conditions: [{
                        source: 'type',
                        type: 'notEquals',
                        values: 'section,lastStoredBy,static,menu',
                    }],
                },
                {
                    name: 'required',
                    options: [{ label: 'Required' }],
                    required: false,
                    sequence: 21,
                    type: 'checkbox',
                    unique: false,
                    columnWidth: 3,
                    conditions: [{
                        source: 'type',
                        type: 'notEquals',
                        values: 'section,lastStoredBy,static,menu',
                    }],
                },
                {
                    name: 'hasValue',
                    options: [{ label: 'Must have value' }],
                    required: false,
                    sequence: 21,
                    type: 'checkbox',
                    unique: false,
                    columnWidth: 4,
                    conditions: [{
                        source: 'type',
                        type: 'notEquals',
                        values: 'section,lastStoredBy,static,menu',
                    }],
                },
                {
                    name: 'hasValueOnClose',
                    options: [{ label: 'Must have value on close' }],
                    required: false,
                    sequence: 21,
                    type: 'checkbox',
                    unique: false,
                    columnWidth: 5,
                    conditions: [{
                        source: 'type',
                        type: 'notEquals',
                        values: 'section,lastStoredBy,static,menu',
                    }],
                },
                {
                    name: 'unique',
                    options: [{ label: 'Unique' }],
                    required: false,
                    sequence: 22,
                    type: 'checkbox',
                    unique: false,
                    columnWidth: 3,
                    conditions: [{
                        source: 'type',
                        type: 'notEquals',
                        values: 'section,lastStoredBy,list,form,static,inlineList,menu',
                    }],
                },
                {
                    name: 'localUnique',
                    options: [{ label: 'Local unique' }],
                    required: false,
                    sequence: 22,
                    type: 'checkbox',
                    unique: false,
                    columnWidth: 4,
                    conditions: [{
                        source: 'type',
                        type: 'notEquals',
                        values: 'section,lastStoredBy,list,form,static,inlineList,menu',
                    }],
                },
                {
                    name: 'ignoreClosedUnique',
                    options: [{ label: 'Ignore closed' }],
                    required: false,
                    sequence: 22,
                    type: 'checkbox',
                    unique: false,
                    columnWidth: 5,
                    conditions: [{
                        source: 'type',
                        type: 'notEquals',
                        values: 'section,lastStoredBy,list,form,static,inlineList,menu',
                    }],
                },
                {
                    name: 'pattern',
                    options: [{ label: 'Pattern' }],
                    required: false,
                    sequence: 24,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'text,number',
                    }],
                },
                {
                    name: 'title',
                    options: [{ label: 'Pattern error' }],
                    required: false,
                    sequence: 25,
                    type: 'text',
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'equals',
                        values: 'text,number',
                    }],
                },
                {
                    name: 'formBuilderValidations',
                    options: [{
                        addition: 'Add validation rule',
                        displayFormat: '[type]',
                        elements: [{
                            name: 'formBuilderValidation',
                            type: FormType.FORM,
                        }],
                        label: 'Validations',
                        matchFields: 'type',
                    }],
                    required: false,
                    sequence: 26,
                    type: FormType.LIST,
                    unique: false,
                    conditions: [{
                        source: 'type',
                        type: 'notEquals',
                        values: 'section,lastStoredBy,static,menu',
                    }],
                },
            ],
        },
    },
};
