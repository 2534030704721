import { Roles } from "./constants";

export enum Conditions {
    EQUALS = 'equals',
    NOT_EQUALS = 'notEquals',
    VALID_AND_CLOSED = 'validAndClosed',
    VALID = 'valid',
    HAS_ROLE = 'hasRole',
}

export interface Condition {
    type: Conditions;
    values?: string;
    source?: string;
    roles?: Roles[];
}

export interface FormCondition {
    type: { value: Conditions };
    source?: { value: string };
    values?: { value: string };
    roles?: { value: Roles[] };
}
