import React from "react";
// @ts-ignore
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";

import MaterialList from "@material-ui/core/List";
import { ListItem, ListItemText } from "@material-ui/core";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { getElementArrayValueByFormAndName } from "../../selectors/element";
import { State } from "../../constants";
import { theme } from "../../styles";
import { makeStyles } from "@material-ui/core/styles";
import { CustomError, CustomErrorTypes, ErrorList } from "./Error";
import {
  isInCurrentPathByForm,
  pathToEntryByForm,
} from "../../selectors/routing";
import { hasAtLeastOneValidEntryByFormAndEntryIds } from "../../selectors/entries";

interface ListElementType {
  form: string;
  errors: CustomError[];
  isNewEntry: boolean;
  element: any;
}

// @ts-ignore
const useStyles = makeStyles(() => ({
  arrow: {
    fontSize: 20,
    color: theme.colors.lightGray,
  },
  list: {
    margin: "0 -10px",
    padding: 0,
  },
  formItem: {
    minHeight: "48px",
    height: "56px",
    padding: "0 10px",
    "&.selected": {
      backgroundColor: theme.colors.backgroundLightGray,
    },
  },
  listItemText: {
    fontSize: 16,
  },
}));

const includesNotValid = (errors: CustomError[]) =>
  errors.find(({ type }) => type === CustomErrorTypes.NOT_VALID);

const ListElement = ({
  form,
  element,
  errors = [],
  isNewEntry = true,
}: ListElementType) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isInCurrentPath = useSelector((state: State) =>
    isInCurrentPathByForm(state, { form: element.name })
  );
  const pathToComponent = useSelector((state: State) =>
    pathToEntryByForm(state, { form })
  );
  const targetFormName = element.options[0].elements[0].name;
  const targetValue = useSelector((state: State) =>
    getElementArrayValueByFormAndName(state, { form, name: targetFormName })
  );

  const onClick = () => {
    if (isNewEntry) {
      alert(
        "Het formulier moet opgeslagen worden voordat dit onderdeel gebruikt kan worden"
      );
      return;
    }

    dispatch(push(`${pathToComponent}/${element.name}`));
  };

  const errorList: CustomError[] = [];

  const hasAtLeastOneValidEntry = useSelector((state: State) =>
    hasAtLeastOneValidEntryByFormAndEntryIds(state, {
      form: targetFormName,
      entryIds: targetValue,
    })
  );

  if (targetValue.length > 0 && !hasAtLeastOneValidEntry) {
    if (!includesNotValid([...errors, ...errorList])) {
      errorList.push({ type: CustomErrorTypes.NOT_VALID });
    }
  }

  return (
    <MaterialList dense={true} className={classes.list}>
      <ListItem
        button
        onClick={onClick}
        className={classes.formItem}
        selected={isInCurrentPath}
      >
        <ListItemText
          primary={element.options[0].label}
          secondary={<ErrorList errors={[...errors, ...errorList]} />}
          classes={{ primary: classes.listItemText }}
        />
        <ArrowForwardIos className={classes.arrow} />
      </ListItem>
    </MaterialList>
  );
};

export default ListElement;
