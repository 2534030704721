import { CompanyState } from '../constants';
import * as actions from '../actions/companies';
import { LOGGED_OUT, STATUS_CHANGED } from '../actions/auth';

const initialState: CompanyState = {
    activeCompany: '',
    companies: {},
};

export default (state: CompanyState = initialState, action: any) => {
    switch (action.type) {
        case STATUS_CHANGED:
            return { ...state, activeCompany: action.payload.company };
        case actions.FETCHED:
            return { ...state, companies: { ...state.companies, ...action.payload } };
        case LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};
