import { FormType, Roles } from "../constants";

export default {
    displayName: 'Gebruiker: [name]',
    name: 'user',
    latest: 'default',
    type: FormType.FORM,
    versions: {
        default: {
            elements: [
                {
                    name: 'email',
                    options: [{ label: 'E-mail adres' }],
                    required: true,
                    sequence: 0,
                    type: 'text',
                },
                {
                    name: 'name',
                    options: [{ label: 'Naam' }],
                    required: true,
                    sequence: 1,
                    type: 'text',
                },
                {
                    name: 'password',
                    options: [{ label: 'Wachtwoord' }],
                    required: false,
                    sequence: 2,
                    type: 'text',
                },
                {
                    name: 'role',
                    options: [{
                        label: 'Rechten',
                        placeholder: 'Kies een account niveau',
                        elements: [
                            { label: 'Beheerder', value: Roles.ROOT },
                            { label: 'Supervisor', value: Roles.SUPERVISOR },
                            { label: 'Administrator', value: Roles.ADMIN },
                            { label: 'Gebruiker', value: Roles.USER },
                            { label: 'Gast', value: Roles.GUEST },
                        ],
                    }],
                    required: true,
                    sequence: 3,
                    type: 'select',
                },
                // @todo:
                // - button to disable the account
                // - button to reset password
            ],
        },
    },
}
