import React from "react";
// @ts-ignore
import { useSelector } from "react-redux";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import { State } from "../../constants";
import {
  convertDisplayFormat,
  convertDisplayFormatByEntryId,
} from "../../selectors/displayFormat";

interface StaticProps {
  label?: string;
  displayFormat: string;
  form: string;
  entryId: string;
}

const Static = ({ label, displayFormat, form, entryId }: StaticProps) => {
  let value;
  if (entryId.includes("-")) {
    value = useSelector((state: State) =>
      convertDisplayFormatByEntryId(state, {
        displayFormat,
        currentNode: form,
        entryId,
      })
    );
  } else {
    value = useSelector((state: State) =>
      convertDisplayFormat(state, { displayFormat, currentNode: form })
    );
  }

  return (
    <FormControl
      style={{
        marginTop: 8,
        marginBottom: 14,
        width: "100%",
        lineHeight: "20px",
        whiteSpace: "pre-wrap",
        paddingTop: label ? 20 : 0,
      }}
    >
      {label && <InputLabel shrink={true}>{label}</InputLabel>}
      {value}
    </FormControl>
  );
};

export default Static;

