import { Action } from "redux";
import { Condition } from "./conditionConstants";

export enum Defaults {
  NEW_ENTRY_ID = "__NEW__",
}

export interface Input {
  name: string;
  options: any;
  required?: boolean;
  sequence?: number;
  type: string;
}

export interface InputSimple extends Input {
  options: [{ label: string }];
  type: "text" | "email" | "date" | "number" | "time";
}

export interface InputRadio extends Input {
  options: [
    {
      elements: { label: string; value: string }[];
      label: string;
    }
  ];
  type: "radio";
}

export interface InputTextarea extends Input {
  options: [{ label: string; placeholder: string }];
  type: "textarea";
}

export interface InputSelect extends Input {
  options: [
    {
      label: string;
      elements: { label: string; id: string }[];
    }
  ];
  type: "select";
}

export interface InputMultiSelect extends InputSelect {}

export interface InputFile extends Input {
  options: [
    {
      name: string;
      addition: string;
      deletion: string;
    }
  ];
  type: "file";
}

export interface InputSignature extends Input {
  options: [
    {
      label: string;
      placeholder: string;
    }
  ];
  type: "signature";
}

export interface Section {
  sequence: number;
  type: "section";
  options: [{ label: string }];
}

export interface FormElement extends Input {
  displayFormat: string;
  options: [
    {
      name: string;
      elements: (
        | InputRadio
        | InputSimple
        | InputTextarea
        | InputSelect
        | InputMultiSelect
        | InputFile
        | InputSignature
        | Section
        | List
      )[];
    }
  ];
  type: FormType.FORM;
}

export interface List extends Input {
  options: [
    {
      name: string;
      addition: string;
      matchFields: string[];
      elements: (
        | InputRadio
        | InputSimple
        | InputTextarea
        | InputSelect
        | InputMultiSelect
        | InputFile
        | InputSignature
        | Section
        | FormElement
      )[];
    }
  ];
  type: FormType.LIST;
}

export interface RootList {
  name: string;
  type: FormType.ROOT;
  options: [
    {
      addition: string;
      elements: (
        | InputRadio
        | InputSimple
        | InputTextarea
        | InputSelect
        | InputMultiSelect
        | InputFile
        | InputSignature
        | Section
        | FormElement
      )[];
      matchFields: string[];
    }
  ];
}

export interface FSA extends Action {
  type: string;
  payload?: any | Error;
  meta?: any;
  error?: any;
}

export interface User {
  email: string;
  name: string;
  company: string;
  role: Roles;
  id?: string;
}

export interface Company {
  name: string;
  domain: string;
  id?: string;
  environment: string;
  primaryColor?: string;
}

export interface State {
  app: AppState;
  entries: EntriesState;
  user: UserState;
  company: CompanyState;
  forms: FormsState;
  routing: RoutingState;
  rules: RulesState;
  export: ExportState;
  dashboard: DashboardState;
  schedule: ScheduleState;
}

export enum FetchingState {
  NOT_FETCHED = "not_fetched",
  FETCHED = "fetched",
  FETCHING = "fetching",
}

export enum RuleType {
  AFTER_SAVE = "afterSave",
  LIST_ITEM_CREATION = "listItemCreation",
  UPON_CREATION = "uponCreation",
}

export interface Rule {
  type: RuleType;
  source: string;
  sourceField: string;
  destination: string;
  destinationField: string;
  listField?: string;
  validations?: any[];
}

export interface RulesState {
  [index: string]: Rule;
}

export interface CustomExport {
  name: string;
}

export interface ExportState {
  dialogOpen: boolean;
  form: string;
  entryId: string;
  exportingPdf: boolean;
  pdfDownloadUrl: string;
  customExports: Record<string, CustomExport>;
}

export interface DashboardState {
  config: DashboardConfiguration;
  template: string;
}

export interface ScheduleState {
  activeInterval: boolean;
};

export interface EntriesState {
  fetching: FetchingState;
  storing: boolean;
  initialLoadDone: boolean;
  formsToIgnore: string[];
  [index: string]: any;
}

export enum Roles {
  USER = "user",
  ADMIN = "admin",
  SUPERVISOR = "supervisor",
  ROOT = "root",
  SUPERUSER = "superuser",
  GUEST = "guest",
}

export interface UserState {
  company: string;
  role: Roles;
  name: string;
  uid: string;
  email: string;
}

export interface CompanyState {
  activeCompany: string;
  companies: Record<string, Company>;
}

export interface AppState {
  fullscreen: boolean;
}

export interface RoutingState {
  routingTable: Record<string, RoutingBranch[]>;
  root: string;
  path: string;
  pathParams: Record<string, string>;
  form: string;
}

export enum FormType {
  ROOT = "rootList",
  LIST = "list",
  FORM = "form",
  MENU = "menu",
  ROOTFORM = "root",
}

export interface RoutingBranch {
  entriesHandle: string;
  entryId: string;
  name: string;
  parent: string;
  parentName?: string;
  parentType?: string;
  path: string;
  // @todo: isn't this one obsolete?
  type: FormType;
}

export interface FormVersion {
  created?: number;
  elements: InputElement[];
}

export interface Form {
  closeable?: boolean;
  deletable?: boolean;
  displayName: string;
  lastUpdate?: number;
  latest: string;
  name: string;
  sequence?: number;
  type: FormType;
  versions: Record<string, FormVersion>;
  isRoot?: boolean;
}

export interface FormsState {
  fetchingState: FetchingState;
  globalFetch: FetchingState;
  forms: Record<string, Form>;
}

export enum ValidationTypes {
  EXPIRES = "expires",
  MAX_LIMIT = "maxLimit",
  MIN_LIMIT = "minLimit",
  FORBIDDEN = "forbidden",
  WARNING = "warning",
  GREATER_THAN = "greaterThan",
  SAME_OR_GREATER_THAN = "sameOrGreaterThan",
}

export interface Validation {
  expires?: number;
  type: ValidationTypes;
}

export interface ElementOptions {
  label: string;
  elements?: InputElement[];
  addition?: string;
  isGrouped?: boolean;
  displayFormat?: string;
  groupDisplayFormat?: string;
  groupBy?: string;
  matchFields?: string;
  prefill?: boolean;
  hideLabel?: boolean;
}

export enum InputTypes {
  TEXT = "text",
  RADIO = "radio",
  NUMBER = "number",
  TIME = "time",
  EMAIL = "email",
  DATE = "date",
  DATE_TIME = "dateTime",
  CHECKBOX = "checkbox",
  TEXTAREA = "textarea",
  SELECT = "select",
  CROSS_SELECT = "crossSelect",
  FORM = "form",
  LIST = "list",
  FILE = "file",
  SIGNATURE = "signature",
  SECTION = "section",
  BUTTON = "button",
  LAST_STORED_BY = "lastStoredBy",
  STATIC = "static",
  INLINE_LIST = "inlineList",
  MENU = "menu",
  CONTAINMENT_CALCULATOR = "containmentCalc",
}

export interface InputElement {
  name: string;
  validations?: Validation[];
  options?: ElementOptions[];
  type: InputTypes;
  nonEditable?: boolean;
  conditions?: Condition[];
  defaultValue?: string;
  columnWidth: number;
  sequence?: number;
}

export interface ByForm {
  form: string;
}

export interface FormMeta {
  displayName: string;
  name: string;
  type: FormType;
  deletable: boolean;
  lastUpdate: number;
  sequence: number;
  latest?: string;
  closeable?: boolean;
  isRoot?: boolean;
  roles?: Roles[];
  overrideValidation?: boolean;
  exportable?: boolean;
  actionButtonText?: string;
}

export enum ExpirationStatus {
  VALID = "valid",
  EXPIRING = "expiring",
  EXPIRED = "expired",
}

export enum ExportTarget {
  HTML = "html",
  PDF = "pdf",
  EXCEL = "excel",
}

export interface DashboardItem {
  id: string;
  title: string;
  blocks: any[];
  link: {
    title: string;
    destination: string;
  };
}

export enum FilterType {
  HAS_VALUE = "hasValue",
}

export enum DataSelectionType {
  HAS_VALUE = "hasValue",
}

export interface DashboardItemData {
  title: string;
  id: string;
  reference?: {
    title: string;
    destination: string;
  };
  data: any;
}

export interface DashboardConfiguration {
  linkTitle: string;
  title: string;
  route: string;
  source: string;
  filter?: {
    field: string;
    type: FilterType;
  };
  data: Record<string, any>;
}
