import { takeEvery, put, select, take, call } from "redux-saga/effects";

import { firestore, functions } from "../firebase";
import * as actions from "../actions/companies";
import * as entriesActions from "../actions/entries";
import { Company, FSA } from "../constants";
import { populateEntriesGraph } from "../utility/graphHelpers";
import { getCompanyById } from "../selectors/company";
import { Roles } from "../constants";
import { STATUS_CHANGED } from "../actions/auth";

function* fetchAllCompanies(): IterableIterator<any> {
  let companies: Company[] = [];
  if (!process.env.REACT_APP_OFFLINE) {
    const getCompanies = functions.httpsCallable("getCompanies");
    // @ts-ignore
    const { data } = yield getCompanies();
    if (data) {
        companies = data;
    }
  }

  const entries = companies.reduce((companiesObject, company: Company) => {
    // @ts-ignore
    companiesObject[company.id] = {
      name: { value: company.name },
      domain: { value: company.domain },
      valid: true,
      environment: { value: company.environment },
      primaryColor: { value: company.primaryColor || "" },
    };
    return companiesObject;
  }, {});

  populateEntriesGraph("company", entries);

  yield put(entriesActions.fetched({ company: entries }));
  yield put(
    actions.fetched(
      companies.reduce(
        (companyObj: Record<string, Company>, company: Company) => {
          companyObj[company.id!] = company;
          return companyObj;
        },
        {}
      )
    )
  );
}

function* fetchCompany({ payload }: FSA): IterableIterator<any> {
  // @ts-ignore
  const company: Record<string, Company> = yield firestore
    .collection(`companies`)
    .doc(payload.companyId)
    .get()
    .then((doc: any) => ({ [doc.id]: doc.data() }));

  const companyEntry = {
    [payload.companyId]: {
      name: { value: company[payload.companyId].name },
      domain: { value: company[payload.companyId].domain },
      environment: process.env.REACT_APP_FB_PROJECT_ID,
      primaryColor: { value: company[payload.companyId].primaryColor || "" },
    },
  };

  populateEntriesGraph("company", companyEntry);

  yield put(entriesActions.fetched({ company: companyEntry }));
  yield put(actions.fetched(company));
}

function* createCompany({ payload }: FSA): IterableIterator<any> {
  if (payload.entry.formName !== "company") {
    return;
  }

  let sourceEnv: string | undefined;

  if (payload.entry.sourceCompany && payload.entry.sourceCompany.value) {
    // @ts-ignore
    const sourceCompany: Company = yield select(getCompanyById, {
      id: payload.entry.sourceCompany.value,
    });
    if (sourceCompany) {
      sourceEnv = sourceCompany.environment;
    }
  }

  const createCompanyFunction = functions.httpsCallable("createCompany");

  yield createCompanyFunction({
    domain: payload.entry.domain.value,
    name: payload.entry.name.value,
    primaryColor: payload.entry.primaryColor.value || "",
    email: payload.entry.email.value,
    username: payload.entry.username.value,
    password: payload.entry.password.value,
    environment: payload.entry.environment.value,
    sourceEnv,
    sourceCompany: payload.entry.sourceCompany.value,
  });

  yield put(actions.fetchAll());
}

function* syncForms({ payload }: FSA): IterableIterator<any> {
  if (!payload.sourceCompany || !payload.destinationCompany) {
    return;
  }

  // @ts-ignore
  const source: Company = yield select(getCompanyById, {
    id: payload.sourceCompany,
  });
  // @ts-ignore
  const destination: Company = yield select(getCompanyById, {
    id: payload.destinationCompany,
  });

  if (!source || !destination) {
    return;
  }

  const syncFormsFunction = functions.httpsCallable("syncForms");

  yield syncFormsFunction({
    sourceEnv: source.environment,
    sourceCompany: payload.sourceCompany,
    destinationEnv: destination.environment,
    destinationCompany: payload.destinationCompany,
  });
}

function* syncExports({ payload }: FSA): IterableIterator<any> {
  if (!payload.sourceCompany || !payload.destinationCompany) {
    return;
  }

  // @ts-ignore
  const source: Company = yield select(getCompanyById, {
    id: payload.sourceCompany,
  });
  // @ts-ignore
  const destination: Company = yield select(getCompanyById, {
    id: payload.destinationCompany,
  });

  console.log("SYNC EXPORTS", payload, source, destination);
  if (!source || !destination) {
    return;
  }

  const syncExportsFunction = functions.httpsCallable("syncExports");

  yield syncExportsFunction({
    sourceEnv: source.environment,
    sourceCompany: payload.sourceCompany,
    destinationEnv: destination.environment,
    destinationCompany: payload.destinationCompany,
  });
}

export default function* saga() {
  // @ts-ignore
  const { payload } = yield take(STATUS_CHANGED);

  yield call(fetchAllCompanies);

  if (
    [Roles.SUPERUSER].includes(payload.role) &&
    process.env.REACT_APP_FB_PROJECT_ID === "formbuildersdev"
  ) {
    yield takeEvery(actions.FETCH, fetchCompany);
    yield takeEvery(entriesActions.SAVE, createCompany);
    yield takeEvery(actions.SYNC_FORMS, syncForms);
    yield takeEvery(actions.SYNC_EXPORTS, syncExports);
  }
}
