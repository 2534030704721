import {
  computeTotalCapacityInSquareMeters,
  computeNecessaryEquipmentCapactiy,
  computeSuperfluousCapacity,
  Space,
} from "../../../components/form/ContainmentCalculator";
import { InputElement } from "../../../constants";

export const containmentCalculatorToHTML = ({ options }: InputElement) => (data: {
  spaces: Space[];
  availableCapacity: number;
}) => {
  if (!data) {
    return "";
  }
  // @ts-ignore
  const multiplyFactor = parseInt(options[0].multiplyingFactor, 10);
  const { spaces, availableCapacity } = data;
  if (!spaces || !multiplyFactor || !availableCapacity) {
    return "";
  }
  const spacesArr = Object.values(spaces);
  const totalCapacity = computeTotalCapacityInSquareMeters(spacesArr);
  const necessaryCapacity = computeNecessaryEquipmentCapactiy(
    totalCapacity,
    multiplyFactor
  );
  const superfluousCapacity = computeSuperfluousCapacity(
    availableCapacity,
    necessaryCapacity
  );
  const value = `
<table cellspacing=0 cellpadding=0>
  <tr>
    <th>Lengte</th>
    <th>Breedte</th>
    <th>Hoogte</th>
    <th>Inhoud</th>
  </tr>
  ${spacesArr.map(
    ({ length, width, height }: any) => `
<tr>
  <td>${length}</td>
  <td>${width}</td>
  <td>${height}</td>
  <td>${length * width * height} m<sup>3</sup></td>
</tr>
  `
  )}
  <tr><th colspan=3>Totale inhoud</th><td>${totalCapacity} m<sup>3</sup></td></tr>
  <tr><th colspan=3>Benodigde capaciteit</th><td>${necessaryCapacity} m<sup>3</sup></td></tr>
  <tr><th colspan=3>Geplaatste capaciteit</th><td>${availableCapacity} m<sup>3</sup></td></tr>
  <tr><th colspan=3>Overcapaciteit</th><td>${superfluousCapacity} m<sup>3</sup></td></tr>
</table>
  `;

  return value;
};
