import { createSelector } from "reselect";
import { State, Roles } from "../constants";

const roles = {
  [Roles.SUPERUSER]: "Beheerder",
  [Roles.ROOT]: "Beheerder",
  [Roles.SUPERVISOR]: "Supervisor",
  [Roles.ADMIN]: "Administrator",
  [Roles.USER]: "Gebruiker",
  [Roles.GUEST]: "Gast",
};

const getUser = ({ user }: State) => ({
  ...user,
  humanReadableRole: roles[(user && user.role) || Roles.GUEST],
});

export const get = createSelector(getUser, (user) => user);

export const getUserName = createSelector(getUser, ({ name }) => name);

export const getRole = createSelector(getUser, ({ role }) => role);

export const isRootOrSuperuser = createSelector(getRole, (role) =>
  [Roles.ROOT, Roles.SUPERUSER].includes(role)
);

export const canReopenForm = createSelector(getUser, ({ role }) =>
  [Roles.SUPERVISOR, Roles.ROOT, Roles.SUPERUSER].includes(role)
);

export const isAllowedToExport = createSelector(getRole, (role) =>
  [Roles.SUPERUSER, Roles.ROOT, Roles.SUPERVISOR, Roles.ADMIN].includes(role)
);

export const isAllowedToEditByRoles = createSelector(
  getRole,
  (_: State, { roles }: { roles: Roles[] }) => ({ roles }),
  (role, { roles }) => {
    if (role === Roles.GUEST) {
      return false;
    }

    if (roles && roles.length > 0) {
      return roles.includes(role);
    }

    return true;
  }
);
