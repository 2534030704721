import { randomBytes } from "crypto";
import { InputTypes } from "../constants";

export const deleteIn = (
  object: Record<string, any>,
  ...deleteKeys: string[]
) => {
  return Object.keys(object)
    .filter((key) => !deleteKeys.includes(key))
    .reduce((result, current) => {
      const output = result;
      // @ts-ignore
      output[current] = object[current];
      return output;
    }, {});
};

export const determineType = (match: string) => {
  if (match.indexOf(":") > 0) {
    const [type, handle] = match.split(":");
    return { match, handle, type };
  }

  return { match, handle: match };
};

export const stripPrefix = (match: string) => {
  if (match.indexOf(":") > 0) {
    const [, handle] = match.split(":");
    return handle;
  }

  return match;
};

export const getInlineVariableKeys = (input: string = "") => {
  if (!input.match) {
    return [];
  }

  return input.match(/[^[\]]+(?=])/g);
};

export const sortBySequence = (
  { sequence: sequenceA = 0 },
  { sequence: sequenceB = 0 }
) => sequenceA - sequenceB;

export const randomString = () => randomBytes(20).toString("hex").slice(-12);

export const getReferencedFormName = (element: any) => {
  const referencedFormNameGetter = {
    [InputTypes.FORM]: ({ name }: any) => [name, true],
    [InputTypes.LIST]: ({ options }: any) => [
      options[0].elements[0].name,
      true,
    ],
    [InputTypes.INLINE_LIST]: ({ options }: any) => [
      options[0].elements[0].name,
      true,
    ],
    [InputTypes.CROSS_SELECT]: ({ options }: any) => [options[0].source, true],
    [InputTypes.MENU]: ({ name }: any) => [name, true],
    rootList: ({ options }: any) => [options[0].elements[0].name, true],
  };

  // @ts-ignore
  if (!referencedFormNameGetter[element.type]) {
    return ["", false];
  }

  // @ts-ignore
  return referencedFormNameGetter[element.type](element);
};

export const getLabel = ([{ label, hideLabel }]: any) => {
  if (!hideLabel) {
    return label;
  }
  return "";
};
