import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const fbConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
};

// Initialize firebase instance
firebase.initializeApp(fbConfig);

const firestoreInstance = firebase.firestore()

firestoreInstance
  .settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  })

firestoreInstance
  .enablePersistence()
  .catch(function (err) {
    console.warn("Enable persistence error", err);
    if (err.code == "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

if (process.env.REACT_APP_OFFLINE) {
  firestoreInstance.disableNetwork();
}

export const firestore = firestoreInstance;
export const functions = firebase.functions();
export const storage = firebase.storage();
export const auth = firebase.auth();

export default firebase;
