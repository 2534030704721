import {
  takeEvery,
  select,
  put,
  fork,
  call,
  take,
  cancelled,
} from "redux-saga/effects";

import { firestore, functions } from "../firebase";
import { getCompany } from "../selectors/company";
import * as actions from "../actions/users";
import * as entriesActions from "../actions/entries";
import { FSA } from "../constants";
import { eventChannel } from "redux-saga";
import { populateEntriesGraph } from "../utility/graphHelpers";

function* getUsers(ref: any) {
  const users: any = {};
  yield ref.get().then((querySnapshot: any) => {
    querySnapshot.forEach((doc: any) => {
      const user = doc.data();
      users[doc.id] = {
        role: { value: user.role },
        email: { value: user.email },
        name: { value: user.name },
        password: { value: "-verborgen-" },
        readonly: true,
        valid: true,
      };
    });
  });

  populateEntriesGraph("user", users);

  yield put(entriesActions.fetched({ user: users }));
}

/*
function attachRefListener(ref: any) {
  return eventChannel((emit) => ref.onSnapshot(emit));
}

function* listenForValues(ref: any) {
  const channel = yield call(attachRefListener, ref);

  try {
    while (true) {
      const snapshot = yield take(channel);
      const users: any = {};
      snapshot.docChanges().forEach((change: any) => {
        const user = change.doc.data();
        users[change.doc.id] = {
          role: { value: user.role },
          email: { value: user.email },
          name: { value: user.name },
          password: { value: "-verborgen-" },
          readonly: true,
          valid: true,
        };
      });

      populateEntriesGraph("user", users);

      yield put(entriesActions.fetched({ user: users }));
    }
  } finally {
    if (yield cancelled() && typeof ref.unsubscribe === "function") {
      ref.unsubscribe();
    }
  }
}
*/

function* createUser({ payload }: FSA): IterableIterator<any> {
  if (payload.entry.formName !== "user") {
    return;
  }

  const company = yield select(getCompany);
  const createUser = functions.httpsCallable("createUser");

  yield createUser({
    email: payload.entry.email.value,
    name: payload.entry.name.value,
    password: payload.entry.password.value,
    company,
    role: payload.entry.role.value || "user",
  });
}

export default function* saga() {
  yield take(actions.FETCH);
  const company = yield select(getCompany);
  const usersRef = firestore
    .collection(`users/`)
    .where("company", "==", company);

  // note: disabled to prevent unnecessary connections
  //yield fork(listenForValues, usersRef);
  yield call(getUsers, usersRef);
  yield takeEvery(entriesActions.SAVE, createUser);
}
