import { createSelector } from "reselect";
import {
  DashboardItem,
  State,
  FilterType,
  DataSelectionType,
  DashboardItemData,
} from "../constants";
import { getInlineVariableKeys } from "../utility/helpers";
import { getAll } from "./entries";
import { convertDisplayFormatByFormAndEntryId } from "./displayFormatByGraph";

const getDashboardState = ({ dashboard }: State) => dashboard;

export const hasDashboard = createSelector(
  getDashboardState,
  ({ template }) => template !== ""
);

const getDashboardTemplate = createSelector(
  getDashboardState,
  ({ template }) => template
);

const getDashboardConfiguration = createSelector(
  getDashboardState,
  ({ config }) => config
);

const getRawDashboardData = createSelector(
  getAll,
  getDashboardConfiguration,
  (state: State) => state,
  (entries, config, state) => {
    if (!entries[config.source]) {
      return [];
    }

    const filteredEntries = Object.entries(entries[config.source])
      .filter(([_, entry]: any) => {
        if (!config.filter) {
          return true;
        }

        if (!entry[config.filter.field]) {
          return false;
        }

        if (config.filter.type === FilterType.HAS_VALUE) {
          return entry[config.filter.field];
        }

        return false;
      })
      .map(
        ([entryId, entry]): DashboardItemData => {
          let reference;

          if (config.route) {
            reference = {
              title: convertDisplayFormatByFormAndEntryId(state, {
                form: config.source,
                entryId,
                displayFormat: config.linkTitle,
              }),
              destination: `${config.route}/${entryId}`,
            };
          }

          const data = Object.entries(config.data).reduce(
            (acc: any, [key, selectionConfiguration]: any) => {
              // @ts-ignore
              const rawSelectedValue = entry[selectionConfiguration.field];
              const convertedValue = convertDisplayFormatByFormAndEntryId(
                state,
                {
                  form: config.source,
                  entryId,
                  displayFormat: `[${selectionConfiguration.field}]`,
                }
              );

              switch (selectionConfiguration.type) {
                case DataSelectionType.HAS_VALUE: {
                  if (rawSelectedValue && rawSelectedValue.value) {
                    acc[key] = convertedValue;
                  }

                  if (
                    rawSelectedValue &&
                    selectionConfiguration.positiveValue
                  ) {
                    acc[key] = selectionConfiguration.positiveValue;
                  }

                  if (
                    !rawSelectedValue &&
                    selectionConfiguration.negativeValue
                  ) {
                    acc[key] = selectionConfiguration.negativeValue;
                  }
                  break;
                }
                default: {
                  acc[key] = convertedValue;
                }
              }
              return acc;
            },
            {}
          );

          return {
            title: convertDisplayFormatByFormAndEntryId(state, {
              form: config.source,
              entryId,
              displayFormat: config.title,
            }),
            id: entryId,
            data,
            reference,
          };
        }
      );

    return filteredEntries;
  }
);

const lens = (obj: any, path: string) =>
  path.split(".").reduce((o, key) => (o && o[key] ? o[key] : null), obj);

export const getDashboardItems = createSelector(
  getDashboardTemplate,
  getRawDashboardData,
  (template, data): DashboardItem[] => {
    const variables = getInlineVariableKeys(template) || [];
    const result = data.map((item) => {
      const filledTemplate = variables.reduce((acc: string, handle: string) => {
        return acc.replace(`[${handle}]`, lens(item.data, handle));
      }, template);

      return {
        title: item.title,
        id: item.id,
        blocks: [filledTemplate],
        link: {
          title: item.reference ? item.reference.title : "",
          destination: item.reference ? item.reference.destination : "",
        },
      };
    });

    return result;
  }
);
