// @ts-ignore
import { useSelector } from 'react-redux';
import { getElementsByParentForm, getFormMeta } from '../selectors/forms';
import { State, ByForm } from '../constants';
import { convertDisplayFormatByEntryId } from '../selectors/displayFormat';

export const getFormElementsByForm = (options: ByForm) => useSelector((state: State) => getElementsByParentForm(state, options));

export const getFormTitle = (form: string, entryId: string) => {
    const { displayName } = useSelector((state: State) => getFormMeta(state, { form }));

    if (displayName.indexOf('[') === -1) {
        return displayName;
    }

    const convertArgs = {
        displayFormat: displayName,
        currentNode: form,
        entryId,
    };

    return useSelector((state: State) => convertDisplayFormatByEntryId(state, convertArgs));
};
