import React, { useState } from "react";
// @ts-ignore
import FileUploader from "react-firebase-file-uploader";
// @ts-ignore
import { connect } from "react-redux";

import { storage } from "../../firebase";
import { getCompany } from "../../selectors/company";

import { getErrorMessage } from "./Error";

import { theme } from "../../styles";
import { makeStyles, lighten, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";

// @ts-ignore
const useStyles = makeStyles(() => ({
  row: {
    minHeight: 48,
    lineHeight: "48px",
    padding: 10,
  },
  gray: theme.button,
  formControl: theme.input,
  icon: {
    maxHeight: "30px",
    maxWidth: "60px",
    marginTop: "18px",
  },
  input: theme.input,
}));

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten(theme.colors.red, 0.75),
    marginTop: 16,
  },
  bar: {
    borderRadius: 20,
    backgroundColor: theme.colors.red,
  },
})(LinearProgress);

const File = ({
  options: [option],
  name,
  handleChange,
  value,
  readonly = false,
  form,
  entryId,
  company,
  errors = [],
  inline = false,
}: any) => {
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const classes = useStyles();

  const requestSignedUrl = (path: string) => {
    // @ts-ignore
    const storageRef = storage.ref(path);

    storageRef
      .getDownloadURL()
      .then((url: string) => {
        setFileUrl(url);
        if (path.endsWith(".png") || path.endsWith(".jpg")) {
          const thumbnailPath = path.split("/");
          thumbnailPath[thumbnailPath.length - 1] = `thumb_${
            thumbnailPath[thumbnailPath.length - 1]
          }`;
          const thumbnailRef = storage.ref(thumbnailPath.join("/"));
          return thumbnailRef.getDownloadURL().then((thumbUrl: string) => {
            setThumbnailUrl(thumbUrl);
          });
        }
      })
      .catch(function (error: any) {
        console.warn(error);
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
      });
  };

  const deleteFile = () => {
    handleChange({ target: { name, value: "" } });
  };

  const handleUploadStart = () => {
    setProgress(1);
  };

  const handleUploadError = (error: any) => {
    console.warn("upload error", error);
  };

  const handleUploadSuccess = (_: string, task: any) => {
    setProgress(0);

    const file = {
      value: task.blob_.data_.name,
      type: task.blob_.type_,
      path: task.metadata_.fullPath,
      created: Date.now(),
      lastModified: task.blob_.data_.lastModified,
      filename: task.blob_.data_.name,
    };

    handleChange({ target: { name, value: file } });

    requestSignedUrl(task.metadata_.fullPath);
  };

  const handleProgress = (progress: number) => {
    setProgress(progress);
  };

  const customHandleChange = (event: any) => {
    handleChange({
      target: { name, value: { ...value, value: event.target.value } },
    });
  };

  let content;

  if (progress > 0) {
    content = <BorderLinearProgress variant="determinate" value={progress} />;
  } else if (value !== "") {
    if (fileUrl === "") {
      requestSignedUrl(value.path);
    }

    let iconUrl = `${process.env.PUBLIC_URL}/icons/file.png`;

    const parts = value.path.split(".");
    const type = parts[parts.length - 1];
    switch (type) {
      case "png":
      case "jpeg":
      case "jpg":
        if (thumbnailUrl) {
          iconUrl = thumbnailUrl;
        } else {
          iconUrl = `${process.env.PUBLIC_URL}/icons/${type}.png`;
        }
        break;
      case "ai":
      case "avi":
      case "css":
      case "csv":
      case "dbf":
      case "doc":
      case "dwg":
      case "exe":
      case "file":
      case "fla":
      case "html":
      case "iso":
      case "javascript":
      case "json":
      case "mp3":
      case "mp4":
      case "pdf":
      case "ppt":
      case "psd":
      case "rtf":
      case "svg":
      case "txt":
      case "xls":
      case "xml":
      case "zip":
        iconUrl = `${process.env.PUBLIC_URL}/icons/${type}.png`;
        break;
      default:
        iconUrl = `${process.env.PUBLIC_URL}/icons/file.png`;
    }

    content = (
      <Grid container spacing={3}>
        <Grid item xs={2}>
          {fileUrl === "" && <img src={iconUrl} className={classes.icon} />}
          {fileUrl !== "" && (
            <a href={fileUrl} target="_blank">
              <img src={iconUrl} className={classes.icon} />
            </a>
          )}
        </Grid>
        <Grid item xs={8} style={{ paddingTop: "20px" }}>
          {fileUrl === "" && <BorderLinearProgress />}
          {fileUrl !== "" && (
            <TextField
              value={value.value}
              className={classes.input}
              id={name}
              name={name}
              type={type}
              style={{ marginTop: 8 }}
              fullWidth
              margin="normal"
              onChange={customHandleChange}
              disabled={readonly}
              InputLabelProps={{ shrink: true }}
              InputProps={{ disableUnderline: true }}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          {!readonly && !inline && (
            <IconButton onClick={deleteFile}>
              <Delete />
            </IconButton>
          )}
        </Grid>
      </Grid>
    );
  } else {
    content = (
      <Button
        variant="contained"
        className={classes.gray}
        component="label"
        style={{ marginTop: 16, marginBottom: 8, width: "100%" }}
        disabled={readonly}
        disableElevation
      >
        {option.addition}
        <FileUploader
          hidden
          name="file"
          randomizeFilename
          storageRef={storage.ref(`${company}/${form}/${entryId}`)}
          onUploadStart={handleUploadStart}
          onUploadError={handleUploadError}
          onUploadSuccess={handleUploadSuccess}
          onProgress={handleProgress}
        />
      </Button>
    );
  }

  return (
    <FormControl
      className={classes.formControl}
      style={{ marginTop: 8, marginBottom: 4 }}
      fullWidth
    >
      {!option.hideLabel && (
        <InputLabel htmlFor={name} shrink={true}>
          Bestand
        </InputLabel>
      )}
      {content}
      {errors.length > 0 &&
        errors.map((error: any, index: number) => (
          <FormHelperText error key={`${error.type}-${index}`}>
            {getErrorMessage(error.type, error.message)}
          </FormHelperText>
        ))}
    </FormControl>
  );
};

const FileConnector = connect((state: any) => ({ company: getCompany(state) }))(
  File
);

export default FileConnector;
