import React, { CSSProperties, HTMLAttributes } from "react";
// @ts-ignore
import { useSelector } from "react-redux";
import clsx from "clsx";
import Select from "react-select";
import {
  createStyles,
  emphasize,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NoSsr from "@material-ui/core/NoSsr";
import TextField, { BaseTextFieldProps } from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { ValueContainerProps } from "react-select/src/components/containers";
import { ControlProps } from "react-select/src/components/Control";
import { MenuProps, NoticeProps } from "react-select/src/components/Menu";
import { MultiValueProps } from "react-select/src/components/MultiValue";
import { OptionProps } from "react-select/src/components/Option";
import { PlaceholderProps } from "react-select/src/components/Placeholder";
import { SingleValueProps } from "react-select/src/components/SingleValue";
import { ValueType } from "react-select/src/types";
import { Omit } from "@material-ui/types";
import { sortBySequence } from "../../utility/helpers";
import FormHelperText from "@material-ui/core/FormHelperText";
import { getErrorMessage } from "./Error";
import { theme } from "../../styles";
import { FormControl, InputLabel, InputBase } from "@material-ui/core";
import { State } from "../../constants";
import { convertDisplayFormat } from "../../selectors/displayFormat";

interface OptionType {
  label: string;
  value: string;
}

const useStyles = makeStyles((materialTheme: Theme) =>
  createStyles({
    root: {
      marginTop: 8,
      marginBottom: 4,
    },
    input: {
      display: "flex",
      padding: 0,
      height: "auto",
      ...theme.input,
    },
    valueContainer: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    chip: {
      margin: materialTheme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        materialTheme.palette.type === "light"
          ? materialTheme.palette.grey[300]
          : materialTheme.palette.grey[700],
        0.08
      ),
    },
    noOptionsMessage: {
      padding: materialTheme.spacing(1, 2),
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: "absolute",
      left: 2,
      bottom: 6,
      fontSize: 16,
    },
    paper: {
      position: "absolute",
      zIndex: 1,
      marginTop: materialTheme.spacing(1),
      left: 0,
      right: 0,
    },
    disabledInput: {
      marginTop: 12,
      marginBottom: 4,
    },
  })
);

function NoOptionsMessage(props: NoticeProps<OptionType>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      Geen opties
    </Typography>
  );
}

type InputComponentProps = Pick<BaseTextFieldProps, "inputRef"> &
  HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<OptionType>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      className={classes.input}
      fullWidth
      InputProps={{
        inputComponent,
        disableUnderline: true,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props: OptionProps<OptionType>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

type MuiPlaceholderProps = Omit<PlaceholderProps<OptionType>, "innerProps"> &
  Partial<Pick<PlaceholderProps<OptionType>, "innerProps">>;
function Placeholder(props: MuiPlaceholderProps) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props: MultiValueProps<OptionType>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props: MenuProps<OptionType>) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const CustomSelect = ({
  options: [option],
  name,
  handleChange,
  value,
  readonly = false,
  errors = [],
  hideLabel = false,
  form,
}: any) => {
  const classes = useStyles();
  const { placeholder } = option;
  let placeholderValue = placeholder;

  if (form && placeholder) {
    const selectorArgs = { displayFormat: placeholder, currentNode: form };
    placeholderValue = useSelector((state: State) =>
      convertDisplayFormat(state, selectorArgs)
    );
  }

  const customHandleChange = (newValue: any) => {
    const storageValue = option.multiple
      ? newValue.map(({ value }: any) => value)
      : newValue && newValue.value!;
    return handleChange({ target: { name, value: storageValue } });
  };

  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      ...theme.input,
      "& input": {
        font: "inherit",
      },
    }),
  };

  let transformedValue;
  if (value) {
    transformedValue = option.multiple
      ? option.elements.filter((element: any) => value.includes(element.value))
      : option.elements.find((element: any) => element.value === value);
  } else {
    transformedValue = "";
  }

  let content = (
    <NoSsr>
      <Select
        classes={classes}
        styles={selectStyles}
        inputId={`custom-select-${name}`}
        TextFieldProps={{
          label: !hideLabel && !option.hideLabel && option.label,
          InputLabelProps: {
            htmlFor: `custom-select-${name}`,
            shrink: true,
          },
        }}
        placeholder={placeholderValue}
        options={option.elements.sort(sortBySequence)}
        components={components}
        value={transformedValue}
        onChange={customHandleChange}
        isMulti={option.multiple}
        disabled={readonly}
        isClearable={true}
      />
      {errors.length > 0 &&
        errors.map(({ type, message }: any, index: number) => (
          <FormHelperText error key={`${type}-${index}`}>
            {getErrorMessage(type, message)}
          </FormHelperText>
        ))}
    </NoSsr>
  );

  if (readonly) {
    let defaultValue;

    if (Array.isArray(transformedValue)) {
      defaultValue = transformedValue.map(({ label }) => label).join(", ");
    } else if (transformedValue) {
      defaultValue = transformedValue.label;
    }

    content = (
      <FormControl style={{ marginTop: 10, marginBottom: 0, width: "100%" }}>
        {!hideLabel && !option.hideLabel && (
          <InputLabel htmlFor={name} shrink={true}>
            {option.label}
          </InputLabel>
        )}
        <InputBase
          className={classes.disabledInput}
          value={defaultValue}
          disabled
          inputProps={{
            style: { color: theme.colors.black, cursor: "pointer" },
          }}
        />
      </FormControl>
    );
  }

  return <div className={classes.root}>{content}</div>;
};

export default CustomSelect;
