import { createSelector } from "reselect";
import { format } from "date-fns";
import { getRoot, getPathParams } from "./routing";
import { getAll } from "./entries";
import { getAll as getAllForms, getFormElements } from "./forms";
import {
  getInlineVariableKeys,
  getReferencedFormName,
  stripPrefix,
} from "../utility/helpers";
import {
  State,
  EntriesState,
  FormsState,
  InputElement,
  InputTypes,
} from "../constants";
import {
  findLowestCommonAncestor,
  findPathBetweenNodes,
  formGraph,
  entriesGraph,
  findPathBetweenNodesBottomUp,
} from "../graph";

const getDestinationNode = (handle: string, currentNode: string) => {
  if (!handle.includes(".")) {
    return currentNode;
  }

  return handle.split(".").shift() || currentNode;
};

export const getFieldToSelect = (handle: string) => {
  const handleParts = handle.split(".");

  if (handleParts.length === 1) {
    return handle;
  }

  return handleParts[1];
};

const getSingularIdValue = (id: string[] | string) =>
  Array.isArray(id) ? id[0] : id;

const getEntryIdByFormAndSubForm = (
  entries: any,
  path: string[],
  startingEntryId: string | string[],
  destination: string
): string => {
  const entryId = getSingularIdValue(startingEntryId);
  const [form, subForm, ...remainingPath] = path;

  if (!entries[form] || !entries[form][entryId]) {
    return "";
  }

  if (entries[form][entryId][destination]) {
    return getSingularIdValue(entries[form][entryId][destination].value);
  }

  if (!entries[form][entryId][subForm]) {
    return "";
  }

  const subEntryId = getSingularIdValue(entries[form][entryId][subForm].value);

  if (remainingPath.length === 0) {
    return subEntryId;
  }

  return getEntryIdByFormAndSubForm(
    entries,
    [subForm, ...remainingPath],
    subEntryId,
    destination
  );
};

const getIdFromEntry = (
  entries: any,
  form: string,
  destination: string,
  entryId: string,
  pathParams: Record<string, string>
) => {
  let pathToDestinationByDestination = Array.from(
    findPathBetweenNodesBottomUp(formGraph, destination, form)
  ) as string[];

  if (pathToDestinationByDestination.length === 0) {
    pathToDestinationByDestination = Array.from(
      findPathBetweenNodes(formGraph, form, destination)
    ) as string[];
  } else {
    pathToDestinationByDestination = pathToDestinationByDestination.reverse();
  }

  if (pathToDestinationByDestination.length === 2 && entries[form][entryId]) {
    const destinationInCurrentEntry = entries[form][entryId][destination];

    if (destinationInCurrentEntry) {
      return getSingularIdValue(destinationInCurrentEntry.value);
    }
  }

  if (pathToDestinationByDestination.length > 2) {
    const startingEntryId = maybeGetPathParamsEntryId(
      pathParams,
      pathToDestinationByDestination[0]
    );
    if (startingEntryId) {
      return getEntryIdByFormAndSubForm(
        entries,
        pathToDestinationByDestination,
        startingEntryId,
        destination
      );
    }
  }

  return "";
};

const maybeGetPathParamsEntryId = (
  pathParams: Record<string, string>,
  form: string
) => pathParams[`${form}Id`];

const getDestinationId = (
  root: string,
  destination: string,
  current: string,
  pathParams: Record<string, string>,
  entries: any,
  currentEntryId?: string
) => {
  if (currentEntryId && entriesGraph.has(currentEntryId)) {
    const idNode = entriesGraph.get(currentEntryId);

    if (idNode.name === destination) {
      // the given id is the id of the destination entry
      return currentEntryId;
    }

    const entryIdByGivenEntry = getIdFromEntry(
      entries,
      idNode.name!,
      destination,
      currentEntryId,
      pathParams
    );

    if (entryIdByGivenEntry) {
      return entryIdByGivenEntry;
    }
  }

  const pathParamsEntryId = maybeGetPathParamsEntryId(pathParams, destination);

  if (pathParamsEntryId) {
    return pathParamsEntryId;
  }

  // @todo: we could implement one method to determine the path to destination from LCA
  let lca = findLowestCommonAncestor(formGraph, root, current, destination) as
    | string
    | undefined;

  if (!lca) {
    // we assume this is root and just return the first entryId we encounter
    return Object.keys(entries[destination])[0];
  }

  const lcaNode = formGraph.get(lca);

  if (!lcaNode) {
    // we assume this is root and just return the first entryId we encounter
    return Object.keys(entries[destination])[0];
  }

  if (lcaNode.type === "menu" && lcaNode.introvertedEdges.size > 0) {
    lca = Array.from(lcaNode.introvertedEdges)[0];
  }

  const lcaId = pathParams[`${lca}Id`];

  // @ts-ignore
  return getIdFromEntry(
    entries,
    lca as string,
    destination,
    currentEntryId || lcaId,
    pathParams
  );
};

const getFormElementByName = (elements: InputElement[], elementName: string) =>
  elements.find(({ name }) => name === elementName);

const deriveValue = (
  rootNode: string,
  currentNode: string,
  pathParams: Record<string, string>,
  entries: EntriesState,
  forms: FormsState["forms"],
  convertByType: boolean,
  givenEntryId?: string
) => (handle: string): any => {
  const destinationNode = getDestinationNode(handle, currentNode);
  const fieldToSelect = getFieldToSelect(handle);

  let selectedField: any;
  let currentEntryId = givenEntryId;

  if (
    currentEntryId &&
    currentEntryId.includes("-") &&
    pathParams[`${currentNode}Id`]
  ) {
    currentEntryId = pathParams[`${currentNode}Id`];
  }

  // This is an inlineList
  if (givenEntryId && givenEntryId.includes("-")) {
    const [handle, idx] = givenEntryId.split("-");

    if (!entries[currentNode] || !entries[currentNode][currentEntryId!]) {
      return [handle, ""];
    }

    selectedField =
      entries[currentNode][currentEntryId!][handle][idx][destinationNode];
  } else {
    if (!entries[destinationNode]) {
      return [handle, ""];
    }

    const entryId = getDestinationId(
      rootNode,
      destinationNode,
      currentNode,
      pathParams,
      entries,
      currentEntryId
    );

    const selectedEntry =
      entries[destinationNode][currentEntryId!] ||
      entries[destinationNode][entryId];

    if (!selectedEntry || !selectedEntry[fieldToSelect]) {
      return [handle, ""];
    }

    selectedField = selectedEntry[fieldToSelect];
  }

  const destinationElements = getFormElements(forms, destinationNode);
  const fieldElement = getFormElementByName(destinationElements, fieldToSelect);
  let value = selectedField.value;

  if (value && typeof value === "string" && value.includes("[")) {
    value = replaceVariables(
      value,
      rootNode,
      currentNode,
      pathParams,
      entries,
      forms,
      convertByType,
      currentEntryId
    );
  }

  if (fieldElement && value) {
    if (
      [InputTypes.CROSS_SELECT, InputTypes.FORM].includes(fieldElement.type) &&
      handle.split(".").length > 2
    ) {
      const [referencedForm] = getReferencedFormName(fieldElement);

      let stringValue = "";

      if (Array.isArray(value)) {
        stringValue = value[0];
      } else {
        stringValue = value;
      }

      const newHandle = handle
        .replace(`${destinationNode}.`, "")
        .replace(`${fieldElement.name}`, referencedForm);

      return deriveValue(
        rootNode,
        currentNode,
        pathParams,
        entries,
        forms,
        convertByType,
        stringValue
      )(newHandle);
    }

    if (convertByType) {
      switch (fieldElement.type) {
        case InputTypes.SELECT:
          // @ts-ignore
          const option = fieldElement.options[0].elements.find(
            (opt: any) => opt.value === value
          );

          if (option) {
            // @ts-ignore
            return [handle, option.label];
          }

          return [handle, value];
        case InputTypes.DATE:
          return [handle, format(new Date(value), "dd-MM-yyyy")];
        case InputTypes.DATE_TIME:
          return [handle, format(new Date(value), "dd-MM-yyyy HH:mm")];
        case InputTypes.FILE:
          return [handle, value.value];
        case InputTypes.CHECKBOX:
          return [
            handle,
            // @ts-ignore
            value === "true" ? `<br />${fieldElement.options[0].label}` : "",
          ];
        // no default
      }
    }
  }

  return [handle, value];
};

export const replaceVariables = (
  displayFormat: string = "",
  rootNode: string,
  currentNode: string,
  pathParams: Record<string, string>,
  entries: EntriesState,
  forms: FormsState["forms"],
  convertByType: boolean = true,
  entryId?: string
) => {
  const variablesToReplace = getInlineVariableKeys(displayFormat);

  if (!variablesToReplace) {
    return displayFormat;
  }

  return variablesToReplace
    .map((handle: string) => {
      const handleWithoutPrefix = stripPrefix(handle);
      const [, value] = deriveValue(
        rootNode,
        currentNode,
        pathParams,
        entries,
        forms,
        convertByType,
        entryId
      )(handleWithoutPrefix);

      return [handle, value];
    })
    .reduce((acc, [key, value]) => {
      return acc.replace(`[${key}]`, value);
    }, displayFormat);
};

export const convertDisplayFormat = createSelector(
  getRoot,
  getPathParams,
  getAll,
  getAllForms,
  (
    _: State,
    {
      displayFormat,
      currentNode,
    }: { displayFormat: string; currentNode: string }
  ) => ({ displayFormat, currentNode }),
  (rootNode, pathParams, entries, { forms }, { displayFormat, currentNode }) =>
    replaceVariables(
      displayFormat,
      rootNode,
      currentNode,
      pathParams,
      entries,
      forms
    )
);

interface ConvertDisplayFormatByEntryId {
  displayFormat: string;
  currentNode: string;
  entryId: string;
}

export const convertDisplayFormatByEntryId = createSelector(
  getRoot,
  getPathParams,
  getAll,
  getAllForms,
  (
    _: State,
    { displayFormat, currentNode, entryId }: ConvertDisplayFormatByEntryId
  ) => ({ displayFormat, currentNode, entryId }),
  (
    rootNode,
    pathParams,
    entries,
    { forms },
    { displayFormat, currentNode, entryId }
  ) =>
    replaceVariables(
      displayFormat,
      rootNode,
      currentNode,
      pathParams,
      entries,
      forms,
      true,
      entryId
    )
);
