import React, { useState } from "react";
// @ts-ignore
import SignatureCanvas from "react-signature-canvas";
// @ts-ignore
import { connect } from "react-redux";

import { get } from "../../selectors/user";

import { getErrorMessage } from "./Error";

import { theme } from "../../styles";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";

// @ts-ignore
const useStyles = makeStyles(() => ({
  disabledInput: {
    marginTop: 12,
    marginBottom: 4,
  },
  signatureContainer: {
    border: `1px solid ${theme.colors.lightGray}`,
    marginTop: 16,
    height: 200,
  },
  placeholder: {
    width: "360px",
    height: "200px",
    padding: "6px",
    lineHeight: "13px",
    fontSize: "11px",
  },
}));

const Signature = ({
  options: [option],
  name,
  handleChange,
  value,
  readonly = false,
  user,
  errors = [],
  isNewEntry = false,
}: any) => {
  const [sigCanvas, setSigCanvas] = useState({ clear: () => {} });
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const classes = useStyles();

  if (value && showPlaceholder) {
    setShowPlaceholder(false);
  }

  const setSignatureReference = (ref: any) => {
    setSigCanvas(ref);
    if (!value && ref && isNewEntry) {
      ref.clear();
      ref.on();
    }
    if (value && ref) {
      ref.fromData(Object.values(value.signature));
    }
    if (readonly && ref) {
      ref.off();
    }
  };

  const customHandleChange = () => {
    handleChange({
      target: {
        name,
        value: {
          // @ts-ignore
          signature: { ...sigCanvas.toData() },
          ...(option.forUser
            ? {
                userId: option.userId || user.uid,
                userName: option.userName || user.name,
              }
            : {}),
        },
      },
    });
  };

  const handleClear = () => {
    sigCanvas.clear();
  };

  return (
    <>
      {option.forUser && (
        <>
          <FormControl style={{ marginTop: 8, marginBottom: 4 }}>
            <InputLabel htmlFor={name} shrink={true}>
              Gebruikersnaam
            </InputLabel>
            <InputBase
              className={classes.disabledInput}
              defaultValue={option.userName || user.name}
              disabled
              inputProps={{ style: { color: theme.colors.black } }}
            />
          </FormControl>
          <br />
        </>
      )}
      <FormControl style={{ marginTop: 8, marginBottom: 4 }}>
        {!option.hideLabel && (
          <InputLabel shrink={true}>{option.label}</InputLabel>
        )}
        <div
          className={`${classes.disabledInput} ${classes.signatureContainer}`}
        >
          {showPlaceholder && (
            <div
              className={classes.placeholder}
              onClick={() => setShowPlaceholder(false)}
            >
              {option.placeholder}
            </div>
          )}
          {!showPlaceholder && (
            <SignatureCanvas
              canvasProps={{ width: 360, height: 200, className: "sigCanvas" }}
              ref={(ref: any) => setSignatureReference(ref)}
              onEnd={customHandleChange}
            />
          )}
        </div>
      </FormControl>
      <br />
      {!readonly && !showPlaceholder && (
        <Button
          variant="contained"
          type="button"
          onClick={handleClear}
          style={{
            marginBottom: 12,
            backgroundColor: theme.colors.buttonGray,
            color: theme.colors.white,
          }}
          disableElevation
        >
          Wis handtekening
        </Button>
      )}
      {errors.length > 0 &&
        errors.map((error: any, index: number) => (
          <FormHelperText error key={`${error.type}-${index}`}>
            {getErrorMessage(error.type, error.message)}
          </FormHelperText>
        ))}
    </>
  );
};

const SignatureConnector = connect((state: any) => ({ user: get(state) }))(
  Signature
);

export default SignatureConnector;
