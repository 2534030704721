import { createSelector } from 'reselect';
// @ts-ignore
import { matchPath } from 'react-router';

import { State, ByForm, FormType } from '../constants';
import { getElementsByForm, getParentFormRouteByForm } from './forms';
import { getCurrentBranch, getCurrentFormsParentFormAndId, getPathParams, getParentFormAndIdByForm } from './routing';
import { getUserName } from './user';

export function getAll({ entries }: State) {
    return entries;
}

export const isStoring = createSelector(
    getAll,
    ({ storing }) => storing
);

export const isInitialLoadDone = createSelector(
    getAll,
    ({ initialLoadDone }) => initialLoadDone
);

export const getInitialLoadProgress = createSelector(
    getAll,
    ({ initialLoadProgress }) => initialLoadProgress
);

export const formsToIgnore = createSelector(
  getAll,
  ({ formsToIgnore }) => formsToIgnore
);

export const getBuilderMetas = createSelector(
    getAll,
    ({ formBuilderFormMeta }) => formBuilderFormMeta
);

export const getEntriesByForm = createSelector(
    getAll,
    (_: State, { form }: ByForm) => form,
    (entries, form) => entries[form]
);

export const getValidEntriesByForm = createSelector(
    getEntriesByForm,
    (entries) => Object
        .entries(entries)
        .filter(([, { valid }]: [string, any]) => valid)
        .reduce((acc: any, [entryId, entry]: any) => {
            acc[entryId] = entry;
            return acc;
        }, {})
)

interface ByFormAndEntryId extends ByForm {
    entryId: string;
}

export const getEntryByFormAndId = createSelector(
    getAll,
    (_: State, { form, entryId }: ByFormAndEntryId) => ({ form, entryId }),
    (entries, { form, entryId }: ByFormAndEntryId) => entries[form] && entries[form][entryId]
);

export const getLastStoredByName = createSelector(
    getAll,
    getUserName,
    getEntryByFormAndId,
    (entries, currentUserName, entry) => {
        if (entry && entry.lastStoredBy && entries.user && entries.user[entry.lastStoredBy]) {
            return entries.user[entry.lastStoredBy].name.value;
        }

        return currentUserName;
    }
);

interface ByFormAndEntryIds extends ByForm {
    entryIds: string[];
}

export const hasAtLeastOneValidEntryByFormAndEntryIds = createSelector(
    getEntriesByForm,
    (_: State, { entryIds = [] }: ByFormAndEntryIds) => ({ entryIds }),
    (entries, { entryIds }) => {
        if (!entries) {
            return false;
        }

        return Object
            .entries(entries)
            .filter(([entryId]) => entryIds.includes(entryId))
            // @ts-ignore
            .some(([, entry]) => entry.valid);
    }
)

export const getStatusOfFormsAndListsByFormAndEntryId = createSelector(
    getAll,
    getElementsByForm,
    (_: State, { form, entryId }: ByFormAndEntryId) => ({ form, entryId }),
    (entries, elements, { form, entryId }) => {
        // @ts-ignore
        const elementsToValidate = elements.filter(({ type }) => [FormType.LIST, FormType.FORM].includes(type));

        if (!entries[form]) {
            return {};
        }

        const entry = entries[form][entryId];

        if (!entry) {
            return {};
        }

        return elementsToValidate.reduce((status: any, element) => {
            let formName: string;

            if (
                // @ts-ignore
                element.type === FormType.FORM
                && entries[element.name]
                && entry[element.name]
                && entry[element.name].value
                && entries[element.name][entry[element.name].value]
            ) {
                formName = element.name;

                if (entries[formName][entry[formName].value].closed) {
                    if (!status[element.name]) {
                        status[element.name] = {};
                    }
                    status[element.name].closed = true;
                }

                // @ts-ignore
                if (!element.required) {
                    if (!status[element.name]) {
                        status[element.name] = {};
                    }
                    status[element.name].valid = true;
                    return status;
                }

            // @ts-ignore
            } else if (element.type === FormType.LIST) {
                // @ts-ignore
                if (!element.required && !element.hasValue) {
                    if (!status[element.name]) {
                        status[element.name] = {};
                    }
                    status[element.name].valid = true;
                    return status;
                }
            }

            if (!status[element.name]) {
                status[element.name] = { valid: false };
            }
            return status;
        }, {});
    }
);

// @todo: reimplement leveraging the graph
export const getEntryIdsLimitedByCurrentParentByForm = createSelector(
    getCurrentBranch,
    getAll,
    getParentFormRouteByForm,
    (_: State, { form }: ByForm) => ({ form }),
    (routes, entries, formRoute, { form }) => {
        if (!routes || !formRoute) {
            return;
        }

        const currentRoute = routes.find(({ path }) => {
            const match = matchPath(window.location.pathname, { path, exact: true, strict: true });
            return match;
        });

        if (!currentRoute) {
            return;
        }

        const { params } = matchPath(window.location.pathname, { path: currentRoute.path });

        if (!params) {
            return;
        }

        const { path, entryId, name } = formRoute;
        const id = params[entryId];
        const notAllParamsInPath = Object
            .entries(params)
            .reduce((route: string, [key, value]: any[]) => route.replace(`:${key}`, value), path)
            .includes(':');

        if (notAllParamsInPath) {
            return;
        }

        if (entries[name] && entries[name][id] && entries[name][id][form]) {
            return entries[name][id][form].value;
        }

        return [];
    }
);

export const getCurrentFormsParentEntry = createSelector(
    getCurrentFormsParentFormAndId,
    getAll,
    (parent, entries) => {
        if (!parent) {
            return;
        }

        const { form, entryId } = parent;

        return entries[form][entryId];
    }
);

export const getEntryIdByCurrentBranchAndForm = createSelector(
    getPathParams,
    getParentFormAndIdByForm,
    getAll,
    (_: State, { form }: ByForm) => ({ form }),
    (params, parent, entries, { form }) => {
        if (params[`${form}Id`]) {
            return params[`${form}Id`];
        }

        if (
            parent
            && entries[parent.form]
            && entries[parent.form][parent.entryId]
            && entries[parent.form][parent.entryId][form]
            && entries[parent.form][parent.entryId][form].value
            && entries[parent.form][parent.entryId][form].value[0]
        ) {
            return entries[parent.form][parent.entryId][form].value[0];
        }

        return;
    }
);
