import deepMerge from "deepmerge";
import React, { useState, useEffect } from "react";
// @ts-ignore
import { connect, useStore, useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { Prompt } from "react-router-dom";

import {
  State,
  Form as FormType,
  InputElement,
  Roles,
  FormMeta,
  Defaults,
  InputTypes,
  Input,
} from "../../constants";
import useForm from "./useForm";
import { execValidations, ignoreExpiring } from "../../utility/validation";
import {
  getElementsByVersion,
  getFormMeta,
  getInlineListElementsByForm,
} from "../../selectors/forms";

import Elements from "./Elements";
import { theme } from "../../styles";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getEntriesByForm,
  getStatusOfFormsAndListsByFormAndEntryId,
  isStoring,
} from "../../selectors/entries";
import { getFormEntry } from "../../selectors/drawerContainer";
import {
  getDefaultValuesByForm,
  getDefaultValuesByFormAndSource,
} from "../../selectors/defaultValues";
import {
  canReopenForm,
  isAllowedToEditByRoles,
  isAllowedToExport,
} from "../../selectors/user";
import { getClosedStatus } from "../../selectors/validation";
import { CustomErrorTypes } from "./Error";
import { dialogOpen } from "../../actions/export";
import { isEntryValidByFormAndEntryId } from "../../selectors/entry";
import { getElementsSortedAndFilteredByFormAndEntry } from "../../selectors/elements";

// @ts-ignore
const useStyles = makeStyles(() => ({
  row: {
    minHeight: 48,
    lineHeight: "48px",
    padding: 10,
  },
  green: theme.greenButton,
  red: theme.redButton,
  buttonProgress: {
    color: theme.colors.green,
    marginLeft: "5px",
  },
}));

export interface FormProps {
  name: string;
  version: string;
  elements?: InputElement[];
  meta: FormType & {
    versions: undefined;
    deletable: boolean;
    closeable: boolean;
    roles?: Roles[];
    overrideValidation: true;
    exportable: boolean;
    actionButtonText?: string;
  };
  values: any;
  baseUrl: string;
  submit: Function;
  allEntries: any;
  entryId: string;
  deleteEntry: Function;
  uniqueReferenceIds: string[];
  isLast?: boolean;
  inlineLists: Record<string, InputElement[]>;
  canReopen: boolean;
  isAllowedToEdit: boolean;
  closedStatus: boolean;
  status: any;
  isStoring: boolean;
}

const isNewEntry = (entryId: string) => entryId === Defaults.NEW_ENTRY_ID;

const Form = ({
  elements = [],
  name,
  values = {},
  baseUrl = "",
  submit = () => {},
  allEntries,
  entryId,
  deleteEntry = () => {},
  uniqueReferenceIds,
  isLast = false,
  meta,
  inlineLists,
  canReopen,
  isAllowedToEdit,
  closedStatus,
  status,
  isStoring,
}: FormProps) => {
  if (Object.keys(values).length === 0 && !isNewEntry(entryId)) {
    return null;
  }

  const [formName, setFormName] = useState("");
  const [errors, setErrors] = useState({});
  const [navigationBlocked, setNavigationBlocked] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const allowedToExport = useSelector(isAllowedToExport);
  const isValid = useSelector((state: State) =>
    isEntryValidByFormAndEntryId(state, { form: name, entryId })
  );

  const performValidations = (strict: boolean = false) => {
    // We only want to use the values value if the state doesn't have the value yet
    const options = {
      customMerge: (key: string) => {
        if (key === 'value') {
          return (valueA: string, valueB: string) => (valueA ? valueA : valueB);
        }
      }
    };
    let errorObject = execValidations(
      // @NOTE: this could be dangerous
      deepMerge(values, state, options),
      // @todo: can we leverage the filteredElements?
      elements,
      allEntries,
      entryId,
      name,
      uniqueReferenceIds,
      strict
    );
    const inlineListsToValidate = filteredElements
      .filter(({ type }: InputElement) => type === InputTypes.INLINE_LIST)
      .map(({ name }: Input) => name);

    errorObject = Object.entries(errorObject).reduce(
      (acc: any, [key, error]) => {
        // we validate the inline lists later, so it is fine to ignore them now
        if (inlineListsToValidate.includes(key)) {
          return acc;
        }

        if (
          status[key] &&
          status[key].valid &&
          !error.find(
            ({ type }: { type: CustomErrorTypes }) =>
              type === CustomErrorTypes.NO_VALUE
          )
        ) {
          return acc;
        }

        acc[key] = error;
        return acc;
      },
      {}
    );

    const inlineListErrors = Object.entries(inlineLists)
      .filter(([form]) => inlineListsToValidate.includes(form))
      .reduce((acc: any, [form, inlineElements]) => {
        if (state[form]) {
          state[form].forEach((entry: any, idx: number) => {
            const validationResults = execValidations(
              entry,
              inlineElements,
              state[form],
              idx.toString(),
              "",
              Object.keys(state[form]),
              strict
            );

            Object.entries(ignoreExpiring(validationResults)).forEach(
              ([field, validationResult]) => {
                acc[`${form}.${idx}.${field}`] = validationResult;
              }
            );
          });
        }
        return acc;
      }, {});

    const errors = { ...ignoreExpiring(errorObject), ...inlineListErrors };

    // START TEMP
    if (Object.keys(errors).length > 0) {
      console.warn(errors);
    }
    // END TEMP

    return errors;
  };

  const closeHandler = (event?: any) => {
    if (event) {
      event.preventDefault();
    }

    if (Object.keys(closedStatus).length > 0) {
      setErrors(closedStatus);
      alert("Niet alle onderliggende formulieren zijn afgesloten.");
      return;
    }

    let valid = false;
    if (!valid) {
      const errorObject = performValidations(true);
      setErrors(errorObject);
      valid = Object.keys(errorObject).length === 0;
    }

    if (!valid && meta.overrideValidation) {
      const confirm = window.confirm(
        "Het formulier is niet valide, weet u zeker dat u dit formulier wilt afsluiten?"
      );
      if (confirm) {
        submit(
          {
            ...state,
            formName: name,
            readonly: true,
            closed: Math.round(Date.now() / 1000),
            valid,
          },
          valid
        );
        setNavigationBlocked(false);
      }
    } else if (valid) {
      const confirm = window.confirm(
        "Weet u zeker dat u dit formulier wilt afsluiten?"
      );
      if (confirm) {
        submit(
          {
            ...state,
            formName: name,
            readonly: true,
            closed: Math.round(Date.now() / 1000),
            valid,
          },
          valid
        );
        setNavigationBlocked(false);
      }
    } else {
      alert(
        "Het ingevulde formulier is niet valide, verbeter de inhoud van de velden met een foutmelding en probeer het opnieuw."
      );
    }
  };

  const submitHandler = () => {
    let blocking = false;
    const errorObject = performValidations();
    setErrors(errorObject);
    const valid = Object.keys(errorObject).length === 0;
    blocking = Object.values(errorObject).some((errors: any) =>
      errors.some(({ type }: any) => ["not_unique", "required"].includes(type))
    );

    // if (valid && meta.closeable) {
    //     const strictErrorObject = performValidations(true);
    //     if (Object.keys(strictErrorObject).length === 0) {
    //         const confirm = window.confirm('Het formulier is volledig ingevuld en OK. Wilt u het formulier afsluiten?');
    //         if (confirm) {
    //             closeHandler();
    //             return;
    //         }
    //     }
    // }

    if (!blocking) {
      submit({ ...state, formName: name, valid, readonly: false }, valid);
      setNavigationBlocked(false);
    }

    if (!valid) {
      alert(
        "Het ingevulde formulier is niet valide, verbeter de inhoud van de velden met een foutmelding."
      );
    }
  };

  const deleteHandler = (event: any) => {
    event.preventDefault();

    const confirm = window.confirm(
      "Weet u zeker dat u dit formulier wilt wissen en alle gekoppelde documenten wilt verwijderen? Dit kan niet worden herstelt!"
    );
    if (confirm) {
      const doubleCheck = window.confirm(
        "Verwijderde data kan niet worden herstelt!"
      );
      if (doubleCheck) {
        deleteEntry(entryId);
        setNavigationBlocked(false);
      }
    }
  };

  const globalState = useStore();
  let initialState = { ...values };

  if (formName !== name && isNewEntry(entryId)) {
    const defaultValues = getDefaultValuesByForm(globalState.getState(), {
      form: name,
    });
    Object.entries(defaultValues).forEach(([key, value]) => {
      if (!initialState[key]) {
        initialState[key] = { value };
      } else if (!initialState[key].value) {
        initialState[key].value = value;
      }
    });
  }

  const { state, handleChange, handleSubmit, handleReset } = useForm(
    submitHandler,
    initialState,
    entryId
  );

  // @TODO: this has been disabled to make prefill of forms with cross select work again
  /*
  useEffect(() => {
    handleReset(values, true);
  }, [values]);
   */

  // @ts-ignore
  const filteredElements = useSelector((reduxState) =>
    getElementsSortedAndFilteredByFormAndEntry(reduxState, {
      form: name,
      // @ts-ignore
      values: state,
    })
  );

  if (formName !== name) {
    handleReset(null, true);
    setFormName(name);
  }

  const customHandleChange = (event: any) => {
    setNavigationBlocked(true);
    const isCrossSelect = elements.find(
      ({ name, type }) => name === event.target.name && type === "crossSelect"
    );
    if (isCrossSelect) {
      const defaultValues = getDefaultValuesByFormAndSource(
        globalState.getState(),
        {
          form: name,
          element: event.target.name,
          value: event.target.value,
        }
      );

      defaultValues.forEach(({ name, value }) => {
        if (!values[name] || !values[name].value) {
          handleChange({ target: { name, value } });
        }
      });
    }
    handleChange(event);
  };

  const reopenHandler = () => {
    submit({ ...state, formName: name, readonly: false, closed: false });
  };

  const isReadonly = () => {
    return Boolean(values.readonly) || !isAllowedToEdit || isStoring;
  };

  const openExport = () => {
    dispatch(dialogOpen(name, entryId));
  };

  const canExport = allowedToExport && meta.exportable && !isNewEntry(entryId);
  const ignoreErrors =
    isValid && JSON.stringify(state) !== JSON.stringify(values);

  return (
    <form onSubmit={handleSubmit}>
      <Elements
        readonly={isReadonly()}
        baseUrl={baseUrl}
        name={formName}
        handleChange={customHandleChange}
        elements={elements}
        values={state}
        errors={!ignoreErrors ? errors : {}}
        entry={allEntries && allEntries[entryId]}
        entryId={entryId}
        isLast={isLast}
      />
      {!isReadonly() && (
        <div className={classes.row}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Button
                variant="contained"
                type="submit"
                className={classes.green}
                disableElevation
                disabled={isStoring}
              >
                {meta.actionButtonText || "Opslaan"}
              </Button>
              {isStoring && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Grid>
            {meta.closeable && (
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  onClick={closeHandler}
                  className={classes.green}
                  disableElevation
                >
                  Afsluiten
                </Button>
              </Grid>
            )}
            {/* <Grid item xs={4}>
                            <input type="reset" onClick={handleReset} />
                        </Grid> */}
            {meta.deletable && (
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  onClick={deleteHandler}
                  className={classes.red}
                  disableElevation
                >
                  Verwijder
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      )}
      {isStoring && (
        <div className={classes.row}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              {isStoring && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Grid>
          </Grid>
        </div>
      )}
      {values.readonly && canReopen && (
        <div className={classes.row}>
          <Button
            variant="contained"
            onClick={reopenHandler}
            className={classes.red}
            disableElevation
          >
            Heropen formulier
          </Button>
        </div>
      )}
      {canExport && (
        <div className={classes.row}>
          <Button variant="contained" onClick={openExport} disableElevation>
            Export
          </Button>
        </div>
      )}
      <Prompt
        when={navigationBlocked}
        message="De gemaakte wijzigingen gaan verloren als je dit formulier verlaat"
      />
    </form>
  );
};

const FormConnector = connect(
  (state: State, { name, version, entryId }: FormProps) => {
    // @ts-ignore
    const meta: FormMeta = {
      closeable: false,
      deletable: false,
      ...getFormMeta(state, { form: name }),
    };
    const elements = getElementsByVersion(state, { form: name, version });
    const entry = getFormEntry(state, { form: name, entryId });
    const closedStatus = getClosedStatus(state, {
      form: name,
      version,
      entryId,
    });

    return {
      allEntries: getEntriesByForm(state, { form: name }),
      elements,
      inlineLists: getInlineListElementsByForm(state, { form: name, version }),
      meta,
      values: entry,
      canReopen: canReopenForm(state),
      isAllowedToEdit: isAllowedToEditByRoles(state, {
        roles: meta.roles || [],
      }),
      closedStatus,
      status: getStatusOfFormsAndListsByFormAndEntryId(state, {
        form: name,
        entryId,
      }),
      isStoring: isStoring(state),
    };
  }
)(Form);

export default FormConnector;
