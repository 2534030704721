import { takeEvery, select, put } from 'redux-saga/effects';

import { firestore } from '../firebase';
import { formGraph, addVertex, addEdge } from '../graph';

import * as actions from '../actions/form';

import { getCompany } from '../selectors/company';
import { FSA, Form, InputTypes } from '../constants';
import { getReferencedFormName } from '../utility/helpers';

function* fetchAllForms(): IterableIterator<any> {
    const company = yield select(getCompany);

    const forms = yield firestore
        .collection(`companies/${company}/forms`)
        .get()
        .then((formMeta: any) => Promise.all(
            formMeta.docs.map((meta: any) => {
                if (!meta.data().latest) {
                    return Promise.resolve({
                        id: meta.id,
                        ...meta.data(),
                        versions: {},
                    });
                }

                return firestore
                    .collection(`companies/${company}/forms/${meta.id}/versions`)
                    .doc(meta.data().latest)
                    .get()
                    .then((doc: any) => ({
                        id: meta.id,
                        ...meta.data(),
                        versions: { [doc.id]: doc.data() },
                    }));
            })))
        .then((formList: any) => formList.reduce((acc: any, { id, ...form }: any) => {
            acc[id] = form;
            return acc;
        }, {}));

    if (forms) {
        const directingElementTypes = [
            InputTypes.FORM,
            InputTypes.LIST,
            InputTypes.INLINE_LIST,
            InputTypes.CROSS_SELECT,
            InputTypes.MENU,
            'rootList',
        ];
        // @todo: since we lifted the restriction on the destination node being present; we can combine these two
        Object.entries(forms).forEach(([formName, { type }]: any) => {
            addVertex(formGraph, { id: formName, name: formName, type  });
        });
        Object.values(forms as Record<string, Form>).forEach((form: Form) => {
            const latestFormVersion = form.versions[form.latest];
            if (latestFormVersion && latestFormVersion.elements.length > 0) {
                latestFormVersion.elements
                    .filter((element) => directingElementTypes.includes(element.type))
                    // @ts-ignore
                    .forEach((element) => addEdge(formGraph, form.name, ...getReferencedFormName(element)));
            }
        });
    }

    yield put(actions.fetchedAll(forms));
}

function* fetchVersion(formId: string, versionId: string) {
    const company = yield select(getCompany);

    return yield firestore
        .collection(`companies/${company}/forms/${formId}/versions`)
        .doc(versionId)
        .get()
        .then((doc: any) => ({ [doc.id]: doc.data() }));
}

// @todo: update the graph after fetching a form
export function* fetchForm(id: string) {
    const company = yield select(getCompany);

    let formMeta = yield firestore
        .collection(`companies/${company}/forms/`)
        .doc(id)
        .get()
        .then(doc => doc.data());

    let version = {};

    if (formMeta.latest) {
        version = yield fetchVersion(id, formMeta.latest);
    }

    return { ...formMeta, versions: version };
}

export function* fetchFormVersion({ payload }: FSA) {
    const form = yield fetchForm(payload.formId);
    const version = yield fetchVersion(payload.formId, payload.versionId);
    yield put(actions.update(payload.formId, { ...form, versions: { ...form.versions, ...version } }));
}

export default function* saga() {
    yield takeEvery(actions.FETCH_ALL, fetchAllForms);
    yield takeEvery(actions.FETCH_VERSION, fetchFormVersion);
}
