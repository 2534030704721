import { createSelector } from 'reselect';
// @ts-ignore
import { matchPath } from 'react-router';
import { getCurrentBranch, getCurrentPath } from './routing';

export const getActiveDrawers = createSelector(
    getCurrentPath,
    getCurrentBranch,
    (currentPath, currentBranch) => {
        if (!currentBranch) {
            return [];
        }

        return currentBranch
            .filter(({ path }) => matchPath(currentPath, { path }))
            .map(({ parent, path, name, entryId, parentType, parentName }) => {
                const matchedRoute = matchPath(location.pathname, { path });
                return {
                    rootForm: currentBranch[0].name,
                    formName: name,
                    entryId: matchedRoute.params[entryId],
                    params: matchedRoute.params,
                    parent,
                    parentType,
                    parentName,
                    path: matchedRoute.url,
                };
            });
    }
);
