import { takeEvery, select, put } from "redux-saga/effects";

import { firestore } from "../firebase";

import * as formActions from "../actions/form";
import * as entriesActions from "../actions/entries";
import { getCompany } from "../selectors/company";
import {
  FSA,
  FormMeta,
  FormType,
  Defaults,
  ValidationTypes,
} from "../constants";
import user from "../fixedForms/user";
import { getAll, getBuilderMetas } from "../selectors/entries";
import { randomString } from "../utility/helpers";
import { FormCondition, Condition, Conditions } from "../conditionConstants";
import { populateEntriesGraph } from "../utility/graphHelpers";

// @todo:
// - allow forms to be deleted?

const transformToBoolean = (
  input: { value?: string | boolean } | undefined
) => {
  if (!input || !input.value) {
    return false;
  }

  const { value } = input;

  if (typeof value === "boolean") {
    return value;
  }

  return value === "true";
};

const convertFormConditionToCondition = (formCondition: FormCondition) => {
  switch (formCondition.type.value) {
    case Conditions.EQUALS:
      return {
        type: Conditions.EQUALS,
        values: formCondition.values!.value,
        source: formCondition.source!.value,
      };
    case Conditions.VALID_AND_CLOSED:
      return {
        type: Conditions.VALID_AND_CLOSED,
        source: formCondition.source!.value,
      };
    case Conditions.VALID:
      return {
        type: Conditions.VALID,
        source: formCondition.source!.value,
      };
    case Conditions.HAS_ROLE:
      return {
        type: Conditions.HAS_ROLE,
        roles: formCondition.roles!.value,
      };
  }
};

const convertConditionToFormCondition = (condition: Condition) => {
  switch (condition.type) {
    case Conditions.EQUALS:
      return {
        type: { value: Conditions.EQUALS },
        values: { value: condition.values },
        source: { value: condition.source },
      };
    case Conditions.VALID_AND_CLOSED:
      return {
        type: { value: Conditions.VALID_AND_CLOSED },
        source: { value: condition.source },
      };
    case Conditions.VALID:
      return {
        type: { value: Conditions.VALID },
        source: { value: condition.source },
      };
    case Conditions.HAS_ROLE:
      return {
        type: { value: Conditions.HAS_ROLE },
        roles: { value: condition.roles },
      };
  }
};

const transformConditions = (
  elementConditions: string[],
  formConditions: Record<string, FormCondition>
) => {
  return elementConditions
    .filter((condition: string) => formConditions[condition])
    .map((condition: string) =>
      convertFormConditionToCondition(formConditions[condition])
    );
};

const getValueByKey = (input: any, key: string, fallback: any = "") => {
  if (!input[key] || !input[key].value) {
    return fallback;
  }

  return input[key].value;
};

function* publish(form: string, fields: string[]): IterableIterator<any> {
  const {
    formBuilderFormField,
    formBuilderOption,
    formBuilderCondition,
    formBuilderValidation,
    formBuilderFilter,
  }: any = yield select(getAll);

  // @ts-ignore
  fields = [...new Set(fields)];

  let elements = fields
    .filter((key) => formBuilderFormField[key])
    .map((key: string) => {
      const options: any = [
        {
          label: formBuilderFormField[key].displayName.value,
          hideLabel: transformToBoolean(formBuilderFormField[key].hideLabel),
        },
      ];

      const field: any = {
        options,
        sequence: getValueByKey(formBuilderFormField[key], "sequence", 0),
        type: formBuilderFormField[key].type.value,
        name: getValueByKey(formBuilderFormField[key], "name"),
      };

      if (
        !["section", "lastStoredBy", "static"].includes(
          formBuilderFormField[key].type.value
        )
      ) {
        field.required = formBuilderFormField[key].required
          ? transformToBoolean(formBuilderFormField[key].required)
          : false;
        field.unique = formBuilderFormField[key].unique
          ? transformToBoolean(formBuilderFormField[key].unique)
          : false;
        field.localUnique = formBuilderFormField[key].localUnique
          ? transformToBoolean(formBuilderFormField[key].localUnique)
          : false;
        field.ignoreClosedUnique = formBuilderFormField[key].ignoreClosedUnique
          ? transformToBoolean(formBuilderFormField[key].ignoreClosedUnique)
          : false;
        field.hasValue = formBuilderFormField[key].hasValue
          ? transformToBoolean(formBuilderFormField[key].hasValue)
          : false;
        field.hasValueOnClose = formBuilderFormField[key].hasValueOnClose
          ? transformToBoolean(formBuilderFormField[key].hasValueOnClose)
          : false;
      }

      if (
        ![FormType.ROOT, "signature", "section"].includes(
          formBuilderFormField[key].type.value
        )
      ) {
        field.columnWidth = getValueByKey(
          formBuilderFormField[key],
          "columnWidth",
          12
        );
      }

      if (
        ["text", "number", "textarea", "select", "radio"].includes(
          formBuilderFormField[key].type.value
        )
      ) {
        field.defaultValue = getValueByKey(
          formBuilderFormField[key],
          "defaultValue"
        );
      }

      switch (formBuilderFormField[key].type.value) {
        case FormType.ROOT:
        case FormType.LIST:
          options[0].displayFormat = getValueByKey(
            formBuilderFormField[key],
            "displayFormat"
          );
          options[0].matchFields = getValueByKey(
            formBuilderFormField[key],
            "matchFields"
          );
          options[0].addition = getValueByKey(
            formBuilderFormField[key],
            "addition"
          );
          options[0].elements = [
            {
              type: FormType.FORM,
              name: formBuilderFormField[key].sourceName.value || "",
            },
          ];
          options[0].isGrouped = transformToBoolean(
            formBuilderFormField[key].isGrouped
          );
          options[0].groupBy = getValueByKey(
            formBuilderFormField[key],
            "groupBy"
          );
          options[0].groupDisplayFormat = getValueByKey(
            formBuilderFormField[key],
            "groupDisplayFormat"
          );
          options[0].noUnclosed = transformToBoolean(
            formBuilderFormField[key].noUnclosed
          );
          options[0].table = transformToBoolean(
            formBuilderFormField[key].table
          );
          options[0].limitByUser = getValueByKey(
            formBuilderFormField[key],
            "limitByUser"
          );
          field.nonEditable = transformToBoolean(
            formBuilderFormField[key].nonEditable
          );
          break;
        case "inlineList":
          options[0].addition = getValueByKey(
            formBuilderFormField[key],
            "addition"
          );
          options[0].editAtOnce = getValueByKey(
            formBuilderFormField[key],
            "editAtOnce"
          );
          options[0].elements = [
            {
              type: FormType.FORM,
              name: getValueByKey(formBuilderFormField[key], "sourceName"),
            },
          ];
          break;
        case FormType.FORM:
          options[0].displayFormat = getValueByKey(
            formBuilderFormField[key],
            "displayFormat"
          );
          break;
        case "textarea":
          options[0].placeholder = getValueByKey(
            formBuilderFormField[key],
            "placeholder"
          );
          break;
        case "signature":
          options[0].placeholder = getValueByKey(
            formBuilderFormField[key],
            "placeholder"
          );
          options[0].forUser = transformToBoolean(
            formBuilderFormField[key].forUser
          );
          break;
        case "containmentCalc":
          options[0].multiplyingFactor = getValueByKey(
            formBuilderFormField[key],
            "multiplyingFactor",
            1
          );
          break;
        case "select":
          options[0].placeholder = getValueByKey(
            formBuilderFormField[key],
            "placeholder"
          );
          options[0].multiple = transformToBoolean(
            formBuilderFormField[key].multiple
          );
          if (formBuilderFormField[key].formBuilderOption) {
            options[0].elements = formBuilderFormField[
              key
            ].formBuilderOption.value
              .filter((option: string) => formBuilderOption[option] !== null)
              .map((option: string) => ({
                label: getValueByKey(formBuilderOption[option], "label"),
                value: getValueByKey(formBuilderOption[option], "value"),
                sequence: getValueByKey(
                  formBuilderOption[option],
                  "sequence",
                  0
                ),
              }));
          }
          break;
        case "crossSelect":
          options[0].displayFormat = getValueByKey(
            formBuilderFormField[key],
            "displayFormat"
          );
          options[0].placeholder = getValueByKey(
            formBuilderFormField[key],
            "placeholder"
          );
          options[0].source = getValueByKey(
            formBuilderFormField[key],
            "source"
          );
          options[0].multiple = transformToBoolean(
            formBuilderFormField[key].multiple
          );
          options[0].sortField = getValueByKey(
            formBuilderFormField[key],
            "sortField"
          );
          options[0].backLink = transformToBoolean(
            formBuilderFormField[key].backLink
          );
          options[0].allowClosed = transformToBoolean(
            formBuilderFormField[key].allowClosed
          );
          if (formBuilderFormField[key].formBuilderFilter) {
            options[0].filters = formBuilderFormField[
              key
            ].formBuilderFilter.value
              .filter((option: string) => formBuilderFilter[option] !== null)
              .map((option: string) => ({
                key: getValueByKey(formBuilderFilter[option], "key"),
                value: getValueByKey(formBuilderFilter[option], "value"),
              }));
          }
          break;
        case "text":
        case "number":
          field.pattern = getValueByKey(formBuilderFormField[key], "pattern");
          field.title = getValueByKey(formBuilderFormField[key], "title");
          break;
        case "time":
        case "date":
        case "dateTime":
          options[0].prefill = transformToBoolean(
            formBuilderFormField[key].prefill
          );
          break;
        case "file":
          options[0].addition = getValueByKey(
            formBuilderFormField[key],
            "addition"
          );
          break;
        case "radio":
          if (formBuilderFormField[key].formBuilderOption) {
            // @todo: could be rewritten as .filter().map()
            options[0].elements = formBuilderFormField[
              key
            ].formBuilderOption.value.reduce((options: any, option: string) => {
              if (
                formBuilderOption[option] &&
                formBuilderOption[option] !== null
              ) {
                options.push({
                  label: getValueByKey(formBuilderOption[option], "label"),
                  value: getValueByKey(formBuilderOption[option], "value"),
                  sequence: getValueByKey(
                    formBuilderOption[option],
                    "sequence",
                    0
                  ),
                });
              }

              return options;
            }, []);
          }
          break;
        case "static":
          options[0].displayFormat = getValueByKey(
            formBuilderFormField[key],
            "displayFormat"
          );
          break;
        default:
          break;
      }

      if (formBuilderFormField[key].formBuilderCondition) {
        field.conditions = transformConditions(
          formBuilderFormField[key].formBuilderCondition.value,
          formBuilderCondition
        );
      }

      if (formBuilderFormField[key].formBuilderValidation) {
        field.validations = formBuilderFormField[
          key
        ].formBuilderValidation.value
          .filter(
            (validation: string) => formBuilderValidation[validation] !== null
          )
          .map((validation: string) => {
            switch (formBuilderValidation[validation].type.value) {
              case ValidationTypes.EXPIRES:
                return {
                  type: formBuilderValidation[validation].type.value,
                  expires: formBuilderValidation[validation].expires.value,
                };
              case ValidationTypes.GREATER_THAN:
              case ValidationTypes.SAME_OR_GREATER_THAN:
                return {
                  type: formBuilderValidation[validation].type.value,
                  source: formBuilderValidation[validation].source.value,
                };
              case ValidationTypes.FORBIDDEN:
              case ValidationTypes.WARNING:
              case ValidationTypes.MAX_LIMIT:
              case ValidationTypes.MIN_LIMIT:
                return {
                  type: formBuilderValidation[validation].type.value,
                  value: formBuilderValidation[validation].value.value,
                  message: formBuilderValidation[validation].message.value,
                };
            }
          });
      }

      return field;
    });

  // get rid of all undefineds
  elements = JSON.parse(JSON.stringify(elements));

  const company = yield select(getCompany);

  const { id: version }: any = yield firestore
    .collection(`companies/${company}/forms/${form}/versions`)
    .add({
      elements,
      created: Date.now(),
    });

  yield firestore
    .collection(`companies/${company}/forms`)
    .doc(form)
    .update({ latest: version });
}

const reduceOptionValues = (option: any) => (acc: any, key: string) => {
  acc[key] = { value: option[key] ? option[key].toString() : "" };
  return acc;
};

function* setForms({ payload }: FSA): IterableIterator<any> {
  const formMetas = {
    user: {
      displayName: { value: user.displayName },
      name: { value: user.name },
      type: { value: user.type },
      readonly: true,
      sequence: { value: 0 },
    },
  };

  const entries = Object.values(payload).reduce(
    (
      acc: any,
      {
        displayName,
        name,
        type,
        latest,
        versions,
        sequence = 0,
        closeable = false,
        deletable = false,
        roles = [],
        overrideValidation = false,
        exportable = false,
      }: any
    ) => {
      if (!latest) {
        acc.formBuilderFormMeta[name] = {
          displayName: { value: displayName },
          name: { value: name },
          type: { value: type },
          formBuilderFormField: { value: [] },
          sequence: { value: sequence.toString() },
          deletable: { value: deletable },
          valid: true,
        };

        if (type === FormType.FORM) {
          acc.formBuilderFormMeta[name].closeable = { value: closeable };
          acc.formBuilderFormMeta[name].exportable = { value: exportable };
          acc.formBuilderFormMeta[name].roles = { value: roles };
          acc.formBuilderFormMeta[name].overrideValidation = {
            value: overrideValidation,
          };
        }

        return acc;
      }
      const elements = versions[latest].elements;

      acc.formBuilderFormMeta[name] = {
        displayName: { value: displayName },
        name: { value: name },
        type: { value: type },
        formBuilderFormField: {
          value: elements.map((field: any) => `${name}_${field.name}`),
        },
        sequence: { value: sequence.toString() },
        deletable: { value: deletable },
        valid: true,
      };

      if (type === FormType.FORM) {
        acc.formBuilderFormMeta[name].closeable = { value: closeable };
        acc.formBuilderFormMeta[name].exportable = { value: exportable };
        acc.formBuilderFormMeta[name].roles = { value: roles };
        acc.formBuilderFormMeta[name].overrideValidation = {
          value: overrideValidation,
        };
      }

      acc.formBuilderFormField = elements.reduce((fields: any, field: any) => {
        fields[`${name}_${field.name}`] = {
          type: { value: field.type },
          name: { value: field.name },
          displayName: { value: field.options[0].label },
          sequence: { value: field.sequence.toString() },
          required: { value: field.required },
          unique: { value: field.unique },
          localUnique: { value: field.localUnique },
          ignoreClosedUnique: { value: field.ignoreClosedUnique },
          columnWidth: { value: field.columnWidth },
          hasValue: { value: field.hasValue },
          hasValueOnClose: { value: field.hasValueOnClose },
          valid: true,
        };

        let optionAttributes: string[] = [];

        switch (field.type) {
          case FormType.ROOT:
          case FormType.LIST:
            optionAttributes = [
              "displayFormat",
              "matchFields",
              "addition",
              "isGrouped",
              "groupBy",
              "groupDisplayFormat",
              "noUnclosed",
              "table",
              "limitByUser",
            ];
            fields[`${name}_${field.name}`].sourceName = {
              value: field.options[0].elements[0].name,
            };
            fields[`${name}_${field.name}`].nonEditable = {
              value: field.nonEditable,
            };
            break;
          case "inlineList":
            optionAttributes = ["addition", "editAtOnce"];
            fields[`${name}_${field.name}`].sourceName = {
              value: field.options[0].elements[0].name,
            };
            break;
          case FormType.FORM:
            optionAttributes = ["displayFormat"];
            fields[`${name}_${field.name}`].technicalName = {
              value: field.name,
            };
            break;
          case FormType.MENU:
            fields[`${name}_${field.name}`].technicalName = {
              value: field.name,
            };
            break;
          case "textarea":
            optionAttributes = ["placeholder"];
            fields[`${name}_${field.name}`].defaultValue = {
              value: field.defaultValue,
            };
            break;
          case "signature":
            optionAttributes = ["placeholder", "forUser"];
            break;
          case "containmentCalc":
            optionAttributes = ["multiplyingFactor"];
            break;
          case "select":
            optionAttributes = ["placeholder", "multiple"];
            fields[`${name}_${field.name}`].formBuilderOption = { value: [] };
            fields[`${name}_${field.name}`].defaultValue = {
              value: field.defaultValue,
            };
            if (field.options[0].elements) {
              field.options[0].elements.forEach(
                (element: { label: string; value: string; sequence: any }) => {
                  acc.formBuilderOption[
                    `${name}_${field.name}_${element.value}`
                  ] = {
                    value: { value: element.value },
                    label: { value: element.label },
                    sequence: { value: element.sequence },
                    valid: true,
                  };
                  fields[`${name}_${field.name}`].formBuilderOption.value.push(
                    `${name}_${field.name}_${element.value}`
                  );
                }
              );
            }
            break;
          case "crossSelect":
            optionAttributes = [
              "displayFormat",
              "placeholder",
              "source",
              "multiple",
              "sortField",
              "backLink",
              "allowClosed",
            ];
            fields[`${name}_${field.name}`].formBuilderFilter = { value: [] };
            if (field.options[0].filters) {
              field.options[0].filters.forEach(
                (element: { key: string; value: string }) => {
                  acc.formBuilderFilter[
                    `${name}_${field.name}_${element.key}`
                  ] = {
                    value: { value: element.value },
                    key: { value: element.key },
                    valid: true,
                  };
                  fields[`${name}_${field.name}`].formBuilderFilter.value.push(
                    `${name}_${field.name}_${element.key}`
                  );
                }
              );
            }
            break;
          case "text":
          case "number":
            fields[`${name}_${field.name}`].pattern = { value: field.pattern };
            fields[`${name}_${field.name}`].title = { value: field.title };
            fields[`${name}_${field.name}`].defaultValue = {
              value: field.defaultValue,
            };
            break;
          case "time":
          case "date":
          case "dateTime":
            optionAttributes = ["prefill"];
            break;
          case "file":
            optionAttributes = ["addition"];
            break;
          case "radio":
            fields[`${name}_${field.name}`].formBuilderOption = { value: [] };
            fields[`${name}_${field.name}`].defaultValue = {
              value: field.defaultValue,
            };
            if (field.options[0].elements) {
              field.options[0].elements.forEach(
                (element: { label: string; value: string; sequence: any }) => {
                  acc.formBuilderOption[
                    `${name}_${field.name}_${element.value}`
                  ] = {
                    value: { value: element.value },
                    label: { value: element.label },
                    sequence: { value: element.sequence },
                    valid: true,
                  };
                  fields[`${name}_${field.name}`].formBuilderOption.value.push(
                    `${name}_${field.name}_${element.value}`
                  );
                }
              );
            }
            break;
          case "static":
            optionAttributes = ["displayFormat"];
            break;
        }

        optionAttributes.push("hideLabel");

        if (optionAttributes.length > 0) {
          fields[`${name}_${field.name}`] = {
            ...fields[`${name}_${field.name}`],
            ...optionAttributes.reduce(
              reduceOptionValues(field.options[0]),
              {}
            ),
          };
        }

        if (field.conditions) {
          fields[`${name}_${field.name}`].formBuilderCondition = { value: [] };
          field.conditions
            .filter((condition: any) => condition)
            .forEach((condition: Condition) => {
              const conditionName = randomString();
              const formCondition = convertConditionToFormCondition(condition);
              acc.formBuilderCondition[conditionName] = {
                ...formCondition,
                valid: true,
              };
              fields[`${name}_${field.name}`].formBuilderCondition.value.push(
                conditionName
              );
            });
        }

        if (field.validations) {
          fields[`${name}_${field.name}`].formBuilderValidation = { value: [] };
          field.validations.forEach(
            (element: {
              type: string;
              expires?: string;
              source?: string;
              value?: string;
              message?: string;
            }) => {
              acc.formBuilderValidation[
                `${name}_${field.name}_${element.type}`
              ] = {
                type: { value: element.type },
                valid: true,
              };
              // @todo: simplify
              if (element.expires) {
                acc.formBuilderValidation[
                  `${name}_${field.name}_${element.type}`
                ].expires = { value: element.expires };
              }
              if (element.source) {
                acc.formBuilderValidation[
                  `${name}_${field.name}_${element.type}`
                ].source = { value: element.source };
              }
              if (element.value) {
                acc.formBuilderValidation[
                  `${name}_${field.name}_${element.type}`
                ].value = { value: element.value };
              }
              if (element.message) {
                acc.formBuilderValidation[
                  `${name}_${field.name}_${element.type}`
                ].message = { value: element.message };
              }
              fields[`${name}_${field.name}`].formBuilderValidation.value.push(
                `${name}_${field.name}_${element.type}`
              );
            }
          );
        }

        return fields;
      }, acc.formBuilderFormField);

      return acc;
    },
    {
      formBuilderFormMeta: formMetas,
      formBuilderFormField: {},
      formBuilderOption: {},
      formBuilderCondition: {},
      formBuilderValidation: {},
      formBuilderFilter: {},
    }
  );

  Object
    // @ts-ignore
    .entries(entries)
    .forEach(([key, entryObj]: [string, any]) => {
      // @ts-ignore
      populateEntriesGraph(key, entryObj);
    });

  yield put(entriesActions.fetched(entries));
}

function* createMeta(entry: any): IterableIterator<any> {
  const company = yield select(getCompany);
  const name = entry.name.value.trim();
  const meta: FormMeta = {
    displayName: entry.displayName.value,
    name,
    type: entry.type.value,
    deletable: transformToBoolean(entry.deletable),
    lastUpdate: Date.now(),
    sequence: parseInt(entry.sequence.value, 10),
    exportable: transformToBoolean(entry.exportable),
  };

  if (entry.type.value === FormType.FORM && entry.closeable) {
    meta.closeable = transformToBoolean(entry.closeable);
    meta.roles = entry.roles.value;
    meta.overrideValidation = transformToBoolean(entry.overrideValidation);
  }

  if (entry.type.value === FormType.MENU && entry.isRoot) {
    meta.isRoot = transformToBoolean(entry.isRoot);
  }

  if (entry.id === Defaults.NEW_ENTRY_ID) {
    // create
    return firestore
      .collection(`companies/${company}/forms`)
      .doc(name)
      .set({ ...meta, latest: "" });
  }

  // update
  yield firestore
    .collection(`companies/${company}/forms`)
    .doc(name)
    .update(meta);

  const confirm = window.confirm("Do you want to publish a new version?");
  if (confirm) {
    const metas: any = yield select(getBuilderMetas);
    yield publish(name, metas[name].formBuilderFormField.value);
  }
}

// @ts-ignore
const handleConditionSave = (condition: any): FormCondition => {
  switch (condition.type.value) {
    case Conditions.EQUALS:
      return {
        type: { value: Conditions.EQUALS },
        values: condition.values,
        source: condition.source,
      };
    case Conditions.VALID_AND_CLOSED:
      return {
        type: { value: Conditions.VALID_AND_CLOSED },
        source: condition.source,
      };
    case Conditions.VALID:
      return {
        type: { value: Conditions.VALID },
        source: condition.source,
      };
    case Conditions.HAS_ROLE:
      return {
        type: { value: Conditions.HAS_ROLE },
        roles: condition.roles,
      };
  }
};

const saveAction = (
  entryId: string,
  handle: string,
  name: string,
  condition: FormCondition
) => {
  populateEntriesGraph("formBuilderFormField", {
    [entryId]: { value: [name] },
  });
  populateEntriesGraph(handle, { [name]: condition });

  return entriesActions.builderFetched({
    formBuilderFormField: {
      [entryId]: {
        [handle]: { value: [name] },
      },
    },
    [handle]: { [name]: condition },
  });
};

function* saveField({ payload }: FSA): IterableIterator<any> {
  let entryName;
  switch (payload.entry.formName) {
    case "formBuilderValidation":
      entryName = `${payload.entry.entryId}_${payload.entry.type.value.trim()}`;

      const validationRule: any = {
        type: payload.entry.type,
      };

      if (payload.entry.type.value === ValidationTypes.EXPIRES) {
        validationRule.expires = payload.entry.expires;
      }

      if (
        [
          ValidationTypes.GREATER_THAN,
          ValidationTypes.SAME_OR_GREATER_THAN,
        ].includes(payload.entry.type.value)
      ) {
        validationRule.source = payload.entry.source;
      }

      if (
        [
          ValidationTypes.FORBIDDEN,
          ValidationTypes.WARNING,
          ValidationTypes.MAX_LIMIT,
          ValidationTypes.MIN_LIMIT,
        ].includes(payload.entry.type.value)
      ) {
        validationRule.value = payload.entry.value;
        validationRule.message = payload.entry.message;
      }

      populateEntriesGraph("formBuilderFormField", {
        [payload.entry.entryId]: {
          formBuilderValidation: { value: [entryName] },
        },
      });
      populateEntriesGraph("formBuilderValidation", {
        [entryName]: validationRule,
      });

      yield put(
        entriesActions.builderFetched({
          formBuilderFormField: {
            [payload.entry.entryId]: {
              formBuilderValidation: {
                value: [entryName],
              },
            },
          },
          formBuilderValidation: { [entryName]: validationRule },
        })
      );
      return;
    case "formBuilderCondition":
      const id =
        payload.entry.id !== Defaults.NEW_ENTRY_ID
          ? payload.entry.id
          : randomString();
      const condition = handleConditionSave(payload.entry);
      yield put(
        saveAction(payload.entry.entryId, "formBuilderCondition", id, condition)
      );
      return;
    case "formBuilderFilter":
      entryName = `${payload.entry.entryId}_${payload.entry.key.value.trim()}`;
      populateEntriesGraph("formBuilderFormField", {
        [payload.entry.entryId]: {
          formBuilderFilter: {
            value: [entryName],
          },
        },
      });
      populateEntriesGraph("formBuilderFilter", {
        [entryName]: {
          key: payload.entry.key,
          value: payload.entry.value,
        },
      });
      yield put(
        entriesActions.builderFetched({
          formBuilderFormField: {
            [payload.entry.entryId]: {
              formBuilderFilter: {
                value: [entryName],
              },
            },
          },
          formBuilderFilter: {
            [entryName]: {
              key: payload.entry.key,
              value: payload.entry.value,
            },
          },
        })
      );
      return;
    case "formBuilderOption":
      entryName = `${
        payload.entry.entryId
      }_${payload.entry.value.value.trim()}`;
      populateEntriesGraph("formBuilderFormField", {
        [payload.entry.entryId]: {
          formBuilderOption: {
            value: [entryName],
          },
        },
      });
      populateEntriesGraph("formBuilderFilter", {
        [entryName]: {
          value: payload.entry.value,
          label: payload.entry.label,
          sequence: payload.entry.sequence,
        },
      });

      yield put(
        entriesActions.builderFetched({
          formBuilderFormField: {
            [payload.entry.entryId]: {
              formBuilderOption: {
                value: [entryName],
              },
            },
          },
          formBuilderOption: {
            [entryName]: {
              value: payload.entry.value,
              label: payload.entry.label,
              sequence: payload.entry.sequence,
            },
          },
        })
      );
      return;
    case "formBuilderFormMeta":
      yield createMeta(payload.entry);
      return;
    case "formBuilderFormField":
      const parent = payload.entry.entryId;
      const entry: any = {
        type: payload.entry.type,
        displayName: payload.entry.displayName,
        sequence: payload.entry.sequence,
        name: {
          value: payload.entry.name ? payload.entry.name.value.trim() : "",
        },
        hideLabel: payload.entry.hideLabel,
      };

      if (
        !["section", "lastStoredBy", "static"].includes(
          payload.entry.type.value
        )
      ) {
        entry.required = payload.entry.required;
        entry.unique = payload.entry.unique;
        entry.localUnique = payload.entry.localUnique;
        entry.ignoreClosedUnique = payload.entry.ignoreClosedUnique;
        entry.hasValue = payload.entry.hasValue;
        entry.hasValueOnClose = payload.entry.hasValueOnClose;
      }

      if (
        ![FormType.ROOT, "signature", "section"].includes(
          payload.entry.type.value
        )
      ) {
        entry.columnWidth = payload.entry.columnWidth;
      }

      switch (payload.entry.type.value) {
        case FormType.ROOT:
        case FormType.LIST:
          entry.displayFormat = payload.entry.displayFormat;
          entry.matchFields = payload.entry.matchFields;
          entry.addition = payload.entry.addition;
          entry.sourceName = payload.entry.sourceName;
          entry.isGrouped = payload.entry.isGrouped;
          entry.groupBy = payload.entry.groupBy;
          entry.groupDisplayFormat = payload.entry.groupDisplayFormat;
          entry.noUnclosed = payload.entry.noUnclosed;
          entry.table = payload.entry.table;
          entry.limitByUser = payload.entry.limitByUser;
          entry.nonEditable = payload.entry.nonEditable;
          break;
        case "inlineList":
          entry.addition = payload.entry.addition;
          entry.sourceName = payload.entry.sourceName;
          entry.editAtOnce = payload.entry.editAtOnce;
          break;
        case FormType.FORM:
          entry.displayFormat = payload.entry.displayFormat;
          entry.name = { value: payload.entry.technicalName.value.trim() };
          break;
        case FormType.MENU:
          entry.name = { value: payload.entry.technicalName.value.trim() };
          break;
        case "textarea":
          entry.placeholder = payload.entry.placeholder;
          entry.defaultValue = payload.entry.defaultValue;
          break;
        case "signature":
          entry.placeholder = payload.entry.placeholder;
          entry.forUser = payload.entry.forUser;
          break;
        case "containmentCalc":
          entry.multiplyingFactor = payload.entry.multiplyingFactor;
          break;
        case "select":
          entry.placeholder = payload.entry.placeholder;
          entry.multiple = payload.entry.multiple;
          entry.defaultValue = payload.entry.defaultValue;
          break;
        case "crossSelect":
          entry.displayFormat = payload.entry.displayFormat;
          entry.placeholder = payload.entry.placeholder;
          entry.source = payload.entry.source;
          entry.multiple = payload.entry.multiple;
          entry.sortField = payload.entry.sortField;
          entry.backLink = payload.entry.backLink;
          entry.allowClosed = payload.entry.allowClosed;
          break;
        case "text":
        case "number":
          entry.pattern = payload.entry.pattern;
          entry.title = payload.entry.title;
          entry.defaultValue = payload.entry.defaultValue;
          break;
        case "time":
        case "date":
        case "dateTime":
          entry.prefill = payload.entry.prefill;
          break;
        case "file":
          entry.addition = payload.entry.addition;
          break;
        case "static":
          entry.displayFormat = payload.entry.displayFormat;
          break;
        case "radio":
          entry.defaultValue = payload.entry.defaultValue;
      }

      entryName = `${parent}_${entry.name.value}`;
      populateEntriesGraph("formBuilderFormMeta", {
        [parent]: {
          formBuilderFormField: {
            value: [entryName],
          },
        },
      });
      populateEntriesGraph("formBuilderFilter", { [entryName]: entry });

      yield put(
        entriesActions.builderFetched({
          formBuilderFormMeta: {
            [parent]: {
              formBuilderFormField: {
                value: [entryName],
              },
            },
          },
          formBuilderFormField: {
            [entryName]: entry,
          },
        })
      );
      return;
    default:
      return;
  }
}

function* deleteField({ payload }: FSA): IterableIterator<any> {
  const { form, entryId } = payload;

  switch (form) {
    case "formBuilderFormField":
    case "formBuilderCondition":
    case "formBuilderValidation":
    case "formBuilderFilter":
    case "formBuilderOption":
      yield put(entriesActions.fetched({ [form]: { [entryId]: null } }));
      return;
    default:
      return;
  }
}

export default function* saga() {
  yield takeEvery(formActions.FETCHED_ALL, setForms);
  yield takeEvery(entriesActions.SAVE, saveField);
  yield takeEvery(entriesActions.DELETE, deleteField);
}
