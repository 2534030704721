import { call, put, takeEvery, select, take } from "redux-saga/effects";
import { firestore } from "../../firebase";
import { getCompany } from "../../selectors/company";
import * as actions from "../../actions/form";
import {
  FormType,
  Form,
  InputTypes,
  FSA,
  InputElement,
  ExportTarget,
} from "../../constants";
import * as entriesActions from "../../actions/entries";
import {
  setCustomExports,
  dialogOpen,
  setExporting,
} from "../../actions/export";
import { convertDisplayFormatByFormAndEntryId } from "../../selectors/displayFormatByGraph";
import { getCustomExport } from "../../selectors/export";
import { exporters } from "./helpers";
import getData from "./data";
import { getEntryByFormAndId } from "../../selectors/entries";
import { randomString } from "../../utility/helpers";
import { format } from "date-fns";

// @todo:
// - implement display value that support loops
// - implement display value that supports sums

let exportFormNames: string[] = [];

interface Filter {
  key: string;
  value: string;
}

interface ExportSource {
  label: string;
  placeholder?: string;
  displayFormat?: string;
  source?: string;
  filters?: Filter[];
  isMain?: boolean;
  type: CustomExportSourceType;
  sequence: number;
  columnWidth: number;
  name: string;
  sortField?: string;
}

interface CustomExport {
  label: string;
  name: string;
  sources: ExportSource[];
  title: string;
  formElements: InputElement[];
}

enum CustomExportSourceType {
  SOURCE = "source",
  DATE = "date",
}

function getMainExportSource(sources: ExportSource[]) {
  return sources.find(({ isMain }) => isMain);
}

const elementGenerator = {
  [CustomExportSourceType.SOURCE]: ({
    sequence,
    label,
    placeholder,
    displayFormat,
    source,
    filters,
    columnWidth = 12,
    sortField = "",
  }: ExportSource) => ({
    name: source || "",
    options: [
      {
        label,
        placeholder,
        displayFormat,
        source,
        filters,
        sortField,
        ignoreValidation: true,
        allowClosed: true,
      },
    ],
    required: true,
    sequence,
    type: InputTypes.CROSS_SELECT,
    columnWidth,
  }),
  [CustomExportSourceType.DATE]: ({
    name,
    sequence,
    columnWidth,
    label,
  }: ExportSource) => ({
    name,
    options: [{ label }],
    required: true,
    sequence,
    type: InputTypes.DATE,
    columnWidth,
  }),
};

function generateExportForm(customExport: CustomExport) {
  if (!customExport.sources) {
    return {
      displayName: customExport.label,
      name: customExport.name,
      latest: "default",
      type: FormType.FORM,
      versions: { default: { elements: [] } },
      actionButtonText: "Exporteer",
    };
  }
  const elements = customExport.sources.map((element) =>
    elementGenerator[element.type](element)
  );

  const exportTypeDropdown = {
    name: "exportType",
    sequence: 999,
    options: [
      {
        label: "Export formaat",
        multiple: false,
        placeholder: "kies het export formaat",
        elements: [
          { label: "HTML", value: ExportTarget.HTML, sequence: 1 },
          { label: "PDF", value: ExportTarget.PDF, sequence: 2 },
        ],
      },
    ],
    columnWidth: 12,
    type: InputTypes.SELECT,
    required: false,
    defaultValue: ExportTarget.HTML,
  };

  return {
    displayName: customExport.label,
    name: customExport.name,
    latest: "default",
    type: FormType.FORM,
    versions: { default: { elements: [...elements, exportTypeDropdown] } },
    actionButtonText: "Exporteer",
  };
}

function generateExportMenuElement({ name, displayName }: Form, idx: number) {
  return {
    name,
    type: FormType.FORM,
    options: [
      { hideLabel: true, label: displayName, displayFormat: displayName },
    ],
    sequence: idx,
  };
}

function* getCustomExports() {
  const company = yield select(getCompany);
  const customExports: Record<string, CustomExport> = yield firestore
    .collection(`companies/${company}/exports`)
    .get()
    .then((exportsObject: any) =>
      exportsObject.docs.reduce((acc: any, exportObj: any) => {
        acc[exportObj.id] = exportObj.data();
        return acc;
      }, {})
    );

  const exportForms = Object.values(customExports).map(generateExportForm);
  const exportFormElements = exportForms.map(generateExportMenuElement);

  const rootMenu = {
    latest: "default",
    displayName: "Exports",
    sequence: 9999,
    name: "customExports",
    type: FormType.MENU,
    isRoot: true,
    versions: { default: { created: 0, elements: exportFormElements } },
  };

  exportFormNames = exportForms.map(({ name }) => name);
  yield put(
    actions.setFixed({
      customExports: rootMenu,
      ...exportForms.reduce((acc: any, form: any) => {
        acc[form.name] = form;
        return acc;
      }, {}),
    })
  );

  yield put(setCustomExports(customExports));
}

function replaceFilterValuesWithEntry(
  entry: Record<string, any>,
  mainSource: ExportSource
) {
  const propsToIgnore = [
    "entryId",
    "formName",
    "id",
    "readonly",
    "rootForm",
    "valid",
    "exportType",
    mainSource.source,
  ];
  const valuesToReplace = Object.entries(entry)
    .filter(([key]) => !propsToIgnore.includes(key))
    .map(([key, { value }]: [string, { value: string }]) => ({ key, value }));

  return function replaceValueInDisplayFormat(element: InputElement) {
    return {
      ...element,
      options: [
        {
          ...element.options![0],
          displayFormat: valuesToReplace.reduce(
            (acc: string, { key, value }: { key: string; value: string }) => {
              acc = acc
                .split(`date:${key}`)
                .join(format((value as unknown) as number, "dd-MM-yyyy"));
              acc = acc.split(key).join(value);
              return acc;
            },
            element.options![0].displayFormat || ""
          ),
        },
      ],
    };
  };
}

function* requestExport({ payload }: FSA): IterableIterator<any> {
  const { entry } = payload;

  if (exportFormNames.includes(entry.formName)) {
    // @ts-ignore
    const customExport: CustomExport = yield select(getCustomExport, {
      name: entry.formName,
    });

    if (!customExport) {
      return;
    }

    const mainSource = getMainExportSource(customExport.sources);
    if (!mainSource) {
      return;
    }

    const mainEntry = yield select(getEntryByFormAndId, {
      form: mainSource.source!,
      entryId: entry[mainSource.source!].value,
    });

    const title = yield select(convertDisplayFormatByFormAndEntryId, {
      form: mainSource.source!,
      entryId: entry[mainSource.source!].value,
      displayFormat: customExport.title,
    });

    const formElementsWithFilterData = customExport.formElements.map(
      replaceFilterValuesWithEntry(payload.entry, mainSource)
    );

    const data = yield getData(
      {
        form: mainSource.source,
        entryId: entry[mainSource.source!].value,
        ...(mainEntry || {}),
      },
      // @ts-ignore
      formElementsWithFilterData,
      {}
    );

    const targetFormat = entry.exportType.value as ExportTarget;
    const entryId = randomString();

    if (targetFormat === ExportTarget.PDF) {
      yield put(dialogOpen("customExport", entryId));
      yield put(setExporting());
    }

    yield exporters[targetFormat](
      title || "",
      customExport.formElements,
      data || {},
      // we do not expect to have inline lists
      {},
      { form: "customExport", entryId }
    );
  }
}

export default function* saga() {
  yield call(getCustomExports);
  yield takeEvery(entriesActions.SAVE, requestExport);
}
