const colors = {
    red: '#FF0000',
    gray: '#E6E6E6',
    orange: '#F1911F',
    backgroundLightGray: '#EFEFEF',
    backgroundDarkGray: '#E6E6E6',
    lightGray: '#DCDCDC',
    disabledGray: '#AAAAAA',
    buttonGray: '#787878',
    borderGray: '#C8C7CC',
    green: '#0F7F12',
    black: '#000000',
    white: '#FFFFFF',
};

export const theme = {
    colors,
    input: {
        borderColor: colors.red,
        '& label': {
            width: '135%',
        },
        '& label.Mui-focused': {
            color: colors.red,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: colors.red,
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: colors.red,
            },
            '&.Mui-focused fieldset': {
                borderColor: colors.red,
            },
        },
        '& :disabled': {
            color: colors.black,
        },
    },
    button: {
        color: colors.white,
        backgroundColor: colors.buttonGray,
        '&:hover': {
            backgroundColor: colors.black,
        },
        '&:active': {
            backgroundColor: colors.black,
        },
        '&:disabled': {
            backgroundColor: colors.lightGray,
            color: colors.white,
        },
    },
    redButton: {
        color: colors.white,
        backgroundColor: colors.red,
        '&:hover': {
            backgroundColor: colors.red,
        },
        '&:active': {
            backgroundColor: colors.black,
        },
        '&:disabled': {
            backgroundColor: colors.red,
            color: colors.white,
        },
    },
    greenButton: {
        color: colors.white,
        backgroundColor: colors.green,
        '&:hover': {
            backgroundColor: colors.green,
        },
        '&:active': {
            backgroundColor: colors.black,
        },
        '&:disabled': {
            backgroundColor: colors.green,
            color: colors.white,
        },
    },
    orangeButton: {
        color: colors.white,
        backgroundColor: colors.orange,
        '&:hover': {
            backgroundColor: colors.orange,
        },
        '&:active': {
            backgroundColor: colors.black,
        },
        '&:disabled': {
            backgroundColor: colors.orange,
            color: colors.white,
        },
    },
};
