import { createSelector } from "reselect";
import {
  ExpirationStatus,
  FormType,
  InputElement,
  Roles,
  State,
  ValidationTypes,
} from "../constants";
import { entriesGraph, findPathBetweenNodes, formGraph } from "../graph";
import { getAll as getAllEntries } from "./entries";
import { getElementsByForm, getAll } from "./forms";
import { getParentFormAndIdByForm, getPathParams, getRoot } from "./routing";
import { getWorstExpirationStatus } from "./validation";
import { getExpirationStatus, isExpiringElement } from "../utility/validation";
import { replaceVariables } from "./displayFormat";
import { get as getCurrentUser } from "./user";
import { getConditionStatus } from "./conditions";

const USER_ENTRIES_HANDLE = "user";

const getListElementByForm = createSelector(getElementsByForm, (elements) => {
  if (!elements) {
    return [];
  }

  return elements.find(({ type }) =>
    // @ts-ignore
    [FormType.LIST, FormType.ROOT].includes(type)
  );
});

const getExpiringElementsByList = createSelector(
  getListElementByForm,
  getAll,
  (listElement, { forms }) => {
    if (!listElement) {
      return [];
    }

    // @ts-ignore
    const { name } = listElement.options[0].elements[0];

    if (!forms[name]) {
      return [];
    }

    const { latest, versions } = forms[name];
    return versions[latest].elements.filter(isExpiringElement);
  }
);

const ignoreGroupedListEntry = (entry: any, groupBy: string) => {
  if (!entry[groupBy]) {
    return true;
  }

  if (entry.closed) {
    return true;
  }

  return false;
};

export interface ListEntry {
  id: string;
  entryId: string;
  readonly: boolean;
  expirationStatus: ExpirationStatus;
  label?: string;
  filter?: Record<string, any>;
}

const transformEntry = (expiringElements: InputElement[]) => ([
  entryId,
  entry,
]: [string, any]) => {
  let expirationStatus: ExpirationStatus = ExpirationStatus.VALID;

  if (expiringElements.length > 0) {
    const entryExpirationStatusList = expiringElements
      .filter(({ name }) => entry[name])
      // @ts-ignore
      .map(({ name, validations }) => {
        // @ts-ignore
        const { expires } = validations!.find(
          ({ type }) => type === ValidationTypes.EXPIRES
        );
        return getExpirationStatus(
          new Date(entry[name].value),
          parseInt(expires, 10)
        );
      });

    expirationStatus = getWorstExpirationStatus(entryExpirationStatusList);
  }

  return {
    id: entryId,
    entryId,
    readonly: entry ? (entry.readonly as boolean) : false,
    expirationStatus,
  };
};

const isUserAssigned = (
  form: string,
  { uid, role }: State["user"],
  entry: { value?: string | string[] }
) => {
  if (role !== Roles.USER) {
    return true;
  }

  if (!entry || !entry.value || entry.value.length === 0) {
    return false;
  }

  const referenceIds = Array.isArray(entry.value) ? entry.value : [entry.value];

  if (!formGraph.has(form)) {
    return false;
  }

  // does referenceIds directly reference the user records?
  if (formGraph.get(form).edges.has(USER_ENTRIES_HANDLE)) {
    return referenceIds.includes(uid);
  }

  return referenceIds.some((id) => {
    if (!entriesGraph.has(id)) {
      return false;
    }

    const edges = entriesGraph.get(id).edges;

    if (!edges.has(uid)) {
      return false;
    }

    return true;
  });
};

export const getEntryIds = createSelector(
  getListElementByForm,
  getExpiringElementsByList,
  getAllEntries,
  getPathParams,
  getRoot,
  getParentFormAndIdByForm,
  getAll,
  getCurrentUser,
  (_: State, { groupId }: { groupId?: string }) => groupId,
  (state: State) => state,
  (
    listElement,
    expiringElements,
    allEntries,
    pathParams,
    rootForm,
    parent,
    { forms },
    currentUser,
    groupId,
    state
  ) => {
    if (!listElement) {
      return [];
    }

    const {
      elements,
      isGrouped,
      groupBy,
      groupDisplayFormat,
      displayFormat,
      matchFields,
      limitByUser,
    // @ts-ignore
    } = listElement.options[0];
    const { name } = elements[0];
    const entries = allEntries[name];

    if (!entries) {
      return [];
    }

    let listEntries: ListEntry[] = Object.entries(entries)
      .filter(([, entry]: [string, any]) => {
        if (!entry === null) {
          return false;
        }

        if (limitByUser && entry[limitByUser]) {
          return isUserAssigned(name, currentUser, entry[limitByUser]);
        }

        return true;
      })
      .map(([entryId, entry]) => {
        const conditionMet = getConditionStatus(state, {
          // @ts-ignore
          values: entry,
          form: name,
        });
        const activeExpiringElements = Object.entries(conditionMet)
          .filter(([, met]) => met)
          .map(([field]) => {
            const element = expiringElements.find(({ name }) => name === field);
            if (element) {
              return element;
            }
            return null;
          })
          .filter((value) => value);
        // @ts-ignore
        return transformEntry(activeExpiringElements)([entryId, entry]);
      });

    // limit entries by parent
    if (parent && allEntries[parent.form][parent.entryId]) {
      const parentEntryIds = allEntries[parent.form][parent.entryId][name];
      if (
        parentEntryIds &&
        parentEntryIds.value &&
        Array.isArray(parentEntryIds.value)
      ) {
        listEntries = listEntries.filter(({ entryId }) =>
          parentEntryIds.value.includes(entryId)
        );
      } else {
        listEntries = [];
      }
    }

    // @ts-ignore
    if (listElement.type !== FormType.ROOT) {
      const pathToRoot = Array.from(
        findPathBetweenNodes(formGraph, rootForm, name)
      );
      const parentForm = pathToRoot[pathToRoot.length - 2];
      const parentId = pathParams[`${parentForm}Id`];
      const parentNode = entriesGraph.get(parentId);

      if (parentNode && parentNode.edges.size > 0) {
        // @ts-ignore
        listEntries = Array.from(parentNode.edges)
          .map((entryId) => listEntries.find(({ id }) => id === entryId))
          .filter((entry) => entry);
      }
    }

    // @ts-ignore
    if (isGrouped) {
      if (!groupId) {
        const groups = Object.entries(entries)
          // @ts-ignore
          .reduce(
            (acc: Record<string, any>, [entryId, entry]: [string, any]) => {
              if (ignoreGroupedListEntry(entry, groupBy)) {
                return acc;
              }

              const groupedValue = entry[groupBy].value;
              acc[groupedValue] = [...(acc[groupedValue] || []), entryId];

              return acc;
            },
            {}
          );

        // @ts-ignore
        listEntries = Object.entries(groups).map(
          ([groupId, groupEntryIds]: [string, string[]]) => {
            const { readonly, expirationStatus } = groupEntryIds.reduce(
              (acc: any, entryId: string) => {
                const listEntry = listEntries.find(({ id }) => id === entryId);

                if (!listEntry) {
                  return acc;
                }

                acc.expirationStatus = getWorstExpirationStatus([
                  listEntry.expirationStatus,
                  acc.expirationStatus,
                ]);
                acc.readonly = !listEntry.readonly ? false : acc.readonly;

                return acc;
              },
              { readonly: true, expirationStatus: ExpirationStatus.VALID }
            );

            return {
              id: groupId,
              expirationStatus,
              readonly,
              entryId: groupEntryIds.shift(),
            };
          }
        );
      } else {
        // @ts-ignore
        listEntries = Object.entries(entries)
          .filter(
            // @ts-ignore
            ([, entry]) => entry[groupBy] && entry[groupBy].value === groupId
          )
          .map(([entryId]) => listEntries.find(({ id }) => id === entryId));
      }
    }

    let displayFormatForLabelComputation = displayFormat;

    if (isGrouped && !groupId) {
      displayFormatForLabelComputation = groupDisplayFormat;
    }

    return listEntries
      .filter((entry) => entry)
      .map((entry) => {
        const filter = matchFields
          .split(",")
          .reduce((acc: Record<string, any>, field: string) => {
            const label = replaceVariables(
              `[${field}]`,
              rootForm,
              name,
              pathParams,
              allEntries,
              forms,
              false,
              entry.entryId
            );

            if (!label) {
              return acc;
            }

            acc[field] = label;

            return acc;
          }, {});

        const label = replaceVariables(
          displayFormatForLabelComputation,
          rootForm,
          name,
          pathParams,
          allEntries,
          forms,
          true,
          entry.entryId
        );

        return {
          ...entry,
          label,
          filter,
        };
      });
  }
);
