import React, { useState } from "react";
// @ts-ignore
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { theme } from "../styles";
import MaterialList from "@material-ui/core/List";
import Add from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Restore from "@material-ui/icons/Restore";
import SortByAlpha from "@material-ui/icons/SortByAlpha";
import EntryListItem from "./form/EntryListItem";
import { getEntryIds, ListEntry } from "../selectors/list";
import { Defaults, State } from "../constants";
import { pathToEntryByForm } from "../selectors/routing";
import { push } from "connected-react-router";

// @ts-ignore
const useStyles = makeStyles((materialTheme) => ({
  listItem: {
    borderTop: `1px solid ${theme.colors.borderGray}`,
    height: "56px",
    "&.selected": {
      backgroundColor: theme.colors.backgroundLightGray,
    },
  },
  arrow: {
    fontSize: 20,
    color: theme.colors.lightGray,
  },
  filterInput: {
    marginLeft: materialTheme.spacing(2),
    marginRight: materialTheme.spacing(1),
    ...theme.input,
  },
  button: {
    marginTop: materialTheme.spacing(2),
    marginRight: materialTheme.spacing(2),
    backgroundColor: theme.colors.lightGray,
    float: "right",
  },
  closedButton: {
    marginTop: materialTheme.spacing(1.5),
  },
  activeClosedButton: {
    color: theme.colors.red,
  },
  listItemText: {
    fontSize: 16,
  },
  valid: {},
  expired: {
    color: theme.colors.red,
  },
  expiring: {
    color: theme.colors.orange,
  },
}));

interface List {
  addition: string;
  readonly: boolean;
  form: string;
  groupId?: string;
  noUnclosed?: boolean;
  elements: any;
  isRoot: boolean;
  isGrouped?: boolean;
  nonEditable?: boolean;
}

const hasClosedEntry = (entries: any[]) =>
  entries.length > 0 && entries.some(({ readonly = false }) => readonly);
const hasUnclosedEntry = (entries: any[]) =>
  entries.length > 0 &&
  entries.some(({ readonly = false }) => readonly === false);
const isAddButtonDisabled = (noUnclosed = false, entries: any[]) =>
  noUnclosed && hasUnclosedEntry(entries);

const List = ({
  addition,
  readonly,
  noUnclosed,
  form,
  groupId,
  elements,
  isRoot = false,
  isGrouped = false,
  nonEditable = false,
}: List) => {
  const [filter, setFilter] = useState("");
  const [showClosed, setShowClosed] = useState(nonEditable || false);
  const [reverse, setReverse] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedEntries = useSelector((state: State) =>
    getEntryIds(state, { groupId, form })
  );
  const pathToComponent = useSelector((state: State) =>
    pathToEntryByForm(state, { form })
  );

  const add = () => {
    setFilter("");

    let entryPath = Defaults.NEW_ENTRY_ID as string;

    if (isGrouped) {
      if (groupId) {
        entryPath = `${groupId}/${entryPath}`;
      } else {
        entryPath = `ungrouped/${entryPath}`;
      }
    } else if (!isRoot) {
      entryPath = `${elements[0].name}/${entryPath}`;
    }

    dispatch(push(`${pathToComponent}/${entryPath}`));
  };

  const isEntryMatchingFilters = (entry: any) => {
    if (!showClosed && entry.readonly) {
      return false;
    }

    return Object.values(entry.filter)
      .map(
        (filterValue) =>
          typeof filterValue === "string" && filterValue.toLowerCase()
      )
      .join(" ")
      .includes(filter.toLowerCase());
  };

  const sortByMatchFields = (
    a: Record<string, { filter: Record<string, any> }>,
    b: Record<string, { filter: Record<string, any> }>
  ) => {
    const firstString = Object.values(a.filter).join(" ");
    const secondString = Object.values(b.filter).join(" ");

    if (reverse) {
      return secondString.localeCompare(firstString);
    }

    return firstString.localeCompare(secondString);
  };

  return (
    <div>
      <div style={{ height: "56px" }}>
        <TextField
          id="filter"
          label="Filter lijst"
          type="search"
          margin="dense"
          className={classes.filterInput}
          value={filter}
          onChange={(event: any) => setFilter(event.target.value)}
        />
        {hasClosedEntry(selectedEntries) && (
          <>
            <IconButton
              className={`${classes.closedButton} ${
                showClosed && classes.activeClosedButton
              }`}
              onClick={() => setShowClosed(!showClosed)}
              title={
                showClosed
                  ? "Verberg afgesloten formulieren"
                  : "Toon afgesloten formulieren"
              }
            >
              <Restore />
            </IconButton>
          </>
        )}
        <IconButton
          className={`${classes.closedButton} ${
            !reverse && classes.activeClosedButton
          }`}
          onClick={() => setReverse(!reverse)}
          title={reverse ? "Sorteer van A-Z" : "Sorteer van Z-A"}
        >
          <SortByAlpha />
        </IconButton>
        {addition && !readonly && (
          <Button
            variant="contained"
            className={classes.button}
            onClick={add}
            startIcon={<Add />}
            disabled={isAddButtonDisabled(noUnclosed, selectedEntries)}
            disableElevation
          >
            {addition}
          </Button>
        )}
      </div>
      <MaterialList dense={true} style={{ paddingTop: 8 }}>
        {selectedEntries
          .filter(isEntryMatchingFilters)
          .sort(sortByMatchFields)
          .map(({ id, label, expirationStatus }: ListEntry) => (
            <EntryListItem
              id={id}
              list={form}
              form={elements[0].name}
              key={id}
              // @ts-ignore
              label={label}
              groupId={groupId}
              isRoot={isRoot}
              expirationStatus={expirationStatus}
            />
          ))}
      </MaterialList>
    </div>
  );
};

export default List;
