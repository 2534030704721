import { FSA, ExportTarget, CustomExport } from "../constants";

export interface DialogOpen extends FSA {
  payload: {
    form: string;
    entryId: string;
  };
}

export const DIALOG_OPEN = "EXPORT_DIALOG_OPEN";
export const dialogOpen = (form: string, entryId: string) => ({
  type: DIALOG_OPEN,
  payload: { form, entryId },
});

export const DIALOG_CLOSE = "EXPORT_DIALOG_CLOSE";
export const dialogClose = () => ({ type: DIALOG_CLOSE });

export const EXPORTING = "EXPORT_EXPORTING";
export const setExporting = () => ({ type: EXPORTING });

export interface Request extends FSA {
  payload: {
    form: string;
    entryId: string;
    targetFormat: ExportTarget;
    coverUrl?: string;
  };
}

export const REQUEST = "EXPORT_REQUEST";
export const request = (
  form: string,
  entryId: string,
  targetFormat: ExportTarget,
  coverUrl?: string
): Request => ({
  type: REQUEST,
  payload: { form, entryId, targetFormat, coverUrl },
});

export const PDF_URL = "EXPORT_PDF_URL";
export const setPDFUrl = (url: string) => ({ type: PDF_URL, payload: url });

export const SET_CUSTOM_EXPORTS = "EXPORT_SET_CUSTOM_EXPORTS";
export const setCustomExports = (
  customExports: Record<string, CustomExport>
): FSA => ({
  type: SET_CUSTOM_EXPORTS,
  payload: customExports,
});
