import "date-fns";
import { startOfDay } from "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import nlLocale from "date-fns/locale/nl";
import { getErrorMessage } from "./Error";
import red from "@material-ui/core/colors/red";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { theme } from "../../styles";
import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import { ExpirationStatus, ValidationTypes } from "../../constants";
import { getExpirationStatus } from "../../utility/validation";
import { getLabel } from "../../utility/helpers";

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: red,
  },
});

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      ...theme.input,
      marginTop: 8,
    },
    [ExpirationStatus.EXPIRED]: {
      color: theme.colors.red,
    },
    [ExpirationStatus.EXPIRING]: {
      color: theme.colors.orange,
    },
  })
);

const DatePicker = ({
  options,
  name,
  handleChange,
  value,
  readonly = false,
  errors = [],
  validations = [],
  disableUnderline = true,
}: any) => {
  const classes = useStyles();
  let inputClass = "";
  let title = "";
  let defaultValue = null;

  const handleDateChange = (date: Date | null) => {
    if (date) {
      return handleChange({
        target: { name, value: startOfDay(date).getTime() },
      });
    }
  };

  if (validations && value) {
    const expires = validations.find(
      ({ type }: any) => type === ValidationTypes.EXPIRES
    );
    if (expires) {
      const status = getExpirationStatus(
        new Date(value),
        parseInt(expires.expires, 10)
      );
      // @ts-ignore
      inputClass = classes[status];
      title = {
        [ExpirationStatus.EXPIRING]: "Deze datum verloopt binnenkort",
        [ExpirationStatus.EXPIRED]: "Deze datum is verlopen",
        [ExpirationStatus.VALID]: "",
      }[status];
    }
  }

  const label = getLabel(options);

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd-MM-yyyy"
          margin="normal"
          label={label}
          value={value || defaultValue}
          onChange={handleDateChange}
          invalidDateMessage="Ongeldige datum"
          className={classes.input}
          error={errors.length > 0}
          title={title}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          InputProps={{
            disableUnderline,
            className: inputClass,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={readonly}
          helperText={
            errors.length > 0 &&
            getErrorMessage(errors[0].type, errors[0].message)
          }
          fullWidth
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DatePicker;
