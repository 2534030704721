import React from "react";
// @ts-ignore
import { useDispatch } from "react-redux";
// @ts-ignore
import { matchPath } from "react-router";
import { push } from "connected-react-router";

import Select from "./Select";
import { Grid, IconButton } from "@material-ui/core";
import Launch from "@material-ui/icons/Launch";
import {
  getCrossSelectElements,
  getSourceAndTargetRoute,
} from "../../reduxMiddleware/crossSelect";
import { getPath } from "../../reduxMiddleware/routing";

const CrossSelect = (props: any) => {
  let { value } = props;
  const {
    label,
    placeholder,
    multiple,
    backLink,
    source,
    sortField = "",
    displayFormat,
    filters = [],
    allowClosed = false,
    ignoreValidation = false,
  } = props.options[0];

  const element = {
    form: source,
    sortFields: sortField.split(","),
    displayFormat,
    filters,
    allowClosed,
  };

  const elements = getCrossSelectElements({
    currentEntryId: value,
    element,
    form: props.form,
    ignoreValidation,
  });

  const options = [
    {
      label,
      placeholder,
      multiple,
      elements,
    },
  ];

  if (backLink) {
    const dispatch = useDispatch();
    const { sourceRoute, targetRoute } = getSourceAndTargetRoute({
      form: props.form,
      source: props.options[0].source,
    });
    const currentPath = getPath();

    if (value) {
      const matchedWithCurrentPath = matchPath(currentPath, {
        path: sourceRoute,
      });
      let params = { [`:${source}Id`]: value };

      if (matchedWithCurrentPath) {
        params = Object.entries(matchedWithCurrentPath.params).reduce(
          (resultParams: any, [paramKey, paramValue]: any) => {
            resultParams[`:${paramKey}`] = paramValue;
            return resultParams;
          },
          params
        );
      }

      const selectedElementPath = Object.entries(params).reduce(
        (route: string, [key, param]) => route.replace(key, param),
        targetRoute
      );

      return (
        <Grid container spacing={0}>
          <Grid item xs={11}>
            <Select {...props} options={options} form={props.currentForm} />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={() => {
                dispatch(push(selectedElementPath));
              }}
            >
              <Launch />
            </IconButton>
          </Grid>
        </Grid>
      );
    }
  }

  return <Select {...props} options={options} form={props.currentForm} />;
};

export default CrossSelect;
