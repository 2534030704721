import React from 'react';
// @ts-ignore
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { ListItem, ListItemText, FormControl, InputLabel, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialList from '@material-ui/core/List';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { Defaults, State } from '../../constants';
import { getElementArrayValueByFormAndName } from '../../selectors/element';
import { CustomError, CustomErrorTypes, ErrorList } from './Error';
import { convertDisplayFormat } from '../../selectors/displayFormat';
import { isEntryValidByFormAndEntryId } from '../../selectors/entry';
import { theme } from '../../styles';
import { isInCurrentPathByForm, pathToEntryByForm } from '../../selectors/routing';

interface FormItemType {
    form: string;
    element: any;
    errors: CustomError[];
    isNewEntry: boolean;
    isReadonly: boolean;
}

// @ts-ignore
const useStyles = makeStyles(() => ({
    arrow: {
        fontSize: 20,
        color: theme.colors.lightGray,
    },
    disabledInput: {
        marginTop: 12,
        marginBottom: 4,
    },
    list: {
        margin: '0 -10px',
        padding: 0,
    },
    formItem: {
        minHeight: '48px',
        height: '56px',
        padding: '0 10px',
        '&.selected': {
            backgroundColor: theme.colors.backgroundLightGray,
        },
    },
    listItemText: {
        fontSize: 16,
    },
}));

const includesNotValid = (errors: CustomError[]) => errors.find(({ type }) => type === CustomErrorTypes.NOT_VALID);

const FormItem = ({
    form,
    element,
    isNewEntry = true,
    isReadonly = false,
    errors = [],
}: FormItemType) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isInCurrentPath = useSelector((state: State) => isInCurrentPathByForm(state, { form: element.name }));
    const pathToComponent = useSelector((state: State) => pathToEntryByForm(state, { form }));
    const targetValue = useSelector((state: State) => getElementArrayValueByFormAndName(state, { form, name: element.name }));
    let displayValue = '';

    if (!targetValue && isReadonly) {
        displayValue = 'Bevat geen data';
    } else {
        const { displayFormat } = element.options[0];
        // @ts-ignore
        displayValue = useSelector((state) => convertDisplayFormat(state, { displayFormat, currentNode: form }));
    }

    const errorList: CustomError[] = [];

    const entryIsValid = useSelector((state: State) => isEntryValidByFormAndEntryId(state, { form: element.name, entryId: targetValue }));
    if (element.required && !entryIsValid) {
        if (!includesNotValid([...errors, ...errorList])) {
            errorList.push({ type: CustomErrorTypes.NOT_VALID });
        }
    }

    const onClick = () => {
        if (isNewEntry) {
            alert('Het formulier moet opgeslagen worden voordat dit onderdeel gebruikt kan worden');
            return;
        }

        dispatch(push(`${pathToComponent}/${element.name}/${targetValue[0] || Defaults.NEW_ENTRY_ID}`));
    };

    return (
        <MaterialList dense={true} className={classes.list}>
            <ListItem
                button
                key={targetValue}
                onClick={onClick}
                className={classes.formItem}
                selected={isInCurrentPath}
            >
                {element.options[0].hideLabel ? (
                    <ListItemText
                        primary={displayValue}
                        secondary={<ErrorList errors={[...errors, ...errorList]} />}
                        classes={{ primary: classes.listItemText }}
                    />
                ) : (
                    <FormControl style={{ marginTop: 14, marginBottom: 0, width: '100%' }}>
                        <InputLabel htmlFor={element.name} shrink={true}>{element.options[0].label}</InputLabel>
                        <InputBase
                            className={classes.disabledInput}
                            value={displayValue}
                            disabled
                            inputProps={{ style: { color: theme.colors.black, cursor: 'pointer' } }}
                        />
                        <ErrorList errors={[...errors, ...errorList]} />
                    </FormControl>
                )}
                <ArrowForwardIos className={classes.arrow} />
            </ListItem>
        </MaterialList>
    );
};

export default FormItem;
