import { AppState } from '../constants';
import { TOGGLE_FULLSCREEN } from '../actions/app';

const initialState: AppState = {
    fullscreen: false,
};

export default (state: AppState = initialState, action: any) => {
    switch (action.type) {
        case TOGGLE_FULLSCREEN:
            return { ...state, fullscreen: !state.fullscreen };
        default:
            return state;
    }
};
