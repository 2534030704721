interface Vertex {
  id: string;
  name?: string;
  type?: string;
}

interface Map<K, V> {
  clear(): void;
  delete(key: K): boolean;
  entries(): IterableIterator<[K, V]>;
  forEach(
    callbackfn: (value: V, index: K, map: Map<K, V>) => void,
    thisArg?: any
  ): void;
  get(key: K): V;
  has(key: K): boolean;
  keys(): IterableIterator<K>;
  set(key: K, value?: V): Map<K, V>;
  size: number;
  values(): IterableIterator<V>;
  [Symbol.iterator](): IterableIterator<[K, V]>;
  [Symbol.toStringTag]: string;
}

interface Node {
  edges: Set<string>;
  introvertedEdges: Set<string>;
  name?: string;
  type?: string;
}

type Graph = Map<string, Node>;

export const addVertex = (graph: Graph, vertex: Vertex) => {
  if (!graph.has(vertex.id)) {
    graph.set(vertex.id, {
      edges: new Set(),
      name: vertex.name,
      type: vertex.type,
      introvertedEdges: new Set(),
    });
  } else {
    const existingVertex = graph.get(vertex.id);
    existingVertex.name = vertex.name;
    existingVertex.type = vertex.type;
  }
};

// @todo:
// - since we allow storing an edge without the destination node being present, we should implement catchers for the findPathBetweenNodes method

export const addEdge = (
  graph: Graph,
  vertexId: string,
  nodeId: string,
  bidirectional = false
) => {
  if (graph.has(vertexId)) {
    const vertex = graph.get(vertexId);
    vertex.edges.add(nodeId);

    if (bidirectional) {
      if (!graph.has(nodeId)) {
        addVertex(graph, { id: nodeId });
      }
      const child = graph.get(nodeId);
      child.introvertedEdges.add(vertexId);
    }
  }
};

// @todo: we should be able to find the root by traversing the introvertedEdges, but that could lead to multiple root nodes
// const findRootNode = (graph: Graph, departureNodeId: string) => {
//     if (!graph.has(departureNodeId)) {
//         throw Error(`${departureNodeId} does not exist`);
//     }

//     let rootNode = graph.get(departureNodeId);

//     while (rootNode.introvertedEdges.size > 0) {
//         rootNode.introvertedEdges.keys[0];
//     }

//     return rootNode.name;
// }

export const findPathBetweenNodesBottomUp = (
  graph: Graph,
  startingNode: string,
  nodeToFind: string
): Set<string> => {
  const visited: Set<string> = new Set();

  const findNode = (node: string, searchedNode: string) => {
    visited.add(node);

    if (node === searchedNode) {
      return true;
    }

    if (!graph.has(node)) {
      return false;
    }

    // @ts-ignore
    for (let neighbour of graph.get(node).introvertedEdges) {
      if (!visited.has(neighbour)) {
        if (findNode(neighbour, searchedNode)) {
          return true;
        }
      }
    }

    visited.delete(node);

    return false;
  };

  findNode(startingNode, nodeToFind);

  return visited;
};

export const findPathBetweenNodes = (
  graph: Graph,
  startingNode: string,
  nodeToFind: string
): Set<string> => {
  const visited: Set<string> = new Set();

  if (!graph.has(startingNode) || !graph.has(nodeToFind)) {
    return visited;
  }

  // Find the shortest path
  if (graph.has(startingNode) && graph.has(nodeToFind)) {
    const startingVertex = graph.get(startingNode);
    if (startingVertex.edges.has(nodeToFind)) {
      visited.add(startingNode).add(nodeToFind);

      return visited;
    }
  }

  const findNode = (node: string, searchedNode: string) => {
    visited.add(node);
    if (node === searchedNode) {
      return true;
    }

    if (!graph.has(node)) {
      visited.delete(node);

      return false;
    }

    // @ts-ignore
    for (let neighbour of graph.get(node).edges) {
      if (!visited.has(neighbour)) {
        if (findNode(neighbour, searchedNode)) {
          return true;
        }
      }
    }

    visited.delete(node);

    return false;
  };

  findNode(startingNode, nodeToFind);

  return visited;
};

export const findLowestCommonAncestor = (
  graph: Graph,
  rootNode: string,
  firstNode: string,
  secondNode: string
) => {
  if (!graph.has(firstNode) || !graph.has(secondNode)) {
    console.warn(`One of the nodes does not exist, ${firstNode} ${secondNode}`);
    return firstNode;
  }

  const firstNodePathFromRoot = findPathBetweenNodes(
    graph,
    rootNode,
    firstNode
  );
  const pathBetweenFirstAndSecondNode = findPathBetweenNodes(
    graph,
    firstNode,
    secondNode
  );

  if (pathBetweenFirstAndSecondNode.size > 1) {
    return firstNode;
  }

  const secondNodePathFromRoot = findPathBetweenNodes(
    graph,
    rootNode,
    secondNode
  );

  return Array.from(firstNodePathFromRoot)
    .reverse()
    .find((node) => secondNodePathFromRoot.has(node));
};

export const findIdOfChild = (
  graph: Graph,
  startingNode: string,
  originalPath: string[]
) => {
  const drillDown = (node: string, path: string[]): string[] => {
    if (!graph.has(node)) {
      return [node];
    }

    const vertex = graph.get(node);

    if (path.length === 0) {
      return [node];
    }

    if (path[0] === vertex.name) {
      path.shift();
    }

    const nodeName = path.shift();

    const nodesToCheck = Array.from(vertex.edges).filter(
      (nodeId) => graph.has(nodeId) && graph.get(nodeId).name === nodeName
    );

    if (nodesToCheck.length > 1) {
      return nodesToCheck;
    }

    return drillDown(nodesToCheck.pop() || "", path);
  };

  return drillDown(startingNode, originalPath);
};

export const formGraph: Graph = new Map();
export const entriesGraph: Graph = new Map();
