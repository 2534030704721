import {
  DIALOG_OPEN,
  DIALOG_CLOSE,
  REQUEST,
  PDF_URL,
  SET_CUSTOM_EXPORTS,
  EXPORTING,
} from "../actions/export";
import { ExportState, FSA, ExportTarget } from "../constants";

const initialState = {
  dialogOpen: false,
  form: "",
  entryId: "",
  exportingPdf: false,
  pdfDownloadUrl: "",
  customExports: {},
};

export default (state: ExportState = initialState, { type, payload }: FSA) => {
  switch (type) {
    case DIALOG_OPEN:
      return {
        ...state,
        dialogOpen: true,
        form: payload.form,
        entryId: payload.entryId,
        exportingPdf: false,
        pdfDownloadUrl: "",
      };
    case DIALOG_CLOSE:
      return {
        ...state,
        dialogOpen: false,
        exportingPdf: false,
        pdfDownloadUrl: "",
      };
    case REQUEST:
      return {
        ...state,
        exportingPdf: payload.targetFormat === ExportTarget.PDF,
        pdfDownloadUrl: "",
      };
    case EXPORTING:
      return {
        ...state,
        exportingPdf: true,
        pdfDownloadUrl: "",
      };
    case PDF_URL:
      return {
        ...state,
        exportingPdf: false,
        pdfDownloadUrl: payload,
      };
    case SET_CUSTOM_EXPORTS:
      return {
        ...state,
        customExports: { ...state.customExports, ...payload },
      };
    default:
      return state;
  }
};
