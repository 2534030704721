import { FSA, Company } from '../constants';

export const FETCH_ALL = 'COMPANIES_FETCH_ALL';
export const fetchAll = (): FSA => ({ type: FETCH_ALL });

export const FETCH = 'COMPANIES_FETCH';
export const fetch = (companyId: string): FSA => ({ type: FETCH, payload: { companyId } });

export const FETCHED = 'COMPANIES_FETCHED';
export const fetched = (companies: Record<string, Company>): FSA => ({ type: FETCHED, payload: companies });

export const SYNC_FORMS = 'COMPANIES_SYNC_FORMS';
export const syncForms = ({ syncSource, id }: { syncSource: string, id: string }): FSA => ({
    type: SYNC_FORMS,
    payload: { sourceCompany: syncSource, destinationCompany: id },
});

export const SYNC_EXPORTS = 'COMPANIES_SYNC_EXPORTS';
export const syncExports = ({ syncSource, id }: { syncSource: string, id: string }): FSA => ({
    type: SYNC_EXPORTS,
    payload: { sourceCompany: syncSource, destinationCompany: id },
});
