import React from "react";
// @ts-ignore
import { useSelector } from "react-redux";

import { getErrorMessage } from "./Error";
import { convertDisplayFormat } from "../../selectors/displayFormat";

import { theme } from "../../styles";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { State } from "../../constants";
import { getLabel } from "../../utility/helpers";

const useStyles = makeStyles(() => ({
  input: theme.input,
}));

interface TextareaProps {
  options: any[];
  name: string;
  handleChange: any;
  value?: string;
  readonly?: boolean;
  errors?: any[];
  disableUnderline: boolean;
  form: string;
}

const Textarea = ({
  options,
  name,
  handleChange,
  value = "",
  readonly = false,
  errors = [],
  disableUnderline = true,
  form,
}: TextareaProps) => {
  const classes = useStyles();
  const { placeholder } = options[0];
  const selectorArgs = { displayFormat: placeholder, currentNode: form };
  const placeholderValue = useSelector((state: State) =>
    convertDisplayFormat(state, selectorArgs)
  );
  const label = getLabel(options);

  return (
    <TextField
      className={classes.input}
      id={name}
      name={name}
      label={label}
      style={{ marginTop: 8 }}
      fullWidth
      margin="normal"
      value={value}
      onChange={handleChange}
      disabled={readonly}
      InputLabelProps={{ shrink: true }}
      InputProps={{ disableUnderline }}
      multiline
      placeholder={placeholderValue}
      // @ts-ignore
      helperText={
        errors.length > 0 && getErrorMessage(errors[0].type, errors[0].message)
      }
      error={errors.length > 0}
    />
  );
};

export default Textarea;
