import { FormType, RuleType } from "../constants";

export default {
    displayName: 'Form',
    name: 'rule',
    latest: 'default',
    type: FormType.FORM,
    versions: {
        default: {
            elements: [{
                name: 'type',
                options: [{
                    label: 'Type',
                    placeholder: '-- Choose the type --',
                    elements: [
                        { value: RuleType.AFTER_SAVE, label: 'Apply after save' },
                        { value: RuleType.UPON_CREATION, label: 'Upon creation' },
                        { value: RuleType.LIST_ITEM_CREATION, label: 'List item creation' },
                    ],
                }],
                required: true,
                sequence: 0,
                type: 'select',
                unique: false,
            }, {
                name: 'source',
                options: [{
                    label: 'Source form',
                    placeholder: '-- Select the source --',
                    displayFormat: '[displayName] ([name], [type])',
                    source: 'formBuilderFormMeta',
                    filters: [{ key: 'type', value: FormType.FORM }],
                }],
                required: true,
                sequence: 1,
                type: 'crossSelect',
                unique: false,
            }, {
                // @todo: display the fields from the selected form in a dropdown
                name: 'sourceField',
                options: [{ label: 'Source field name' }],
                required: false,
                sequence: 2,
                type: 'text',
                unique: false,
            }, {
                name: 'destination',
                options: [{
                    label: 'Destination form',
                    placeholder: '-- Select the destination --',
                    displayFormat: '[displayName] ([name], [type])',
                    source: 'formBuilderFormMeta',
                    filters: [{ key: 'type', value: FormType.FORM }],
                }],
                required: true,
                sequence: 3,
                type: 'crossSelect',
                unique: false,
            }, {
                // @todo: display the fields from the selected form in a dropdown
                name: 'destinationField',
                options: [{ label: 'Destination field name' }],
                required: false,
                sequence: 4,
                type: 'text',
                unique: false,
            }, {
                name: 'listField',
                options: [{ label: 'Name of the list of subforms field' }],
                required: false,
                sequence: 5,
                type: 'text',
                unique: false,
                conditions: [{
                    source: 'type',
                    type: 'equals',
                    values: RuleType.LIST_ITEM_CREATION,
                }],
            },
            {
                hasValue: false,
                hasValueOnClose: false,
                ignoreClosedUnique: false,
                name: 'ruleBuilderValidations',

                options: [{
                    addition: 'Add validation rule',
                    elements: [{
                        name: 'ruleBuilderValidation',
                        type: FormType.FORM,
                    }],
                    label: 'Validations',
                }],

                required: false,
                sequence: 6,
                type: 'inlineList',
                unique: false,
            }],
        },
    },
};
