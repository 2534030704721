import { useReducer, useState } from 'react';
import { deleteIn } from '../../utility/helpers.ts';

export const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'set':
            if (payload.key.indexOf('.') !== -1) {
                const [listName, index, fieldName] = payload.key.split('.');
                let listEntries = [];

                if (!fieldName) {
                    listEntries = [...state[listName]];
                    listEntries.splice(index, 1);
                } else if (!state[listName]) {
                    listEntries = [{ [fieldName]: { value: payload.value } }];
                } else if (!state[listName][parseInt(index, 10)]) {
                    listEntries = [...state[listName], { [fieldName]: { value: payload.value } }];
                } else {
                    listEntries = [...state[listName]];
                    listEntries[parseInt(index, 10)][fieldName] = { value: payload.value };
                }

                return { ...state, [listName]: listEntries };
            }
            return { ...state, [payload.key]: { ...state[payload.key], value: payload.value } };
        case 'reset':
            return { ...payload };
        case 'partialReset':
            return deleteIn(state, ...payload);
        default:
            return state;
    }
};

const useForm = (callback, values = {}, entryId) => {
    const [state, dispatch] = useReducer(reducer, values);
    const [currentEntryId, setCurrentEntryId] = useState(entryId);

    if (currentEntryId !== entryId) {
        setCurrentEntryId(entryId);
        dispatch({ type: 'reset', payload: values });
    }

    const handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        callback();
    };

    const handleChange = (event) => {
        if (event.persist) {
            event.persist();
        }
        dispatch({ type: 'set', payload: { key: event.target.name, value: event.target.value } });
    };

    const handleReset = (initialState, silent = false) => {
        if (silent) {
            return dispatch({ type: 'reset', payload: initialState || values });
        }

        const confirm = window.confirm('Weet u zeker dat u de wijzigingen in dit formulier wilt wissen en alle gekoppelde documenten wilt verwijderen? Dit kan niet worden herstelt!');
        if (confirm) {
            dispatch({ type: 'reset', payload: values });
        }
    };

    return {
        handleChange,
        handleSubmit,
        handleReset,
        state,
    }
};

export default useForm;
