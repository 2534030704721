import { createSelector } from "reselect";
import { State, Roles, InputTypes } from "../constants";
import { getElementsByForm } from "./forms";
import { convertDisplayFormat } from "./drawerContainer";
import { getRole } from "./user";
import { Conditions } from "../conditionConstants";
import { getReferencedFormName } from "../utility/helpers";

const makeGetValue = (state: State, form: string) => {
  return (handle: string, values: any) => {
    if (!values || !handle) {
      return "";
    }

    if (handle.includes("[")) {
      return convertDisplayFormat(state, form, handle)(values, true);
    }

    return values[handle] && values[handle].value;
  };
};

const makeGetCloseStatus = (state: State) => {
  return (handle: string, values: string | string[]) => {
    if (!values || !handle) {
      return false;
    }

    let id;

    if (typeof values !== "string") {
      id = values[0];
    } else {
      id = values;
    }

    if (!state.entries[handle] || !state.entries[handle][id]) {
      return false;
    }

    return state.entries[handle][id].closed;
  };
};

const getReferencedAccValueOrTrue = (
  acc: Record<string, boolean>,
  reference: string
) => {
  if (typeof acc[reference] === "undefined") {
    return true;
  }

  return acc[reference];
};

const hasNoValueOrIsInvalid = (values: any, reference: string, value: any) => {
  if (!value) {
    return true;
  }

  if (!values[reference]) {
    return true;
  }

  if (!values[reference].valid) {
    return true;
  }

  return false;
};

const hasNoValueOrIsInvalidOrUnclosed = (
  values: any,
  reference: string,
  value: any,
  closed?: number
) => {
  if (hasNoValueOrIsInvalid(values, reference, value)) {
    return true;
  }

  if (!closed) {
    return true;
  }

  return false;
};

export const getConditionStatus = createSelector(
  getElementsByForm,
  getRole,
  // @todo: type this!
  (_: State, { values, form }: any) => ({ values, form }),
  (state: State) => state,
  (elements, role, { values, form }, state) => {
    const getValue = makeGetValue(state, form);
    const getClosedStatus = makeGetCloseStatus(state);

    const elementsWithConditions = elements.filter(
      ({ conditions }: any) => conditions && conditions.length > 0
    );
    const referencedFormTypes = [
      InputTypes.LIST,
      InputTypes.FORM,
      InputTypes.INLINE_LIST,
      InputTypes.MENU,
    ];

    return elementsWithConditions
      .sort((firstElement) => {
        const hasNoConditionalDependency = firstElement.conditions!.some(
          (condition) => {
            if (
              ![Conditions.VALID, Conditions.VALID_AND_CLOSED].includes(
                condition.type
              )
            ) {
              return false;
            }

            return elementsWithConditions.find(
              ({ name }) => name === condition.source
            );
          }
        );

        return hasNoConditionalDependency ? 1 : -1;
      })
      .reduce((acc: any, { conditions, name }: any) => {
        acc[name] = !conditions.some((condition: any) => {
          const sourceElement = elements.find(
            ({ name }) => name === condition.source
          );
          let referencedValueHandle = condition.source;

          if (
            sourceElement &&
            referencedFormTypes.includes(sourceElement.type)
          ) {
            [referencedValueHandle] = getReferencedFormName(sourceElement);
          }

          const value = getValue(referencedValueHandle, values);

          switch (condition.type) {
            case Conditions.EQUALS: {
              if (!value && condition.values === "false") {
                return false;
              }

              return !condition.values.split(",").includes(value);
            }
            case Conditions.NOT_EQUALS: {
              if (!value && condition.values === "false") {
                return true;
              }

              return condition.values.split(",").includes(value);
            }
            case Conditions.VALID: {
              if (
                hasNoValueOrIsInvalid(values, referencedValueHandle, value) &&
                getReferencedAccValueOrTrue(acc, referencedValueHandle)
              ) {
                return true;
              }

              return false;
            }
            case Conditions.VALID_AND_CLOSED: {
              const closed = getClosedStatus(referencedValueHandle, value);
              if (
                hasNoValueOrIsInvalidOrUnclosed(
                  values,
                  referencedValueHandle,
                  value,
                  closed
                ) &&
                getReferencedAccValueOrTrue(acc, referencedValueHandle)
              ) {
                return true;
              }

              return false;
            }
            case Conditions.HAS_ROLE: {
              if (role === Roles.SUPERUSER) {
                return false;
              }
              return !condition.roles.includes(role);
            }
            default:
            // no default
          }
        });

        return acc;
      }, {});
  }
);
