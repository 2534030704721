import { createSelector } from "reselect";
import { format } from "date-fns";
import {
  State,
  EntriesState,
  FormsState,
  InputTypes,
  InputElement,
  FormType,
} from "../constants";
import {
  entriesGraph,
  findPathBetweenNodes,
  formGraph,
  findPathBetweenNodesBottomUp,
} from "../graph";
import { getInlineVariableKeys, stripPrefix } from "../utility/helpers";
import { getAll as getAllEntries } from "./entries";
import { getAll as getAllForms, getFormElements } from "./forms";
import {
  convertValueToNumber,
  ValueType,
  arithmetic,
  convertMinutesToHours,
} from "../utility/arithmetic";

const generateDeriveFormAndEntryId = (form: string, entryId: string) => (
  handle: string
): string[] => {
  const [field, ...path] = handle.split(".").reverse();

  if (path.length > 0) {
    const [nextForm, ...pathParts] = path.reverse();

    if (nextForm === form) {
      return [form, entryId, field];
    }

    if (!entriesGraph.has(entryId)) {
      return [form, entryId, field];
    }

    const referencedEntryIds = getEntryIdsByForm(entryId, nextForm);
    if (referencedEntryIds.length > 0) {
      const remainingHandle = [...pathParts, field].join(".");
      return generateDeriveFormAndEntryId(
        nextForm,
        referencedEntryIds[0]
      )(remainingHandle);
    } else {
      // we should just use the first entryId of the target form we encounter
      const firstNode = Array.from(entriesGraph).find(
        ([, { name }]) => name === nextForm
      );

      if (firstNode) {
        return [nextForm, firstNode[0], field];
      }
    }
  }

  return [form, entryId, field];
};

const getEntry = (entries: EntriesState, form: string, entryId: string) => {
  if (!entries[form] || !entries[form][entryId]) {
    return;
  }

  return entries[form][entryId];
};

const getElement = ({ forms }: FormsState, form: string, field: string) =>
  getFormElements(forms, form).find(({ name }) => name === field);

const getFieldValue = (entry: any, field: string) => {
  if (entry[field] && entry[field].value) {
    return entry[field].value;
  }

  return entry[field];
};

// @todo: move the individual formatters in component specific files
const valueFormatter = {
  [InputTypes.SELECT]: (value: string, element: InputElement) => {
    // @ts-ignore
    const option = element.options[0].elements.find(
      (opt: any) => opt.value === value
    );

    if (option) {
      // @ts-ignore
      return option.label;
    }

    return value;
  },
  [InputTypes.DATE]: (value: number = 0) =>
    format(new Date(value), "dd-MM-yyyy"),
  [InputTypes.DATE_TIME]: (value: number) =>
    format(new Date(value), "dd-MM-yyyy HH:mm"),
  [InputTypes.FILE]: ({ value }: { value: string }) => value,
  [InputTypes.CHECKBOX]: (value: string, element: InputElement) =>
    // @ts-ignore
    value === "true" ? `<br />${element.options[0].label}` : "",
  [InputTypes.RADIO]: (value: string, element: InputElement) => {
    // @ts-ignore
    const option = element.options[0].elements.find(
      (opt: any) => opt.value === value
    );

    if (option) {
      // @ts-ignore
      return option.label;
    }

    return value;
  },
};

const generateSelectValue = (forms: FormsState, entries: EntriesState) => (
  form: string,
  entryId: string,
  field: string,
  raw = false
) => {
  const entry = getEntry(entries, form, entryId);
  if (entry) {
    const element = getElement(forms, form, field);
    const value = getFieldValue(entry, field);

    // @ts-ignore
    if (element && valueFormatter[element.type] && !raw) {
      // @ts-ignore
      return valueFormatter[element.type](value, element);
    }

    return value;
  }

  return "";
};

const replaceVariablesInDisplayFormat = (
  displayFormat: string,
  deriveFormAndEntryId: Function,
  selectValue: Function,
  entryId: string
): string => {
  const variablesToReplace = getInlineVariableKeys(displayFormat);
  if (!variablesToReplace) {
    return displayFormat;
  }

  const valuesToSelect = variablesToReplace.map((handle: string) => [
    handle,
    ...deriveFormAndEntryId(stripPrefix(handle)),
  ]);

  return valuesToSelect.reduce(
    (acc, [handle, sourceForm, sourceEntryId, field]) => {
      const selectedValue = selectValue(sourceForm, sourceEntryId, field);
      return acc.replace(
        `[${handle}]`,
        replaceVariablesInDisplayFormat(
          selectedValue,
          deriveFormAndEntryId,
          selectValue,
          entryId
        )
      );
    },
    displayFormat
  );
};

const replaceInlineListVariablesInDisplayFormat = (
  displayFormat: string,
  deriveFormAndEntryId: Function,
  selectValue: Function,
  entryId: string,
  inlineList: string,
  inlineListItem: Record<string, any>
) => {
  const variablesToReplace = getInlineVariableKeys(displayFormat);
  if (!variablesToReplace) {
    return displayFormat;
  }

  const valuesToSelect = variablesToReplace
    .filter((variable) => variable.startsWith(inlineList))
    .map((handle) => {
      const [field, ...path] = handle.split(".").reverse();
      // we try to select a deeper value
      if (path.length > 0) {
        const [, nextForm, ...remainingPath] = path.reverse();
        const referencedEntryValue = inlineListItem[nextForm];
        if (referencedEntryValue) {
          const { value } = referencedEntryValue;
          const deriveFormAndEntryIdFromInlineList = generateDeriveFormAndEntryId(
            nextForm,
            value
          );
          return [
            handle,
            ...deriveFormAndEntryIdFromInlineList(
              [...remainingPath, field].join(".")
            ),
          ];
        }
      }

      let value = inlineListItem[field];
      if (handle.includes("-")) {
        const [a, b] = handle.split("-");
        const derivedA = inlineListItem[a.split(".")[1]];
        const derivedB = inlineListItem[b.split(".")[1]];
        if (!derivedA || !derivedB) {
          const { value } = derivedA || derivedB;
          if (value.includes(":")) {
            return [handle, "00:00"];
          }

          return [handle, 0];
        } else {
          const subtracted = arithmetic["-"](derivedA.value, derivedB.value);
          return [handle, subtracted];
        }
      }
      return [handle, value && value.value];
    });

  const augmentedDisplayFormat = valuesToSelect.reduce(
    (acc, [handle, sourceForm, sourceEntryId, field]) => {
      // if the value comes directly from the inlineList
      if (!sourceEntryId) {
        return acc.replace(`[${handle}]`, sourceForm);
      }

      const selectedValue = selectValue(sourceForm, sourceEntryId, field);
      return acc.replace(
        `[${handle}]`,
        replaceVariablesInDisplayFormat(
          selectedValue,
          deriveFormAndEntryId,
          selectValue,
          entryId
        )
      );
    },
    displayFormat
  );

  return replaceVariablesInDisplayFormat(
    augmentedDisplayFormat,
    deriveFormAndEntryId,
    selectValue,
    entryId
  );
};

// @TODO: move into helpers?
function removeMenusFromPath(path: Set<string>) {
  return Array.from(path).filter((pathItem) => {
    if (!formGraph.has(pathItem)) {
      return false;
    }
    if (formGraph.get(pathItem).type !== FormType.MENU) {
      return true;
    }
    return false;
  });
}

// @TODO: move into helpers?
function filterEdgesByFormName(form: string) {
  return (edgeId: string) => {
    if (!entriesGraph.has(edgeId)) {
      return false;
    }
    if (entriesGraph.get(edgeId).name === form) {
      return true;
    }
    return false;
  };
}

// @TODO: move into helpers?
function getInverseReferencedEntryIdsByPath(
  [form, ...remainingPath]: string[],
  sourceEntryId: string
): string[] {
  if (!entriesGraph.has(sourceEntryId)) {
    return [];
  }

  const { introvertedEdges } = entriesGraph.get(sourceEntryId);
  if (remainingPath.length === 0) {
    return Array.from(introvertedEdges).filter(filterEdgesByFormName(form));
  }

  return Array.from(introvertedEdges)
    .filter(filterEdgesByFormName(form))
    .map((edgeId) => getInverseReferencedEntryIdsByPath(remainingPath, edgeId))
    .flat();
}

// @TODO: move into helpers?
function getReferencedEntryIdsByPath(
  [form, ...remainingPath]: string[],
  sourceEntryId: string
): string[] {
  if (!entriesGraph.has(sourceEntryId)) {
    return [];
  }

  const { edges } = entriesGraph.get(sourceEntryId);
  if (remainingPath.length === 0) {
    return Array.from(edges).filter(filterEdgesByFormName(form));
  }

  return Array.from(edges)
    .filter(filterEdgesByFormName(form))
    .map((edgeId) => getReferencedEntryIdsByPath(remainingPath, edgeId))
    .flat();
}

// @TODO: move into helpers?
function getEntryIdsByForm(entryId: string, form: string) {
  if (!entriesGraph.has(entryId)) {
    return [];
  }

  const entryNode = entriesGraph.get(entryId);
  const pathBetweenCurrentEntryAndTarget = findPathBetweenNodes(
    formGraph,
    entryNode.name!,
    form
  );

  if (pathBetweenCurrentEntryAndTarget.size == 0) {
    const inversePath = findPathBetweenNodesBottomUp(
      formGraph,
      entryNode.name!,
      form
    );
    if (inversePath) {
      const cleanedPath = removeMenusFromPath(inversePath)
        // remove the starting form to prevent self reference lookup
        .filter((referencedForm) => referencedForm !== entryNode.name);

      return getInverseReferencedEntryIdsByPath(cleanedPath, entryId);
    }
  }

  const cleanedPath = removeMenusFromPath(pathBetweenCurrentEntryAndTarget)
    // remove the starting form to prevent self reference lookup
    .filter((referencedForm) => referencedForm !== entryNode.name);

  return getReferencedEntryIdsByPath(cleanedPath, entryId);
}

// @TODO: move into seperate files
function generateComputeSum(entryId: string, selectValue: any) {
  return function computeSum(args: string) {
    const [sourceForm, field, valueType] = args
      .replace("$.SUM(", "")
      .replace(")", "")
      .split(",");

    const entryIds = getEntryIdsByForm(entryId, sourceForm);
    const valuesToSum = entryIds.map((sourceEntryId: string) => {
      const value = selectValue(sourceForm, sourceEntryId, field);
      return convertValueToNumber(value, valueType as ValueType);
    });

    if (valueType === ValueType.TIME) {
      const sumedTime = valuesToSum.reduce(
        (acc: any, [hours, minutes]: any) => {
          acc[0] += hours;
          acc[1] += minutes;
          return acc;
        },
        [0, 0]
      );

      const convertedMinutes = convertMinutesToHours(
        sumedTime[0],
        sumedTime[1]
      );

      return `${convertedMinutes[0]}:${convertedMinutes[1]}`;
    } else {
      return valuesToSum.reduce((acc: any, value: any) => {
        return acc + value;
      }, 0);
    }
  };
}

function filterValues(
  entryId: string,
  form: string,
  selectValue: any,
  values: any[],
  filter: string
) {
  if (!filter) {
    return values;
  }

  const selectRawValueWrapper = (
    form: string,
    entryId: string,
    field: string
  ) => selectValue(form, entryId, field, true);

  const getFilterData = generateGetLoopData(entryId, selectRawValueWrapper);
  const filters = filter.replace("(", "").replace(")", "").split("~");
  const fields = filters.map((filterString: string) => {
    const [field] = filterString.split("=");
    return `[${field}]`;
  });
  const filterValues = getFilterData(form, fields.join("|"));
  const filteredValues = values.filter((_: any, idx: number) => {
    return filters.every((filter: string, index: number) => {
      const [, condition] = filter.split("=");
      const filterValue = filterValues[idx][index];

      if (condition.includes("[")) {
        const referencedEntryIds = getEntryIdsByForm(
          entryId,
          "assignedEmployee"
        );
        return referencedEntryIds.includes(filterValue);
      }

      if (condition.includes("BETWEEN")) {
        const [, start, end] = condition.split("|");
        const value = parseInt(filterValue, 10);
        return value >= parseInt(start, 10) && value <= parseInt(end, 10);
      }

      return condition.includes(filterValue);
    });
  });
  return filteredValues;
}

function generateComputeEntryValueSum(entryId: string, selectValue: any) {
  const getLoopData = generateGetLoopData(entryId, selectValue);
  return function computeSum(args: string) {
    const [sourceForm, value, valueType, filter] = args
      .replace("$.SUM_ENTRY_VALUE(", "")
      .replace(")", "")
      .split(",");

    const valuesToSum = getLoopData(sourceForm, `(${value})`);
    const filteredValuesToSum = filterValues(
      entryId,
      sourceForm,
      selectValue,
      valuesToSum,
      filter
    );

    if (valueType === ValueType.TIME) {
      const sumedTime = filteredValuesToSum
        // @ts-ignore
        .map(([time]) => time.split(":"))
        .reduce(
          (acc: any, [hours, minutes]: any) => {
            acc[0] += parseInt(hours, 10);
            acc[1] += parseInt(minutes, 10);
            return acc;
          },
          [0, 0]
        );

      const convertedMinutes = convertMinutesToHours(
        sumedTime[0],
        sumedTime[1]
      );

      return `${convertedMinutes[0]}:${convertedMinutes[1]
        .toString()
        .padStart(2, "0")}`;
    } else {
      return filteredValuesToSum.reduce((acc: any, value: any) => {
        return acc + value;
      }, 0);
    }
  };
}

// @TODO: move into seperate file
function generateComputeMin(entryId: string, selectValue: any) {
  const getLoopData = generateGetLoopData(entryId, selectValue);
  return function min(args: string) {
    const [sourceForm, value, filter] = args
      .replace("$.MIN(", "")
      .replace(")", "")
      .split(",");

    const values = getLoopData(sourceForm, `(${value})`);
    const filteredValues = filterValues(
      entryId,
      sourceForm,
      selectValue,
      values,
      filter
    );
    const uniqueValues = new Set(filteredValues.flat());

    if (uniqueValues.size === 0) {
      return "";
    }

    const uniqueValuesArr = Array.from(uniqueValues);

    // if we are dealing with dates
    if (uniqueValuesArr[0] && uniqueValuesArr[0].includes("-")) {
      // sort by date format dd-mm-yyyy
      return Array.from(uniqueValues).sort((a, b) => {
        const aa = a.split("-");
        const bb = b.split("-");
        return aa[2] - bb[2] || aa[1] - bb[1] || aa[0] - bb[0];
      })[0];
    }

    return uniqueValuesArr.sort()[0];
  };
}

// @TODO: move into seperate file
function generateComputeMax(entryId: string, selectValue: any) {
  const getLoopData = generateGetLoopData(entryId, selectValue);
  return function max(args: string) {
    const [sourceForm, value, filter] = args
      .replace("$.MAX(", "")
      .replace(")", "")
      .split(",");

    const values = getLoopData(sourceForm, `(${value})`);
    const filteredValues = filterValues(
      entryId,
      sourceForm,
      selectValue,
      values,
      filter
    );
    const uniqueValues = new Set(filteredValues.flat());

    if (uniqueValues.size === 0) {
      return "";
    }

    const uniqueValuesArr = Array.from(uniqueValues);

    // if we are dealing with dates
    if (uniqueValuesArr[0] && uniqueValuesArr[0].includes("-")) {
      // sort by date format dd-mm-yyyy
      return Array.from(uniqueValues).sort((a, b) => {
        const aa = a.split("-");
        const bb = b.split("-");
        return aa[2] - bb[2] || aa[1] - bb[1] || aa[0] - bb[0];
      }).reverse()[0];
    }

    return uniqueValuesArr.sort().reverse()[0];
  };
}

// @TODO: move into seperate file
function generateComputeCount(entryId: string, selectValue: any) {
  const getLoopData = generateGetLoopData(entryId, selectValue);
  return function count(args: string) {
    const [sourceForm, value, filter] = args
      .replace("$.COUNT(", "")
      .replace(")", "")
      .split(",");

    const valuesToCount = getLoopData(sourceForm, `(${value})`);
    const filteredValuesToCount = filterValues(
      entryId,
      sourceForm,
      selectValue,
      valuesToCount,
      filter
    );
    const uniqueValues = new Set(filteredValuesToCount.flat());
    return uniqueValues.size;
  };
}

// @TODO: implement a table generator helper
function generateHeaders(headers: string[]) {
  if (headers.length === 0) {
    return "";
  }

  const columns = headers.map((header) => `<td>${header}</td>`);
  return `<thead><tr>${columns.join("")}</tr></thead>`;
}

function generateRow(values: string[]) {
  const columns = values.map((value) => `<td>${value}</td>`);
  return `<tr>${columns.join("")}</tr>`;
}

function getSeperateKeys(keyString: string) {
  return keyString.replace("(", "").replace(")", "").split("|");
}

function generateGetLoopData(entryId: string, selectValue: any) {
  return function (sourceForm: string, valueString: string) {
    const rowPlaceholders = getSeperateKeys(valueString);
    const [form, inlineList] = sourceForm.split(".");
    const entryIds = getEntryIdsByForm(entryId, form);
    const rowData: string[][] = [];
    entryIds.forEach((sourceEntryId: string) => {
      const deriveFormAndEntryId = generateDeriveFormAndEntryId(
        form,
        sourceEntryId
      );

      if (!inlineList) {
        return rowData.push(
          rowPlaceholders.map((displayFormat: string) => {
            return replaceVariablesInDisplayFormat(
              displayFormat,
              deriveFormAndEntryId,
              selectValue,
              entryId
            );
          })
        );
      }

      const inlineListItems = Object.values(
        selectValue(form, sourceEntryId, inlineList)
      );
      inlineListItems.map((inlineListItem) => {
        return rowData.push(
          rowPlaceholders.map((displayFormat: string) => {
            return replaceInlineListVariablesInDisplayFormat(
              displayFormat,
              deriveFormAndEntryId,
              selectValue,
              entryId,
              inlineList,
              // @ts-ignore
              inlineListItem
            );
          })
        );
      });
    });

    return rowData;
  };
}

function generateComputeLoopTable(entryId: string, selectValue: any) {
  const getLoopData = generateGetLoopData(entryId, selectValue);
  return function (args: string) {
    const [sourceForm, headerString, valueString, filter] = args
      .replace("$.LOOP@", "")
      .replace("@", "")
      .split(",");

    const rowData = getLoopData(sourceForm, valueString);
    const filteredRowData = filterValues(
      entryId,
      sourceForm,
      selectValue,
      rowData,
      filter
    );
    const headers = getSeperateKeys(headerString);

    return `<table>${generateHeaders(headers)}${filteredRowData
      .map(generateRow)
      .join("")}</table>`;
  };
}

function computeSpecialValuesInDisplayFormat(
  { entryId }: { form: string; entryId: string },
  displayFormat: any,
  selectValue: any
) {
  if (!displayFormat.includes("$.")) {
    return displayFormat;
  }

  if (displayFormat.includes("$.MIN")) {
    const matches = displayFormat.match(/(\$\.MIN\(.*\))/g);
    const computeMin = generateComputeMin(entryId, selectValue);
    const computedValues = matches
      .map((match: string) => [match, computeMin(match)])
      .reduce(
        (acc: string, [match, count]: [string, string]) =>
          acc.replace(match, count),
        displayFormat
      );
    return computedValues;
  }

  if (displayFormat.includes("$.MAX")) {
    const matches = displayFormat.match(/(\$\.MAX\(.*\))/g);
    const computeMax = generateComputeMax(entryId, selectValue);
    const computedValues = matches
      .map((match: string) => [match, computeMax(match)])
      .reduce(
        (acc: string, [match, count]: [string, string]) =>
          acc.replace(match, count),
        displayFormat
      );
    return computedValues;
  }

  if (displayFormat.includes("$.COUNT")) {
    const matches = displayFormat.match(/(\$\.COUNT\(.*\))/g);
    const computeCount = generateComputeCount(entryId, selectValue);
    const computedValues = matches
      .map((match: string) => [match, computeCount(match)])
      .reduce(
        (acc: string, [match, count]: [string, string]) =>
          acc.replace(match, count),
        displayFormat
      );
    return computedValues;
  }

  if (displayFormat.includes("$.SUM(")) {
    const matches = displayFormat.match(/(\$\.SUM\(.*\))/g);
    const computeSum = generateComputeSum(entryId, selectValue);
    const computedValues = matches
      .map((match: string) => [match, computeSum(match)])
      .reduce(
        (acc: string, [match, computedSum]: [string, string]) =>
          acc.replace(match, computedSum),
        displayFormat
      );
    return computedValues;
  }

  if (displayFormat.includes("$.SUM_ENTRY_VALUE")) {
    const matches = displayFormat.match(/(\$\.SUM_ENTRY_VALUE\(.*\))/g);
    const computeSum = generateComputeEntryValueSum(entryId, selectValue);
    const computedValues = matches
      .map((match: string) => [match, computeSum(match)])
      .reduce(
        (acc: string, [match, computedSum]: [string, string]) =>
          acc.replace(match, computedSum),
        displayFormat
      );
    return computedValues;
  }

  if (displayFormat.includes("$.LOOP")) {
    const matches = displayFormat.match(/(\$\.LOOP@.*@)/g);
    const computeLoopTable = generateComputeLoopTable(entryId, selectValue);
    const computedValues = matches
      .map((match: string) => [match, computeLoopTable(match)])
      .reduce(
        (acc: string, [match, loopTable]: [string, string]) =>
          acc.replace(match, loopTable),
        displayFormat
      );
    return computedValues;
  }

  return displayFormat;
}

interface ByFormEntryIdAndDisplayFormat {
  form: string;
  entryId: string;
  displayFormat: string;
}

export const convertDisplayFormatByFormAndEntryId = createSelector(
  getAllEntries,
  getAllForms,
  (
    _: State,
    { form, entryId, displayFormat }: ByFormEntryIdAndDisplayFormat
  ) => ({ form, entryId, displayFormat }),
  (entries, forms, { form, entryId, displayFormat }) => {
    const deriveFormAndEntryId = generateDeriveFormAndEntryId(form, entryId);
    const selectValue = generateSelectValue(forms, entries);
    const augmentedDisplayFormat = computeSpecialValuesInDisplayFormat(
      { form, entryId },
      displayFormat,
      selectValue
    );

    return replaceVariablesInDisplayFormat(
      augmentedDisplayFormat,
      deriveFormAndEntryId,
      selectValue,
      entryId
    );
  }
);
