import { DashboardConfiguration } from "../constants";

export const SET_DASHBOARD_DATA = "DASHBOARD_SET_DATA";
export const setDashboardData = (
  config: DashboardConfiguration,
  template: string
) => ({
  type: SET_DASHBOARD_DATA,
  payload: { config, template },
});
